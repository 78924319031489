import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { cost_calculation, cost_calculation_nrm, trial_name } from "../../../recoil/atom";
import {
  costCalculation, costCalculationNRM
} from '../../../recoil/selector'

const CostCalculation = () => {
  const [CostCalculation, setCostCalculation] = useRecoilState(cost_calculation)
  const [CostCalculationNRM, setCostCalculationNRM] =
    useRecoilState(cost_calculation_nrm)

  //calculation

  const costCalculatedvalues = useRecoilValue(costCalculation)
  const costCalculatedvaluesNRM = useRecoilValue(costCalculationNRM)
  console.log(costCalculatedvalues, 'costCalculatedvalues')
  console.log(costCalculatedvaluesNRM, 'costCalculatedvaluesNRM')

  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'

  const handleCostCalculationsChange = (name, value) => {
    setCostCalculation((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleCostCalculationsNRMChange = (name, value) => {
    setCostCalculationNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))


  return (
    <div
      style={{ height: "320px" }}
      className="mt-4 w-full overflow-y-auto pb-4 pr-4"
    >
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Cost Calculations
      </h2>
      <div className="mt-4">
        <div className="w-full">
          <label
            htmlFor="avg_in_heat_size"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average heat size in a campaign (MT)
          </label>
          <input
            type="text"
            id="avg_in_heat_size"
            name="avg_in_heat_size"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvalues.averageHeatSize}
            onChange={(event) =>
              handleCostCalculationsChange('avg_in_heat_size', event.target.value)
            }
          />
        </div>

        <div className="w-full">
          <label
            htmlFor="avg_life"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average life in a campaign (Heats)
          </label>
          <input
            type="text"
            id="avg_life"
            name="avg_life"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvalues.averageLife}
            onChange={(event) =>
              handleCostCalculationsChange('avg_life', event.target.value)
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="avg_life"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total No of cycle in a campaign (each)
          </label>
          <input
            type="text"
            id="avg_life"
            name="avg_life"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvalues.averageLife}
            onChange={(event) =>
              handleCostCalculationsChange('avg_life', event.target.value)
            }
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="w-full">
          <label
            htmlFor="no_of_cycles"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total no of heats in a campaign (Heats)
          </label>
          <input
            type="text"
            id="no_of_cycles"
            name="no_of_cycles"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvalues.totNoOfCycle}
            onChange={(event) =>
              handleCostCalculationsChange('no_of_cycles', event.target.value)
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total Production per Campaign (MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvalues.totProduction}
            onChange={(event) =>
              handleCostCalculationsChange('tot_prod', event.target.value)
            }
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="ramming_material_landed_cost_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ramming material Landed cost - 1 (dia / MT)
        </label>
        <input
          type="text"
          id="ramming_material_landed_cost_1"
          name="ramming_material_landed_cost_1"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={CostCalculation.ramming_material_landed_cost_1}
          onChange={(event) =>
            handleCostCalculationsChange(
              'ramming_material_landed_cost_1',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="ramming_material_landed_cost_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ramming material cost In a Campaign - 1 (dia)
        </label>
        <input
          type="text"
          id="ramming_material_landed_cost_1"
          name="ramming_material_landed_cost_1"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={
            costCalculatedvalues.rammingMaterialCost_1
              ? costCalculatedvalues.rammingMaterialCost_1
              : 0
          }
          onChange={(event) =>
            handleCostCalculationsChange(
              'ramming_cost_in_campaign_1',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="customer_ref_no"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Topping material Landed cost - 2 (dia / MT)
        </label>
        <input
          type="text"
          id="customer_ref_no"
          name="customer_ref_no"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={CostCalculation.topping_material_2}
          onChange={(event) =>
            handleCostCalculationsChange(
              'topping_material_2',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="customer_ref_no"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Topping material cost in a Campaign - 2 (dia)
        </label>
        <input
          type="text"
          id="cost_in_campaign_2"
          name="cost_in_campaign_2"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvalues.toppingMaterialCost_2}
          onChange={(event) =>
            handleCostCalculationsChange(
              'cost_in_campaign_2',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="topping_material_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Topping material Landed cost - 3 (dia / MT)
        </label>
        <input
          type="text"
          id="topping_material_3"
          name="topping_material_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={CostCalculation.topping_material_3}
          onChange={(event) =>
            handleCostCalculationsChange(
              'topping_material_3',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="cost_in_campaign_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Topping material cost in a Campaign - 3 (dia)
        </label>
        <input
          type="text"
          id="cost_in_campaign_3"
          name="cost_in_campaign_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvalues.toppingMaterialCost_3}
          onChange={(event) =>
            handleCostCalculationsChange(
              'cost_in_campaign_3',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="tot_cost_in_campaign"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Total cost in a campaign (Refractory) (dia /MT)
        </label>
        <input
          type="text"
          id="tot_cost_in_campaign"
          name="tot_cost_in_campaign"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvalues.totCostCampaignRefractory}
          onChange={(event) =>
            handleCostCalculationsChange(
              'tot_cost_in_campaign',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="tot_refractory"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Total Refractory Consumption (MT)
        </label>
        <input
          type="text"
          id="tot_refractory"
          name="tot_refractory"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvalues.totRefractoryConsumption}
          onChange={(event) =>
            handleCostCalculationsChange('tot_refractory', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="spec_refractory_consumpt"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Specific Refractory Consumption (kg/T of steel) (Kg / MT)
        </label>
        <input
          type="text"
          id="spec_refractory_consumpt"
          name="spec_refractory_consumpt"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvalues.SpecificRefractoryConsumpt}
          onChange={(event) =>
            handleCostCalculationsChange(
              'spec_refractory_consumpt',
              event.target.value
            )
          }
        />
      </div>


      <div className="mt-4">
        <div className="w-full">
          <label
            htmlFor="avg_in_heat_size"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average heat size in a campaign (MT)
          </label>
          <input
            type="text"
            id="avg_in_heat_size"
            name="avg_in_heat_size"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvalues.averageHeatSize}
            onChange={(event) =>
              handleCostCalculationsChange('avg_in_heat_size', event.target.value)
            }
          />
        </div>

        <div className="w-full">
          <label
            htmlFor="avg_life"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average life in a campaign (Heats)
          </label>
          <input
            type="text"
            id="avg_life"
            name="avg_life"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvalues.averageLife}
            onChange={(event) =>
              handleCostCalculationsChange('avg_life', event.target.value)
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="avg_life"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total No of cycle in a campaign (each)
          </label>
          <input
            type="text"
            id="avg_life"
            name="avg_life"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvalues.averageLife}
            onChange={(event) =>
              handleCostCalculationsChange('avg_life', event.target.value)
            }
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="w-full">
          <label
            htmlFor="no_of_cycles"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total no of heats in a campaign (Heats)
          </label>
          <input
            type="text"
            id="no_of_cycles"
            name="no_of_cycles"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvalues.totNoOfCycle}
            onChange={(event) =>
              handleCostCalculationsChange('no_of_cycles', event.target.value)
            }
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total Production per Campaign (MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={costCalculatedvalues.totProduction}
            onChange={(event) =>
              handleCostCalculationsChange('tot_prod', event.target.value)
            }
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="ramming_material_landed_cost_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ramming material Landed cost - 1 (dia / MT)
        </label>
        <input
          type="text"
          id="ramming_material_landed_cost_1"
          name="ramming_material_landed_cost_1"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={CostCalculation.ramming_material_landed_cost_1}
          onChange={(event) =>
            handleCostCalculationsChange(
              'ramming_material_landed_cost_1',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="ramming_material_landed_cost_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ramming material cost In a Campaign - 1 (dia)
        </label>
        <input
          type="text"
          id="ramming_material_landed_cost_1"
          name="ramming_material_landed_cost_1"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={
            costCalculatedvalues.rammingMaterialCost_1
              ? costCalculatedvalues.rammingMaterialCost_1
              : 0
          }
          onChange={(event) =>
            handleCostCalculationsChange(
              'ramming_cost_in_campaign_1',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="customer_ref_no"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Topping material Landed cost - 2 (dia / MT)
        </label>
        <input
          type="text"
          id="customer_ref_no"
          name="customer_ref_no"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={CostCalculation.topping_material_2}
          onChange={(event) =>
            handleCostCalculationsChange(
              'topping_material_2',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="customer_ref_no"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Topping material cost in a Campaign - 2 (dia)
        </label>
        <input
          type="text"
          id="cost_in_campaign_2"
          name="cost_in_campaign_2"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvalues.toppingMaterialCost_2}
          onChange={(event) =>
            handleCostCalculationsChange(
              'cost_in_campaign_2',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="topping_material_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Topping material Landed cost - 3 (dia / MT)
        </label>
        <input
          type="text"
          id="topping_material_3"
          name="topping_material_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={CostCalculation.topping_material_3}
          onChange={(event) =>
            handleCostCalculationsChange(
              'topping_material_3',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="cost_in_campaign_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Topping material cost in a Campaign - 3 (dia)
        </label>
        <input
          type="text"
          id="cost_in_campaign_3"
          name="cost_in_campaign_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvalues.toppingMaterialCost_3}
          onChange={(event) =>
            handleCostCalculationsChange(
              'cost_in_campaign_3',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="tot_cost_in_campaign"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Total cost in a campaign (Refractory) (dia /MT)
        </label>
        <input
          type="text"
          id="tot_cost_in_campaign"
          name="tot_cost_in_campaign"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvalues.totCostCampaignRefractory}
          onChange={(event) =>
            handleCostCalculationsChange(
              'tot_cost_in_campaign',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="tot_refractory"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Total Refractory Consumption (MT)
        </label>
        <input
          type="text"
          id="tot_refractory"
          name="tot_refractory"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvalues.totRefractoryConsumption}
          onChange={(event) =>
            handleCostCalculationsChange('tot_refractory', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="spec_refractory_consumpt"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Specific Refractory Consumption (kg/T of steel) (Kg / MT)
        </label>
        <input
          type="text"
          id="spec_refractory_consumpt"
          name="spec_refractory_consumpt"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={costCalculatedvalues.SpecificRefractoryConsumpt}
          onChange={(event) =>
            handleCostCalculationsChange(
              'spec_refractory_consumpt',
              event.target.value
            )
          }
        />
      </div>
    </div>
  );
};

export default CostCalculation;
