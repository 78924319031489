class Helper {
  static CrucibleInfo = [
    { id: 1, name: 'A' },
    { id: 2, name: 'A,B' },
    { id: 3, name: 'A,B,C' },
    { id: 4, name: 'A,B,C,D' },
  ]

  static dataFileTempalte = [
    { id: 1, name: 'Daily Trial Monitoring' },
    { id: 2, name: 'NRM Performance' },
    { id: 1, name: 'Erosion Profile' },
    { id: 4, name: 'Data Collection' },
    { id: 5, name: 'Cost Sheet' },
  ]

  static Parameter = [
    { id: 1, name: 'SRM' },
    { id: 2, name: 'NRM competition ' },
    { id: 1, name: 'NRM Other trial' },
    { id: 4, name: 'Basic' },
  ]

  static carbonType = [
    { id: 1, name: 'CPC' },
    { id: 2, name: 'Anthracite' },
    { id: 3, name: 'Carbon block' },
    { id: 4, name: 'Met coke' },
  ]

  static FurnaceNo = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: '8' },
    { id: 9, name: '9' },
    { id: 10, name: '10' },
    { id: 11, name: '11' },
    { id: 12, name: '12' },
    { id: 13, name: '13' },
    { id: 14, name: '14' },
    { id: 15, name: '15' },
    { id: 16, name: '16' },
    { id: 17, name: '17' },
    { id: 18, name: '18' },
    { id: 19, name: '19' },
    { id: 20, name: '20' },
  ]

  static patchingNo = [
    { id: 1, name: 'New Lining' },
    { id: 2, name: 'Side Lining 1' },
    { id: 3, name: 'Side Lining 2' },
    { id: 4, name: 'Side Lining 3' },
    { id: 5, name: 'Side Lining 4' },
    { id: 6, name: 'Side Lining 5' },
    { id: 7, name: 'Side Lining 6' },
    { id: 8, name: 'Side Lining 7' },
    { id: 9, name: 'Side Lining 8' },
    { id: 10, name: 'Side Lining 9' },
    { id: 11, name: 'Side Lining 10' },
    { id: 12, name: 'Side Lining 11' },
    { id: 13, name: 'Side Lining 12' },
    { id: 14, name: 'Side Lining 13' },
    { id: 15, name: 'Side Lining 14' },
    { id: 16, name: 'Side Lining 15' },
    { id: 17, name: 'Patching 1' },
    { id: 18, name: 'Patching 2' },
    { id: 19, name: 'Patching 3' },
    { id: 20, name: 'Patching 4' },
    { id: 21, name: 'Patching 5' },
    { id: 22, name: 'Patching 6' },
    { id: 23, name: 'Patching 7' },
    { id: 24, name: 'Patching 8' },
    { id: 25, name: 'Patching 9' },
    { id: 26, name: 'Patching 10' },
    { id: 27, name: 'Patching 11' },
    { id: 28, name: 'Patching 12' },
    { id: 29, name: 'Patching 13' },
    { id: 30, name: 'Patching 14' },
    { id: 31, name: 'Patching 15' },
    { id: 32, name: 'Patching 16' },
    { id: 33, name: 'Patching 17' },
    { id: 34, name: 'Patching 18' },
    { id: 35, name: 'Patching 19' },
    { id: 36, name: 'Patching 20' },
    { id: 37, name: 'Patching 21' },
    { id: 38, name: 'Patching 22' },
    { id: 39, name: 'Patching 23' },
    { id: 40, name: 'Patching 24' },
    { id: 41, name: 'Patching 25' },
  ]

  static FormerType = [
    { id: 1, name: 'Straight' },
    { id: 2, name: 'Glass' },
    { id: 3, name: 'Double taper' },
  ]

  static LiningMethod = [
    { id: 1, name: 'Manual' },
    { id: 2, name: 'Machine' }
  ]
  static CastingMethod = [
    { id: 1, name: 'Continuous casting' },
    { id: 2, name: 'Ingot casting' }
  ]

  static FurnaceMake = [
    { id: 1, name: 'Inductotherm (IT)' },
    { id: 2, name: 'Megatherm (MT)' },
    { id: 3, name: 'Electrotherm (ET)' },
    { id: 4, name: 'ABP Induction Systems (ABP)' },
    { id: 5, name: 'Oritech solution' },
    { id: 6, name: 'Chinese model' },
    { id: 7, name: 'Others' },
  ]

  static CampaignNo = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: '8' },
    { id: 9, name: '9' },
    { id: 10, name: '10' },
    { id: 11, name: '11' },
    { id: 12, name: '12' },
    { id: 13, name: '13' },
    { id: 14, name: '14' },
    { id: 15, name: '15' },
    { id: 16, name: '16' },
    { id: 17, name: '17' },
    { id: 18, name: '18' },
    { id: 19, name: '19' },
    { id: 20, name: '20' },
    { id: 21, name: '21' },
    { id: 22, name: '22' },
    { id: 23, name: '23' },
    { id: 24, name: '24' },
    { id: 25, name: '25' },
    { id: 26, name: '26' },
    { id: 27, name: '27' },
    { id: 28, name: '28' },
    { id: 29, name: '29' },
    { id: 30, name: '30' },
    { id: 31, name: '31' },
    { id: 32, name: '32' },
    { id: 33, name: '33' },
    { id: 34, name: '34' },
    { id: 35, name: '35' },
    { id: 36, name: '36' },
    { id: 37, name: '37' },
    { id: 38, name: '38' },
    { id: 39, name: '39' },
    { id: 40, name: '40' },
    { id: 41, name: '41' },
    { id: 42, name: '42' },
    { id: 43, name: '43' },
    { id: 44, name: '44' },
    { id: 45, name: '45' },
    { id: 46, name: '46' },
    { id: 47, name: '47' },
    { id: 48, name: '48' },
    { id: 49, name: '49' },
    { id: 50, name: '50' },
    { id: 51, name: '51' },
    { id: 52, name: '52' },
    { id: 53, name: '53' },
    { id: 54, name: '54' },
    { id: 55, name: '55' },
    { id: 56, name: '56' },
    { id: 57, name: '57' },
    { id: 58, name: '58' },
    { id: 59, name: '59' },
    { id: 60, name: '60' },
    { id: 61, name: '61' },
    { id: 62, name: '62' },
    { id: 63, name: '63' },
    { id: 64, name: '64' },
    { id: 65, name: '65' },
    { id: 66, name: '66' },
    { id: 67, name: '67' },
    { id: 68, name: '68' },
    { id: 69, name: '69' },
    { id: 70, name: '70' },
    { id: 71, name: '71' },
    { id: 72, name: '72' },
    { id: 73, name: '73' },
    { id: 74, name: '74' },
    { id: 75, name: '75' },
    { id: 76, name: '76' },
    { id: 77, name: '77' },
    { id: 78, name: '78' },
    { id: 79, name: '79' },
    { id: 80, name: '80' },
    { id: 81, name: '81' },
    { id: 82, name: '82' },
    { id: 83, name: '83' },
    { id: 84, name: '84' },
    { id: 85, name: '85' },
    { id: 86, name: '86' },
    { id: 87, name: '87' },
    { id: 88, name: '88' },
    { id: 89, name: '89' },
    { id: 90, name: '90' },
    { id: 91, name: '91' },
    { id: 92, name: '92' },
    { id: 93, name: '93' },
    { id: 94, name: '94' },
    { id: 95, name: '95' },
    { id: 96, name: '96' },
    { id: 97, name: '97' },
    { id: 98, name: '98' },
    { id: 99, name: '99' },
    { id: 100, name: '100' },
  ]

  static CrucibleNo = [
    { id: 1, name: 'A' },
    { id: 2, name: 'B' },
    { id: 3, name: 'C' },
    { id: 4, name: 'D' },
    { id: 5, name: 'E' },
  ]

  static CostSheet = [
    { id: 1, name: 'NORVIBE A1248 LG' },
    { id: 2, name: 'NORVIBE A1251' },
    { id: 3, name: 'NORVIBE A1248LG I' },
    { id: 4, name: 'NORVIBE A1248LG S' },
    { id: 5, name: 'NORVIBE A1248LG P' },
    { id: 6, name: 'NORVIBE A1248LG Z' },
    { id: 7, name: 'NORVIBE A1275 S' },
    { id: 8, name: 'NORVIBE A1251 S' },
    { id: 9, name: 'NORVIBE A1251 SF' },
    { id: 10, name: 'NORVIBE A1173' },
    { id: 11, name: 'NORVIBE A1251 TK' },
    { id: 12, name: 'NORVIBE A1256' },
    { id: 13, name: 'SRM/Silica' },
    { id: 14, name: 'VA 1248 LG' },
    { id: 15, name: 'VA 1251' },
    { id: 16, name: 'VA 1248LG I' },
    { id: 17, name: 'VA 1248LG S' },
    { id: 18, name: 'VA 1248LG P' },
    { id: 19, name: 'VA 1248LG Z' },
    { id: 20, name: 'VA 1275 S' },
    { id: 21, name: 'VA 1251 S' },
    { id: 22, name: 'VA 1251 SF' },
    { id: 23, name: 'VA 1173' },
    { id: 24, name: 'VA 1251 TK' },
    { id: 25, name: 'VA 1256' },
    { id: 26, name: 'VA 1211' },
    { id: 27, name: 'PA 1451T' },
    { id: 28, name: 'PA 8415' },
    { id: 29, name: 'TA1211' },
    { id: 30, name: 'SRM' },
    { id: 31, name: 'OTHER PRODUCTS' },
  ]

  static Segment = [
    { id: 1, name: 'Segment 1 : DRI - MF/ < or = 15MT/upto 5" thk' },
    { id: 2, name: 'Segment 2 : DRI - MF/ < or = 15MT/ > 5" thk' },
    { id: 3, name: 'Segment 3 : DRI - LF/ < 15MT/ upto 5" thk' },
    { id: 4, name: 'Segment 4 : DRI - LF/ < or = 15MT/ > 5" thk' },
    { id: 5, name: 'Segment 5 : DRI - LF/ > 15MT/ > 5" thk' },
    { id: 6, name: 'Segment 6 : Scrap - MF or LF / < 20 MT' },
    { id: 7, name: 'Segment 7 : Scrap - LF or MF / > or = 30 MT' },
    { id: 8, name: 'Segment 7A : Scrap - LF or MF/ Fce Cap 20 to 28 MT' },
    { id: 9, name: 'Segment 8 : Alloy - (Ingot customer)' },
    { id: 10, name: 'Segment 9 : Alloy - (CCM customer)' },
    { id: 11, name: 'Segment 13 : Alloy - LF or MF/ > or = 20 MT' },
    {
      id: 12,
      name: 'Segment 10 : SS - Stainless Steel (Chipping not required)',
    },
    { id: 13, name: 'Segment 11 : SS - Stainless Steel (Chipping required)' },
    { id: 14, name: 'Segment 12 : Small Fdy - Fdy/ < or = 8MT' },
    { id: 15, name: 'Others' },
  ]

  static async getCompaignNumbers() {
    const CampaignNo = []

    // Loop from 1 to 100 to generate campaign numbers
    for (let i = 1; i <= 100; i++) {
      // Push an object with id and name properties to the array
      CampaignNo.push({ id: i, name: `${i}` })
    }

    return CampaignNo
  }

  static async getCutomerTrigram(name) {
    // Split the organization name by spaces
    const words = name.trim().split(' ')

    // Initialize trigram
    let trigram = ''

    // Check the number of words
    if (words.length === 1) {
      // If it's one word, take the first three characters
      trigram = words[0].slice(0, 3).toUpperCase()
    } else if (words.length === 2) {
      // If it's two words, take the first two characters of each word
      trigram =
        words[0].slice(0, 2).toUpperCase() + words[1].charAt(0).toUpperCase()
    } else if (words.length >= 3) {
      // If it's three or more words, take the first character of the first three words
      trigram =
        words[0].charAt(0).toUpperCase() +
        words[1].charAt(0).toUpperCase() +
        words[2].charAt(0).toUpperCase()
    }

    return trigram
  }
}

export default Helper
