import React from 'react';
import { useNavigate } from 'react-router-dom';
import StatusImage from '../../assets/images/icons/ic_status.svg';

const ApproveStatus = ({closeModal}) => {

  const navigate = useNavigate();

  // const handleDataManagement = () => {
  //   closeModal()
  // };

  return (
    <div className="flex flex-col items-center w-full">
      <img src={StatusImage} alt="Status" className="mb-5" />
      <h1 className="font-inter font-extrabold text-lg leading-7">
        Data File is Approved
      </h1>
      <p className="text-center text-gray-600">
        Your Data is Submitted and is Approved.
      </p>
      <div className="mt-10">
        <button
          className="px-6 py-2 bg-custom-red text-white rounded-md font-medium hover:bg-custom-red transition"
          onClick={closeModal}
        >
          Go to Data Management
        </button>
      </div>
    </div>
  );
};

export default ApproveStatus;
