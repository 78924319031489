import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { lining_reference } from "../../recoil/atom";
import { v4 as uuidv4 } from "uuid";
import Helper from "../../service/Helper";
import axios from "axios";
import API from "../../service/API";
import DatePicker from "react-datepicker";


const LiningReferenceWeb = ({validationErrors}) => {
  const [compaignNo, setCompaignNo] = useState([]);
  const [liningPatchingNo, setLiningPatchingNo] = useState([]);
  const [liningReference, setLiningReference] = useRecoilState(lining_reference);
  const dataFile = localStorage.getItem("dataFileResponse");
  const dataFileResponse = JSON.parse(dataFile);

 
  useEffect(() => {
    const TrialName = localStorage.getItem("Trial");
    if (TrialName === "new") {
      const dataFile = localStorage.getItem("dataFileResponse");
      const dataFileResponse = JSON.parse(dataFile);
  
      if (dataFileResponse?.furnace?.furnace_number) {
        // Ensure liningReference is defined before accessing its properties
        if (!liningReference || liningReference.furnace_no !== dataFileResponse.furnace.furnace_number) {
          setLiningReference((prevState) => ({
            ...prevState,
            furnace_no: dataFileResponse.furnace.furnace_number,
          }));
        }
      }
    } else {
      const selectedItem = localStorage.getItem("SelectedItem");
      const selectedItemData = JSON.parse(selectedItem);
      if (selectedItemData?.lining_reference?.length) {
        const furnaceNumber = selectedItemData.lining_reference[0]?.furnace_no || "";
        if (!liningReference || liningReference.furnace_no !== furnaceNumber) {
          setLiningReference((prevState) => ({
            ...prevState,
            furnace_no: furnaceNumber,
          }));
        }
      }
    }
  }, [liningReference]);
  


  // const handleChange = (name, value) => {
  //   const TrialName = localStorage.getItem("Trial");
  //   // Check if TrialName is "new"
  //   if (TrialName === "new" || TrialName === "add") {
  //     // Generate unique ID
  //     const uniqueId = uuidv4(); // Replace this with your unique ID generation logic
  //     setLiningReference((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //       id: uniqueId, // Set the unique ID
  //       isdelete: false,
  //     }));
  //   } else {
  //     // If TrialName is not "new", no need to generate unique ID
  //     setLiningReference((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //     }));
  //   }
  // };
  const handleChange = (name, value) => {
    const TrialName = localStorage.getItem("Trial");
    if (TrialName === "new" || TrialName === "add") {
      const uniqueId = uuidv4(); // Replace this with your unique ID generation logic
      setLiningReference((prevState) => ({
        ...prevState,
        [name]: value, // Format date if necessary
        id: uniqueId,
        isdelete: false,
      }));
    } else {
      setLiningReference((prevState) => ({
        ...prevState,
        [name]: value, // Format date if necessary
      }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const campaignNumbers = await Helper.getCompaignNumbers();
        console.log("campaignNumbers", campaignNumbers);
        setCompaignNo(campaignNumbers);
      } catch (error) {
        console.error("Error fetching campaign numbers:", error);
      }
    };
    const fetchLiningData = async () => {
      const access_token = localStorage.getItem("accesstoken");

      try {
        const response = await axios.get(API.LINING_PATCHING_NO, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        console.log("RESSSS123", response);
        setLiningPatchingNo(response.data);
      } catch (error) {
        console.error("Error fetching lining:", error);
      }
    };

    fetchData();
    fetchLiningData();
  }, []);

  const formatDate = (isoDate) => {
    if (!isoDate) return "";
    const [year, month, day] = isoDate.split("-");
    return `${day}/${month}/${year}`;
  };

  // Parse dd/mm/yyyy to ISO format
  const parseDateToISO = (formattedDate) => {
    if (!formattedDate) return "";
    const [day, month, year] = formattedDate.split("/");
    return `${year}-${month}-${day}`;
  };

  // Handle date input change
  const handleDateChange = (key, value) => {
    const isoDate = parseDateToISO(value);
    handleChange(key, isoDate);
  };

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Lining Reference
      </h2>
      <div className="flex space-x-4 mt-4">
      <div className="w-1/2">
            <label
              htmlFor="furnace_no"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Furnace No *
            </label>
            <input
              type="text"
              id="furnace_no"
              name="furnace_no"
              value={liningReference.furnace_no || ""}
              readOnly
              className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
            />
          </div>

        <div className="w-1/2">
          <label
            htmlFor="crucible_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Crucible No *
          </label>
          <select
            id="crucible_no"
            name="crucible_no"
            value={liningReference.crucible_no}
            onChange={(e) => handleChange("crucible_no", e.target.value)}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a crucible</option>
            {Helper.CrucibleNo.map((option) => (
              <option key={option.id} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
            {validationErrors.crucible_no && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors.crucible_no}
            </p>
          )}
        </div>
      </div> 
      {/* Line 2: Lining/Patching No and Date of Completion */}
      <div className="flex space-x-4 mt-4">
        {/* Lining/Patching No Dropdown */}
        <div className="w-1/2">
          <label
            htmlFor="lining_or_patching"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Lining/Patching No *
          </label>
          <select
            id="lining_or_patching"
            name="lining_or_patching"
            value={liningReference.lining_or_patching}
            onChange={(e) => handleChange("lining_or_patching", e.target.value)}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a lining/patching</option>
            {liningPatchingNo.map((option) => (
              <option key={option.id} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
          {validationErrors.lining_or_patching && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors.lining_or_patching}
            </p>
          )}
        </div>

        {/* Campaign No Dropdown */}
        <div className="w-1/2">
          <label
            htmlFor="campaign_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Campaign No *
          </label>
          <select
            id="campaign_no"
            name="campaign_no"
            value={liningReference.campaign_no}
            onChange={(e) => handleChange("campaign_no", e.target.value)}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a campaign no</option>
            {compaignNo.map((option) => (
              <option key={option.id} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
          {validationErrors.campaign_no && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors.campaign_no}
            </p>
          )}
        </div>
      </div>

      {/* <div className="w-full mb-4 mt-4">
        <label
          htmlFor="date_of_completion_lining_or_patching"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Date of Completion Lining or Patching
        </label>
        <DatePicker
          id="date_of_completion_lining_or_patching"
          selected={liningReference.date_of_completion_lining_or_patching}
          onChange={(date) => handleChange("date_of_completion_lining_or_patching", date)}
          dateFormat="dd-MM-yyyy"
          placeholderText="Select Date"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div> */}

        <div className="w-full mb-4 mt-4">
          <label
            htmlFor="date_of_completion_lining_or_patching"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Date of Completion Lining or Patching
          </label>
          <DatePicker
            id="date_of_completion_lining_or_patching"
            selected={
              liningReference.date_of_completion_lining_or_patching
                ? new Date(liningReference.date_of_completion_lining_or_patching)
                : null
            }
            onChange={(date) => {
              const formattedDate = date
                ? date.toISOString().split("T")[0] // Convert to ISO string
                : "";
              handleChange("date_of_completion_lining_or_patching", formattedDate);
            }}
            dateFormat="dd-MM-yyyy"
            placeholderText="Select Date"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

    </>
  );
};

export default LiningReferenceWeb;
