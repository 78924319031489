import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { dimensional_details, trial_name } from "../../recoil/atom";
import { initialLiningHeight } from "../../recoil/selector";
import { v4 as uuidv4 } from 'uuid'
import { useRecoilValue } from "recoil";

const DimensionalDetails = () => {
  const [dimensionalDetails, setDimensionalDetails] =
    useRecoilState(dimensional_details);
  const [trialName] = useRecoilState(trial_name);
  const isReadOnly = trialName === "viewErosionProfile";
  const initialLiningHeightvalue = useRecoilValue(initialLiningHeight);
  const liningHeightValue = useRecoilValue(initialLiningHeight);
  const uuid = uuidv4();

  const handleChange = (name, value) => {
    console.log("nammeee", name);
    console.log("valuee", value);
    const TrialName = localStorage.getItem("Trial");
    if (TrialName === "new" || TrialName === "add") {
      const uniqueId = uuidv4();
      setDimensionalDetails((prevState) => ({
        ...prevState,
        [name]: value,
        id: uniqueId, // Set the unique ID
        isdelete: false,
      }));
    } else {
      // If TrialName is not "new", no need to generate unique ID
      setDimensionalDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Dimensional Details
      </h2>

      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="fce_height"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Fce Height - (inch)
          </label>
          <input
            type="text"
            id="fce_height"
            name="fce_height"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Height"
            value={dimensionalDetails.fce_height}
            onChange={(event) => handleChange("fce_height", event.target.value)}
            disabled={isReadOnly}
          />
        </div>

        {/* Date */}
        <div className="w-full mb-4">
          <label
            htmlFor="castable_height"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Castable height - (inch)
          </label>
          <input
            type="text"
            id="castable_height"
            name="castable_height"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Height"
            value={dimensionalDetails.castable_height}
            onChange={(event) =>
              handleChange("castable_height", event.target.value)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        
        <div className="w-full mb-4">
          <label
            htmlFor="bottom_thickness"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Bottom Thickness - (inch)
          </label>
          <input
            type="text"
            id="bottom_thickness"
            name="bottom_thickness"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Bottom Thickness"
            value={dimensionalDetails.bottom_thickness}
            onChange={(event) =>
              handleChange("bottom_thickness", event.target.value)
            }
            disabled={isReadOnly}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label
            htmlFor="bottom_erosion"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Bottom Erosion - (inch)
          </label>
          <input
            type="text"
            id="bottom_erosion"
            name="bottom_erosion"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Bottom Erosion"
            value={dimensionalDetails.bottom_erosion}
            onChange={(event) =>
              handleChange("bottom_erosion", event.target.value)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        
        <div className="w-full mb-4">
          <label
            htmlFor="former_top_od"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Former Top OD - (inch)
          </label>
          <input
            type="text"
            id="former_top_od"
            name="former_top_od"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Former Top OD"
            value={dimensionalDetails.former_top_od}
            onChange={(event) =>
              handleChange("former_top_od", event.target.value)
            }
            disabled={isReadOnly}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label
            htmlFor="former_bottom_od"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Former Bottom OD - (inch)
          </label>
          <input
            type="text"
            id="former_bottom_od"
            name="former_bottom_od"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Former Bottom OD"
            value={dimensionalDetails.former_bottom_od}
            onChange={(event) =>
              handleChange("former_bottom_od", event.target.value)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        
        <div className="w-full mb-4">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Former Height - (inch)
          </label>
          <input
            type="text"
            id="former_height"
            name="former_height"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Former Height"
            value={dimensionalDetails.former_height}
            onChange={(event) =>
              handleChange("former_height", event.target.value)
            }
            disabled={isReadOnly}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label
            htmlFor="grade"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Former 1st taper OD - (inch)
          </label>
          <input
            type="text"
            id="grade"
            name="grade"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Former 1st taper OD"
            value={dimensionalDetails.former_first_taper}
            onChange={(event) =>
              handleChange("former_first_taper", event.target.value)
            }
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="former_taper_length"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Former Taper length - (inch)
          </label>
          <input
            type="text"
            id="former_taper_length"
            name="former_taper_length"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Former Taper length"
            value={dimensionalDetails.former_taper_length}
            onChange={(event) =>
              handleChange("former_taper_length", event.target.value)
            }
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="initLiningHt"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Initial Lining Height - (inch)
          </label>
          <input
            type="text"
            id="initLiningHt"
            name="initLiningHt"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Initial Lining Height"
            value={initialLiningHeightvalue.initLiningHt}
            onChange={(event) =>
              handleChange("former_taper_length", event.target.value)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="liningHeightAE"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Lining Height After Erosion - (inch)
          </label>
          <input
            type="text"
            id="liningHeightAE"
            name="liningHeightAE"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Initial Lining Height"
            value={liningHeightValue.liningHeightAE}
            onChange={() => handleChange()}
            disabled={isReadOnly}
          />
        </div>
      </div>
    </>
  );
};

export default DimensionalDetails;
