import React from "react";
import { useRecoilState } from "recoil";
import { alloying_addition, general_details } from "../../recoil/atom";

const AlloyingAdditionWeb = () => {
  const [alloyingAddition, setAlloyingAddition] =
    useRecoilState(alloying_addition);
  const [generalDetails] = useRecoilState(general_details);
  const uniqueId = generalDetails.id ? generalDetails.id : "";

  console.log(alloyingAddition, "alloyingAddition")

  const handleChange = (e) => {
    const { name, value } = e.target;
    const TrialName = localStorage.getItem("Trial");

    const validInputRegex = /^[a-zA-Z0-9\s!@#$%^&*()_\-+=\[\]{}|;:'",.<>?/]*$/;
    if (name === "any_other_alloy_addition" && validInputRegex.test(value)) {
      if (TrialName === "new" || TrialName === "add") {
        setAlloyingAddition((prevState) => ({
          ...prevState,
          [name]: value,
          id: uniqueId,
        }));
      } else {
        setAlloyingAddition((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else if (name !== "any_other_alloy_addition") {
      if (TrialName === "new" || TrialName === "add") {
        setAlloyingAddition((prevState) => ({
          ...prevState,
          [name]: value,
          id: uniqueId,
        }));
      } else {
        setAlloyingAddition((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
  };


  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Alloying Addition
      </h2>
      <div className="flex space-x-4 mt-4">
        {/* Heat No */}
        <div className="w-full mb-4">
          <label
            htmlFor="si_before_alloy_addition"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Si% Before addition - %
          </label>
          <input
            type="text"
            id="si_before_alloy_addition"
            name="si_before_alloy_addition"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Si%"
            value={alloyingAddition?.si_before_alloy_addition}
            onChange={handleChange}
          />
        </div>

        {/* Date */}
        <div className="w-full mb-4">
          <label
            htmlFor="final_si"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Final Si% - %
          </label>
          <input
            type="text"
            id="final_si"
            name="final_si"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Si%"
            value={alloyingAddition?.final_si}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="mn_before_alloy_addition"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Mn% before Addition - %
          </label>
          <input
            type="text"
            id="mn_before_alloy_addition"
            name="mn_before_alloy_addition"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter  Mn%"
            value={alloyingAddition?.mn_before_alloy_addition}
            onChange={handleChange}
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="final_mn"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Final Mn% - %
          </label>
          <input
            type="text"
            id="final_mn"
            name="final_mn"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Mn%"
            value={alloyingAddition?.final_mn}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label
            htmlFor="cr_before_alloy_addition"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Cr% before addition - %
          </label>
          <input
            type="text"
            id="cr_before_alloy_addition"
            name="cr_before_alloy_addition"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Cr%"
            value={alloyingAddition?.cr_before_alloy_addition}
            onChange={handleChange}
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="final_cr"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Final Cr% - %
          </label>
          <input
            type="text"
            id="final_cr"
            name="final_cr"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Cr%"
            value={alloyingAddition?.final_cr}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="si_mn"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            SiMn - Kg
          </label>
          <input
            type="text"
            id="si_mn"
            name="si_mn"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter SiMn"
            value={alloyingAddition?.si_mn}
            onChange={handleChange}
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="mn_in_simn"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Mn % in SiMn - %
          </label>
          <input
            type="text"
            id="mn_in_simn"
            name="mn_in_simn"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Mn %"
            value={alloyingAddition?.mn_in_simn}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="feMn"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            FeMn - Kg
          </label>
          <input
            type="text"
            id="feMn"
            name="feMn"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter FeMn"
            value={alloyingAddition?.feMn}
            onChange={handleChange}
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="grade"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            FeSi - Kg
          </label>
          <input
            type="text"
            id="feSi"
            name="feSi"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter FeSi"
            value={alloyingAddition?.feSi}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            FeCr - Kg
          </label>
          <input
            type="text"
            id="feCr"
            name="feCr"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter FeCr"
            value={alloyingAddition?.feCr}
            onChange={handleChange}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label
            htmlFor="mill_scale"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Mill scale - Kg
          </label>
          <input
            type="text"
            id="mill_scale"
            name="mill_scale"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Mill scale"
            value={alloyingAddition?.mill_scale}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label
            htmlFor="any_other_alloy_addition"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Any other Alloy addition
          </label>
          <input
            type="text"
            id="any_other_alloy_addition"
            name="any_other_alloy_addition"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter"
            value={alloyingAddition?.any_other_alloy_addition}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export default AlloyingAdditionWeb;
