import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { charge_mix_details, general_details } from "../../recoil/atom";

const ChargeMixDetailsWeb = () => {
  const [chargeMixDetails, setChargeMixDetails] =
    useRecoilState(charge_mix_details);
  const [generalDetails] = useRecoilState(general_details);
  const uniqueId = generalDetails.id ? generalDetails.id : "";
  const [value, setValue] = useState("");
  console.log(chargeMixDetails, "chargeMixDetails")
  const handleChange = (e) => {
    const { name, value } = e.target;
    const TrialName = localStorage.getItem("Trial");
    const regex = /^\d{0,5}(\.\d{0,2})?$/;

    if (regex.test(value)) {
      const updatedDetails = { ...chargeMixDetails, [name]: value };

      if (TrialName === "new") {
        updatedDetails.id = uniqueId;
      }

      const total = calculateTotalCharge(updatedDetails);
      setChargeMixDetails({
        ...updatedDetails,
        total_charge: total.toFixed(2), // Adding total charge here
      });
    } else {
      e.target.value = "";
    }
  };

  const calculateTotalCharge = (details) => {
    let total = 0;
    const chargeFields = [
      "scrap",
      "shredded",
      "pig_iron",
      "pooled_iron",
      "hot_heel",
      "lump",
      "pellet",
      "fines_lessthan_5mm",
      "fines_lessthan_1mm",
      "slag_crushers",
      "tundish_or_slag_or_metalic_jam",
    ];

    chargeFields.forEach((field) => {
      if (details[field] && !isNaN(details[field])) {
        total += parseFloat(details[field]);
      }
    });

    return total;
  };

  // // Recoil state to manage form data
  // const [formData, setFormData] = useRecoilState(furnace_details);

  // // Common handle input change function for all fields
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value, // Update the state dynamically based on the input name
  //   });
  // };

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Charge Mix Details
      </h2>

      <div className="flex space-x-4 mt-4">
        {/* Heat No */}
        <div className="w-full mb-4">
          <label
            htmlFor="scrap"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Scrap (MT)
          </label>
          <input
            type="text"
            id="scrap"
            name="scrap"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter scrap"
            value={chargeMixDetails.scrap}
            onChange={handleChange}
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="shredded"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Shredded (MT)
          </label>
          <input
            type="text"
            id="shredded"
            name="shredded"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Shredded"
            value={chargeMixDetails.shredded}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label
            htmlFor="pig_iron"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Pig Iron (MT)
          </label>
          <input
            type="text"
            id="pig_iron"
            name="pig_iron"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Pig Iron"
            value={chargeMixDetails.pig_iron}
            onChange={handleChange}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label
            htmlFor="pooled_iron"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Pooled Iron (MT)
          </label>
          <input
            type="text"
            id="pooled_iron"
            name="pooled_iron"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Pooled Iron"
            value={chargeMixDetails.pooled_iron}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="hot_heel"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Hot Heel (MT)
          </label>
          <input
            type="text"
            id="hot_heel"
            name="hot_heel"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Hot Heel"
            value={chargeMixDetails.hot_heel}
            onChange={handleChange}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label
            htmlFor="lump"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Lump (MT)
          </label>
          <input
            type="text"
            id="lump"
            name="lump"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter lump"
            value={chargeMixDetails.lump}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="pellet"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Pellet (MT)
          </label>
          <input
            type="text"
            id="pellet"
            name="pellet"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Pellet"
            value={chargeMixDetails.pellet}
            onChange={handleChange}
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="fines_lessthan_5mm"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Fines less than 5 mm (MT)
          </label>
          <input
            type="text"
            id="fines_lessthan_5mm"
            name="fines_lessthan_5mm"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Fines"
            value={chargeMixDetails.fines_lessthan_5mm}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="fines_lessthan_1mm"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Fines less than 1 mm (MT)
          </label>
          <input
            type="text"
            id="fines_lessthan_1mm"
            name="fines_lessthan_1mm"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Fines"
            value={chargeMixDetails.fines_lessthan_1mm}
            onChange={handleChange}
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="slag_crushers"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Slag Crushers (MT)
          </label>
          <input
            type="text"
            id="slag_crushers"
            name="slag_crushers"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Slag Crushers"
            value={chargeMixDetails.slag_crushers}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="tundish_or_slag_or_metalic_jam"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Tundish or Slag or Metalic Jam (MT)
          </label>
          <input
            type="text"
            id="tundish_or_slag_or_metalic_jam"
            name="tundish_or_slag_or_metalic_jam"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Tundish"
            value={chargeMixDetails.tundish_or_slag_or_metalic_jam}
            onChange={handleChange}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label
            htmlFor="total_charge"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total charge (MT)
          </label>
          <input
            type="text"
            id="total_charge"
            name="total_charge"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Total charge"
            value={chargeMixDetails.total_charge}
            onChange={handleChange}
            disabled
          />
        </div>
      </div>
    </>
  );
};

export default ChargeMixDetailsWeb;
