import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { savings_metallurgical_benefit, trial_name, savings_metallurgical_benefit_nrm } from "../../../recoil/atom";

const MetallurgicalBenefits = () => {
  const [metallurgicalBenefits, setMetallurgicalBenefits] = useRecoilState(
    savings_metallurgical_benefit
  )
  const [metallurgicalBenefitsNRM, setMetallurgicalBenefitsNRM] =
    useRecoilState(savings_metallurgical_benefit_nrm)
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'
  const handleMetallurgicalBenefitsNRM = (name, value) => {
    setMetallurgicalBenefitsNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const metaObj = {
    savings_metallurgical: 'NA',
  }

  const metaObjNRM = {
    savings_metallurgical: metallurgicalBenefitsNRM.savings_metallurgical,
  }

  // useEffect(() => {
  //   if (
  //     props.trialName === 'costSheetEdit' &&
  //     props.editSavingsDetails.length > 0
  //   ) {
  //     console.log(props.editSavingsDetails, 'props.editSavingsDetails')
  //     let editObj = doesKeyExist(
  //       props.editSavingsDetails,
  //       'metallurgicalBenefitsObject'
  //     )
  //     console.log(editObj, 'editObj')
  //     if (editObj) {
  //       setMetallurgicalBenefits(
  //         editObj.metallurgicalBenefitsObject.metallurgicalBenefits.basic
  //       )
  //       setMetallurgicalBenefitsNRM(
  //         editObj.metallurgicalBenefitsObject.metallurgicalBenefits.nrm
  //       )
  //     }
  //   }
  // }, [props.editSavingsDetails])

  function doesKeyExist(array, key) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].hasOwnProperty(key)) {
        return array[i]
      }
    }
    return false
  }

  const handlenextTriger = () => {
    props.handleNext()
    props.handleMetaObject(metaObj, metaObjNRM)
  }

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        S8 : Savings due to other metallurgical benefits
      </h2>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Savings due to Metallurgical Benefit (F6) (dia / MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value="NA"
            onChange={(event) =>
              handleMetallurgicalBenefitsNRM(
                'savings_metallurgical',
                event.target.value
              )
            }
          />
        </div>
      </div>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        NRM
      </h2>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="savings_metallurgical"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Savings due to Metallurgical Benefit (F6) (dia / MT)
          </label>
          <input
            type="text"
            id="savings_metallurgical"
            name="savings_metallurgical"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={metallurgicalBenefitsNRM.savings_metallurgical}
            onChange={(event) =>
              handleMetallurgicalBenefitsNRM(
                'savings_metallurgical',
                event.target.value
              )
            }
          />
        </div>
      </div>


    </>
  );
};

export default MetallurgicalBenefits;
