import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useRecoilState, useRecoilValue } from "recoil";
import { nrm_usage_details, trial_name } from "../../recoil/atom";
import { tot_NRM_Consumption } from "../../recoil/selector";
import Helper from "../../service/Helper";

const NRMUsageDetail = () => {
  const [isEdit, setIsEdit] = useState(
    localStorage.getItem("Trial") === "Edit"
  );
  const [usageDetails, setusageDetails] = useRecoilState(nrm_usage_details);
  const [trialName] = useRecoilState(trial_name);
  const isReadOnly = trialName === "viewErosionProfile";
  const totalNRM = useRecoilValue(tot_NRM_Consumption);
  console.log(totalNRM, "totalNRM");
  const handleChange = (name, value) => {
    console.log("nammeee", name);
    console.log("valuee", value);
    const TrialName = localStorage.getItem("Trial");
    if (TrialName === "new" || TrialName === "add") {
      const uniqueId = uuidv4();
      setusageDetails((prevState) => ({
        ...prevState,
        [name]: value,
        id: uniqueId,
        isdelete: false,
      }));
    } else {
      setusageDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Alloying Addition
      </h2>

      {/* Furnace No Dropdown */}

      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="furnace_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Bottom Product
          </label>
          <select
            id="bottom_product"
            name="bottom_product"
            value={usageDetails.bottom_product}
            onChange={(event) => handleChange("bottom_product", event.target.value)}
            disabled={isReadOnly}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            {Helper.CostSheet.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>

        </div>

        <div className="w-1/2">
          <label
            htmlFor="sidewall_1"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Sidewall Product 1
          </label>
          <select
            id="sidewall_1"
            name="sidewall_1"
            options={Helper.CostSheet.map((option) => option.name)}
            value={usageDetails.sidewall_1}
            onChange={(event, value) => handleChange("sidewall_1", value)}
            disabled={isReadOnly}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            {Helper.CostSheet.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="sidewall_2"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Sidewall Product 2
          </label>
          <select
            id="sidewall_2"
            name="sidewall_2"
            options={Helper.CostSheet.map((option) => option.name)}
            value={usageDetails.sidewall_2}
            onChange={(event, value) => handleChange("sidewall_2", value)}
            disabled={isReadOnly}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            {Helper.CostSheet.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
        </div>

        {/* Crucible No Dropdown */}
        <div className="w-1/2">
          <label
            htmlFor="locking_product"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Locking product
          </label>
          <select
            id="locking_product"
            name="locking_product"
            options={Helper.CostSheet.map((option) => option.name)}
            value={usageDetails.locking_product}
            onChange={(event, value) => handleChange("locking_product", value)}
            disabled={isReadOnly}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            {Helper.CostSheet.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="spout_launder_product"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Spout/ Launder product
          </label>
          <select
            id="spout_launder_product"
            name="spout_launder_product"
            options={Helper.CostSheet.map((option) => option.name)}
            value={usageDetails.spout_launder_product}
            onChange={(event, value) =>
              handleChange("spout_launder_product", value)
            }
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            {Helper.CostSheet.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex space-x-4 mt-4">
        {/* Heat No */}
        <div className="w-full mb-4">
          <label
            htmlFor="conspt_bottom_product"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            NRM Consumption - Bottom Product - (MT)
          </label>
          <input
            type="text"
            id="conspt_bottom_product"
            name="conspt_bottom_product"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter"
            value={usageDetails.conspt_bottom_product}
            onChange={(event) =>
              handleChange("conspt_bottom_product", event.target.value)
            }
            disabled={isReadOnly}
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="conspt_sidewall_1"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            NRM Consumption - sidewall product 1 - (MT)
          </label>
          <input
            type="text"
            id="conspt_sidewall_1"
            name="conspt_sidewall_1"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter SideWall 1"
            value={usageDetails.conspt_sidewall_1}
            onChange={(event) =>
              handleChange("conspt_sidewall_1", event.target.value)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="conspt_sidewall_2"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            NRM Consumption - sidewall product 2 - (MT)
          </label>
          <input
            type="text"
            id="conspt_sidewall_2"
            name="conspt_sidewall_2"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter SideWall 2"
            value={usageDetails.conspt_sidewall_2}
            onChange={(event) =>
              handleChange("conspt_sidewall_2", event.target.value)
            }
            disabled={isReadOnly}
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="conspt_locking_product"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            NRM Consumption - Locking product - (MT)
          </label>
          <input
            type="text"
            id="conspt_locking_product"
            name="conspt_locking_product"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter product"
            value={usageDetails.conspt_locking_product}
            onChange={(event) =>
              handleChange('conspt_locking_product', event.target.value)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label
            htmlFor="conspt_spout_launder_product"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            NRM Consumption - Spout/ Launder product - (MT)
          </label>
          <input
            type="text"
            id="conspt_spout_launder_product"
            name="conspt_spout_launder_product"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Product"
            value={usageDetails.conspt_spout_launder_product}
            onChange={(event) =>
              handleChange('conspt_spout_launder_product', event.target.value)
            }
            disabled={isReadOnly}
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="tot_Consumpt"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total NRM Consumption - (MT)
          </label>
          <input
            type="text"
            id="tot_Consumpt"
            name="tot_Consumpt"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter"
            value={totalNRM.tot_Consumpt}
            onChange={() => handleChange()}
            disabled={isReadOnly}
          />
        </div>
      </div>
    </>
  );
};

export default NRMUsageDetail;
