import React from 'react';
import { ReactComponent as MailIcon } from "../../assets/images/SVGIcon/MailIcon.svg";
import { ReactComponent as TeamsIcon } from "../../assets/images/SVGIcon/TeamsIcon.svg";

const HelpandSupport = () => {
    return (
        <>
            <div className="bg-white px-4 pb-4 pt-3 border-b sm:flex justify-between items-start">
                <h3 className="text-3xl font-bold pt-1">Help & Support</h3>
            </div>
            <div className='w-full flex justify-center items-center min-h-[550px]'>
                <a href='msteams://teams.microsoft.com'>
                    <div className='w-[250px] h-[240px] bg-[#eef6ff] border-2 border-[#0e4e95] p-4 flex rounded-xl justify-center items-center flex-col'>
                        <TeamsIcon className='mb-2' />
                        <p className='font-semibold text-sm text-center mb-2'>Talk to our support engineers</p>
                        <p className='font-normal text-xs text-center'>Let us know your difficulties with the Application. We're happy to help!</p>
                    </div>
                </a>
                <a href='mailto:'>
                    <div className='w-[250px] h-[240px] bg-[#eef6ff] border-2 border-[#0e4e95] p-4 flex rounded-xl justify-center items-center flex-col ml-4'>
                        <MailIcon className='mb-2 mt-6' />
                        <p className='font-semibold text-sm text-center mb-2'>Write to us</p>
                        <p className='font-normal text-xs text-center'>Request a demo or get started with
                            implementing Digital Foundry Application.</p>
                    </div>
                </a>
            </div>
        </>
    );
}

export default HelpandSupport;
