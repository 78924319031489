import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import API from '../../service/API';
import AlertDialog from '../Common/AlertDialog';

const CustomerModal = () => {
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState(null); // State to store the selected customer
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const access_token = localStorage.getItem('accesstoken');

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(API.GET_CUSTOMER, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      const customerResponse = response.data;

      if (currentUser.role.role_id === 1) {
        const customerId = currentUser.assign_customer_id[0];
        const customers = customerResponse.filter((item) => item.id === customerId);
        setCustomers(customers);
      } else if (currentUser.role.role_id === 2 || currentUser.role.role_id === 3) {
        const assignCustomerIds = currentUser.assign_customer_id.map((id) => id);
        const filteredCustomers = customerResponse.filter((customer) =>
          assignCustomerIds.includes(customer.id)
        );
        setCustomers(filteredCustomers);
      } else {
        setCustomers(customerResponse);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCustomerChange = (cust) => {
    setCustomer(cust);
  };

  const handleGetStarted = () => {
    if (customer) {
      localStorage.setItem('selectedCustomer', JSON.stringify(customer));
      navigate('/DashboardLayout');
    } else {
      AlertDialog({
        type: "warning", // Changed type to "warning" since it aligns with the title
        title: "Warning",
        text: "Please select a customer first!",
        confirmButtonText: "Ok",
      });

    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
      <div className="bg-white rounded-lg shadow-lg w-11/12 sm:w-4/5 md:w-1/2 p-6 flex flex-col items-center">
        <h2 className="text-2xl font-semibold text-center mb-4">
          Hi {currentUser.firstname} {currentUser.lastname}, Please select a customer to get started!
        </h2>

        <div className="flex flex-wrap justify-center gap-4 mt-4">
          {customers.map((cust, index) => (
            <button
              key={index}
              className={`rounded-full px-4 py-2 border transition-colors duration-300 ${
                customer === cust
                  ? 'bg-custom-red text-white border-blue-600'
                  : 'bg-white text-blue-600 border-blue-600 hover:bg-gray-100'
              }`}
              onClick={() => handleCustomerChange(cust)}
            >
              {cust.organization_name}
            </button>
          ))}
        </div>

        <button
          className="mt-6 px-4 py-2 bg-custom-red text-white rounded border border-blue-600 hover:bg-custom-red transition-colors duration-300"
          onClick={handleGetStarted}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default CustomerModal;
