import React, { useState } from "react";
import Users from "./Users";
import Customers from "./Customers";
import Furnaces from "./Furnaces";
// import Customers from "./Customers";
// import Users from "./Users";

const Settings = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleButtonClick = () => {
    alert("Button clicked!");
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [activeTab, setActiveTab] = useState("customerTab");

  return (
    <div>
      <div className="flex border-b px-4 border-gray-200 dark:border-gray-700 justify-between items-center">
        <ul className="flex -mb-px text-sm font-medium text-center overflow-auto">
          <li className="me-2" role="presentation">
            <button
              onClick={() => handleTabClick("customerTab")}
              className={`inline-block p-4 whitespace-nowrap border-b-2 rounded-t-lg ${
                activeTab === "customerTab"
                  ? "text-black font-semibold border-custom-red"
                  : "border-transparent text-gray-500"
              }`}
              type="button"
              role="tab"
              aria-controls="customerTab"
              aria-selected={activeTab === "customerTab"}
            >
              CUSTOMERS
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              onClick={() => handleTabClick("userTab")}
              className={`inline-block p-4 whitespace-nowrap border-b-2 rounded-t-lg ${
                activeTab === "userTab"
                  ? "text-black font-semibold border-custom-red"
                  : "border-transparent text-gray-500"
              }`}
              type="button"
              role="tab"
              aria-controls="userTab"
              aria-selected={activeTab === "userTab"}
            >
             USERS
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              onClick={() => handleTabClick("furnaceTab")}
              className={`inline-block p-4 whitespace-nowrap border-b-2 rounded-t-lg ${
                activeTab === "furnaceTab"
                  ? "text-black font-semibold border-custom-red"
                  : "border-transparent text-gray-500"
              }`}
              type="button"
              role="tab"
              aria-controls="furnaceTab"
              aria-selected={activeTab === "furnaceTab"}
            >
             FURNACES
            </button>
          </li>
        </ul>
      </div>
      <div>
        <div>
          {activeTab === "customerTab" && <Customers />}
          {activeTab === "userTab" && <Users />}
          {activeTab === "furnaceTab" && <Furnaces />}
        </div>
      </div>
    </div>
  );
};

export default Settings;
