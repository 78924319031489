import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { erosion_details } from "../../recoil/atom";
import axios from "axios";
import API from "../../service/API";
import Helper from "../../service/Helper";
import { trial_name } from "../../recoil/atom";
import { v4 as uuidv4 } from "uuid";
import DatePicker from "react-datepicker";
const Details = () => {
  const [isEdit, setIsEdit] = useState(
    localStorage.getItem("Trial") === "Edit"
  );
  const [details, setDetails] = useRecoilState(erosion_details);
  const [trialName] = useRecoilState(trial_name);
  const isReadOnly = trialName === "viewErosionProfile";

  const handleChange = (name, value) => {
    console.log("nammeee", name);
    console.log("valuee", value);
    const TrialName = localStorage.getItem("Trial");
    if (TrialName === "new" || TrialName === "add") {
      const uniqueId = uuidv4();
      setDetails((prevState) => ({
        ...prevState,
        [name]: value,
        id: uniqueId, // Set the unique ID
        isdelete: false,
      }));
    } else {
      // If TrialName is not "new", no need to generate unique ID
      setDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Details
      </h2>

      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="cus_name"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Customer Name
          </label>
          <input
            type="text"
            id="cus_name"
            name="cus_name"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Customer Name"
            value={details.cus_name || ""}
            onChange={(event) => handleChange("cus_name", event.target.value)}
            disabled={isReadOnly}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="date_of_lining"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Date of Lining or patching or side lining (DD-MM-YYYY)
          </label>
          <DatePicker
            id="date_of_lining"
            selected={details.date_of_lining}
            onChange={(date) => handleChange("date_of_lining", date)}
            dateFormat="dd-MM-yyyy"
            placeholderText="Select Date"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </div>

      <div className="flex space-x-4 mt-4">
        {/* Customer Ref No. */}
        <div className="w-1/2">
          <label
            htmlFor="segment"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Segment
          </label>
          <select
            id="segment"
            name="segment"
            value={details.segment || ""}
            onChange={(event) => handleChange("segment", event.target.value)}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a Segment</option>
            {Helper.Segment.map((segment) => (
              <option key={segment.id} value={segment.name}>
                {segment.name}
              </option>
            ))}
          </select>
        </div>

        <div className="w-1/2">
          <label
            htmlFor="segment"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Furnace No - (Nos)
          </label>
          <select
            id="furnace_no"
            name="furnace_no"
            value={details.furnace_no || ""}
            onChange={(event) => handleChange("furnace_no", event.target.value)}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a Furnace</option>
            {Helper.FurnaceNo.map((furnace) => (
              <option key={furnace.id} value={furnace.name}>
                {furnace.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex space-x-4 mt-4">
        {/* Customer Ref No. */}
        <div className="w-1/2">
          <label
            htmlFor="crucible_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Crucible No - (Nos)
          </label>

          <select
            id="crucible_no"
            name="crucible_no"
            value={details.crucible_no || ""}
            onChange={(event) =>
              handleChange("crucible_no", event.target.value)
            }
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a Crucible</option>
            {Helper.CrucibleNo.map((crucible) => (
              <option key={crucible.id} value={crucible.name}>
                {crucible.name}
              </option>
            ))}
          </select>
        </div>

        {/* Grade */}
        <div className="w-1/2">
          <label
            htmlFor="grade"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Lining / Patching No - (No.)
          </label>
          <select
            id="lining_no"
            name="lining_no"
            value={details.lining_no || ""}
            onChange={(event) => handleChange("lining_no", event.target.value)}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a Lining</option>
            {Helper.patchingNo.map((crucible) => (
              <option key={crucible.id} value={crucible.name}>
                {crucible.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Campaign No. - (No.)
          </label>
          <select
            id="campaign_no"
            name="campaign_no"
            value={details.campaign_no || ""}
            onChange={(event) =>
              handleChange("campaign_no", event.target.value)
            }
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a campaign</option>
            {Helper.CampaignNo.map((campaign) => (
              <option key={campaign.id} value={campaign.name}>
                {campaign.name}
              </option>
            ))}
          </select>
        </div>

        {/* Grade */}
        <div className="w-1/2">
          <label
            htmlFor="furnace_capacity"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Furnace capacity (MT)
          </label>
          <input
            type="text"
            id="furnace_capacity"
            name="furnace_capacity"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Furnace capacity"
            value={details.furnace_capacity}
            onChange={(event) =>
              handleChange("furnace_capacity", event.target.value)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4 mt-4">
        {/* Customer Ref No. */}
        <div className="w-1/2">
          <label
            htmlFor="furnace_down"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Furnace Down at - (No of Heats)
          </label>
          <input
            type="text"
            id="furnace_down"
            name="furnace_down"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Furnace Down at"
            value={details.furnace_down}
            onChange={(event) =>
              handleChange("furnace_down", event.target.value)
            }
            disabled={isReadOnly}
          />
        </div>

        {/* Grade */}
        <div className="w-1/2">
          <label
            htmlFor="former_type"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Type of Former
          </label>
          <select
            id="former_type"
            name="former_type"
            value={details.former_type || ""}
            onChange={(event) =>
              handleChange("former_type", event.target.value)
            }
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a Former</option>
            {Helper.FormerType.map((FormerType) => (
              <option key={FormerType.id} value={FormerType.name}>
                {FormerType.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex space-x-4 mt-4">
        {/* Customer Ref No. */}
        <div className="w-1/2">
          <label
            htmlFor="furnace_make"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Make of the furnace
          </label>
          <select
            id="furnace_make"
            name="furnace_make"
            value={details.furnace_make || ""}
            onChange={(event) =>
              handleChange("furnace_make", event.target.value)
            }
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a Furnace Make</option>
            {Helper.FurnaceMake.map((FurnaceMake) => (
              <option key={FurnaceMake.id} value={FurnaceMake.name}>
                {FurnaceMake.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default Details;
