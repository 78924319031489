import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import TruckImage from '../../assets/icons/PCR.png'
// import DigitalFoundaryIcon from '../../assets/icons/ic_digital_foundary.svg'
// import saintGobainLogo from '../../assets/icons/saint-gobain-logo.svg'
import axios from "axios";
import jwtDecode from "jwt-decode";
import API from "../../service/API";
import configParam from "../../config";
import Furnusimg from '../../assets/images/Furnusimg.jpg'; // Your image file
import { ReactComponent as DSLogo } from '../../assets/images/DFLogo.svg'; // Your SVG or Image
import { ReactComponent as SAINTGOBAIN } from '../../assets/images/SaintGobainLogo.svg'; // Your SVG or Image

// const Container = styled(Grid)({
//   minHeight: '100vh',
//   alignItems: 'center',
//   background: 'linear-gradient(to bottom, #f0f0f0, #d0d0d0)',
//   overflow: 'hidden',
// })

const Login = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSSoLogin = () => {
    const url = configParam.RUN_SSO_URL();
    window.location.replace(url);
  };

  const navigate = useNavigate();
  useEffect(() => {
    userDetails();
  }, []);

  const userDetails = async () => {
    const url = window.location.href;
    const decodedUrl = decodeURIComponent(url);
    console.log("decodedUrl:", decodedUrl);

    const myArray = decodedUrl.split("?");
    if (myArray.length > 1) {
      const queryParameters = new URLSearchParams(window.location.search);
      console.log("queryParameters", queryParameters);

      const error = queryParameters.get("err");
      if (error) {
        console.error("Error:", error);
        setError(error || "Access denied");
        return; // Exit the function
      }

      const code = queryParameters.get("code");

      if (code) {
        const Apiurl = configParam.API_URL;
        const response = await axios.get(`${Apiurl + "login"}?code=${code}`);

        if (response.status === 200) {
          const userData = response.data;
          let access_token = userData.data.access_token.replace(/"/g, "");
          console.log("access_token", access_token);
          const token = jwtDecode(access_token);

          const apiUrl = `${API.GET_USER_DATA_FROM_ID}?id=${token.userId}`;
          console.log("Request URL:", apiUrl);
          access_token = access_token.replace(/"/g, "");
          try {
            const response = await axios.get(apiUrl, {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            });

            if (response.status === 200) {
              if (response && response.data) {
                let userData = response.data;
                console.log("userData:", userData);
                userData["access_token"] = `${access_token}`;
                localStorage.setItem("user", JSON.stringify(userData));
                localStorage.setItem("accesstoken", access_token);

                let path = "/CustomerSelection";
                if (userData.role.role_id === 2) {
                  path = `/CustomerSelection`;
                }
                console.log("Navigation Path:", path);
                navigate(path);
              }
            } else {
              console.log("Server error");
              localStorage.setItem("userDetails", "");
              setError("Error fetching user details. Please try again.");
            }
          } catch (error) {
            console.error("Request failed:", error);
            setError(
              "An error occurred while fetching user details. Please try again."
            );
          } finally {
            setLoading(false);
          }
        }
      }
    }
  };


  // const Container = styled(Grid)({
  //   minHeight: '100vh',
  //   alignItems: 'center',
  //   background: 'linear-gradient(to bottom, #f0f0f0, #d0d0d0)',
  //   overflow: 'hidden',
  // })

  // const Login = () => {
  //   const [error, setError] = useState("");
  //   const [loading, setLoading] = useState(false);

  //   const handleSSoLogin = () => {
  //     const url = configParam.RUN_SSO_URL();
  //     window.location.replace(url);
  //   };

  //   const navigate = useNavigate();
  //   useEffect(() => {
  //     userDetails();
  //   }, []);

  //   const userDetails = async () => {
  //     const url = window.location.href;
  //     const decodedUrl = decodeURIComponent(url);
  //     console.log("decodedUrl:", decodedUrl);

  //     const myArray = decodedUrl.split("?");
  //     if (myArray.length > 1) {
  //       const queryParameters = new URLSearchParams(window.location.search);
  //       console.log("queryParameters", queryParameters);

  //       const error = queryParameters.get("err");
  //       if (error) {
  //         console.error("Error:", error);
  //         setError(error || "Access denied");
  //         return; // Exit the function
  //       }

  //       const code = queryParameters.get("code");

  //       if (code) {
  //         const Apiurl = configParam.API_URL;
  //         const response = await axios.get(`${Apiurl + "login"}?code=${code}`);

  //         if (response.status === 200) {
  //           const userData = response.data;
  //           let access_token = userData.data.access_token.replace(/"/g, "");
  //           console.log("access_token", access_token);
  //           const token = jwtDecode(access_token);

  //           const apiUrl = `${API.GET_USER_DATA_FROM_ID}?id=${token.userId}`;
  //           console.log("Request URL:", apiUrl);
  //           access_token = access_token.replace(/"/g, "");
  //           try {
  //             const response = await axios.get(apiUrl, {
  //               headers: {
  //                 Authorization: `Bearer ${access_token}`,
  //               },
  //             });

  //             if (response.status === 200) {
  //               if (response && response.data) {
  //                 let userData = response.data;
  //                 console.log("userData:", userData);
  //                 userData["access_token"] = `${access_token}`;
  //                 localStorage.setItem("user", JSON.stringify(userData));
  //                 localStorage.setItem("accesstoken", access_token);

  //                 let path = "/CustomerSelection";
  //                 if (userData.role.role_id === 2) {
  //                   path = `/CustomerSelection`;
  //                 }
  //                 console.log("Navigation Path:", path);
  //                 navigate(path);
  //               }
  //             } else {
  //               console.log("Server error");
  //               localStorage.setItem("userDetails", "");
  //               setError("Error fetching user details. Please try again.");
  //             }
  //           } catch (error) {
  //             console.error("Request failed:", error);
  //             setError(
  //               "An error occurred while fetching user details. Please try again."
  //             );
  //           } finally {
  //             setLoading(false);
  //           }
  //         }
  //       }
  //     }
  //   };

  //   return (
  //     // <Grid
  //     //   container
  //     //   style={{ height: '100vh', objectFit: 'cover', overflow: 'hidden' }}
  //     // >
  //     //   <Grid item md={9} xl={9} sm={12} xs={12}>
  //     //     <img
  //     //       src={TruckImage}
  //     //       alt="Truck"
  //     //       style={{
  //     //         height: '100%',
  //     //         width: '100%',
  //     //       }}
  //     //     />
  //     //   </Grid>
  //     //   <Grid
  //     //     item
  //     //     md={3}
  //     //     xl={3}
  //     //     sm={12}
  //     //     xs={12}
  //     //     style={{
  //     //       display: 'flex',
  //     //       justifyContent: 'space-evenly',
  //     //       flexDirection: 'column',
  //     //       background: '#FFF',
  //     //       borderRadius: '10px',
  //     //     }}
  //     //   >
  //     //     <div
  //     //       style={{
  //     //         display: 'flex',
  //     //         justifyContent: 'flexStart',
  //     //         flexDirection: 'column',
  //     //         alignItems: 'center',
  //     //         padding: '20px',
  //     //       }}
  //     //     >
  //     //       <img
  //     //         src={DigitalFoundaryIcon}
  //     //         alt="Logo"
  //     //         style={{ marginTop: '30px', marginBottom: '30px' }}
  //     //       />
  //     //       <Typography
  //     //         variant="subtitle1"
  //     //         style={{ color: 'red', marginTop: '15px' }}
  //     //       >
  //     //         {error}&nbsp;
  //     //       </Typography>
  //     //       {!loading ? (
  //     //         <StyledButton
  //     //           variant="contained"
  //     //           onClick={handleSSoLogin}
  //     //           style={{
  //     //             width: '50%',
  //     //             backgroundColor: '#0086DC',
  //     //             color: '#fff',
  //     //           }}
  //     //         >
  //     //           Sign-On
  //     //         </StyledButton>
  //     //       ) : (
  //     //         <CircularProgress />
  //     //       )}
  //     //       <Typography
  //     //         variant="body2"
  //     //         style={{
  //     //           marginTop: 20,
  //     //           color: '#6E7C87',
  //     //           fontWeight: 400,
  //     //           fontSize: '12px',
  //     //           lineHeight: '14.52px',
  //     //           fontFamily: 'Inter',
  //     //         }}
  //     //       >
  //     //         Note: Login using Single Sign-On <br /> if you are Saint-Gobain
  //     //         User.
  //     //       </Typography>
  //     //     </div>
  //     //     <div
  //     //       style={{
  //     //         display: 'flex',
  //     //         flexDirection: 'column',
  //     //         alignItems: 'center',
  //     //         justifyContent: 'center',
  //     //         padding: '20px',
  //     //         backgroundColor: '#fff',
  //     //       }}
  //     //     >
  //     //       <img
  //     //         src={saintGobainLogo}
  //     //         alt="Truck"
  //     //         style={{
  //     //           height: '50px',
  //     //           width: '90px',
  //     //         }}
  //     //       />
  //     //       <Typography
  //     //         variant="h6"
  //     //         style={{
  //     //           fontFamily: 'Inter',
  //     //           fontSize: '12px',
  //     //           fontWeight: 600,
  //     //           lineHeight: '12.1px',
  //     //           letterSpacing: '-0.006em',
  //     //           textAlign: 'center',
  //     //           marginTop: '-5px',
  //     //         }}
  //     //       >
  //     //         Copyright © 2024 INDEC4.0
  //     //       </Typography>
  //     //     </div>
  //     //   </Grid>

  //     //   <style>
  //     //     {`
  //     //       @media only screen and (max-width: 1024px) and (min-width: 768px) {
  //     //         .MuiGrid-root.MuiGrid-item {
  //     //           flex-direction: column;
  //     //           align-items: center;
  //     //           text-align: center;
  //     //         }

  //     //         // .MuiGrid-root.MuiGrid-item img[alt="Truck"] {
  //     //         //   display: none;
  //     //         // }

  //     //         .MuiGrid-root.MuiGrid-item div {
  //     //           margin-top: 20px;
  //     //         }
  //     //       }
  //     //     `}
  //     //   </style>
  //     // </Grid>
  //     <div>
  //       <button
  //         type="button"
  //         className="ms-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8"
  //         onClick={handleSSoLogin}
  //         aria-label="Close"
  //       >
  //         <span className="sr-only">Close</span>
  //         <svg
  //           className="w-3 h-3"
  //           aria-hidden="true"
  //           xmlns="http://www.w3.org/2000/svg"
  //           fill="none"
  //           viewBox="0 0 14 14"
  //         >
  //           <path
  //             stroke="currentColor"
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //             strokeWidth="2"
  //             d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
  //           />
  //         </svg>
  //       </button>
  //     </div>
  //   );
  // };

  // export default Login;


  // import React from 'react';


  return (
    <div className="flex min-h-screen">
      {/* Left side - Image (80% width) */}
      <div className="lg:w-4/5 h-screen lg:flex hidden justify-center items-center">
        {/* Image with 80% of width */}
        <img
          src={Furnusimg}
          alt="Furnus"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Right side - Login form (20% width) */}
      <div className="lg:w-1/5 w-full h-screen bg-white p-8 flex flex-col justify-between">
        <div className="w-full max-w-xs m-auto">
          {/* Logo */}
          <div className="mb-6">
            <DSLogo className="w-full h-auto" />
          </div>

          {/* Login Form */}
          <h2 className="text-2xl font-semibold text-gray-700 mb-6">SSO Login</h2>
            <button
              onClick={handleSSoLogin}
              className="w-full px-3 py-2 bg-gradient-to-br from-custom-red to-custom-orange text-white rounded-md"
            >
              Sign-On
            </button>
          <p className="text-sm text-gray-600 mt-4">Note: Login using Single Sign-On if you are a Saint-Gobain User.</p>
        </div>

        {/* Note and SAINTGOBAIN logo and copyright section */}
        <div className="mt-4 text-center mb-4">
          <SAINTGOBAIN className="w-24 h-auto mx-auto" /> {/* Adjust the size of the logo */}
          <p className="text-xs text-gray-500 ">Copyright © 2024 INDEC4.0</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
