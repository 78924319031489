import React, { useEffect, useState } from "react";
import axios from "axios";
import API from "./../../service/API";
import AlertDialog from "../Common/AlertDialog";
import MultiSelect from "../Common/MultiSelect";

const AddUser = ({ closeModal, formData: initialFormData }) => {
  const [roles, setRoles] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [region, setRegion] = useState([]);
  const [formData, setFormData] = useState(
    initialFormData || {
      firstname: "",
      lastname: "",
      sgid: "",
      role_id: "",
      region_id: "",
      designation: "",
      email: "",
      contact_number: "",
      assign_customer_id: "",
    }
  );
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const access_token = localStorage.getItem("accesstoken");
  const currentUser = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    fetchRoles();
    fetchCustomers();
    fetchRegion();

    if (initialFormData) {
      setFormData({
        firstname: initialFormData.firstname || "",
        lastname: initialFormData.lastname || "",
        sgid: initialFormData.sgid || "",
        role_id: initialFormData.role_id || "",
        region_id: initialFormData.region_id || "",
        designation: initialFormData.designation || "",
        email: initialFormData.email || "",
        contact_number: initialFormData.contact_number || "",
        assign_customer_id: initialFormData.assign_customer_id || "",
      });
    }
  }, [initialFormData]);

  const fetchRoles = async () => {
    try {
      const response = await axios.get(API.ROLE, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      setRoles(response.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const fetchRegion = async () => {
    try {
      const response = await axios.get(API.REGION, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      console.log("regionnn", response.data);
      setRegion(response.data);
    } catch (error) {
      console.error("Error fetching region:", error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(API.GET_CUSTOMER, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log("formdata", formData);
  };

  const validateFormData = () => {
    const errors = {};
    console.log("formData.assign_customer_id",formData.assign_customer_id);

    if (!formData.firstname) errors.firstname = "First name is required";
    if (!formData.lastname) errors.lastname = "Last name is required";
    if (!formData.sgid) errors.sgid = "SGID is required";
    if (!formData.role_id) errors.role_id = "Role is required";
    if (!formData.assign_customer_id)
      errors.assign_customer_id = "Assign Customer is required";
    if (!formData.region_id) errors.region_id = "Region is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  function getRoleIdById(data, id) {
    const item = data.find((obj) => obj.id === id);
    return item ? item.role_id : null;
  }
  const roleId = getRoleIdById(roles, formData.role_id);

  const handleMultiSelectChange = (selectedIds) => {
    setFormData((prev) => ({
      ...prev,
      assign_customer_id: selectedIds, // Update formData with selected customer IDs
    }));
  };

  const handleSave = async () => {
    if (!validateFormData()) return;

    try {
      setLoading(true);
      const jsonData = { ...formData };

      const roleId = getRoleIdById(roles, formData.role_id);
      console.log("roleIDD", roleId);

      // Handle customer assignment logic
      if (roleId === 4 || roleId === 3) {
        jsonData.assign_customer_id = customers
          .map((customer) => customer.id)
          .join(",");
      } else {
        jsonData.assign_customer_id = formData.assign_customer_id;
      }

      let response;
      let successMessage;

      if (initialFormData && initialFormData.id) {
        // Check if user is being updated
        const updateUrl = `${API.UPDATE_USER}/${initialFormData.id}`;
        response = await axios.put(updateUrl, jsonData, {
          headers: { Authorization: `Bearer ${access_token}` },
        });
        successMessage = "User Updated Successfully";
      } else {
        response = await axios.post(API.INSERT_USER, jsonData, {
          headers: { Authorization: `Bearer ${access_token}` },
        });
        successMessage = "User Inserted Successfully";
      }

      if (response.status === 200) {
        AlertDialog({
          type: "success",
          title: "Success",
          text: successMessage,
          confirmButtonText: "Ok",
          onConfirm: async () => {
            const response = await axios.get(API.GET_USERS, {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            });
            await fetchRoles();
            await fetchCustomers();
            await fetchRegion();
            await fetchCustomers();
            // await fetchCustomers(); // Wait for customers to fetch
            closeModal(); // Close modal regardless of fetch success or failure
          },
        });
      } else {
        throw new Error("Failed to save data");
      }
    } catch (error) {
      console.error(
        "Error saving data:",
        error.response?.data || error.message || error
      );
      AlertDialog({
        type: "warning",
        title: "Error",
        text: error.response?.data?.message || "An error occurred",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setFormData({
      firstname: "",
      lastname: "",
      sgid: "",
      role_id: "",
      designation: "",
      email: "",
      contact_number: "",
      assign_customer_id: "",
    });
    setFormErrors({});
    closeModal();
  };
  return (
    <div className="w-full pr-4">
      <div
        className="w-full"
        style={{ maxHeight: "460px", minHeight: "440px" }}
      >
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <label
              htmlFor="firstname"
              className="block text-sm font-medium text-gray-700"
            >
              First Name*
            </label>
            <input
              id="firstname"
              name="firstname"
              type="text"
              value={formData.firstname}
              onChange={handleChange}
              required
              className={`w-full px-3 py-2 border rounded-md ${formErrors.firstname ? "border-red-500" : "border-gray-300"} focus:ring focus:ring-blue-300 focus:outline-none`}
            />
            {formErrors.firstname && (
              <p className="text-sm text-red-500 mt-1">
                {formErrors.firstname}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="lastname"
              className="block text-sm font-medium text-gray-700"
            >
              Last Name*
            </label>
            <input
              id="lastname"
              name="lastname"
              type="text"
              value={formData.lastname}
              onChange={handleChange}
              required
              className={`w-full px-3 py-2 border rounded-md ${formErrors.lastname ? "border-red-500" : "border-gray-300"} focus:ring focus:ring-blue-300 focus:outline-none`}
            />
            {formErrors.lastname && (
              <p className="text-sm text-red-500 mt-1">{formErrors.lastname}</p>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <label
              htmlFor="sgid"
              className="block text-sm font-medium text-gray-700"
            >
              SGID*
            </label>
            <input
              id="sgid"
              name="sgid"
              type="text"
              value={formData.sgid}
              onChange={handleChange}
              required
              className={`w-full px-3 py-2 border rounded-md ${formErrors.sgid ? "border-red-500" : "border-gray-300"} focus:ring focus:ring-blue-300 focus:outline-none`}
            />
            {formErrors.sgid && (
              <p className="text-sm text-red-500 mt-1">{formErrors.sgid}</p>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="role_id"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Role*
            </label>
            <select
              id="role_id"
              name="role_id"
              value={formData.role_id || ""} // Controlled value with a fallback
              onChange={handleChange}
              required
              className={`w-full px-3 py-2 border rounded-md ${
                formErrors.role_id ? "border-red-500" : "border-gray-300"
              } focus:ring focus:ring-blue-300 focus:outline-none text-sm`}
            >
              <option value="">Select Role</option>
              {roles.length > 0 ? (
                roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No roles available
                </option>
              )}
            </select>

            {formErrors.role_id && (
              <p className="text-sm text-red-500 mt-1">{formErrors.role_id}</p>
            )}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
        <div className="mb-4 w-full">
  <label
    htmlFor="assign_customer_id"
    className="block text-sm font-medium text-gray-700 mb-1"
  >
    Assign Customer*
  </label>
  
  {/* Render field dynamically based on roleId */}
  {roleId === 1 && (
    <select
      id="assign_customer_id"
      name="assign_customer_id"
      value={formData.assign_customer_id}
      onChange={handleChange}
      required
      className={`w-full px-3 py-2 border rounded-md ${
        formErrors.assign_customer_id ? "border-red-500" : "border-gray-300"
      } focus:ring focus:ring-blue-300 focus:outline-none text-sm`}
    >
      <option value="">Select Customer</option>
      {customers.length > 0 ? (
        customers.map((customer) => (
          <option key={customer.id} value={customer.id}>
            {customer.organization_name}
          </option>
        ))
      ) : (
        <option value="">No customers available</option>
      )}
    </select>
  )}

  {roleId === 2 && (
    <MultiSelect
      options={customers.map((customer) => ({
        value: customer.id,
        label: customer.organization_name,
      }))}
      selectedOptions={formData.assign_customer_id}
      onSelectionChange={handleMultiSelectChange}
      placeholder="Select Customers"
      className={`w-full ${
        formErrors.assign_customer_id
          ? "border-red-500"
          : "border-gray-300"
      }`}
    />
  )}

  {(roleId === 3 || roleId === 4) && (
    <input
      type="text"
      id="assign_customer_id"
      name="assign_customer_id"
      value={`All - ${customers.map((customer) => customer.organization_name).join(", ")}`}
      readOnly
      className="w-full px-3 py-2 border rounded-md border-gray-300 bg-gray-100 text-gray-700 focus:outline-none focus:ring focus:ring-blue-300 text-sm"
    />
  )}

  {/* Error message */}
  {formErrors.assign_customer_id && (
    <p className="text-sm text-red-500 mt-1">
      {formErrors.assign_customer_id}
    </p>
  )}
</div>

          <div className="mb-4 w-full">
            <label
              htmlFor="region_id"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Region *
            </label>
            <select
              id="region_id"
              name="region_id"
              value={formData.region_id}
              onChange={handleChange}
              required
              className={`w-full px-3 py-2 border rounded-md ${formErrors.region_id ? "border-red-500" : "border-gray-300"} focus:ring focus:ring-blue-300 focus:outline-none text-sm`}
            >
              <option value="">Select Region</option>
              {region.length > 0 ? (
                region.map((region) => (
                  <option key={region.id} value={region.id}>
                    {region.region_name}
                  </option>
                ))
              ) : (
                <option value="">No Region available</option>
              )}
            </select>
            {formErrors.region_id && (
              <p className="text-sm text-red-500 mt-1">
                {formErrors.region_id}
              </p>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <label
              htmlFor="designation"
              className="block text-sm font-medium text-gray-700"
            >
              Designation
            </label>
            <input
              id="designation"
              name="designation"
              type="text"
              value={formData.designation}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300 focus:outline-none"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300 focus:outline-none"
            />
          </div>
        </div>

        <div className="mb-4">
          <label
            htmlFor="contact_number"
            className="block text-sm font-medium text-gray-700"
          >
            Contact Number
          </label>
          <input
            id="contact_number"
            name="contact_number"
            type="tel"
            value={formData.contact_number}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>
      <div className="mt-4 flex justify-between">
        <button
          type="button"
          onClick={handleClose}
          className="w-1/2 h-10 px-3 py-2 rounded-md text-gray-800 border border-gray-300 text-sm font-semibold mr-2.5"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={handleSave}
          disabled={loading}
          className="w-1/2 h-10 px-3 py-2 rounded-md text-white bg-custom-red text-sm font-semibold"
        >
          {loading ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
};

export default AddUser;
