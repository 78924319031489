import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { side_lining_details, side_lining_details_nrm, trial_name } from "../../../recoil/atom";
import Helper from "../../../service/Helper";

const SideLiningDetails = () => {
  const [sideLining, setSideLining] = useRecoilState(side_lining_details)
  const [sideLiningNRM, setSideLiningNRM] = useRecoilState(
    side_lining_details_nrm
  )
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'
  const handleSideLiningChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setSideLining((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleSideLiningNRMChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setSideLiningNRM((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  useEffect(() => {
    let sideLiningObj = {
      sideLiningDetails: {
        basic: sideLining,
        nrm: sideLiningNRM,
      },
    }
  }, [sideLining, sideLiningNRM])

  useEffect(() => {
    localStorage.setItem('sideLining', JSON.stringify(sideLining))
    localStorage.setItem('sideLiningNRM', JSON.stringify(sideLiningNRM))
  }, [sideLining, sideLiningNRM])

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Side Lining Details(C)
      </h2>

      <div className="w-full mb-4">
        <label
          htmlFor="ramming_material_side_lining_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ramming material used in side lining - 1
        </label>
        <select
          id="ramming_material_side_lining_1"
          name="ramming_material_side_lining_1"
          options={Helper.CostSheet}
          value={sideLining.ramming_material_side_lining_1}
          onChange={(event) =>
            handleSideLiningChange(
              'ramming_material_side_lining_1',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_side_lining_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Quantity of material required for side lining - 1 (MT)
        </label>
        <input
          type="text"
          id="qty_side_lining_1"
          name="qty_side_lining_1"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Grade"
          value={sideLining.qty_side_lining_1}
          onChange={(event) =>
            handleSideLiningChange('qty_side_lining_1', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_2"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material used in Spout and Top - 2
        </label>
        <select
          id="material_used_spout_and_top_2"
          name="material_used_spout_and_top_2"
          value={sideLining.material_used_spout_and_top_2}
          onChange={(event) =>
            handleSideLiningChange(
              'material_used_spout_and_top_2',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a furnace</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_side_lining_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Qty. of Material required for Spout & Top - 2 (MT)
        </label>
        <input
          type="text"
          id="qty_spout_and_top_2"
          name="qty_spout_and_top_2"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Grade"
          value={sideLining.qty_spout_and_top_2}
          onChange={(event) =>
            handleSideLiningChange('qty_side_lining_1', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_top_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material used in spout and top - 3
        </label>
        <select
          id="material_used_top_spout_and_top_3"
          name="material_used_top_spout_and_top_3"
          value={sideLining.material_used_top_spout_and_top_3}
          onChange={(event) =>
            handleSideLiningChange(
              'material_used_top_spout_and_top_3',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Qty. of Material required for Spout & Top - 3
        </label>
        <input
          type="text"
          id="qty_spout_and_top_3"
          name="qty_spout_and_top_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={sideLining.qty_spout_and_top_3}
          onChange={(event) =>
            handleSideLiningChange('qty_spout_and_top_3', event.target.value)
          }
        />
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="average_side_lining_life"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average Side Lining Life (Heat / lining)
          </label>
          <input
            type="text"
            id="average_side_lining_life"
            name="average_side_lining_life"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sideLining.average_side_lining_life}
            onChange={(event) =>
              handleSideLiningChange(
                'average_side_lining_life',
                event.target.value
              )
            }
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="no_of_side_lining"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            No of Side Lining (No)
          </label>
          <input
            type="text"
            id="no_of_side_lining"
            name="no_of_side_lining"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sideLining.no_of_side_lining}
            onChange={(event) =>
              handleSideLiningChange('no_of_side_lining', event.target.value)
            }
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="avg_heat_size"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average heat size per Side lining (MT)
          </label>
          <input
            type="text"
            id="avg_heat_size"
            name="avg_heat_size"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={sideLining.avg_heat_size}
            onChange={(event) =>
              handleSideLiningChange('avg_heat_size', event.target.value)
            }
          />
        </div>
      </div>


      <h1 className="text-l font-medium text-gray-800 mb-4 uppercase">
        NRM
      </h1>

      <div className="w-full mb-4">
        <label
          htmlFor="ramming_material_side_lining_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ramming material used in side lining - 1
        </label>
        <select
          id="ramming_material_side_lining_1"
          name="ramming_material_side_lining_1"
          options={Helper.CostSheet}
          value={sideLiningNRM.ramming_material_side_lining_1}
          onChange={(event) =>
            handleSideLiningNRMChange(
              'ramming_material_side_lining_1',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_side_lining_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Quantity of material required for side lining - 1 (MT)
        </label>
        <input
          type="text"
          id="qty_side_lining_1"
          name="qty_side_lining_1"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Quantity"
          value={sideLiningNRM.qty_side_lining_1}
          onChange={(event) =>
            handleSideLiningNRMChange('qty_side_lining_1', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_2"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material used in Spout and Top - 2
        </label>
        <select
          id="material_used_spout_and_top_2"
          name="material_used_spout_and_top_2"
          value={sideLiningNRM.material_used_spout_and_top_2}
          onChange={(event) =>
            handleSideLiningNRMChange(
              'material_used_spout_and_top_2',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_side_lining_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Qty. of Material required for Spout & Top - 2 (MT)
        </label>
        <input
          type="text"
          id="qty_spout_and_top_2"
          name="qty_spout_and_top_2"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Quantity"
          value={sideLiningNRM.qty_spout_and_top_2}
          onChange={(event) =>
            handleSideLiningNRMChange('qty_side_lining_1', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_top_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material used in spout and top - 3
        </label>
        <select
          id="material_used_top_spout_and_top_3"
          name="material_used_top_spout_and_top_3"
          value={sideLiningNRM.material_used_top_spout_and_top_3}
          onChange={(event) =>
            handleSideLiningNRMChange(
              'material_used_top_spout_and_top_3',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Qty. of Material required for Spout & Top - 3 (MT)
        </label>
        <input
          type="text"
          id="qty_spout_and_top_3"
          name="qty_spout_and_top_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Quantity"
          value={sideLiningNRM.qty_spout_and_top_3}
          onChange={(event) =>
            handleSideLiningNRMChange('qty_spout_and_top_3', event.target.value)
          }
        />
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="average_side_lining_life"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average Side Lining Life (Heat / Lining)
          </label>
          <input
            type="text"
            id="average_side_lining_life"
            name="average_side_lining_life"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter  Average Side Lining Life"
            value={sideLiningNRM.average_side_lining_life}
            onChange={(event) =>
              handleSideLiningNRMChange(
                'average_side_lining_life',
                event.target.value
              )
            }
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="no_of_side_lining"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            No of Side Lining (No)
          </label>
          <input
            type="text"
            id="no_of_side_lining"
            name="no_of_side_lining"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter No of Side Lining "
            value={sideLiningNRM.no_of_side_lining}
            onChange={(event) =>
              handleSideLiningNRMChange('no_of_side_lining', event.target.value)
            }
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="avg_heat_size"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average heat size per Side lining (MT)
          </label>
          <input
            type="text"
            id="avg_heat_size"
            name="avg_heat_size"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter heat size"
            value={sideLiningNRM.avg_heat_size}
            onChange={(event) =>
              handleSideLiningNRMChange('avg_heat_size', event.target.value)
            }
          />
        </div>
      </div>
    </>
  );
};

export default SideLiningDetails;
