import React from 'react';
import { useRecoilState } from 'recoil';
import { mild_steel_customer, trial_name } from '../../recoil/atom';
import { v4 as uuidv4 } from 'uuid'

const RawMaterialWeb = () => {
  const [mildSteel, setMildSteel] = useRecoilState(mild_steel_customer)
  console.log(mildSteel, "mildSteel")
  const uuid = uuidv4()
  const [trialName] = useRecoilState(trial_name)

  const handleChange = (e) => {
    const { name, value } = e.target
    setMildSteel((prevState) => ({
      ...prevState,
      [name]: value,
      id: uuid,
    }))
  }

  const isReadOnly = trialName === 'viewDataCollection'

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">Mild Steel Customers Only</h2>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label htmlFor="deslagging_by" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Deslagging by - (spoon/Tilt)
          </label>
          <input
            type="text"
            id="deslagging_by"
            name="deslagging_by"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Deslagging"
            value={mildSteel.deslagging_by}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>

        {/* Date */}
        <div className="w-full mb-4">
          <label htmlFor="using_magnet_or_not" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            use Magnet to remove the slag jam after every deslagging - (Yes/No)
          </label>
          <input
            type="text"
            id="using_magnet_or_not"
            name="using_magnet_or_not"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter"
            value={mildSteel.using_magnet_or_not}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label htmlFor="avg_c_percent_at_50" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Avg. C% at 50% of Bath Metal - %
          </label>
          <input
            type="text"
            id="avg_c_percent_at_50"
            name="avg_c_percent_at_50"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Avg. C%"
            value={mildSteel.avg_c_percent_at_50}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label htmlFor="avg_c_percent_at_80" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Avg. C% at 80% of Bath Metal - %
          </label>
          <input
            type="text"
            id="avg_c_percent_at_80"
            name="avg_c_percent_at_80"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter percentage"
            value={mildSteel.avg_c_percent_at_80}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label htmlFor="final_c_percent_beforeTapping" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Final C% Before Tapping - %
          </label>
          <input
            type="text"
            id="final_c_percent_beforeTapping"
            name="final_c_percent_beforeTapping"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Percentage"
            value={mildSteel.final_c_percent_beforeTapping}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label htmlFor="data_mill_scale" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Mill Scale / heat - Kg
          </label>
          <input
            type="text"
            id="data_mill_scale"
            name="data_mill_scale"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Mill Scale"
            value={mildSteel.data_mill_scale}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="tilt_count_for_deslagging" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            No of times TILT the Fce for deslagging per heat - No.
          </label>
          <input
            type="text"
            id="tilt_count_for_deslagging"
            name="tilt_count_for_deslagging"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Nos"
            value={mildSteel.tilt_count_for_deslagging}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label htmlFor="grade" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Are they taking Liquid metal up to full level
          </label>
          <input
            type="text"
            id="are_they_taking_liquid_metal"
            name="are_they_taking_liquid_metal"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter"
            value={mildSteel.are_they_taking_liquid_metal}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label htmlFor="dri_quality" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            DRI Quality (Lumps / Pellets / Fines) - Ratio
          </label>
          <input
            type="text"
            id="dri_quality"
            name="dri_quality"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter DRI QUALITY"
            value={mildSteel.dri_quality}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label htmlFor="grade" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Slag Generation per heat - %
          </label>
          <input
            type="text"
            id="slag_generation_per_heat"
            name="slag_generation_per_heat"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Slag"
            value={mildSteel.slag_generation_per_heat}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="power_utilised" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Power utilised - (Own/Grid)
          </label>
          <input
            type="text"
            id="power_utilised"
            name="power_utilised"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Power"
            value={mildSteel.power_utilised}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label htmlFor="silica_price" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Silica Price ,( Landed Price) - INR / Kg
          </label>
          <input
            type="text"
            id="silica_price"
            name="silica_price"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter price"
            value={mildSteel.silica_price}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
      </div>


      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label htmlFor="ccm" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            CCM (Avg casting speed) - (m/min)
          </label>
          <input
            type="text"
            id="ccm"
            name="ccm"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter CCM"
            value={mildSteel.ccm}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>

        <div className="w-full mb-4">
          <label htmlFor="casting_time" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Casting time - (min)
          </label>
          <input
            type="text"
            id="casting_time"
            name="casting_time"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Grade"
            value={mildSteel.casting_time}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="tundish_cap" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Tundish cap - (MT)
          </label>
          <input
            type="text"
            id="tundish_cap"
            name="tundish_cap"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Customer Ref No."
            value={mildSteel.tundish_cap}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label htmlFor="min_level_of_tundish" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Min level of tundish - (MT)
          </label>
          <input
            type="text"
            id="min_level_of_tundish"
            name="min_level_of_tundish"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter level"
            value={mildSteel.min_level_of_tundish}
          onChange={handleChange}
          disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="billet_size" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Billet size in (LXHXT) - (mm X mm X mm)
          </label>
          <input
            type="text"
            id="billet_size"
            name="billet_size"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Billet Size"
            value={mildSteel.billet_size}
          onChange={handleChange}
          disabled={isReadOnly}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label htmlFor="no_of_sequence" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            No of sequence - (each)
          </label>
          <input
            type="text"
            id="no_of_sequence"
            name="no_of_sequence"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Nos"
            value={mildSteel.no_of_sequence}
          onChange={handleChange}
          disabled={isReadOnly}
          />
        </div>
      </div>


      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="ccm_make" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            CCM make - OEM
          </label>
          <input
            type="text"
            id="ccm_make"
            name="ccm_make"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter CCM Make"
            value={mildSteel.ccm_make}
          onChange={handleChange}
          disabled={isReadOnly}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label htmlFor="ccm_preparation_time" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            CCM Preparation time in Minutes - (mins)
          </label>
          <input
            type="text"
            id="ccm_preparation_time"
            name="ccm_preparation_time"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter CCM time"
            value={mildSteel.ccm_preparation_time}
          onChange={handleChange}
          disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="customer_ref_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Objective / Purpose of using NRM
          </label>
          <input
            type="text"
            id="purpose_of_using_nrm"
            name="purpose_of_using_nrm"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Purpose"
            value={mildSteel.purpose_of_using_nrm}
          onChange={handleChange}
          disabled={isReadOnly}
          />
        </div>
      </div>

    </>
  );
};

export default RawMaterialWeb;
