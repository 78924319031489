import React, { useState, useEffect, useRef } from "react";
import DailyTrialMonitoring from "./DailyTriallMonitoring";
import NRMPerformance from "./NRMPerformance";
import API from "./../../service/API";
import AlertDialog from "./../Common/AlertDialog";
import axios from "axios";
import FileSaver from "file-saver";
import JSZip, { forEach } from "jszip";
import * as XLSX from "xlsx";
import {
  BrowserRouter as Router,
  Link,
  useNavigate,
  json,
} from "react-router-dom"; // Import Routes
import ErosionProfile from "./ErosionProfile";
import DataCollection from "./DataCollection";
import CostSheet from "./CostSheet";
import ModalLayout from "../Common/ModalLayout";
import { ReactComponent as UploadIcon } from '../../assets/images/icons/upload.svg'
import { ReactComponent as AddIcon } from '../../assets/images/icons/AddIcon.svg'
import { ReactComponent as DownloadIcon } from '../../assets/images/icons/downloadIcon.svg'
import ExcelIcon from '../../assets/images/SVGIcon/Sheet.svg'
import { ReactComponent as FilterIcon } from '../../assets/images/SVGIcon/FilterIcon.svg'

const DataManagementLayout = () => {
  const [activeTab, setActiveTab] = useState("DailyTrialMonitoringTab");
  const options = ["Option 1", "Option 2", "Option 3", "Option 4"];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const storedCustomerDet = JSON.parse(
    localStorage.getItem("selectedCustomer")
  );

  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();
  const [showModals, setShowModal] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [furnaceCapacity, setFurnaceCapacity] = useState("");
  const [segment, setSegment] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [fileTemplate, setfileTemplate] = useState([]);
  const [selectedSegment, setselectedSegment] = useState("");
  const [file, setFile] = useState(null);
  const [fileexcel, setFileExcel] = useState([]);
  const [cards, setCards] = useState([]);
  const [startRow, setStartRow] = useState(13);
  const access_token = localStorage.getItem("accesstoken");
  const [dailyTrials, setDailyTrials] = useState([]);
  console.log(dailyTrials, "dailyTrials");
  const [dataCollection, setDataCollection] = useState([]);
  const [costSheetData, setCostSheetData] = useState([]);
  const [nrmPerformanceData, setNRMPerformanceData] = useState([]);
  const [erosionProfileData, setErosionProfileData] = useState([]);

  const [filteredDailyTrials, setFilteredDailyTrials] = useState([]);
  const [filteredDataCollection, setFilteredDataCollection] = useState([]);
  const [filteredErosionProfile, setFilteredErosionProfile] = useState([]);
  const [filteredCostSheetData, setFilteredCostSheetData] = useState([]);
  const [filteredNRMPerformanceData, setFilteredNRMPerformanceData] = useState(
    []
  );

  const [cardsDraft, setCardsDraft] = useState([]);
  const [cardsSubmitted, setCardsSubmitted] = useState([]);
  const [cardsApproved, setCardsApproved] = useState([]);
  const [cardsRejected, setCardsRejected] = useState([]);
  const [nrmcardsDraft, setNRMCardsDraft] = useState([]);
  const [nrmcardsSubmitted, setNRMCardsSubmitted] = useState([]);
  const [nrmCardsApproved, setNrmCardsApproved] = useState([]);
  const [nrmCardsRejected, setNrmCardsRejected] = useState([]);
  const [erosionProfileDraft, setErosionProfileDraft] = useState([]);
  const [erosionProfileSubmitted, setErosionProfileSubmitted] = useState([]);
  const [erosionProfileApproved, setErosionProfileApproved] = useState([]);
  const [erosionProfileRejected, setErosionProfileRejected] = useState([]);
  const [dataCollectionDraft, setDataCollectionDraft] = useState([]);
  const [dataCollectionSubmitted, setDataCollectionSubmitted] = useState([]);
  const [dataCollectionApproved, setDataCollectioApproved] = useState([]);
  const [dataCollectionRejected, setDataCollectionRejected] = useState([]);
  const [costSheetSubmitted, setCostSheetSubmitted] = useState([]);
  const [costSheetDraft, setCostSheetDraft] = useState([]);
  const [costSheetApproved, setCostSheetApproved] = useState([]);
  const [costSheetRejected, setCostSheetRejected] = useState([]);

  const [approvedData, setapprovedData] = useState([]);
  const [rejectData, setrejectData] = useState([]);
  const [selectedSegmentList, setselectedSegmentList] = useState([]);
  const [SegmentList, setSegmentList] = useState([]);
  const [templateId, setTemplateId] = useState("");
  const [Segmentid, setSegmentid] = useState("");
  const [customers, setCustomers] = useState([]);
  const [selectCustomers, setSelectCustomers] = useState([]);
  const [segments, setSegments] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [furnaces, setFurnaces] = useState([]);
  const [formData, setFormData] = useState({
    segment_id: "",
    customer_id: "",
    template_id: "",
    furnace_id: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [blobtemplates, setBlobTemplates] = useState([]);
  const [templateNames, setTemplateNames] = useState([]);
  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const selectedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));
  const customer_id = selectedCustomer?.id;
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectCustomer, setSelectCustomer] = useState("");

  const handleOpen = () => setOpen(true);
  const handleFilterClose = () => setOpen(false);

  const dropdownRef = useRef(null);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleDownload = async (templateName) => {
    console.log(templateName, 'templateName')
    try {
      const template = blobtemplates.find((t) => t.name === templateName)
      if (template) {
        const byteCharacters = atob(template.data)
        const byteNumbers = new Array(byteCharacters.length)

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        FileSaver.saveAs(blob, template.name)
      } else {
        console.error(`Template ${templateName} not found`)
      }
    } catch (error) {
      console.error(`Error downloading ${templateName} template:`, error)
    }
  }

  const handleDownloadAll = async () => {
    try {
      const zip = new JSZip()

      blobtemplates.forEach((template) => {
        const byteCharacters = atob(template.data)
        const byteNumbers = new Array(byteCharacters.length)

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        zip.file(template.name, blob)
      })

      const zipBlob = await zip.generateAsync({ type: 'blob' })
      FileSaver.saveAs(zipBlob, 'All Templates.zip')
    } catch (error) {
      console.error('Error downloading all templates:', error)
    }
  }

  const handleClose = () => {
    setShowModal(false)
    setOpen(false)
  }
  const fetchCards = async () => {
    try {
      if (currentUser.role.role_id === 1) {
        const userId = currentUser.id;
        const selectedCustomer = JSON.parse(
          localStorage.getItem("selectedCustomer")
        );
        const customer_id = selectedCustomer?.id;
        console.log(customer_id, "customer_id");
        const response = await axios.get(API.GET_ALL_DAILY_TRIAL_MONITORING, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const dailyTrialResponse = response.data;
        console.log(
          dailyTrialResponse,
          "dailyTrialResponsedailyT123rialResponse"
        );
        if (Array.isArray(dailyTrialResponse)) {
          let filteredResponse = dailyTrialResponse.filter(
            (item) =>
              item.dataFile.customer_id === customer_id &&
              item.dataFile.user_id === userId
          );
          console.log(filteredResponse, "filteredResponse123");
          setDailyTrials(filteredResponse);
          console.log();

          let cardsDraftArray = [];
          let cardsSubmittedArray = [];
          let cardsApprovedArray = [];
          let cardsRejectedArray = [];

          filteredResponse.forEach((item, index) => {
            const generalDetails = item.general_details;
            if (Array.isArray(generalDetails) && generalDetails.length > 0) {
              const submissionStatus =
                generalDetails?.[0]?.submission_status || "test";
              if (
                submissionStatus === "draft" &&
                item.dataFile.filename === null
              ) {
                cardsDraftArray.push(item);
              } else if (submissionStatus === "submitted") {
                cardsSubmittedArray.push(item);
              } else if (submissionStatus === "approved") {
                cardsApprovedArray.push(item);
              } else {
                cardsRejectedArray.push(item);
              }
            } else {
              console.log(
                `general_details array is empty or not an array for item ${index}`
              );
            }
          });

          setCardsDraft(cardsDraftArray);
          setCardsSubmitted(cardsSubmittedArray);
          setCardsRejected(cardsRejectedArray);
          setCardsApproved(cardsApprovedArray);
        } else {
          console.log("dailyTrialResponse is not an array");
        }

        //  nrm performance
        const nrmresponse = await axios.get(API.GET_ALL_NRM_PERFORMANCE, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const nrmPerformnaceResponse = nrmresponse.data;
        if (Array.isArray(nrmPerformnaceResponse)) {
          let filteredResponse = nrmPerformnaceResponse.filter(
            (item) =>
              item.dataFile.customer_id === customer_id &&
              item.dataFile.user_id === userId
          );
          setNRMPerformanceData(filteredResponse);

          let nrmcardsDraftArray = [];
          let nrmcardsSubmittedArray = [];
          let nrmCardsApprovedArray = [];
          let nrmCardsRejectedArray = [];

          filteredResponse.forEach((item, index) => {
            const submissionStatus = item.lining_reference[0].submission_status;
            if (
              submissionStatus === "draft" &&
              item.dataFile.filename === null
            ) {
              nrmcardsDraftArray.push(item);
            } else if (submissionStatus === "submitted") {
              nrmcardsSubmittedArray.push(item);
            } else if (submissionStatus === "approved") {
              nrmCardsApprovedArray.push(item);
            } else {
              nrmCardsRejectedArray.push(item);
            }
          });
          setNRMCardsDraft(nrmcardsDraftArray);
          setNRMCardsSubmitted(nrmcardsSubmittedArray);
          setNrmCardsApproved(nrmCardsApprovedArray);
          setNrmCardsRejected(nrmCardsRejectedArray);
        } else {
          console.log("nrm Performance is not an array");
        }

        //data collection
        const responseData = await axios.get(API.GET_ALL_DATA_COLLECTION, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const dataCollectionResponse = responseData.data;
        console.log(responseData, "responseData");

        if (Array.isArray(dataCollectionResponse)) {
          let filteredResponse = dataCollectionResponse.filter(
            (item) =>
              item.dataFile.customer_id === customer_id &&
              item.dataFile.user_id === userId
          );
          setDataCollection(filteredResponse);

          let dataCollectioncardsDraftArray = [];
          let dataCollectioncardsSubmittedArray = [];
          let dataCollectionApprovedArray = [];
          let dataCollectionRejectedArray = [];

          filteredResponse.forEach((item, index) => {
            const submissionStatus = item.submission_status;
            if (
              item.dataFile.customer_id === customer_id &&
              item.dataFile.user_id === userId
            ) {
              if (
                submissionStatus === "draft" &&
                item.dataFile.filename === null
              ) {
                dataCollectioncardsDraftArray.push(item);
              } else if (submissionStatus === "submitted") {
                dataCollectioncardsSubmittedArray.push(item);
              } else if (submissionStatus === "approved") {
                dataCollectionApprovedArray.push(item);
              } else {
                dataCollectionRejectedArray.push(item);
              }
            } else {
              console.log(
                `lining_reference array is empty or not an array for item ${index}`
              );
            }
          });
          setDataCollectionSubmitted(dataCollectioncardsSubmittedArray);
          setDataCollectionDraft(dataCollectioncardsDraftArray);
          setDataCollectioApproved(dataCollectionApprovedArray);
          setDataCollectionRejected(dataCollectionRejectedArray);
        } else {
          console.log("nrm Performance is not an array");
        }

        // cost sheet
        const costSheetData = await axios.get(API.GET_ALL_COST_SHEETS, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const costSheetResponse = costSheetData.data;
        if (Array.isArray(costSheetResponse)) {
          let filteredResponse = costSheetResponse.filter(
            (item) =>
              item.dataFile.customer_id === customer_id &&
              item.dataFile.user_id === userId
          );
          setCostSheetData(filteredResponse);

          let costSheetCardsDraftArray = [];
          let costSheetCardsSubmittedArray = [];
          let costSheetApprovedArray = [];
          let costSheetRejectedArray = [];

          filteredResponse.forEach((item, index) => {
            const submissionStatus = item.submission_status;
            if (
              submissionStatus === "draft" &&
              item.dataFile.filename === null
            ) {
              costSheetCardsDraftArray.push(item);
            } else if (submissionStatus === "submitted") {
              costSheetCardsSubmittedArray.push(item);
            } else if (submissionStatus === "approved") {
              costSheetApprovedArray.push(item);
            } else {
              costSheetRejectedArray.push(item);
            }
          });
          setCostSheetSubmitted(costSheetCardsSubmittedArray);
          setCostSheetDraft(costSheetCardsDraftArray);
          setCostSheetApproved(costSheetApprovedArray);
          setCostSheetRejected(costSheetRejectedArray);
        } else {
          console.log("nrm Performance is not an array");
        }
        // erosion Profile
        const erosionResponse = await axios.get(API.GET_ALL_EROSION_PROFILE, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const erosionData = erosionResponse.data;
        console.log(erosionData, "erosiondata");
        if (Array.isArray(erosionData)) {
          let filteredResponse = erosionData.filter(
            (item) =>
              item.dataFile.customer_id === customer_id &&
              item.dataFile.user_id === userId
          );
          setErosionProfileData(filteredResponse);
          console.log(filteredResponse, "filteredResponseerosion");
          let erosioncardsDraftArray = [];
          let erosionDatacardsSubmittedArray = [];
          let erosionDataCardsApprovedArray = [];
          let erosionDataCardsRejectedArray = [];

          filteredResponse.forEach((item) => {
            const submissionStatus = item.submission_status;
            if (
              submissionStatus === "draft" &&
              item.dataFile.filename === null
            ) {
              erosioncardsDraftArray.push(item);
            } else if (submissionStatus === "submitted") {
              erosionDatacardsSubmittedArray.push(item);
            } else if (submissionStatus === "approved") {
              erosionDataCardsApprovedArray.push(item);
            } else {
              erosionDataCardsRejectedArray.push(item);
            }
          });

          setErosionProfileDraft(erosioncardsDraftArray);
          setErosionProfileSubmitted(erosionDatacardsSubmittedArray);
          setErosionProfileRejected(erosionDataCardsRejectedArray);
          setErosionProfileApproved(erosionDataCardsApprovedArray);
        } else {
          console.log("NRM performance response is not an array");
        }
      } else if (currentUser.role.role_id === 2) {
        const assignCustomerIds = currentUser.assign_customer_id.map(
          (id) => id
        );

        const selectedCustomer = JSON.parse(
          localStorage.getItem("selectedCustomer")
        );
        const customer_id = selectedCustomer?.id;
        const userId = currentUser.id;

        const response = await axios.get(API.GET_ALL_DAILY_TRIAL_MONITORING, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const dailyTrialResponse = response.data;

        if (Array.isArray(dailyTrialResponse)) {
          let filteredResponse = dailyTrialResponse.filter(
            (item) =>
              assignCustomerIds.includes(item.dataFile.customer_id) &&
              item.dataFile.customer_id === customer_id
          );

          setDailyTrials(filteredResponse);

          let cardsDraftArray = [];
          let cardsSubmittedArray = [];
          let cardsApprovedArray = [];
          let cardsRejectedArray = [];
          filteredResponse.forEach((item) => {
            const generalDetails = item.general_details;
            if (Array.isArray(generalDetails) && generalDetails.length > 0) {
              const submissionStatus = generalDetails[0].submission_status;
              if (
                submissionStatus === "draft" &&
                item.dataFile.filename === null
              ) {
                cardsDraftArray.push(item);
              } else if (submissionStatus === "submitted") {
                cardsSubmittedArray.push(item);
              } else if (submissionStatus === "approved") {
                cardsApprovedArray.push(item);
              } else {
                cardsRejectedArray.push(item);
              }
            }
          });

          console.log("cardsDraftArray", cardsDraftArray);
          console.log("cardsSubmittedArray", cardsSubmittedArray);

          setCardsDraft(cardsDraftArray);
          setCardsSubmitted(cardsSubmittedArray);
          setCardsRejected(cardsRejectedArray);
          setCardsApproved(cardsApprovedArray);
        } else {
          console.log("dailyTrialResponse is not an array");
        }

        // nrm
        const nrmresponse = await axios.get(API.GET_ALL_NRM_PERFORMANCE, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const nrmPerformnaceResponse = nrmresponse.data;
        if (Array.isArray(nrmPerformnaceResponse)) {
          let filteredResponse = nrmPerformnaceResponse.filter(
            (item) =>
              assignCustomerIds.includes(item.dataFile.customer_id) &&
              item.dataFile.customer_id === customer_id
          );
          setNRMPerformanceData(filteredResponse);

          let nrmcardsDraftArray = [];
          let nrmcardsSubmittedArray = [];
          let nrmCardsApprovedArray = [];
          let nrmCardsRejectedArray = [];

          filteredResponse.forEach((item, index) => {
            if (assignCustomerIds.includes(item.dataFile.customer_id)) {
              const liningReference = item.lining_reference;
              const submissionStatus = liningReference[0].submission_status;
              if (
                submissionStatus === "draft" &&
                item.dataFile.filename === null
              ) {
                nrmcardsDraftArray.push(item);
              } else if (submissionStatus === "submitted") {
                nrmcardsSubmittedArray.push(item);
              } else if (submissionStatus === "approved") {
                nrmCardsApprovedArray.push(item);
              } else {
                nrmCardsRejectedArray.push(item);
              }
            }
          });

          setNRMCardsDraft(nrmcardsDraftArray);
          setNRMCardsSubmitted(nrmcardsSubmittedArray);
          setNrmCardsRejected(nrmCardsRejectedArray);
          setNrmCardsApproved(nrmCardsApprovedArray);
        } else {
          console.log("NRM performance response is not an array");
        }

        //data collection
        const dataCollectionResponse = await axios.get(
          API.GET_ALL_DATA_COLLECTION,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        const responseDataCollection = dataCollectionResponse.data;
        console.log(responseDataCollection, "responseDataCollection");

        if (Array.isArray(responseDataCollection)) {
          let filteredResponse = responseDataCollection.filter(
            (item) =>
              assignCustomerIds.includes(item.dataFile.customer_id) &&
              item.dataFile.customer_id === customer_id
          );
          setDataCollection(filteredResponse);

          let datacollectionDraftArray = [];
          let datacollectionSubmittedArray = [];
          let dataCollectionApprovedArray = [];
          let dataCollectionRejectedArray = [];

          filteredResponse.forEach((item, index) => {
            const submissionStatus = item.submission_status;
            if (
              submissionStatus === "draft" &&
              item.dataFile.filename === null
            ) {
              datacollectionDraftArray.push(item);
            } else if (submissionStatus === "submitted") {
              datacollectionSubmittedArray.push(item);
            } else if (submissionStatus === "approved") {
              dataCollectionApprovedArray.push(item);
            } else {
              dataCollectionRejectedArray.push(item);
            }
          });

          setDataCollectionSubmitted(datacollectionSubmittedArray);
          setDataCollectionDraft(datacollectionDraftArray);
          setDataCollectioApproved(dataCollectionApprovedArray);
          setDataCollectionRejected(dataCollectionRejectedArray);
        } else {
          console.log("Data collection response is not an array");
        }

        // cost sheet
        const costSheetData = await axios.get(API.GET_ALL_COST_SHEETS, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const costSheetResponse = costSheetData.data;

        if (Array.isArray(costSheetResponse)) {
          let filteredResponse = costSheetResponse.filter(
            (item) =>
              item.dataFile.customer_id === customer_id &&
              item.dataFile.user_id === userId
          );
          setCostSheetData(filteredResponse);
          let costSheetCardsDraftArray = [];
          let costSheetCardsSubmittedArray = [];
          let costSheetRejectedArray = [];
          let costSheetApprovedArray = [];

          costSheetResponse.forEach((item, index) => {
            const submissionStatus = item.submission_status;
            if (assignCustomerIds.includes(item.dataFile.customer_id)) {
              if (
                submissionStatus === "draft" &&
                item.dataFile.filename === null
              ) {
                costSheetCardsDraftArray.push(item);
              } else if (submissionStatus === "submitted") {
                costSheetCardsSubmittedArray.push(item);
              }
            } else if (submissionStatus === "approved") {
              costSheetApprovedArray.push(item);
            } else {
              costSheetRejectedArray.push(item);
            }
          });
          setCostSheetSubmitted(costSheetCardsSubmittedArray);
          setCostSheetDraft(costSheetCardsDraftArray);
          setCostSheetApproved(costSheetApprovedArray);
          setCostSheetRejected(costSheetRejectedArray);
        } else {
          console.log("Cost sheet response is not an array");
        }

        // erosion Profile
        const erosionResponse = await axios.get(API.GET_ALL_EROSION_PROFILE, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const erosionData = erosionResponse.data;
        console.log(erosionData, "erosiondata");
        if (Array.isArray(erosionData)) {
          let filteredResponse = erosionData.filter(
            (item) =>
              item.dataFile.customer_id === customer_id &&
              item.dataFile.user_id === userId
          );
          setErosionProfileData(filteredResponse);

          let erosioncardsDraftArray = [];
          let erosionDatacardsSubmittedArray = [];
          let erosionDataCardsApprovedArray = [];
          let erosionDataCardsRejectedArray = [];

          filteredResponse.forEach((item) => {
            if (assignCustomerIds.includes(item.dataFile.customer_id)) {
              const submissionStatus = item.submission_status;
              if (
                submissionStatus === "draft" &&
                item.dataFile.filename === null
              ) {
                erosioncardsDraftArray.push(item);
              } else if (submissionStatus === "submitted") {
                erosionDatacardsSubmittedArray.push(item);
              } else if (submissionStatus === "approved") {
                erosionDataCardsApprovedArray.push(item);
              } else {
                erosionDataCardsRejectedArray.push(item);
              }
            }
          });

          setErosionProfileDraft(erosioncardsDraftArray);
          setErosionProfileSubmitted(erosionDatacardsSubmittedArray);
          setErosionProfileRejected(erosionDataCardsRejectedArray);
          setErosionProfileApproved(erosionDataCardsApprovedArray);
        } else {
          console.log("NRM performance response is not an array");
        }
      } else {
        const response = await axios.get(API.GET_ALL_DAILY_TRIAL_MONITORING, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const dailyTrialResponse = response.data;
        setDailyTrials(dailyTrialResponse);
        const userId = currentUser.id;
        const selectedCustomer = JSON.parse(
          localStorage.getItem("selectedCustomer")
        );
        const customer_id = selectedCustomer?.id;
        let filteredResponse = dailyTrialResponse.filter(
          (item) => item.dataFile.customer_id === customer_id
        );

        if (Array.isArray(dailyTrialResponse)) {
          let cardsDraftArray = [];
          let cardsSubmittedArray = [];
          let cardsApprovedArray = [];
          let cardsRejectedArray = [];

          filteredResponse.forEach((item, index) => {
            const generalDetails = item.general_details;
            if (Array.isArray(generalDetails) && generalDetails.length > 0) {
              const submissionStatus =
                generalDetails?.[0]?.submission_status ?? "test";
              if (
                submissionStatus === "draft" &&
                item.dataFile.filename === null
              ) {
                cardsDraftArray.push(item);
              } else if (submissionStatus === "submitted") {
                cardsSubmittedArray.push(item);
              } else if (submissionStatus === "approved") {
                cardsApprovedArray.push(item);
              } else {
                cardsRejectedArray.push(item);
              }
            } else {
              console.log(
                `general_details array is empty or not an array for item ${index}`
              );
            }
          });

          console.log("cardsRejectedArray", cardsRejectedArray);

          setCardsDraft(cardsDraftArray);
          setCardsSubmitted(cardsSubmittedArray);
          setCardsApproved(cardsApprovedArray);
          setCardsRejected(cardsRejectedArray);
        } else {
          console.log("dailyTrialResponse is not an array");
        }

        // nrm
        const nrmresponse = await axios.get(API.GET_ALL_NRM_PERFORMANCE, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const nrmPerformnaceResponse = nrmresponse.data;
        // setNRMPerformanceData(nrmPerformanceData);
        const NRMfilteredResponse = nrmPerformnaceResponse.filter(
          (item) => item.dataFile.customer_id === customer_id
        );

        if (Array.isArray(NRMfilteredResponse)) {
          let nrmcardsDraftArray = [];
          let nrmcardsSubmittedArray = [];
          let nrmcardsApprovedArray = [];
          let nrmCardsRejectedArray = [];

          NRMfilteredResponse.forEach((item) => {
            const submissionStatus =
              (item.lining_reference &&
                item.lining_reference.length > 0 &&
                item.lining_reference[0].submission_status) ||
              "";

            if (
              submissionStatus === "draft" &&
              item.dataFile.filename === null
            ) {
              nrmcardsDraftArray.push(item);
            } else if (submissionStatus === "submitted") {
              nrmcardsSubmittedArray.push(item);
            } else if (submissionStatus === "approved") {
              nrmcardsApprovedArray.push(item);
            } else {
              nrmCardsRejectedArray.push(item);
            }
          });
          console.log(nrmcardsDraftArray, "nrmcardsDraftArray");
          console.log(nrmcardsSubmittedArray, "nrmcardsSubmittedArray");
          setNRMCardsDraft(nrmcardsDraftArray);
          setNRMCardsSubmitted(nrmcardsSubmittedArray);
          setNrmCardsApproved(nrmcardsApprovedArray);
          setNrmCardsRejected(nrmCardsRejectedArray);
        } else {
          console.log("nrm Performance is not an array");
        }

        // Data collection
        const responseData = await axios.get(API.GET_ALL_DATA_COLLECTION, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const dataCollectionResponse = responseData.data;
        console.log(dataCollectionResponse, "dataCollectionResponsecheck");
        setDataCollection(dataCollectionResponse);
        const dataCollectionFilteredResponse = dataCollectionResponse.filter(
          (item) => item.dataFile.customer_id === customer_id
        );

        console.log(dataCollectionFilteredResponse, "sekurit cus");

        if (Array.isArray(dataCollectionFilteredResponse)) {
          let dataCollectioncardsDraftArray = [];
          let dataCollectioncardsSubmittedArray = [];
          console.log(
            dataCollectioncardsSubmittedArray,
            "dataCollectioncardsSubmittedArray"
          );
          let dataCollectionApprovedArray = [];
          let dataCollectionRejectedArray = [];

          dataCollectionFilteredResponse.forEach((item, index) => {
            console.log(item, "foreachcheck");
            const submissionStatus = item.submission_status;
            console.log(submissionStatus, "statuscheck");
            if (
              submissionStatus === "draft" &&
              item.dataFile.filename === null
            ) {
              dataCollectioncardsDraftArray.push(item);
            } else if (submissionStatus === "submitted") {
              dataCollectioncardsSubmittedArray.push(item);
            } else if (submissionStatus === "approved") {
              dataCollectionApprovedArray.push(item);
            } else {
              dataCollectionRejectedArray.push(item);
            }
          });

          setDataCollectionSubmitted(dataCollectioncardsSubmittedArray);
          setDataCollectionDraft(dataCollectioncardsDraftArray);
          setDataCollectioApproved(dataCollectionApprovedArray);
          setDataCollectionRejected(dataCollectionRejectedArray);
        } else {
          console.log("Data collection response is not an array");
        }

        // Cost sheet
        const costSheetData = await axios.get(API.GET_ALL_COST_SHEETS, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const costSheetResponse = costSheetData.data;
        setCostSheetData(costSheetResponse);
        const costSheetFilteredResponse = costSheetResponse.filter(
          (item) => item.dataFile.customer_id === customer_id
        );

        if (Array.isArray(costSheetFilteredResponse)) {
          let costSheetCardsDraftArray = [];
          let costSheetCardsSubmittedArray = [];
          let costSheetApprovedArray = [];
          let costSheetRejectedArray = [];

          costSheetFilteredResponse.forEach((item, index) => {
            const submissionStatus = item.submission_status;
            if (
              submissionStatus === "draft" &&
              item.dataFile.filename === null
            ) {
              costSheetCardsDraftArray.push(item);
            } else if (submissionStatus === "submitted") {
              costSheetCardsSubmittedArray.push(item);
            } else if (submissionStatus === "approved") {
              costSheetApprovedArray.push(item);
            } else {
              costSheetRejectedArray.push(item);
            }
          });

          setCostSheetSubmitted(costSheetCardsSubmittedArray);
          setCostSheetDraft(costSheetCardsDraftArray);
          setCostSheetApproved(costSheetApprovedArray);
          setCostSheetRejected(costSheetRejectedArray);
        } else {
          console.log("Cost sheet response is not an array");
        }
        //erosion profile
        const erosionResponse = await axios.get(API.GET_ALL_EROSION_PROFILE, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const erosionData = erosionResponse.data;
        setErosionProfileData(erosionData);
        const erosionFilteredResponse = erosionData.filter(
          (item) => item.dataFile.customer_id === customer_id
        );

        console.log(erosionFilteredResponse, "erosionFilteredResponse");

        if (Array.isArray(erosionFilteredResponse)) {
          let erosioncardsDraftArray = [];
          let erosionDatacardsSubmittedArray = [];
          let erosionDataCardsApprovedArray = [];
          let erosionDataCardsRejectedArray = [];

          erosionFilteredResponse.forEach((item) => {
            const submissionStatus = item.submission_status;
            if (
              submissionStatus === "draft" &&
              item.dataFile.filename === null
            ) {
              erosioncardsDraftArray.push(item);
            } else if (submissionStatus === "submitted") {
              erosionDatacardsSubmittedArray.push(item);
            } else if (submissionStatus === "approved") {
              erosionDataCardsApprovedArray.push(item);
            } else {
              erosionDataCardsRejectedArray.push(item);
            }
          });

          setErosionProfileDraft(erosioncardsDraftArray);
          setErosionProfileSubmitted(erosionDatacardsSubmittedArray);
          setErosionProfileRejected(erosionDataCardsRejectedArray);
          setErosionProfileApproved(erosionDataCardsApprovedArray);
        } else {
          console.log("Erosion Profile response is not an array");
        }
      }
    } catch (error) {
      console.error("An error occurred while fetching submitted cards:", error);
    }
  };
  useEffect(() => {
    fetchCards();
  }, []);

  const handleUploadClick = () => {
    setIsDialogOpen(true);
    setModalTitle('Upload New DataFile')
    setIsUploadModalOpen(true);
  };
  const handleAddFormClick = () => {
    setIsDialogOpen(true);
    setModalTitle('New Form')
    setIsUploadModalOpen(true);
  };

  const closeModal = () => {
    setIsDialogOpen(false);
  };

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(API.GET_CUSTOMER, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      const customerResponse = response.data;
      console.log("customerResponse", customerResponse);

      if (currentUser.role.role_id === 1) {
        const customerId = currentUser.assign_customer_id[0];
        const customers = customerResponse.filter(
          (item) => item.id === customerId
        );
        setCustomers(customers);

        const selectedCustomer = customers.find(
          (item) => item.id === storedCustomerDet?.id
        );
        if (selectedCustomer) setSelectCustomer(selectedCustomer.id);
      } else if (
        currentUser.role.role_id === 2 ||
        currentUser.role.role_id === 3
      ) {
        const assignCustomerIds = currentUser.assign_customer_id;
        const filteredCustomers = customerResponse.filter((customer) =>
          assignCustomerIds.includes(customer.id)
        );
        setCustomers(filteredCustomers);

        const selectedCustomer = filteredCustomers.find(
          (item) => item.id === storedCustomerDet?.id
        );
        if (selectedCustomer) setSelectCustomer(selectedCustomer.id);
      } else {
        setCustomers(customerResponse);

        const selectedCustomer = customerResponse.find(
          (item) => item.id === storedCustomerDet?.id
        );
        if (selectedCustomer) setSelectCustomer(selectedCustomer.id);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleCustomerChange = (selectedOption) => {
    const selectedCustomerDetails = customers.find(
      (customer) => customer.id === selectedOption
    );

    if (selectedCustomerDetails) {
      setSelectCustomer(selectedCustomerDetails.id);
      localStorage.setItem(
        "selectedCustomer",
        JSON.stringify(selectedCustomerDetails)
      );
      setTimeout(() => {
        fetchCustomers();
      }, 0);
    } else {
      console.error("Selected customer not found in the customers list");
    }
  };

  useEffect(() => { }, [selectCustomer]);
  useEffect(() => {
    if (customers.length > 0) {
      setSelectCustomer(
        customers.filter((x) => x.id === storedCustomerDet.id).length > 0 &&
          customers.filter((x) => x.id === storedCustomerDet.id)[0].id
          ? customers.filter((x) => x.id === storedCustomerDet.id)[0].id
          : null
      );
    }
  }, [customers]);


  const handleApply = () => {
    if (!fromDate || !toDate) {
      AlertDialog({
        type: 'warning',
        title: 'Missing Dates',
        text: 'Please select both From Date and To Date to proceed.',
        confirmButtonText: 'Ok',
        onConfirm: () => {},
      });
      return; 
    }
      switch (selectedTab) {
      case 0:
        filterDailyTrials();
        break;
      case 2:
        filterErosionProfile();
        break;
      case 3:
        filterDataCollection();
        break;
      case 4:
        filterCostSheet();
        break;
      default:
        filterNRMPerformance();
        break;
    }
  
    setOpen(false);
    handleFilterClose();
  };
  

  const filterDailyTrials = () => {
    const filtered = dailyTrials.filter((trial) => {
      const trialDate = new Date(trial.created_at)
      const from = new Date(fromDate)
      const to = new Date(toDate)
      to.setHours(23, 59, 59, 999)
      return trialDate >= from && trialDate <= to
    })

    if (filtered.length === 0) {
      AlertDialog({
        type: 'delete',
        title: 'Alert',
        text: 'No Records Matched',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          handleClose()
        },
      })
    } else {
      AlertDialog({
        type: 'success',
        title: 'Success',
        text: 'Filter Applied',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          handleClose()
        },
      })
    }

    setFilteredDailyTrials(filtered)
  }

  const filterErosionProfile = () => {
    const filtered = erosionProfileData.filter((item) => {
      const itemDate = new Date(item.created_at)
      const from = new Date(fromDate)
      const to = new Date(toDate)
      to.setHours(23, 59, 59, 999)
      return itemDate >= from && itemDate <= to
    })

    if (filtered.length === 0) {
      AlertDialog({
        type: 'delete',
        title: 'Alert',
        text: 'No Records Matched',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          handleClose()
        },
      })
    } else {
      AlertDialog({
        type: 'success',
        title: 'Success',
        text: 'Filter Applied',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          handleClose()
        },
      })
    }

    setFilteredErosionProfile(filtered)
  }

  const filterDataCollection = () => {
    const filtered = dataCollection.filter((item) => {
      const itemDate = new Date(item.created_at)
      const from = new Date(fromDate)
      const to = new Date(toDate)
      to.setHours(23, 59, 59, 999)
      return itemDate >= from && itemDate <= to
    })

    if (filtered.length === 0) {
      AlertDialog({
        type: 'delete',
        title: 'Alert',
        text: 'No Records Matched',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          handleClose()
        },
      })
    } else {
      AlertDialog({
        type: 'success',
        title: 'Success',
        text: 'Filter Applied',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          handleClose()
        },
      })
    }

    setFilteredDataCollection(filtered)
  }

  const filterCostSheet = () => {
    const filtered = costSheetData.filter((item) => {
      const itemDate = new Date(item.created_at)
      const from = new Date(fromDate)
      const to = new Date(toDate)
      to.setHours(23, 59, 59, 999)
      return itemDate >= from && itemDate <= to
    })

    if (filtered.length === 0) {
      AlertDialog({
        type: 'delete',
        title: 'Alert',
        text: 'No Records Matched',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          handleClose()
        },
      })
    } else {
      AlertDialog({
        type: 'success',
        title: 'Success',
        text: 'Filter Applied',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          handleClose()
        },
      })
    }

    setFilteredCostSheetData(filtered)
  }

  const filterNRMPerformance = () => {
    const filtered = nrmPerformanceData.filter((item) => {
      const itemDate = new Date(item.created_at)
      const from = new Date(fromDate)
      const to = new Date(toDate)
      to.setHours(23, 59, 59, 999)
      return itemDate >= from && itemDate <= to
    })

    if (filtered.length === 0) {
      AlertDialog({
        type: 'delete',
        title: 'Alert',
        text: 'No Records Matched',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          handleClose()
        },
      })
    } else {
      AlertDialog({
        type: 'success',
        title: 'Success',
        text: 'Filter Applied',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          handleClose()
        },
      })
    }
    setFilteredNRMPerformanceData(filtered)
  }

  const handleReset = () => {
    setOpen(false);
    setShowModal(false);
    setFromDate('');
    setToDate('');
  };
  

  return (
    <>
      <div className="bg-white p-4 border-b sm:flex justify-between items-start">
        <div>
          <h3 className="text-3xl font-bold pt-1">Data Management</h3>
        </div>
        <div className="mr-2 sm:mt-0 block sm:flex mt-4 justify-end items-center">
          <div className="w-64 hidden">
            <select
              id="ProfileStatus"
              value={selectCustomer}
              onChange={(e) => handleCustomerChange(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="">Select a customer</option>
              {customers.map((customer) => (
                <option key={customer.id} value={customer.id}>
                  {customer.organization_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mr-0 sm:mt-0 flex lg:hidden mt-4 ml-4 lg:ml-0">

            <button
              type="button"
              onClick={handleOpen}
              className="mr-4"
            >
              <FilterIcon />
            </button>
            <button
              type="button"
              onClick={() => setShowModal(true)}
            >
              <img src={ExcelIcon} />
            </button>
            <button
              type="button"
              onClick={handleUploadClick}
              className="text-white whitespace-nowrap border-blue-400 border lg:border-0 px-2 lg:bg-gradient-to-br lg:from-custom-red lg:to-custom-orange hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-regular rounded-md text-sm lg:pl-5 lg:pr-10 xl:pr-5 py-2 ml-3 text-center flex"
            >
              <span className="hidden lg:block">Upload Data File</span>
              <UploadIcon className=" lg:hidden block" />
            </button>

            <button
              type="button"
              onClick={handleAddFormClick}
              className="text-white whitespace-nowrap border-blue-400 border lg:border-0 px-2 bg-gradient-to-br from-custom-red to-custom-orange hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-regular rounded-md text-sm lg:pl-5 lg:pr-10 xl:pr-5 py-2 ml-3 text-center flex"
            >

              <span className="hidden lg:block">Add Form</span>
              <AddIcon className=" lg:hidden block" />
            </button>
          </div>

        </div>
      </div>
      <div className="flex border-b px-4 border-gray-200 dark:border-gray-700 justify-between items-center">
        <ul className="flex -mb-px text-sm font-medium text-center overflow-auto">
          <li className="me-2" role="presentation">
            <button
              onClick={() => handleTabClick("DailyTrialMonitoringTab")}
              className={`inline-block p-4 whitespace-nowrap border-b-2 rounded-t-lg ${activeTab === "DailyTrialMonitoringTab"
                ? "text-black font-semibold border-custom-red"
                : "border-transparent text-gray-500"
                }`}
              type="button"
              role="tab"
              aria-controls="DailyTrialMonitoringTab"
              aria-selected={activeTab === "DailyTrialMonitoringTab"}
            >
              Daily Trial Monitoring
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              onClick={() => handleTabClick("NRMPerformanceTab")}
              className={`inline-block p-4 whitespace-nowrap border-b-2 rounded-t-lg ${activeTab === "NRMPerformanceTab"
                ? "text-black font-semibold border-custom-red"
                : "border-transparent text-gray-500"
                }`}
              type="button"
              role="tab"
              aria-controls="NRMPerformanceTab"
              aria-selected={activeTab === "NRMPerformanceTab"}
            >
              NRM Performance
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              onClick={() => handleTabClick("ErosionProfileTab")}
              className={`inline-block p-4 whitespace-nowrap border-b-2 rounded-t-lg ${activeTab === "ErosionProfileTab"
                ? "text-black font-semibold border-custom-red"
                : "border-transparent text-gray-500"
                }`}
              type="button"
              role="tab"
              aria-controls="ErosionProfileTab"
              aria-selected={activeTab === "ErosionProfileTab"}
            >
              Erosion Profile
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              onClick={() => handleTabClick("DataCollectionTab")}
              className={`inline-block p-4 whitespace-nowrap border-b-2 rounded-t-lg ${activeTab === "DataCollectionTab"
                ? "text-black font-semibold border-custom-red"
                : "border-transparent text-gray-500"
                }`}
              type="button"
              role="tab"
              aria-controls="DataCollectionTab"
              aria-selected={activeTab === "DataCollectionTab"}
            >
              Data collection
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              onClick={() => handleTabClick("CostSheetTab")}
              className={`inline-block p-4 whitespace-nowrap border-b-2 rounded-t-lg ${activeTab === "CostSheetTab"
                ? "text-black font-semibold border-custom-red"
                : "border-transparent text-gray-500"
                }`}
              type="button"
              role="tab"
              aria-controls="CostSheetTab"
              aria-selected={activeTab === "CostSheetTab"}
            >
              Cost Sheet
            </button>
          </li>
        </ul>
        <div className="mr-2 sm:mt-0 lg:flex mt-4 ml-4 lg:ml-0 hidden">
          <button
            type="button"
            onClick={handleOpen}
          >
            <FilterIcon />
          </button>
          <button
            type="button"
            onClick={() => setShowModal(true)}
            className="ml-4 w-10"
          >
            <img src={ExcelIcon} />
          </button>

          <button
            type="button"
            onClick={handleUploadClick}
            className="text-white whitespace-nowrap border-blue-400 border lg:border-0 px-2 lg:bg-gradient-to-br lg:from-custom-red lg:to-custom-orange hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-regular rounded-md text-sm lg:pl-5 lg:pr-10 xl:pr-5 py-2 ml-3 text-center flex"
          >
            <span className="hidden lg:block">Upload Data File</span>
            <UploadIcon className=" lg:hidden block" />
          </button>

          <button
            type="button"
            onClick={handleAddFormClick}
            className="text-white whitespace-nowrap border-blue-400 border lg:border-0 px-2 bg-gradient-to-br from-custom-red to-custom-orange hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-regular rounded-md text-sm lg:pl-5 lg:pr-10 xl:pr-5 py-2 ml-3 text-center flex"
          >

            <span className="hidden lg:block">Add Form</span>
            <AddIcon className=" lg:hidden block" />
          </button>
        </div>
      </div>
      <div id="default-tab-content">
        <div
          className={`rounded-lg bg-gray-50 dark:bg-gray-800 ${activeTab === "DailyTrialMonitoringTab" ? "block" : "hidden"}`}
          id="DailyTrialMonitoringTab"
          role="tabpanel"
          aria-labelledby="DailyTrialMonitoringTab-tab"
        >
          <DailyTrialMonitoring
            cardsSubmitted={cardsSubmitted}
            cardsDraft={cardsDraft}
            cardsApproved={cardsApproved}
            cardsRejected={cardsRejected}
            fileexcel={fileexcel}
            selectedTab={selectedTab}
            templateId={templateId}
            filteredDailyTrials={filteredDailyTrials}
          />
        </div>
        <div
          className={`rounded-lg bg-gray-50 dark:bg-gray-800 ${activeTab === "NRMPerformanceTab" ? "block" : "hidden"}`}
          id="NRMPerformanceTab"
          role="tabpanel"
          aria-labelledby="NRMPerformanceTab-tab"
        >
          <NRMPerformance
            cardsSubmitted={nrmcardsSubmitted}
            cardsDraft={nrmcardsDraft}
            cardsApproved={nrmCardsApproved}
            cardsRejected={nrmCardsRejected}
            fileexcel={fileexcel}
            selectedTab={selectedTab}
            templateId={templateId}
            filteredNRMPerformanceData={filteredNRMPerformanceData}
          />
        </div>
        <div
          className={`rounded-lg bg-gray-50 dark:bg-gray-800 ${activeTab === "ErosionProfileTab" ? "block" : "hidden"}`}
          id="ErosionProfileTab"
          role="tabpanel"
          aria-labelledby="ErosionProfileTab-tab"
        >
          <ErosionProfile
            cardsSubmitted={erosionProfileSubmitted}
            cardsDraft={erosionProfileDraft}
            approvedData={erosionProfileApproved}
            rejectData={erosionProfileRejected}
            fileexcel={fileexcel}
            selectedTab={selectedTab}
            templateId={templateId}
            filteredErosionProfile={filteredErosionProfile}
          />
        </div>
        <div
          className={`rounded-lg bg-gray-50 dark:bg-gray-800 ${activeTab === "DataCollectionTab" ? "block" : "hidden"}`}
          id="DataCollectionTab"
          role="tabpanel"
          aria-labelledby="DataCollectionTab-tab"
        >
          <DataCollection
            cardsSubmitted={dataCollectionSubmitted}
            cardsDraft={dataCollectionDraft}
            approvedData={dataCollectionApproved}
            rejectData={dataCollectionRejected}
            fileexcel={fileexcel}
            selectedTab={selectedTab}
            templateId={templateId}
            filteredDataCollection={filteredDataCollection}
          />
        </div>
        <div
          className={`rounded-lg bg-gray-50 dark:bg-gray-800 ${activeTab === "CostSheetTab" ? "block" : "hidden"}`}
          id="CostSheetTab"
          role="tabpanel"
          aria-labelledby="CostSheetTab-tab"
        >
          <CostSheet
            cardsSubmitted={costSheetSubmitted}
            cardsDraft={costSheetDraft}
            approvedData={costSheetApproved}
            rejectData={costSheetRejected}
            fileexcel={fileexcel}
            selectedTab={selectedTab}
            templateId={templateId}
            filteredCostSheetData={filteredCostSheetData}
          />
        </div>
      </div>
      {isDialogOpen && (
        <ModalLayout
          // closeModalOnBackdropClick={closeModalOnBackdropClick}
          closeModal={closeModal}
          modalTitle={modalTitle}
        // modalState={modalState}
        // refreshUserData={refreshUserData}
        // activeTab={activeTab}
        >

        </ModalLayout>
      )}
      {showModals && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
          <div className="bg-white rounded-lg p-4 w-[600px] h-[518px] flex flex-col gap-4">
            {/* Header */}
            <div className="flex items-center justify-between">
              <h2 className="text-left font-semibold text-gray-800 text-lg leading-6">
                Download data template
              </h2>
              <button
                onClick={handleClose}
                className="text-gray-600 hover:text-gray-800"
              >
                ✕
              </button>
            </div>

            {/* File List */}
            {[
              {
                name: "Daily Trial Monitoring Data File Template.xlsx",
                size: "2.0MB",
                file: "01 Daily trial monitoring sheet v7 (1).xlsx",
              },
              {
                name: "NRM Performance Data File Template.xlsx",
                size: "2.0MB",
                file: "02 NRM performance sheet V9 R1.xlsx",
              },
              {
                name: "Erosion Profile Data File Template.xlsx",
                size: "2.0MB",
                file: "03 Erosion profile sheet V6 1.xlsx",
              },
              {
                name: "Data collection.xlsx",
                size: "2.0MB",
                file: "04 Data collection sheet V6.xlsx",
              },
              {
                name: "Cost Sheet Template.xlsx",
                size: "2.0MB",
                file: "05 Cost Sheet V10.xlsx",
              },
            ].map(({ name, size, file }) => (
              <div
                key={name}
                className="flex items-center gap-4 px-2 py-2 border-b border-gray-200"
              >
                <img src={ExcelIcon} />
                <div className="flex-1 flex flex-col">
                  <span className="font-bold text-sm text-gray-800">{name}</span>
                  <span className="text-xs text-gray-500">{size}</span>
                </div>
                <button
                  onClick={() => handleDownload(file)}
                  className="px-4 py-1 text-white bg-custom-red rounded-md hover:bg-custom-red"
                >
                  Download
                </button>
              </div>
            ))}

            {/* Download All */}
            <div className="flex items-center justify-start gap-4 mt-6">
              <button
                onClick={handleDownloadAll}
                className="text-blue-600 font-medium text-sm hover:underline"
              >
                Download all Templates (.zip)
              </button>

              <DownloadIcon onClick={handleDownloadAll} />
            </div>
          </div>
        </div>
      )}
      {open && (
        <div className="fixed inset-0 z-50 flex items-center justify-end bg-black bg-opacity-50 backdrop-blur-sm">
          <div className="bg-white w-[500px]  h-screen flex flex-col gap-4 relative">
            <div className="flex items-center p-4 justify-between border-b border-gray-300">
              <h2 className="text-left font-semibold text-gray-800 text-lg leading-6">
                Filter
              </h2>
              <button
                onClick={handleClose}
                className="text-gray-600 hover:text-gray-800"
              >
                ✕
              </button>
            </div>
            <div className="p-4 bg-white">
              <div className="mb-4">
                <label
                  htmlFor="from-date"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  From Date
                </label>
                <input
                  id="from-date"
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                />
                {<p className="text-red-500 text-xs mt-1">From Date is required.</p>}
              </div>
              <div>
                <label
                  htmlFor="to-date"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  To Date
                </label>
                <input
                  id="to-date"
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                />
                {!toDate && <p className="text-red-500 text-xs mt-1">To Date is required.</p>}
              </div>
            </div>
            <div className="flex w-full left-0 px-4 space-x-4 absolute bottom-5">
              <button
                onClick={handleApply}
                className="px-4 py-2 w-1/2 bg-custom-red text-white rounded-md hover:bg-custom-red"
              >
                Apply
              </button>
              <button
                onClick={handleReset}
                className="px-4 py-2 w-1/2 text-blue-600 border border-blue-600 rounded-md hover:underline"
              >
                Reset
              </button>
            </div>
          </div>
        </div>)}
    </>

  );
};

export default DataManagementLayout;
