import React from 'react';
import { useRecoilState } from 'recoil';
import { alloy_and_steel, trial_name } from '../../recoil/atom';
import Helper from '../../service/Helper';

const AlloyingAdditionWeb = () => {
  const [alloyAndSteel, setaAlloyAndSteel] = useRecoilState(alloy_and_steel)
  const [trialName] = useRecoilState(trial_name)
  console.log(alloyAndSteel, 'alloyAndSteel')

  const handleChange = (e) => {
    const { name, value } = e.target

    const numericRegex = /^\d{0,5}(\.\d{0,2})?$/
    const textWithSpecialCharsRegex = /^[a-zA-Z\s\d\-\.,()&:%/]*$/
    let regex
    switch (name) {
      case 'addition_of_simn':
      case 'final_chemistry_at_furnace':
      case 'casting_method':
      case 'agreement':
      case 'final_chemistry_at_billet':
      case 'simn_consumption':
      case 'mn_percentage':
      case 'mn_recovery':
        regex = textWithSpecialCharsRegex // Apply text and special character regex for these fields
        break
      default:
        regex = numericRegex // Apply numeric regex for other fields
        break
    }

    // Validate value against the selected regex
    if (regex.test(value)) {
      setaAlloyAndSteel((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleDateSelection = (value, type) => {
    console.log('typee', type)
  }

  const isReadOnly = trialName === 'viewDataCollection'

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">Alloy and Stainless Steel Customers</h2>

      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label htmlFor="fecr_consumption" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            FeCr Consumption / heat - (Kg / heat)
          </label>
          <input
            type="text"
            id="fecr_consumption"
            name="fecr_consumption"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Kg"
            value={alloyAndSteel.fecr_consumption}
            onChange={handleChange}
          />
        </div>

        {/* Date */}
        <div className="w-full mb-4">
          <label htmlFor="cr_percentage" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Cr% in FeCr - (%)
          </label>
          <input
            type="text"
            id="cr_percentage"
            name="cr_percentage"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Percentage"
            value={alloyAndSteel.cr_percentage}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="customer_ref_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Final Cr % - (%)
          </label>
          <input
            type="text"
            id="final_cr_percentage"
            name="final_cr_percentage"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Percentage"
            value={alloyAndSteel.final_cr_percentage}
            onChange={handleChange}
          />
        </div>

        <div className="w-full mb-4">
          <label htmlFor="cr_recovery" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Cr Recovery - (%)
          </label>
          <input
            type="text"
            id="cr_recovery"
            name="cr_recovery"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Cr"
            value={alloyAndSteel.cr_recovery}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-1/2 mb-4">
          <label htmlFor="alloy_addition" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Any other alloys additions 
          </label>
          <input
            type="text"
            id="alloy_addition"
            name="alloy_addition"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter"
            value={alloyAndSteel.alloy_addition}
            onChange={handleChange}
          />
        </div>

        <div className="w-1/2">
          <label
            htmlFor="casting_method"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Casting Method
          </label>
          <select
            id="casting_method"
            name="casting_method"
            value={alloyAndSteel.casting_method}
            onChange={handleChange}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a method</option>
            {Helper.CastingMethod.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}

          </select>
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label htmlFor="simn_consumption" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Simn consumption/heat - (Kg/heat)
          </label>
          <input
            type="text"
            id="simn_consumption"
            name="simn_consumption"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Simn"
            value={alloyAndSteel.simn_consumption}
            onChange={handleChange}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label htmlFor="mn_percentage" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Mn % in SiMn - (%)
          </label>
          <input
            type="text"
            id="mn_percentage"
            name="mn_percentage"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Mn"
            value={alloyAndSteel.mn_percentage}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label htmlFor="mn_recovery" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Mn Recovery - (%)
          </label>
          <input
            type="text"
            id="mn_recovery"
            name="mn_recovery"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Mn"
            value={alloyAndSteel.mn_recovery}
            onChange={handleChange}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label htmlFor="addition_of_simn" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Addition of SiMn (FC or ladle Ratio) - (Kg/heat)
          </label>
          <input
            type="text"
            id="addition_of_simn"
            name="addition_of_simn"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter SiMn"
            value={alloyAndSteel.addition_of_simn}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label htmlFor="any_other_alloy_addition" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Any other alloys additions
          </label>
          <input
            type="text"
            id="any_other_alloy_addition"
            name="any_other_alloy_addition"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter"
            value={alloyAndSteel.any_other_alloy_addition}
            onChange={handleChange}
          />
        </div>

        <div className="w-full mb-4">
          <label htmlFor="grade" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Final Chemistry at Furnace (Range)
          </label>
          <input
            type="text"
            id="final_chemistry_at_furnace"
            name="final_chemistry_at_furnace"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Grade"
            value={alloyAndSteel.final_chemistry_at_furnace}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label htmlFor="final_chemistry_at_billet" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Final Chemistry at Billet (Range)
          </label>
          <input
            type="text"
            id="final_chemistry_at_billet"
            name="final_chemistry_at_billet"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter range"
            value={alloyAndSteel.final_chemistry_at_billet}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="agreement" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Agreement to share relevant plant data during trial evaluation of the proposed Heat King Refractory Lining Solution
          </label>
          <input
            type="text"
            id="agreement"
            name="agreement"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter"
            value={alloyAndSteel.agreement}
            onChange={handleChange}
          />
        </div>
      </div>



    </>
  );
};

export default AlloyingAdditionWeb;
