import React, { useEffect, useState } from 'react'
import axios from 'axios'
import API from './../../service/API'

import EditIcon from '../../assets/images/SVGIcon/EditTrial.svg'
import DeleteIcon from '../../assets/images/SVGIcon/Delete.svg'
import SearchIcon from '../../assets/images/SVGIcon/SearchIcon.svg'
import Filter from '../../assets/images/SVGIcon/FilterIcon.svg'
import Download from '../../assets/images/SVGIcon/Download-Icon.svg'
import closeIcon from '../../assets/images/icons/Dashboard.svg'
import AlertDialog from '../Common/AlertDialog'
import ModalLayout from '../Common/ModalLayout'
const ExcelJS = require('exceljs')

const Customers = () => {
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState({
    organization_name: '',
    description: '',
    email: '',
    website: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
  })
  const [customers, setCustomers] = useState([])
  const [selectedCustomerIndex, setSelectedCustomerIndex] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false) // New state for edit mode
  const [isInputVisible, setInputVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredCustomers, setFilteredCustomers] = useState([])
  const [formErrors, setFormErrors] = useState({}) // Initialize formErrors state
  const [loading, setLoading] = useState(false) // Loader state
  const [error, setError] = useState(null) // Error state
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const access_token = localStorage.getItem('accesstoken')

  // Function to fetch data from the API
  const fetchData = async () => {
    setError(null)
    try {
      setLoading(true)
      const response = await axios.get(API.GET_CUSTOMER, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('responseeeecustomers', response.data)
      setCustomers(response.data)
    } catch (error) {
      setError('Error fetching data')
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false) // Close the loader regardless of success or failure
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleSearchIconClick = () => {
    setInputVisible(true)
  }

  const handleInputChange = (event) => {
    const query = event.target.value.toLowerCase() // Convert query to lowercase
    setSearchQuery(query)
    let filtered
    if (query.trim() === '') {
      filtered = customers // Reset to original list if query is empty
    } else {
      filtered = customers.filter((customer) => {
        const orgNameIncludesQuery = customer.organization_name
          .toLowerCase()
          .includes(query)
        const emailIncludesQuery = customer.email.toLowerCase().includes(query)
        const cityIncludesQuery = customer.city.toLowerCase().includes(query)
        const trigramIncludesQuery = getTrigram(customer.organization_name)
          .toLowerCase()
          .includes(query)
        return (
          orgNameIncludesQuery ||
          emailIncludesQuery ||
          cityIncludesQuery ||
          trigramIncludesQuery
        )
      })
    }
    setFilteredCustomers(filtered)
  }

  const handleCloseIconClick = () => {
    setInputVisible(false)
    setSearchQuery('')
    setFilteredCustomers([])
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleOpenModal = () => {
    setOpen(true)
    setIsEditMode(false)
    setFormData({
      organization_name: '',
      description: '',
      email: '',
      website: '',
      address: '',
      city: '',
      state: '',
      pincode: '',
    })
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleSave = async () => {
    try {
      console.log('formDataformDataformData', formData);
      if (!isEditMode) {
      const isValid = await validateFormData()
      if (!isValid) return
      }
      setLoading(true) // Start loader
console.log('formDataformDataformData');
      const jsonData = formData
      console.log('formDataaaaa', formData)
      let response
      let successMessage

      if (isEditMode && selectedCustomerIndex !== null) {
        const customerId = customers[selectedCustomerIndex].id
        response = await axios.put(
          `${API.UPDATE_CUSTOMER}/${customerId}`,
          jsonData,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        successMessage = 'Customer Updated Successfully'
      } else {
        response = await axios.post(API.INSERT_CUSTOMER, jsonData, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        successMessage = 'Customer Inserted Successfully'
      }

      if (response.status === 200) {
        AlertDialog({
          type: 'success',
          title: 'Success',
          text: successMessage,
          confirmButtonText: 'Ok',
          onConfirm: async () => {
            await fetchData()
            await fetchUsers()
          },
        })
      } else {
        console.error('Error saving data:', response.data)
        AlertDialog({
          type: 'warning',
          title: 'Error',
          text: 'Failed to save data',
        })
      }

      setOpen(false) // Close the modal after saving
    } catch (error) {
      console.error('Error saving data:', error)
      AlertDialog({
        type: 'warning',
        title: 'Error',
        text: error.message || 'An error occurred',
      })
    } finally {
      setLoading(false) // Stop loader regardless of success or failure
    }
  }

  const fetchUsers = async () => {
    setError(null)
    try {
      setLoading(true)

      const [usersResponse, customersResponse] = await Promise.all([
        axios.get(API.GET_USERS, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }),
        axios.get(API.GET_CUSTOMER, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }),
      ])

      console.log('userresponse', usersResponse.data)
      console.log('customers response', customersResponse.data)

      const users = usersResponse.data
      const customerIds = customersResponse.data.map((customer) => customer.id)

      // Filter users by role_id 3 or 4 and create an update payload
      const updatePayloads = users
        .filter((user) => user.role.role_id === 3 || user.role.role_id === 4)
        .map((user) => ({
          id: user.id,
          assign_customer_id: customerIds,
        }))

      console.log('updatePayloads', updatePayloads)

      // Update each user with role_id 3 or 4
      for (const payload of updatePayloads) {
        await axios.put(`${API.UPDATE_ALL_USERS}/${payload.id}`, {
          assign_customer_id: payload.assign_customer_id,
        })
      }

      console.log('Update API response:', 'All relevant users updated')
    } catch (error) {
      // setError("Error fetching data");
      console.error('Error fetching data:', error.message)
    } finally {
      setLoading(false) // Close the loader regardless of success or failure
    }
  }

  const validateFormData = async () => {
    const errors = {}
console.log("isEditMode",isEditMode);
    try {
      if (!isEditMode) {
        
      
      console.log('customeresss', customers)

      // Check if organization name is provided
      if (!formData.organization_name) {
        errors.organization_name = 'Organization name is required'
      } else {
        // Check for duplicate organization name
        const isDuplicate = customers.some(
          (org) =>
            org.organization_name.toLowerCase() ===
            formData.organization_name.toLowerCase()
        )

        console.log('isDuplicateisDuplicateisDuplicate', isDuplicate)
        if (isDuplicate) {
          errors.organization_name = 'Organization name already exists'
        }
      }

      // Check if email is provided and valid
      if (!formData.email) {
        errors.email = 'Email address is required'
      } else if (!isValidEmail(formData.email)) {
        errors.email = 'Invalid email address'
      }

      // Check if city is provided
      if (!formData.city) {
        errors.city = 'City is required'
      }

      // Set the form errors state
      setFormErrors(errors)

      // Return true if there are no errors, otherwise return false
      return Object.keys(errors).length === 0
    }
    } catch (error) {
      console.error('Error fetching data for validation:', error)
      return false // Consider validation to fail if fetch fails
    }
  }
  const isValidEmail = (email) => {
    const optimizedEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return optimizedEmailRegex.test(email);
  };

  // const handleEdit = async (index) => {
  //   setSelectedCustomerIndex(index)
  //   setIsEditMode(true)

  //   const sourceArray =
  //     searchQuery.trim() === '' ? customers : filteredCustomers

  //   const selectedCustomer = sourceArray[index]
  //   setFormData({
  //     organization_name: selectedCustomer.organization_name,
  //     description: selectedCustomer.description,
  //     email: selectedCustomer.email,
  //     website: selectedCustomer.website,
  //     address: selectedCustomer.address,
  //     city: selectedCustomer.city,
  //     state: selectedCustomer.state,
  //     pincode: selectedCustomer.pincode,
  //   })

  //   setOpen(true)
  //   console.log('formDataformDataformData', formData);
  // }

  const handleEdit = async (index) => {
    setIsEditMode(true);
    setSelectedCustomerIndex(index);
  
    // Determine the data source based on search query
    const sourceArray = searchQuery.trim() ? filteredCustomers : customers;
    const selectedCustomer = filteredCustomers.length===0?customers[index]:filteredCustomers[index];
  
    if (selectedCustomer) {
      setFormData({
        organization_name: selectedCustomer.organization_name || '',
        description: selectedCustomer.description || '',
        email: selectedCustomer.email || '',
        website: selectedCustomer.website || '',
        address: selectedCustomer.address || '',
        city: selectedCustomer.city || '',
        state: selectedCustomer.state || '',
        pincode: selectedCustomer.pincode || '',
      });
      setOpen(true); // Open the modal after setting data
    } else {
      console.error('Customer data not found for edit.');
    }
  };
  

  const handleDelete = async (index) => {
    setSelectedCustomerIndex(index)
    const selectedCustomer = filteredCustomers.length===0?customers[index]:filteredCustomers[index]
    const id = selectedCustomer.id
    AlertDialog({
      type: 'delete',
      title: 'Delete?',
      text: 'Are you sure you want to delete?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        const response = await axios.delete(`${API.DELETE_CUSTOMER}/${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        if (response.status === 200) {
          AlertDialog({
            type: 'success',
            title: 'Success',
            text: 'Deleted Successfully',
            confirmButtonText: 'Ok',
            onConfirm: async () => {
              await fetchData()
            },
          })
        } else {
          console.error('Error saving data:', response.data)
          AlertDialog({
            type: 'warning',
            title: 'Error',
            text: 'Failed to save data',
          })
        }
      },
    })
  }

  const handleExcelDownload = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Customers')
    // Define column headers
    worksheet.columns = [
      { header: 'No', key: 'no', width: 10 },
      { header: 'Name', key: 'organization_name', width: 20 },
      { header: 'Trigram', key: 'trigram', width: 20 },
      { header: 'Email', key: 'email', width: 30 },
      { header: 'City', key: 'city', width: 20 },
    ]
    const dataToDownload = searchQuery ? filteredCustomers : customers
    dataToDownload.forEach((row, index) => {
      const trigram = getTrigram(row.organization_name)
      worksheet.addRow({ ...row, no: index + 1, trigram: trigram })
    })

    // Generate the Excel file as a Blob
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob)

    // Create a temporary <a> element to trigger the download
    const link = document.createElement('a')
    link.href = url
    link.download = 'customers.xlsx'

    // Programmatically click the link to trigger the download
    document.body.appendChild(link)
    link.click()

    // Cleanup
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  const getTrigram = (name) => {
    // Split the organization name by spaces
    const words = name.trim().split(' ')

    // Initialize trigram
    let trigram = ''

    // Check the number of words
    if (words.length === 1) {
      // If it's one word, take the first three characters
      trigram = words[0].slice(0, 3).toUpperCase()
    } else if (words.length === 2) {
      // If it's two words, take the first two characters of each word
      trigram =
        words[0].slice(0, 2).toUpperCase() + words[1].charAt(0).toUpperCase()
    } else if (words.length >= 3) {
      // If it's three or more words, take the first character of the first three words
      trigram =
        words[0].charAt(0).toUpperCase() +
        words[1].charAt(0).toUpperCase() +
        words[2].charAt(0).toUpperCase()
    }

    return trigram
  }

  return (
    <>
      {error && <div>{error}</div>}
      <div className='pt-3 flex justify-between bg-white border-b pb-2 pl-2'>
        <p className='text-xs text-gray-800 font-medium p-2 mb-0'>Customer Management</p>
        <div className='flex items-center'>
          <div className="flex items-center">
            <label htmlFor="topbar-search" className="sr-only">Search Transactions</label>
            <div className="relative w-full">
              <div
                className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
              >
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  ></path>
                </svg>
              </div>
              <input
                name="search"
                type="text"
                value={searchQuery}
                onChange={handleInputChange}
                id="topbar-search"
                className="bg-white w-full text-gray-900  border border-gray-300 rounded-md text-sm focus:outline-none pl-10 p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search Data"
              />
            </div>
          </div>

          <span className='border-r h-8 mx-3'></span>
          {/* <button
            type="button"
            className="flex mx-3 text-smdark:focus:ring-gray-600"
            id="Filter-table"
            aria-expanded="false"
            data-dropdown-toggle="dropdown"
          >
            <img className="w-6 h-6 rounded-full" src={Filter} alt="Filter" />
          </button>
          <button
            type="button"
            className="flex mx-3 text-smdark:focus:ring-gray-600"
            id="Download-table"
            aria-expanded="false"
            data-dropdown-toggle="dropdown"
          >
            <img className="w-6 h-6 rounded-full" src={Download} alt="Download" />
          </button>
          <span className='border-r h-8 mx-3'></span> */}
          <button data-modal-target="static-modal" onClick={handleOpenModal} data-modal-toggle="static-modal" type="button" class="mr-4 text-white whitespace-nowrap bg-gradient-to-br from-custom-red to-custom-orange hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-regular rounded-md text-sm pl-5 pr-10 xl:pr-5 py-2 ml-3 text-center flex">
            Add Customer
          </button>
        </div>
      </div>
      <div className='w-full'>
        <div className='overflow-x-auto'>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead>
              <tr>
                <th
                  className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400"
                >
                  <div className="flex items-center">
                    S.No
                  </div>
                </th>
                <th
                  className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400"
                >
                  <div className="flex items-center">
                    Name
                  </div>
                </th>
                <th
                  className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400"
                >
                  <div className="flex items-center">
                    Trigram
                  </div>
                </th>
                <th
                  className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400"
                >
                  <div className="flex items-center">
                    Email
                  </div>
                </th>
                <th
                  className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400"
                >
                  <div className="flex items-center">
                    City
                  </div>
                </th>
                <th
                  className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400"
                >
                  <div className="flex items-center">
                    Actions
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {(searchQuery ? filteredCustomers : customers).map(
                (row, index) => (
                  <tr key={row.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">
                      {index + 1}
                    </td>
                    <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">
                      {row.organization_name}
                    </td>
                    <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">
                      {getTrigram(row.organization_name)}
                    </td>
                    <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">
                      {row.email}
                    </td>
                    <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">
                      {row.city}
                    </td>
                    <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">
                      <div className="flex">
                        <button
                          type="button"
                          className="flex mr-3 text-sm dark:focus:ring-gray-600 flex items-center"
                          onClick={() => handleEdit(index)}
                        >
                          <img className="w-5 h-5" src={EditIcon} alt="Edit" />
                        </button>
                        <button
                          type="button"
                          className="flex text-sm dark:focus:ring-gray-600 flex items-center"
                          onClick={() => handleDelete(index)}
                        >
                          <img className="w-5 h-5" src={DeleteIcon} alt="Delete" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <CustomerModal open={open} formErrors={formErrors} isEditMode={isEditMode} handleClose={handleClose} formData={formData} handleChange={handleChange} handleSave={handleSave}/>
      {/* {isDialogOpen && (
        <ModalLayout
        closeModalOnBackdropClick={closeModalOnBackdropClick}
        closeModal={closeModal}
        modalTitle={modalTitle}
        formData={formData}
      >
      </ModalLayout>
      )} */}
    </>
  )
}

const CustomerModal = ({
  open,
  handleClose,
  formData,
  handleChange,
  handleSave,
  isEditMode,
  formErrors,
}) => {
  return (
    <>
      {open && (
        <div onClick={handleClose}  className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div onClick={(e) => e.stopPropagation()} className="bg-white h-[600px] rounded-lg shadow-lg w-[600px]">
            <div className="flex items-center justify-between p-4 border-b">
              <h2 className="text-lg font-semibold text-gray-800">{isEditMode ? 'Edit' : 'New'} Customer</h2>
              <button
                className="text-gray-500 hover:text-gray-800"
                onClick={handleClose}
                aria-label="Close"
              >
                ✕
              </button>
            </div>

            <div className="space-y-4 mt-4 overflow-y-auto px-4 h-[440px]">
              <div>
                <label className="block text-sm font-medium text-gray-700">Organization Name*</label>
                <input
                  type="text"
                  name="organization_name"
                  value={formData.organization_name}
                  onChange={handleChange}
                  className={`w-full mt-1 p-2 border rounded-md text-sm ${formErrors.organization_name ? 'border-red-500' : 'border-gray-300'
                    }`}
                />
                {formErrors.organization_name && (
                  <p className="text-sm text-red-500 mt-1">{formErrors.organization_name}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md border-gray-300 text-sm"
                ></textarea>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Email Address*</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`w-full mt-1 p-2 border rounded-md text-sm ${formErrors.email ? 'border-red-500' : 'border-gray-300'
                    }`}
                />
                {formErrors.email && (
                  <p className="text-sm text-red-500 mt-1">{formErrors.email}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Website</label>
                <input
                  type="text"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md border-gray-300 text-sm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Address</label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md border-gray-300 text-sm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">City*</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className={`w-full mt-1 p-2 border rounded-md text-sm ${formErrors.city ? 'border-red-500' : 'border-gray-300'
                    }`}
                />
                {formErrors.city && <p className="text-sm text-red-500 mt-1">{formErrors.city}</p>}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">State</label>
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md border-gray-300 text-sm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">PinCode</label>
                <input
                  type="text"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md border-gray-300 text-sm"
                />
              </div>
            </div>

            <div className="mt-2 flex justify-end space-x-4 p-4">
              <button
                onClick={handleClose}
                className="w-1/2 h-10 px-3 py-2 rounded-md text-gray-800 border border-gray-300 text-sm font-medium mr-2.5"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="w-1/2 h-10 px-3 py-2 rounded-md text-white bg-custom-red text-sm font-medium"
              >
                {isEditMode ? 'Update' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      )}

    </>

  )
}
export default Customers
