import React, { useState, useEffect } from 'react'
import API from './../../service/API'
import axios from 'axios'

import EditIcon from '../../assets/images/icons/editIcon.svg'
import DeleteIcon from '../../assets/images/icons/Trash.svg'
import AlertDialog from './../Common/AlertDialog'
import Helper from './../../service/Helper'
import SearchIcon from '../../assets/images/icons/Search.svg'
// import FilterIcon from "../../assets/icons/ic_filter.svg";
import DownloadIcon from '../../assets/images/SVGIcon/Download-Icon.svg'
const ExcelJS = require('exceljs')


const Furnaces = () => {
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState({
    furnace_number: '',
    year_of_manufacture: '',
    capacity: '',
    coil_grout: '',
    panels_info: '',
    crucible_info_id: '',
    end_product: '',
    steel_grade: '',
    ms_grade_ratio: '',
    supplier_name: '',
    supplier_contact_number: '',
    assign_customer_id: '',
  })
  const [furnaces, setFurnaces] = useState([])
  const [selectedFurnaceIndex, setSelectedFurnaceIndex] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false) // New state for edit mode
  const [isInputVisible, setInputVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredFurnaces, setFilteredFurnaces] = useState([])
  const [formErrors, setFormErrors] = useState({}) // Initialize formErrors state
  const [loading, setLoading] = useState(false) // Loader state
  const [error, setError] = useState(null) // Error state
  const [customers, setCustomers] = useState([])
  const access_token = localStorage.getItem('accesstoken')

  useEffect(() => {
    fetchData()
    fetchCustomers()
  }, [])

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(API.GET_CUSTOMER, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }) // Adjust the URL as needed
      setCustomers(response.data) // Update state with fetched data
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const fetchData = async () => {
    try {
      const response = await axios.get(API.GET_FURNACES, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('furnace response', response)
      setFurnaces(response.data)
    } catch (error) {
      console.error('Error fetching users:', error)
    }
  }

  const handleOpenModal = () => {
    setOpen(true)
    setIsEditMode(false)
    setFormData({
      furnace_number: '',
      year_of_manufacture: '',
      capacity: '',
      coil_grout: '',
      panels_info: '',
      crucible_info_id: '',
      end_product: '',
      steel_grade: '',
      ms_grade_ratio: '',
      supplier_name: '',
      supplier_contact_number: '',
    })
  }

  const handleCloseModal = () => {
    setFormErrors({});
    setOpen(false)
  }

  const getCrucibleInfoName = (crucible_info_id) => {
    const crucible = Helper.CrucibleInfo.find(
      (crucibleInfo) => crucibleInfo.id === parseInt(crucible_info_id)
    )
    return crucible ? crucible.name : '' //
  }

  const handleEdit = async (index) => {
    setSelectedFurnaceIndex(index)
    setIsEditMode(true)
    // Populate formData with data of the selected customer
    const selectedFurnace = filteredFurnaces.length===0?furnaces[index]:filteredFurnaces[index]
    setFormData({
      furnace_number: selectedFurnace.furnace_number,
      year_of_manufacture: selectedFurnace.year_of_manufacture,
      capacity: selectedFurnace.capacity,
      coil_grout: selectedFurnace.coil_grout,
      panels_info: selectedFurnace.panels_info,
      crucible_info_id: selectedFurnace.crucible_info_id,
      end_product: selectedFurnace.end_product,
      steel_grade: selectedFurnace.steel_grade,
      ms_grade_ratio: selectedFurnace.ms_grade_ratio,
      supplier_name: selectedFurnace.supplier_name,
      supplier_contact_number: selectedFurnace.supplier_contact_number,
      assign_customer_id: selectedFurnace.assign_customer_id,
    })

    setOpen(true)
  }

  const handleDelete = async (index) => {
    setSelectedFurnaceIndex(index)
    const selectedFurnace = filteredFurnaces.length===0?furnaces[index]:filteredFurnaces[index]
    const id = selectedFurnace.id
    AlertDialog({
      type: 'delete',
      title: 'Delete?',
      text: 'Are you sure you want to delete?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        const response = await axios.delete(`${API.DELETE_FURNACE}/${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        if (response) {
          fetchData()
        }
      },
    })
  }

  const handleChange = (event) => {
    const { name, value } = event.target || event

    // Validation for furnace_number
    let updatedValue = value
    if (name === 'furnace_number') {
      const numValue = parseInt(value, 10)
      if (numValue < 1 || numValue > 20) {
        updatedValue = '' // Reset to empty string if out of range
      }
    }

    // const updatedValue = name === 'crucible_info_id' ? value : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }))
  }

  // const handleSave = async () => {
  //   const jsonData = formData;
  //   console.log("jasonDataaaa", jsonData);
  //   const response = await axios.post(API.INSERT_FURNACE, jsonData);
  //   console.log("responseresponse", response);
  // }

  const handleSave = async () => {
    try {
      const isValid = await validateFormData()
      if (!isValid) return

      setLoading(true) // Start loader

      const jsonData = formData
      console.log('jsonData', jsonData)
      let response
      let successMessage

      if (isEditMode && selectedFurnaceIndex !== null) {
        const furnaceId = furnaces[selectedFurnaceIndex].id
        response = await axios.put(
          `${API.UPDATE_FURNACE}/${furnaceId}`,
          jsonData,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        successMessage = 'Furnace Updated Successfully'
      } else {
        response = await axios.post(API.INSERT_FURNACE, jsonData, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        successMessage = 'Furnace Inserted Successfully'
      }

      if (response.status === 200) {
        AlertDialog({
          type: 'success',
          title: 'Success',
          text: successMessage,
          confirmButtonText: 'Ok',
          onConfirm: async () => {
            await fetchData()
          },
        })
      } else {
        console.error('Error saving data:', response.data)
        AlertDialog({
          type: 'warning',
          title: 'Error',
          text: 'Failed to save data',
        })
      }

      setOpen(false) // Close the modal after saving
    } catch (error) {
      console.error('Error saving data:', error)
      AlertDialog({
        type: 'warning',
        title: 'Error',
        text: error.message || 'An error occurred',
      })
    } finally {
      setLoading(false) // Stop loader regardless of success or failure
    }
  }

  const validateFormData = async () => {
    // Implement your validation logic here
    const errors = {}

    // Check if organization name is provided
    if (!formData.assign_customer_id) {
      errors.assign_customer_id = 'Customer is required'
    }
    // Check if organization name is provided
    // if (!formData.furnace_number) {
    //   errors.furnace_number = 'Furnace Number is required';
    // }

    if (!formData.furnace_number) {
      errors.furnace_number = 'Furnace Number is required'
    } else {
      const furnaceNumber = parseInt(formData.furnace_number, 10)
      if (isNaN(furnaceNumber) || furnaceNumber < 1 || furnaceNumber > 20) {
        errors.furnace_number = 'Furnace Number must be between 1 and 20'
      }
    }

    if (!formData.capacity) {
      errors.capacity = 'Capacity is required'
    }

    if (!formData.coil_grout) {
      errors.coil_grout = 'Coil Grout is required'
    }

    // Check if city is provided
    if (!formData.crucible_info_id) {
      errors.crucible_info_id = 'Crucible Info is required'
    }
    if (!formData.end_product) {
      errors.end_product = 'End Product is required'
    }

    if (!formData.steel_grade) {
      errors.steel_grade = 'Steel Grade is required'
    }

    // Set the form errors state
    setFormErrors(errors)

    // Return true if there are no errors, otherwise return false
    return Object.keys(errors).length === 0
  }


  const handleInputChange = (event) => {
    const query = event.target.value.toLowerCase() // Convert query to lowercase
    setSearchQuery(query)
    let filtered
    if (query.trim() === '') {
      filtered = furnaces // Reset to original list if query is empty
    } else {
      filtered = furnaces.filter((furnace) => {
        const furnaceNumberIncludesQuery = furnace.furnace_number
          .toLowerCase()
          .includes(query.toLowerCase()) // Case-insensitive comparison
        const yearOfMfgIncludesQuery = furnace.year_of_manufacture
          .toString()
          .includes(query)
        const capacityIncludesQuery = furnace.capacity
          .toLowerCase()
          .includes(query)
        const coilGroutIncludesQuery = furnace.coil_grout
          .toLowerCase()
          .includes(query)
        const crucibleInfotIncludesQuery = getCrucibleInfoName(
          furnace.crucible_info_id
        )
          .toLowerCase()
          .includes(query)
        const endProductIncludesQuery = furnace.end_product
          .toLowerCase()
          .includes(query)
        const steelGradeIncludesQuery = furnace.steel_grade
          .toLowerCase()
          .includes(query)
        const customerIncludesQuery = furnace.customer.organization_name
          .toLowerCase()
          .includes(query)
        return (
          furnaceNumberIncludesQuery ||
          yearOfMfgIncludesQuery ||
          capacityIncludesQuery ||
          coilGroutIncludesQuery ||
          crucibleInfotIncludesQuery ||
          endProductIncludesQuery ||
          steelGradeIncludesQuery ||
          customerIncludesQuery
        )
      })
    }
    setFilteredFurnaces(filtered)
  }

  const handleExcelDownload = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Furnaces')
    // Define column headers
    worksheet.columns = [
      { header: 'No', key: 'no', width: 10 },
      { header: 'Furnace Number', key: 'furnace_number', width: 20 },
      { header: 'Year of Mfg.', key: 'year_of_manufacture', width: 20 },
      { header: 'Capacity', key: 'capacity', width: 20 },
      { header: 'Coil Grout', key: 'coil_grout', width: 30 },
      { header: 'Crucible Info', key: 'crucible_info', width: 30 },
      { header: 'End Product', key: 'end_product', width: 30 },
      { header: 'Steel Grades', key: 'steel_grade', width: 30 },
    ]

    const dataToDownload = searchQuery ? filteredFurnaces : customers

    // Add customer data to the worksheet
    dataToDownload.forEach((row, index) => {
      const crucibleInfo = getCrucibleInfoName(row.crucible_info_id)
      worksheet.addRow({ ...row, no: index + 1, crucible_info: crucibleInfo })
    })


    // Generate the Excel file as a Blob
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob)

    // Create a temporary <a> element to trigger the download
    const link = document.createElement('a')
    link.href = url
    link.download = 'furnaces.xlsx'

    // Programmatically click the link to trigger the download
    document.body.appendChild(link)
    link.click()

    // Cleanup
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  // const handleSave = async () => {
  //   try {
  //     const jsonData = formData;
  //     try {
  //       // const jsonData = formData;
  //       if (isEditMode && selectedFurnaceIndex !== null) {
  //         const furnaceId = furnaces[selectedFurnaceIndex].id;
  //         const response = await axios.put(`${API.UPDATE_FURNACE}/${furnaceId}`, jsonData);
  //         console.log("response", response);
  //          await fetchData();
  //       } else {
  //         const response = await axios.post(API.INSERT_FURNACE, jsonData);
  //         console.log("response", response);
  //        await fetchData();
  //       }
  //       setOpen(false); // Close the modal after saving
  //     } catch (error) {
  //       console.error('Error saving data:', error);
  //     }
  //   } catch (error) {
  //     console.error('Error saving user:', error);
  //   }
  // };
  return (
    <div>
      {/* <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid #E5E9EB',
          paddingBottom: '8px',
          marginBottom: '8px',
        }}
      >
        <h5
          sx={{
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '-0.006em',
            textAlign: 'left',
            color: '#252C32',
          }}
        >
          Furnace Details
        </h5>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={SearchIcon}
            alt="search icon"
            style={{ marginRight: '15px', cursor: 'pointer' }}
            onClick={handleSearchIconClick}
          />
          {isInputVisible && (
            <div style={{ position: 'relative' }}>
              <input
                type="text"
                value={searchQuery}
                onChange={handleInputChange}
                placeholder="Search..."
                style={{ marginRight: '15px', height: '35px' }}
              />
              <span style={{
                position: 'absolute',
                top: '50%',
                right: '20px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
              }}
                onClick={handleCloseIconClick}>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </span>
            </div>
          )}
          <img
            src={DownloadIcon}
            style={{ marginRight: '20px' }}
            onClick={handleExcelDownload}
          />
          <button
            variant="contained"
            color="primary"
            sx={{
              textTransform: 'none', // Remove all-caps transformation
            }}
            onClick={handleOpenModal}
          >
            Add Furnace
          </button>
        </div>
      </div> */}

      <FurnaceTable
        furnaces={furnaces}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        getCrucibleInfoName={getCrucibleInfoName}
        filteredFurnaces={filteredFurnaces}
        searchQuery={searchQuery}
        handleOpenModal={handleOpenModal}
        handleInputChange={handleInputChange}
        // handleSearchIconClick={handleSearchIconClick}
        handleExcelDownload={handleExcelDownload}
      />

      <FurnaceModal
        open={open}
        handleClose={handleCloseModal}
        formData={formData}
        handleChange={handleChange}
        handleSave={handleSave}
        isEditMode={isEditMode}
        formErrors={formErrors}
        customers={customers}
      />
    </div>
  )
}

const FurnaceTable = ({
  furnaces,
  handleEdit,
  handleDelete,
  getCrucibleInfoName,
  handleInputChange,
  filteredFurnaces,
  handleOpenModal,
  handleExcelDownload,
  searchQuery,
}) => {
  console.log("filteredFurnaces",filteredFurnaces)
  console.log("furnaces",furnaces)
  return (
    <>
      <div className='pt-3 flex justify-between bg-white border-b pb-2 pl-2'>
        <p className='text-xs text-gray-800 font-medium p-2 mb-0'>Furnace Management</p>
        <div className='flex items-center'>
          <div className="flex items-center">
            <label htmlFor="topbar-search" className="sr-only">Search Transactions</label>
            <div className="relative w-full">
              <div
                className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
              >
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  ></path>
                </svg>
              </div>
              <input
                name="search"
                type="text"
                value={searchQuery}
                onChange={handleInputChange}
                id="topbar-search"
                className="bg-white w-full text-gray-900  border border-gray-300 rounded-md text-sm focus:outline-none pl-10 p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search Data"
              />
            </div>
          </div>

          <span className='border-r h-8 mx-3'></span>
          {/* <button
            type="button"
            className="flex mx-3 text-smdark:focus:ring-gray-600"
            id="Filter-table"
            aria-expanded="false"
            data-dropdown-toggle="dropdown"
          >
            <img className="w-6 h-6 rounded-full" src={Filter} alt="Filter" />
          </button> */}
          {/* <button
            type="button"
            className="flex mx-3 text-smdark:focus:ring-gray-600"
            id="Download-table"
            aria-expanded="false"
            data-dropdown-toggle="dropdown"
            onClick={handleExcelDownload}
          >
            <img className="w-6 h-6 rounded-full" src={DownloadIcon} alt="Download" />
          </button>
          <span className='border-r h-8 mx-3'></span> */}
          <button data-modal-target="static-modal" onClick={handleOpenModal} data-modal-toggle="static-modal" type="button" class="mr-4 text-white whitespace-nowrap bg-gradient-to-br from-custom-red to-custom-orange hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-regular rounded-md text-sm pl-5 pr-10 xl:pr-5 py-2 ml-3 text-center flex">
            Add Furnace
          </button>
        </div>
      </div>
      <div className='w-full'>
        <div className='overflow-x-auto'>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead>
              <tr>
                <th
                  className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400"
                >S.No</th>
                <th className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400">Furnace Number</th>
                <th className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400">Year of Mfg.</th>
                <th className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400">Capacity</th>
                <th className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400">Coil Grout</th>
                <th className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400">Crucible Info.</th>
                <th className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400">End Product</th>
                <th className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400">Steel Grades</th>
                <th className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400">Customer Name</th>
                <th className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400">Action</th>
              </tr>
            </thead>
            <tbody>
              {(searchQuery ? filteredFurnaces : furnaces).map((furnace, index) => (
                <tr
                  key={furnace.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">{index + 1}</td>
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">{furnace.furnace_number}</td>
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">{furnace.year_of_manufacture}</td>
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">{furnace.capacity}</td>
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">{furnace.coil_grout}</td>
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">
                    {getCrucibleInfoName(furnace.crucible_info_id)}
                  </td>
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">{furnace.end_product}</td>
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">{furnace.steel_grade}</td>
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">{furnace.customer.organization_name}</td>
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">
                    <button
                      onClick={() => handleEdit(index)}
                      className="mr-2 p-1 text-blue-500 hover:text-blue-700"
                    >
                      <img className="w-5 h-5" src={EditIcon} alt="Edit" />
                    </button>
                    <button
                      onClick={() => handleDelete(index)}
                      className="p-1 text-red-500 hover:text-red-700"
                    >
                      <img className="w-5 h-5" src={DeleteIcon} alt="Delete" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>


  )
}
const FurnaceModal = ({
  open,
  handleClose,
  formData,
  handleChange,
  handleSave,
  isEditMode,
  formErrors,
  customers,
}) => {
  return (
    <>
      {open && (
        <div
          className={`fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50`}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <div
            className="bg-white rounded-lg w-[600px] shadow-lg "
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {isEditMode ? 'Edit' : 'Add'} Furnace
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={handleClose}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="space-y-4 mt-4 overflow-y-auto px-4 h-[440px]">

              <div className='px-4'>
                <div className="mt-4">
                  <h2 className="text-sm font-medium text-gray-500">CUSTOMER INFORMATION</h2>
                  <div className="mt-2">
                    <label htmlFor="assign_customer_id" className="block text-sm font-medium text-gray-700">
                      Assign Customer*
                    </label>
                    <select
                      id="assign_customer_id"
                      name="assign_customer_id"
                      value={formData.assign_customer_id}
                      onChange={handleChange}
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${formErrors.assign_customer_id ? "border-red-500" : ""
                        }`}
                    >
                      <option value="">Select a Customer</option>
                      {customers.map((customer) => (
                        <option key={customer.id} value={customer.id}>
                          {customer.organization_name}
                        </option>
                      ))}
                    </select>
                    {formErrors.assign_customer_id && (
                      <p className="mt-1 text-sm text-red-600">{formErrors.assign_customer_id}</p>
                    )}
                  </div>
                </div>

                <div className="mt-6">
                  <h2 className="text-sm font-medium text-gray-500">FURNACE INFORMATION</h2>
                  <div className="mt-4 flex flex-wrap gap-4">
                    <div className="flex-1">
                      <label htmlFor="furnace_number" className="block text-sm font-medium text-gray-700">
                        Furnace Number*
                      </label>
                      <input
                        id="furnace_number"
                        name="furnace_number"
                        type="text"
                        value={formData.furnace_number}
                        onChange={handleChange}
                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${formErrors.furnace_number ? "border-red-500" : ""
                          }`}
                      />
                      {formErrors.furnace_number && (
                        <p className="mt-1 text-sm text-red-600">{formErrors.furnace_number}</p>
                      )}
                    </div>
                    <div className="flex-1">
                      <label htmlFor="year_of_manufacture" className="block text-sm font-medium text-gray-700">
                        Year of Manufacture
                      </label>
                      <input
                        id="year_of_manufacture"
                        name="year_of_manufacture"
                        type="text"
                        value={formData.year_of_manufacture}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="mt-4 flex flex-wrap gap-4">

                    <div className="flex-1">
                      <label htmlFor="capacity" className="block text-sm font-medium text-gray-700">
                        Capacity*
                      </label>
                      <input
                        id="capacity"
                        name="capacity"
                        type="text"
                        value={formData.capacity}
                        onChange={handleChange}
                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${formErrors.capacity ? "border-red-500" : ""
                          }`}
                      />
                      {formErrors.capacity && (
                        <p className="mt-1 text-sm text-red-600">{formErrors.capacity}</p>
                      )}
                    </div>

                    <div className="flex-1">
                      <label htmlFor="coil_grout" className="block text-sm font-medium text-gray-700">
                        Coil Grout*
                      </label>
                      <input
                        id="coil_grout"
                        name="coil_grout"
                        type="text"
                        value={formData.coil_grout}
                        onChange={handleChange}
                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${formErrors.coil_grout ? "border-red-500" : ""
                          }`}
                      />
                      {formErrors.coil_grout && (
                        <p className="mt-1 text-sm text-red-600">{formErrors.coil_grout}</p>
                      )}
                    </div>
                  </div>
                  <div className="mt-4 flex flex-wrap gap-4">
                    <div className="flex-1">
                      <label htmlFor="panels_info" className="block text-sm font-medium text-gray-700">
                        Panels Information
                      </label>
                      <input
                        id="panels_info"
                        name="panels_info"
                        type="text"
                        value={formData.panels_info}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>

                    <div className="flex-1">
                      <label htmlFor="crucible_info_id" className="block text-sm font-medium text-gray-700">
                        Crucible Info.*
                      </label>
                      <select
                        id="crucible_info_id"
                        name="crucible_info_id"
                        value={formData.crucible_info_id}
                        onChange={handleChange}
                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${formErrors.crucible_info_id ? "border-red-500" : ""
                          }`}
                      >
                        <option value="">Select Crucible Info</option>
                        {Helper.CrucibleInfo.map((info) => (
                          <option key={info.id} value={info.id}>
                            {info.name}
                          </option>
                        ))}
                      </select>
                      {formErrors.crucible_info_id && (
                        <p className="mt-1 text-sm text-red-600">{formErrors.crucible_info_id}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-6">
                  <h2 className="text-sm font-medium text-gray-500">PRODUCT INFORMATION</h2>
                  <div className="mt-4 flex flex-wrap gap-4">
                    <div className="flex-1">
                      <label htmlFor="end_product" className="block text-sm font-medium text-gray-700">
                        End Product*
                      </label>
                      <input
                        id="end_product"
                        name="end_product"
                        type="text"
                        value={formData.end_product}
                        onChange={handleChange}
                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${formErrors.end_product ? "border-red-500" : ""
                          }`}
                      />
                      {formErrors.end_product && (
                        <p className="mt-1 text-sm text-red-600">{formErrors.end_product}</p>
                      )}
                    </div>
                    <div className="flex-1">
                      <label htmlFor="steel_grade" className="block text-sm font-medium text-gray-700">
                        Steel Grade*
                      </label>
                      <input
                        id="steel_grade"
                        name="steel_grade"
                        type="text"
                        value={formData.steel_grade}
                        onChange={handleChange}
                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${formErrors.steel_grade ? "border-red-500" : ""
                          }`}
                      />
                      {formErrors.steel_grade && (
                        <p className="mt-1 text-sm text-red-600">{formErrors.steel_grade}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  <h2 className="text-sm font-medium text-gray-500">SUPPLIER INFORMATION</h2>
                  <div className="mt-4 flex flex-wrap gap-4">
                    <div className="flex-1">
                      <label htmlFor="supplier_name" className="block text-sm font-medium text-gray-700">
                        Supplier Name
                      </label>
                      <input
                        id="supplier_name"
                        name="supplier_name"
                        type="text"
                        value={formData.supplier_name}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                    <div className="flex-1">
                      <label htmlFor="supplier_contact_number" className="block text-sm font-medium text-gray-700">
                        Supplier Contact Number
                      </label>
                      <input
                        id="supplier_contact_number"
                        name="supplier_contact_number"
                        type="text"
                        value={formData.supplier_contact_number}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 flex justify-end space-x-4 p-4">
              <button
                onClick={handleClose}
                className="w-1/2 h-10 px-3 py-2 rounded-md text-gray-800 border border-gray-300 text-sm font-medium mr-2.5"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="w-1/2 h-10 px-3 py-2 rounded-md text-white bg-custom-red text-sm font-medium"
              >
                {isEditMode ? 'Update' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      )}

    </>
  )
}

export default Furnaces
