import React, {useEffect} from "react";
import { useRecoilState } from "recoil";
import { trial_name, cost_sheet_furnace, cost_sheet_furnace_nrm } from "../../../recoil/atom";

const FurnaceDetails = () => {
  const [furnaceDetails, setFurnaceDetails] = useRecoilState(cost_sheet_furnace)
  const [furnaceDetailsNRM, setFurnaceDetailsNRM] = useRecoilState(
    cost_sheet_furnace_nrm
  )
  console.log(furnaceDetails, furnaceDetailsNRM, "furnaceDetailsNRM")
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'

  useEffect(() => {
    let furnaceObj = {
      furnaceDetails: {
        basic: furnaceDetails || {},
        nrm: furnaceDetailsNRM || {}, 
      },
    }
    console.log(furnaceObj, '35656')
  }, [furnaceDetails, furnaceDetailsNRM])

  const handleChange = (e) => {
    const { name, value } = e.target
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setFurnaceDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleChangeNRM = (e) => {
    const { name, value } = e.target
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setFurnaceDetailsNRM((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Furnace Details(A)
      </h2>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="furnace_capacity"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Furnace Capacity - (MT)
          </label>
          <input
            type="text"
            id="furnace_capacity"
            name="furnace_capacity"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Capacity"
            value={furnaceDetails.furnace_capacity || ''}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="furnace_output"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Furnace output in Tons - Sintering heat (As per Former size) - (MT)
          </label>
          <input
            type="text"
            id="furnace_output"
            name="furnace_output"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Tons"
            value={furnaceDetails.furnace_output || ''}
            onChange={handleChange}
          />
        </div>
      </div>

      <h2 className="text-l font-medium text-black-400 mb-4">NRM</h2>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="furnace_capacity"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Furnace Capacity - (MT)
          </label>
          <input
            type="text"
            id="furnace_capacity"
            name="furnace_capacity"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Capacity"
            value={furnaceDetailsNRM.furnace_capacity || ''}
            onChange={handleChangeNRM}
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="furnace_output"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Furnace output in Tons - Sintering heat (As per Former size) - (MT)
          </label>
          <input
            type="text"
            id="furnace_output"
            name="furnace_output"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Output"
            value={furnaceDetailsNRM.furnace_output || ''}
            onChange={handleChangeNRM}
          />
        </div>
      </div>
    </>
  );
};

export default FurnaceDetails;
