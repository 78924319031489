import React, { useState,useEffect } from "react";
import LiningReferenceWeb from "./LiningReferenceWeb";
import ProductionSummaryWeb from "./ProductionSummaryWeb";
import LiningMaterialProductWeb from "./LiningMaterialProductWeb";
import LiningMaterialConsumptionWeb from "./LiningMaterialConsumptionWeb";
import DimensionalParametersWeb from "./DimensionalParametersWeb";
import SinteringParametersWeb from "./SinteringParametersWeb";
import rightArrow from "./../../assets/images/icons/RightArrow.svg";
import { RecoilRoot } from "recoil";
import {
  lining_reference,
  production_summary,
  lining_material_product_details,
  lining_material_consumption,
  dimensional_parameters,
  sintering_parameters,
  trial_name,
} from "../../recoil/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "axios";
import API from "../../service/API";
import AlertDialog from "../Common/AlertDialog";
import ModalLayout from "../Common/ModalLayout";
import ApproveStatus from "../Status/ApproveStatus";
import SubmittedStatus from "../Status/SubmittedStatus";
import DraftStatus from "../Status/DraftStatus";

const NRMLayout = ({closeModal}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [statusDraft,setStatusDraft]= useState(false);
  const [statusSubmitted,setStatusSubmitted]= useState(false);
  const [statusApproved,setStatusApproved]= useState(false);
  const [liningReference, setLiningReference] =
  useRecoilState(lining_reference);
const [productionSummary, setProductionSummary] =
  useRecoilState(production_summary);
const [liningMaterialProductDetails, setLiningMaterialProductDetails] =
  useRecoilState(lining_material_product_details);
const [liningMaterialConsumption, setLiningMaterialConsumption] =
  useRecoilState(lining_material_consumption);
const [dimensionalParameters, setDimensionalParameters] = useRecoilState(
  dimensional_parameters
);
const [sinteringParameters, setSinteringParameters] =
  useRecoilState(sintering_parameters);
const [loading, setLoading] = useState(false); // Loader state
const [error, setError] = useState(null); // Error state
const currentUser = JSON.parse(localStorage.getItem("user"));
const access_token = localStorage.getItem("accesstoken");
const [formErrors, setFormErrors] = useState({});
const [validationErrors, setValidationErrors] = useState({});
const steps = [
  { id: 1, component: <LiningReferenceWeb  validationErrors={validationErrors}/> },
  { id: 2, component: <ProductionSummaryWeb /> },
  { id: 3, component: <LiningMaterialProductWeb /> },
  { id: 4, component: <LiningMaterialConsumptionWeb /> },
  { id: 5, component: <DimensionalParametersWeb /> },
  { id: 6, component: <SinteringParametersWeb /> },
];
const [activeTab, setActiveTab] = React.useState(steps[0]?.id || "Lining");





const isEdit = localStorage.getItem("Trial");



  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
      setActiveTab(steps[currentStep - 2]?.id); // Set activeTab based on the previous step
    }
  };

  // const handleNext = () => {
  //   if (currentStep < steps.length) {
  //     setCurrentStep((prev) => prev + 1);
  //     setActiveTab(steps[currentStep]?.id); // Set activeTab based on the next step
  //   }
  //   if (activeTab === 6) {
  //     submitFormData();
  //   } 
  // };


  useEffect(() => {
      fetchData() // Call the function to fetch data
  }, []);

  const fetchData = async () => {
    if (isEdit === 'view' || isEdit === 'edit') {
      setLoading(true)
      setError(null)
      try {
        const selectedItem = localStorage.getItem("SelectedItem");
        const selectedItemData = JSON.parse(selectedItem);
        const nrmPerformanceId = selectedItemData.id;
        const TrialIndex = localStorage.getItem('TrialIndex');
        const response = await axios.get(
          `${API.GET_NRM_PERFORMANCE_BY_ID}/${nrmPerformanceId}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        const liningReference = response.data.lining_reference[TrialIndex]
        const productionSummary = response.data.production_summary[TrialIndex]
        const liningMaterialProductDetails =
          response.data.lining_material_product_details[TrialIndex]
        const liningMaterialConsumption =
          response.data.lining_material_consumption[TrialIndex]
        const dimensionalParameters =
          response.data.dimensional_parameters[TrialIndex]
        const sinteringParameters =
          response.data.sintering_parameters[TrialIndex]

        setLiningReference(liningReference)
        setProductionSummary(productionSummary)
        setLiningMaterialProductDetails(liningMaterialProductDetails)
        setLiningMaterialConsumption(liningMaterialConsumption)
        setDimensionalParameters(dimensionalParameters)
        setSinteringParameters(sinteringParameters)
      } catch (error) {
        console.error('Error fetching data:', error)
        setError(error.message)
      } finally {
        setLoading(false)
      }
    } else {
      setLiningReference({})
      setProductionSummary({})
      setLiningMaterialProductDetails({})
      setLiningMaterialConsumption({})
      setDimensionalParameters({})
      setSinteringParameters({})
    }
  }

  const handleNext = () => {
    const validateLiningReference = () => {
      const requiredFields = [
        // { key: "furnace_no", label: "Furnace No" },
        { key: "crucible_no", label: "Crucible No" },
        { key: "campaign_no", label: "Campaign No" },
        { key: "lining_or_patching", label: "Lining/Patching No" },
      ];

      const errors = {};
      requiredFields.forEach((field) => {
        if (!liningReference[field.key]) {
          errors[field.key] = `${field.label} is required.`;
        }
      });
  
      setValidationErrors(errors);
      return Object.keys(errors).length === 0;
    };
  
    if (currentStep === 1) {
      // Perform validation for the first tab (General Details)
      if (!validateLiningReference()) {
        return; // Stop progression to the next step if validation fails
      }
    }

    // Proceed to the next step
    if (currentStep < steps.length) {
      setCurrentStep((prev) => prev + 1);
      setActiveTab(steps[currentStep]?.id); // Set activeTab based on the next step
    }
  
    // Submit data when activeTab is 6
    if (activeTab === 6) {
      submitFormData();
    }
  };
  

  const submitFormData = async () => {
    try {
      let updatedLiningReference;
     
      const datafile_id = localStorage.getItem("datafile_id");
      
      if(currentUser.role.role_id === 3 || currentUser.role.role_id === 4){
        updatedLiningReference = { ...liningReference, submission_status: "approved" };
    }else{
      updatedLiningReference = { ...liningReference, submission_status: "submitted" };
    }

    console.log("updatedLiningReference",updatedLiningReference);
    console.log("isEdit",isEdit);


      try {
        if (isEdit === "new") {
          AlertDialog({
            type: "delete",
            title: "Submission",
            text: "Are you sure you want to Submit for an Approval?",
            confirmButtonText: "Yes",
            onConfirm: async () => {
              if(currentUser.role.role_id === 3 || currentUser.role.role_id === 4){
                await insertData(updatedLiningReference, datafile_id,"approved");
              }else{
                await insertData(updatedLiningReference, datafile_id,"submitted");
              }
            },
          });
        } else if (isEdit === "add") {
          // For example:
          const id = localStorage.getItem("nrm_performance_id");
          AlertDialog({
            type: "delete",
            title: "Submission",
            text: "Are you sure you want to Submit for an Approval?",
            confirmButtonText: "Yes",
            onConfirm: async () => {

              if(currentUser.role.role_id === 3 || currentUser.role.role_id === 4){
                await addData(updatedLiningReference, datafile_id, id, "approved");
              }else{
                await addData(updatedLiningReference, datafile_id, id, "submitted");
              }
            },
          });
        } else {
          try {
            const id = localStorage.getItem("nrm_performance_id");
            const TrialIndex = parseInt(localStorage.getItem("TrialIndex"));
            AlertDialog({
              type: "delete",
              title: "Submission",
              text: "Are you sure you want to Submit for an Approval?",
              confirmButtonText: "Yes",
              onConfirm: async () => {
                if(currentUser.role.role_id === 3 || currentUser.role.role_id === 4){
                  await updateData(updatedLiningReference, datafile_id, id,TrialIndex, "approved");
                }else{
                  await updateData(updatedLiningReference, datafile_id, id,TrialIndex, "submitted");
                }  
              },
            });
          } catch (error) {}
        }

        // Optionally, navigate to another page or perform other actions
      } catch (error) {
        // Handle errors
        console.error("An error occurred while inserting data:", error.message);
      }

      // Optionally, navigate to another page or perform other actions
    } catch (error) {
      // Handle errors
      console.error(
        "An error occurred while inserting data123:",
        error.message
      );
    }
  };

  const addData = async (updatedLiningReference, datafile_id, id, status) => {
    setLoading(true);
    setError(null);
    try{
      
      const res = await axios.get(`${API.GET_NRM_PERFORMANCE_BY_ID}/${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
    
      const existingData = res.data;
      // Create a new object to add to existingData
      const newTrialObj = {
        lining_reference: updatedLiningReference,
        production_summary: productionSummary,
        lining_material_product_details: liningMaterialProductDetails,
        lining_material_consumption: liningMaterialConsumption,
        dimensional_parameters: dimensionalParameters,
        sintering_parameters: sinteringParameters,
      };
      // Iterate over each key in newTrialObj
      Object.keys(newTrialObj).forEach((key) => {
        existingData[key].push(newTrialObj[key]);
      });
      console.log(newTrialObj, "newTrialObj");
      console.log(existingData);
      console.log(existingData, "updated existingData");
      
    if(status === "submitted"){
      existingData.lining_reference.forEach((detail) => {
        detail.submission_status = "submitted";
      });
    }else if(status === "approved"){
      existingData.lining_reference.forEach((detail) => {
        detail.submission_status = "approved";
      });
    }else{
      existingData.lining_reference.forEach((detail) => {
        detail.submission_status = "draft";
      });
    }
      const data = {
        datafile_id: datafile_id,
        data: existingData,
      };
      // Make the POST request using axios
      const response = await axios.put(API.UPDATE_NEW_TRIAL_NRM, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      if(status === "submitted"){
        setStatusSubmitted(true);
        setStatusDraft(false);
        setStatusApproved(false);
     }else if(status === "approved"){
       setStatusApproved(true);
       setStatusSubmitted(false);
       setStatusDraft(false);
     }else if (status === "draft"){
       setStatusDraft(true)
       setStatusApproved(false);
       setStatusSubmitted(false);
     }
    }catch(error){
    setError("Problem with adding data", error.message);
    }finally{
      setLoading(false);
    }
  };

  const updateData = async (
    updatedLiningReference,
    datafile_id,
    id,
    TrialIndex,
    status
  ) => {
    setLoading(true)
    setError(null)
    try {
        const response = await axios.get(
          `${API.GET_NRM_PERFORMANCE_BY_ID}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        const data = response.data

        // Update the values based on the index
        data.lining_reference[TrialIndex] = updatedLiningReference
        data.production_summary[TrialIndex] = productionSummary
        data.lining_material_product_details[TrialIndex] =
          liningMaterialProductDetails
        data.lining_material_consumption[TrialIndex] = liningMaterialConsumption
        data.dimensional_parameters[TrialIndex] = dimensionalParameters
        data.sintering_parameters[TrialIndex] = sinteringParameters

        if (status === 'submitted') {
          data.lining_reference.forEach((detail) => {
            detail.submission_status = 'submitted'
          })
        } else if (status === 'approved') {
          data.lining_reference.forEach((detail) => {
            detail.submission_status = 'approved'
          })
        } else {
          data.lining_reference.forEach((detail) => {
            detail.submission_status = 'draft'
          })
        }
        const updateResponse = await axios.put(
          `${API.FORM_UPDATE_NRM}/${id}`,
          {
            data: data,
            id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        console.log('Response:', updateResponse.data)
        if(status === "submitted"){
          setStatusSubmitted(true);
          setStatusDraft(false);
          setStatusApproved(false);
       }else if(status === "approved"){
         setStatusApproved(true);
         setStatusSubmitted(false);
         setStatusDraft(false);
       }else if (status === "draft"){
         setStatusDraft(true)
         setStatusApproved(false);
         setStatusSubmitted(false);
       }
 
    } catch (error) {
      setError(error.message) // Set the error state if an error occurs
    } finally {
      setLoading(false)
    }
  }

  const insertData = async (updatedLiningReference, datafile_id, status) => {
    setLoading(true);
    setError(null);
    try{
      const data = {
        datafile_id: datafile_id,
        data: [
          // Assuming you have an array of data objects to be inserted
          {
            lining_reference: updatedLiningReference,
            production_summary: productionSummary,
            lining_material_product_details: liningMaterialProductDetails,
            lining_material_consumption: liningMaterialConsumption,
            dimensional_parameters: dimensionalParameters,
            sintering_parameters: sinteringParameters,
          },
        ],
      };

      console.log("data",data);
      // Make the POST request using axios
      const response = await axios.post(API.FORM_INSERT_NRM, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      const nrm_performance_id = response.data.raw[0].id;     
      localStorage.setItem("nrm_performance_id", nrm_performance_id);
      // Handle the response if needed
      if(status === "submitted"){
         setStatusSubmitted(true);
         setStatusDraft(false);
         setStatusApproved(false);
      }else if(status === "approved"){
        setStatusApproved(true);
        setStatusSubmitted(false);
        setStatusDraft(false);
      }else if (status === "draft"){
        setStatusDraft(true)
        setStatusApproved(false);
        setStatusSubmitted(false);
      }
    }catch(error){
      setError("Problem with inserting data", error);
    }finally{
      setLoading(false)
    }
  };

  const handleOpenUploadModal = () => {
    setModalTitle("Upload New DataFile");
    setIsModalOpen(true);
  };

  const handleOpenAddFormModal = () => {
    setModalTitle("Add Form");
    setIsModalOpen(true);
  };

  const closeModalOnBackdropClick = (e) => {
    if (e.target.id === "static-modal") {
      setIsModalOpen(false);
    }
  };

  const handleDraft = async () => {
    const validateLiningReference = () => {
      const requiredFields = [
        // { key: "furnace_no", label: "Furnace No" },
        { key: "crucible_no", label: "Crucible No" },
        { key: "campaign_no", label: "Campaign No" },
        { key: "lining_or_patching", label: "Lining/Patching No" },
      ];

      const errors = {};
      requiredFields.forEach((field) => {
        if (!liningReference[field.key]) {
          errors[field.key] = `${field.label} is required.`;
        }
      });
  
      setValidationErrors(errors);
      return Object.keys(errors).length === 0;
    };

    if (currentStep === 1) {
      // Perform validation for the first tab (General Details)
      if (!validateLiningReference()) {
        return; // Stop progression to the next step if validation fails
      }
    }
    const updatedLiningReference = {
      ...liningReference,
      submission_status: "draft",
    };
    
      try {
      if (isEdit === "new") {
        const datafile_id = localStorage.getItem("datafile_id");
        await insertData(updatedLiningReference, datafile_id, "draft");
      } else if (isEdit === "add") {
        const selectedItem = localStorage.getItem("SelectedItem");
        const selectedItemData = JSON.parse(selectedItem);
        const datafile_id = selectedItemData.datafile_id;
        const id = selectedItemData?.id;
        await addData(updatedLiningReference, datafile_id, id, "draft");
      } else {
        const selectedItem = localStorage.getItem("SelectedItem");
        const selectedItemData = JSON.parse(selectedItem);
        const datafile_id = selectedItemData.datafile_id;
        const id = selectedItemData?.id;
         const TrialIndex = parseInt(localStorage.getItem("TrialIndex"));
         await updateData(
          updatedLiningReference,
          datafile_id,
          id,
          TrialIndex,
          "draft"
        );
      }
      // Optionally, navigate to another page or perform other actions
    } catch (error) {
      // Handle errors
      console.error("An error occurred while inserting data:", error.message);
    }
  };
  // State to track the active tab
  // const [activeTab, setActiveTab] = useState("generalDetails");

  // Function to handle tab click
  const handleTabClick = (tab, step) => {
    setActiveTab(tab);
    setCurrentStep(step);
  };
  return (
    <>
      {/* Conditionally render status components */}
      {statusApproved && <ApproveStatus closeModal={closeModal} />}
      {statusDraft && <DraftStatus closeModal={closeModal} />}
      {statusSubmitted && <SubmittedStatus closeModal={closeModal} />}
  
      {/* Render the main content if no specific status is active */}
      {!statusApproved && !statusDraft && !statusSubmitted && (
        <div className="w-full">
          {/* Tab Navigation */}
          <div className="flex border-b border-gray-200 dark:border-gray-700 pr-4">
            <ul className="flex -mb-px text-sm font-medium text-center overflow-auto py-2 track-form-list">
              {steps.map((step, index) => (
                <li key={step.id} className="me-2" role="presentation">
                  <div className="flex items-center">
                    <button
                      onClick={() => handleTabClick(step.id, index + 1)}
                      className={`inline-block p-1 whitespace-nowrap font-normal rounded-t-lg ${
                        activeTab === step.id
                          ? "text-custom-orange"
                          : "text-gray-500"
                      }`}
                      type="button"
                      role="tab"
                      aria-controls={step.id}
                      aria-selected={activeTab === step.id}
                    >
                      <span className="flex items-center justify-center">
                        <span
                          className={`flex items-center justify-center w-5 h-5 me-2 text-xs bg-gray-100 border rounded-full shrink-0 ${
                            activeTab === step.id
                              ? "border-custom-orange text-custom-orange bg-custom-orange-light"
                              : "border-gray-300"
                          }`}
                        >
                          {index + 1}
                        </span>
                        {step.id === 1
                          ? "Lining"
                          : step.id === 2
                          ? "Production"
                          : step.id === 3
                          ? "Lining Material Product"
                          : step.id === 4
                          ? "Consumption"
                          : step.id === 5
                          ? "Dimensional"
                          : "Sintering"}
                      </span>
                    </button>
                    <img src={rightArrow} alt="arrow" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
  
          {/* Content Section */}
          <div
            style={{ height: "365px" }}
            className="mt-4 w-full overflow-y-auto pb-4 pr-4"
          >
            {steps[currentStep - 1]?.component}
          </div>
  
          {/* Navigation Buttons */}
          <div className="flex space-x-4 justify-center bg-white w-full py-4 pr-4">
            <button
              onClick={handlePrevious}
              className="text-grey bg-white border border-gray-500 w-1/2 px-4 py-2 rounded-lg disabled:opacity-50"
              disabled={currentStep === 1} // Disable on the first step
            >
              Previous
            </button>
            <button
              onClick={handleDraft}
              className="text-grey bg-white border border-gray-500 w-1/2 px-4 py-2 rounded-lg disabled:opacity-50"
            >
              Save Draft
            </button>
            <button
              onClick={handleNext}
              className="text-white bg-custom-red w-1/2 px-4 py-2 rounded-lg disabled:opacity-50"
              disabled={currentStep === 6 && !submitFormData} // Disable only if submission is not ready
            >
              {currentStep === 6 ? "Submit" : "Next"}{" "}
              {/* Change label to "Submit" on the last step */}
            </button>
          </div>
        </div>
      )}
    </>
  );
  
};

export default NRMLayout;
