import React from "react";
import { useRecoilState } from "recoil";
import { furnace_Details_data_collection, trial_name } from "../../recoil/atom";
import { v4 as uuidv4 } from 'uuid'
const FurnaceDetailsWeb = () => {
  const [trialName] = useRecoilState(trial_name)
  const [FurnaceDetails, setFurnaceDetails] = useRecoilState(
    furnace_Details_data_collection
  )
  console.log(FurnaceDetails, 'FurnaceDetails')
  const uuid = uuidv4()

  const handleChange = (e) => {
    const { name, value } = e.target
    setFurnaceDetails((prevState) => ({
      ...prevState,
      [name]: value,
      id: uuid,
    }))
  }

  const isReadOnly = trialName === 'viewDataCollection'

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Furnace Details
      </h2>

      {/* Furnace No Dropdown */}

      <div className="flex space-x-4 mt-4">
        {/* Heat No */}
        <div className="w-full mb-4">
          <label
            htmlFor="no_of_panels"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total No. of Panel - No.
          </label>
          <input
            type="text"
            id="no_of_panels"
            name="no_of_panels"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Panel No."
            value={FurnaceDetails.no_of_panels}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="fce_capacity"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Fce. Capacity in which need to trial - MT
          </label>
          <input
            type="text"
            id="fce_capacity"
            name="fce_capacity"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Capacity"
            value={FurnaceDetails.fce_capacity}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>

      </div>


      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="avg_heatsize"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Avg. Heat Size (total MT per heat) - (MT)
          </label>
          <input
            type="text"
            id="avg_heatsize"
            name="avg_heatsize"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Heat Size"
            value={FurnaceDetails.avg_heatsize}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label
            htmlFor="power_rating"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Power rating of Fce. - (KW)
          </label>
          <input
            type="text"
            id="power_rating"
            name="power_rating"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Power Rating"
            value={FurnaceDetails.power_rating}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
      </div>



      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="fce_running"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Is Fce is running with full power - Yes/No
          </label>
          <input
            type="text"
            id="fce_running"
            name="fce_running"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter power."
            value={FurnaceDetails.fce_running}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label
            htmlFor="tap_time"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Tap to tap time - (HH:MM)
          </label>
          <input
            type="text"
            id="tap_time"
            name="tap_time"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Time"
            value={FurnaceDetails.tap_time}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="sintering_panel"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Sintering Panel - Yes/No
          </label>
          <input
            type="text"
            id="sintering_panel"
            name="sintering_panel"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Customer Ref No."
            value={FurnaceDetails.sintering_panel}
          onChange={handleChange}
          disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label
            htmlFor="date"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Power rating (Sintering panel) - (KW)
          </label>
          <input
            type="text"
            id="power_rating_sintering"
            name="power_rating_sintering"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Power rating"
            value={FurnaceDetails.power_rating_sintering}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="no_of_heats_perday"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total No. of Heats / day - (each)
          </label>
          <input
            type="text"
            id="no_of_heats_perday"
            name="no_of_heats_perday"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter heat nos"
            value={FurnaceDetails.no_of_heats_perday}
          onChange={handleChange}
          disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label
            htmlFor="avg_power_consumption"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Avg. Power Consumption / MT of Billet (only Fce) - (Kwh / MT)
          </label>
          <input
            type="text"
            id="avg_power_consumption"
            name="avg_power_consumption"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter avg power"
            value={FurnaceDetails.avg_power_consumption}
          onChange={handleChange}
          disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            LRF/AOD - (Yes/No)
          </label>
          <input
            type="text"
            id="lrf"
            name="lrf"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter LRF"
            value={FurnaceDetails.lrf}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label
            htmlFor="frequency_range"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Frequency Range - (Hz)
          </label>
          <input
            type="text"
            id="frequency_range"
            name="frequency_range"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Range"
            value={FurnaceDetails.frequency_range}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="max_tapping_temp"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Maximum tapping temperature - (⁰C)
          </label>
          <input
            type="text"
            id="max_tapping_temp"
            name="max_tapping_temp"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter temp."
            value={FurnaceDetails.max_tapping_temp}
          onChange={handleChange}
          disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average tapping temperature - (⁰C)
          </label>
          <input
            type="text"
            id="avg_tapping_temp"
            name="avg_tapping_temp"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter temp."
            value={FurnaceDetails.avg_tapping_temp}
          onChange={handleChange}
          disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label
            htmlFor="furnace_voltage"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Furnace voltage - (V)
          </label>
          <input
            type="text"
            id="furnace_voltage"
            name="furnace_voltage"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Voltage"
            value={FurnaceDetails.furnace_voltage}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
      </div>


      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label
            htmlFor="furnace_oem"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Furnace OEM
          </label>
          <input
            type="text"
            id="furnace_oem"
            name="furnace_oem"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter OEM"
            value={FurnaceDetails.furnace_oem}
          onChange={handleChange}
          disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label
            htmlFor="date"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Furnace year of manufacture - (YYYY)
          </label>
          <input
            type="text"
            id="furnace_year"
            name="furnace_year"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Year"
            value={FurnaceDetails.furnace_year}
          onChange={handleChange}
          disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="coil_grout"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Coil grout / coat Supplier name
          </label>
          <input
            type="text"
            id="coil_grout"
            name="coil_grout"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Name"
            value={FurnaceDetails.coil_grout}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label
            htmlFor="sintering_time"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Sintering time - Hrs.
          </label>
          <input
            type="text"
            id="sintering_time"
            name="sintering_time"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Time"
            value={FurnaceDetails.sintering_time}
          onChange={handleChange}
          disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label
            htmlFor="operation"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Operation
          </label>
          <input
            type="text"
            id="operation"
            name="operation"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter operation"
            value={FurnaceDetails.operation}
          onChange={handleChange}
          disabled={isReadOnly}
          />
        </div>
        <div className="w-full mb-4">
          <label
            htmlFor="change_overtime"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Furnace Change over time - (minutes)
          </label>
          <input
            type="text"
            id="change_overtime"
            name="change_overtime"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter time"
            value={FurnaceDetails.change_overtime}
          onChange={handleChange}
          disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="checking_time"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Furnace Checking time - (minutes)
        </label>
        <input
          type="text"
          id="checking_time"
          name="checking_time"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Time"
          value={FurnaceDetails.checking_time}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
    </>
  );
};

export default FurnaceDetailsWeb;
