import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { furnace_details } from "../../recoil/atom";
import { carbon, general_details, carbon_type } from "../../recoil/atom";
import Helper from "../../service/Helper";

const CarbonWeb = () => {
  const [carbonObj, setCarbonObj] = useRecoilState(carbon);
  const [generalDetails] = useRecoilState(general_details);
  const [ddlabel, setLabel] = useState("");
  const [carbonType, setCarbonType] = useRecoilState(carbon_type);
  const [previousKey, setPreviousKey] = useState("");
  console.log(carbonObj, "carbonObj")

  useEffect(() => {
    console.log("generalDetails:", generalDetails);
  }, [generalDetails]);

  const uniqueId = generalDetails.id || "";

  useEffect(() => {
    const TrialName = localStorage.getItem("Trial");
    if (carbonType || TrialName === "edit") {
      setLabel(
        carbonType.charAt(0).toUpperCase() +
        carbonType.slice(1).replace(/_/g, " ")
      );
    }
  }, [carbonType]);

  const getKeyFromCarbonType = (type) => {
    if (!type) return ""; // Return an empty string if type is undefined or null
    switch (type) {
      case "Anthracite":
        return "anthracite";
      case "Carbon block":
        return "carbon_block";
      case "CPC":
        return "cpc";
      default:
        return type.toLowerCase().replace(/\s/g, "_");
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    const TrialName = localStorage.getItem("Trial");
    const regex = /^\d{0,5}(\.\d{0,2})?$/;

    if (regex.test(value)) {
      if (TrialName === "new") {
        setCarbonObj((prevState) => ({
          ...prevState,
          [name]: value,
          id: uniqueId,
        }));
      } else {
        setCarbonObj((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
  };

  const handleChangeCarbonType = (e) => {
    const { value } = e.target;
    const TrialName = localStorage.getItem("Trial");
    const regex = /^[0-9]*\.?[0-9]{0,2}$/;

    if (regex.test(value)) {
      const key = getKeyFromCarbonType(ddlabel || "CPC"); // Default to "CPC" if ddlabel is undefined
      setCarbonObj((prevState) => ({
        ...prevState,
        [key]: value,
        ...(TrialName === "new" ? { id: uniqueId } : {}),
      }));
    }
  };


  useEffect(() => {
    console.log("Current carbonObj state:", carbonObj);
  }, [carbonObj]);

  const handleAutoCompleteChange = (event) => {
    const value = event.target.value || ""; // Safeguard against undefined
    console.log("Changing carbon type to:", value);

    const newKey = getKeyFromCarbonType(value);

    setCarbonObj((prevState) => {
      console.log("Previous state before update:", prevState);
      const newState = { ...prevState };

      // Remove previous keys related to carbon types
      const keysToRemove = ["anthracite", "carbon_block", "cpc", "met_coke"];
      keysToRemove.forEach((key) => {
        if (newState.hasOwnProperty(key)) {
          delete newState[key];
        }
      });

      // Add the new carbon type key
      newState[newKey] = prevState[newKey] || "";

      console.log("New state after update:", newState);
      return newState;
    });

    setLabel(value);
    setCarbonType(value);
  };


  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Carbon
      </h2>
      <div className="flex space-x-4 mt-4">
        <div className="w-full">
          <label
            htmlFor="carbon_type"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Carbon Type
          </label>
          <select
            id="carbon_type"
            name="carbon_type"
            value={carbonType}
            onChange={handleAutoCompleteChange}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select Carbon type</option>
            {Helper.carbonType.map((carbon) => (
              <option key={carbon.id} value={carbon.name}>
                {carbon.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="anthracite"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {ddlabel ? `${ddlabel} (kg)` : 'CPC (kg)'}
          </label>
          <input
            type="text"
            id="anthracite"
            name="anthracite"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter carbon value"
            value={carbonObj[getKeyFromCarbonType(ddlabel)] || ""}
            onChange={handleChangeCarbonType}
          />
        </div>
        <div className="w-full mb-4">
          <label
            htmlFor="met_coke"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Met Coke (Kg)
          </label>
          <input
            type="text"
            id="met_coke"
            name="met_coke"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Met coke"
            value={carbonObj.met_coke}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="fifty_c_percentage"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            50 C% - (%)
          </label>
          <input
            type="text"
            id="fifty_c_percentage"
            name="fifty_c_percentage"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter C%"
            value={carbonObj.fifty_c_percentage}
            onChange={handleChange}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label
            htmlFor="eightyfive_c_percentage"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            85 C% - (%)
          </label>
          <input
            type="text"
            id="eightyfive_c_percentage"
            name="eightyfive_c_percentage"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter C %"
            value={carbonObj.eightyfive_c_percentage}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="final_c_percentage"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Final C% - (%)
          </label>
          <input
            type="text"
            id="final_c_percentage"
            name="final_c_percentage"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter percentage"
            value={carbonObj.final_c_percentage}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export default CarbonWeb;
