import React from "react";
import { useRecoilState } from "recoil";
import { Product_Details, trial_name } from '../../recoil/atom';

const ProductDetailsWeb = () => {
  const [productDetails, setProductDetails] = useRecoilState(Product_Details)
  const [trialName] = useRecoilState(trial_name)
  console.log(productDetails, 'productDetails')

  const handleChange = (e) => {
    const { name, value } = e.target
    setProductDetails((prevProductDetails) => ({
      ...prevProductDetails,
      [name]: value,
    }))
  }

  const isReadOnly = trialName === 'viewDataCollection'

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Product Details
      </h2>
      {/* Furnace No Dropdown */}
      <div className="flex space-x-4 mt-4">
        {/* Heat No */}
        <div  className="w-1/2">
          <label htmlFor="end_product" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          End Product of the Plant 
          </label>
          <input
            type="text"
            id="end_product"
            name="end_product"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Product"
            value={productDetails.end_product}
            onChange={handleChange}
          />
        </div>

        {/* Date */}
        <div  className="w-1/2">
          <label htmlFor="steel_produce" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Grades of Steel Produce
          </label>
          <input
            type="text"
            id="steel_produce"
            name="steel_produce"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Grade"
            value={productDetails.steel_produce}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4 mt-4">
        {/* Customer Ref No. */}
        <div  className="w-1/2">
          <label htmlFor="customer_ref_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
       Ratio of MS Grade
          </label>
          <input
            type="text"
            id="ratio"
            name="ratio"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter ratio"
            value={productDetails.ratio}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export default ProductDetailsWeb;
