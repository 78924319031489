import React from "react";



import { Link as RegLink } from "react-router-dom";



const RouteMissing = () => {

    return (

        <React.Fragment>

        <div>tetetete</div>

        </React.Fragment>

    );
};

export default RouteMissing;