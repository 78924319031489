import React from "react";
import { useRecoilState } from "recoil";
import {
  lining_material_consumption,
  lining_reference,
  production_summary,
} from "../../recoil/atom";

const LiningMaterialConsumptionWeb = () => {
  const [liningMaterialConsumption, setLiningMaterialConsumption] =
    useRecoilState(lining_material_consumption);
  const [liningReference] = useRecoilState(lining_reference);
  const [productionSummary] = useRecoilState(production_summary);

  const uniqueId = liningReference.id ? liningReference.id : "";

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericValue = parseFloat(value) || 0; // Convert value to number or default to 0
    const TrialName = localStorage.getItem('Trial');

    // Prepare updated state with current changes
    const updatedState = { ...liningMaterialConsumption, [name]: numericValue };

    // Calculate total_nrm_consumption_sidewall_one if any of the relevant fields change
    if (
      name === 'nrm_consumption_bottom' ||
      name === 'nrm_consumption_sidewall_one' ||
      name === 'nrm_consumption_sidewall_two' ||
      name === 'nrm_consumption_top_locking' ||
      name === 'topping_mass_consumption'
    ) {
      // Sum all relevant fields
      const totalNRMConsumptionSidewallOne =
        (parseFloat(updatedState.nrm_consumption_bottom) || 0) +
        (parseFloat(updatedState.nrm_consumption_sidewall_one) || 0) +
        (parseFloat(updatedState.nrm_consumption_sidewall_two) || 0) +
        (parseFloat(updatedState.nrm_consumption_top_locking) || 0) +
        (parseFloat(updatedState.topping_mass_consumption) || 0);

      updatedState.total_nrm_consumption_sidewall_one =
        totalNRMConsumptionSidewallOne;
    }

    // Safely calculate specific consumption
    const totalProduction = parseFloat(productionSummary?.total_production) || 0;
    const totalNRMConsumptionSidewallOne =
      parseFloat(updatedState.total_nrm_consumption_sidewall_one) || 0;

    // Check for zero or missing total production to prevent Infinity
    if (totalProduction > 0) {
      updatedState.nrm_specific_consumption = (
        totalNRMConsumptionSidewallOne / totalProduction
      ).toFixed(2);
    } else {
      updatedState.nrm_specific_consumption = ""; // Set to blank or a fallback value
    }

    console.log('updatedState', updatedState);

    // Check if TrialName is "new"
    if (TrialName === 'new') {
      updatedState.id = uniqueId; // Set the unique ID
    }

    setLiningMaterialConsumption(updatedState);
  };


  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Lining material consumption
      </h2>

      <div className="flex space-x-4 mt-4">
        {/* NRM consumption bottom */}
        <div className="w-full mb-4">
          <label
            htmlFor="nrm_consumption_bottom"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            NRM consumption bottom - (Kg)
          </label>
          <input
            type="text"
            id="nrm_consumption_bottom"
            name="nrm_consumption_bottom"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter NRM consumption bottom"
            value={liningMaterialConsumption.nrm_consumption_bottom}
            onChange={handleChange}
          />
        </div>

        {/* NRM consumption Sidewall 1 */}
        <div className="w-full mb-4">
          <label
            htmlFor="nrm_consumption_sidewall_one"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            NRM consumption Sidewall 1 - (Kg)
          </label>
          <input
            type="text"
            id="nrm_consumption_sidewall_one"
            name="nrm_consumption_sidewall_one"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter NRM consumption Sidewall 1"
            value={liningMaterialConsumption.nrm_consumption_sidewall_one}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* NRM consumption Sidewall 2 */}
        <div className="w-full mb-4">
          <label
            htmlFor="nrm_consumption_sidewall_two"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            NRM consumption Sidewall 2 - (Kg)
          </label>
          <input
            type="text"
            id="nrm_consumption_sidewall_two"
            name="nrm_consumption_sidewall_two"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter NRM consumption Sidewall 2"
            value={liningMaterialConsumption.nrm_consumption_sidewall_two}
            onChange={handleChange}
          />
        </div>

        {/* NRM consumption at Top Locking */}
        <div className="w-full mb-4">
          <label
            htmlFor="nrm_consumption_top_locking"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            NRM consumption at Top Locking - (Kg)
          </label>
          <input
            type="text"
            id="nrm_consumption_top_locking"
            name="nrm_consumption_top_locking"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter NRM consumption at Top Locking"
            value={liningMaterialConsumption.nrm_consumption_top_locking}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Topping mass consumption */}
        <div className="w-full mb-4">
          <label
            htmlFor="topping_mass_consumption"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Topping mass consumption - (kg)
          </label>
          <input
            type="text"
            id="topping_mass_consumption"
            name="topping_mass_consumption"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Topping mass consumption"
            value={liningMaterialConsumption.topping_mass_consumption}
            onChange={handleChange}
          />
        </div>

        {/* Total NRM consumption sidewall */}
        <div className="w-full mb-4">
          <label
            htmlFor="total_nrm_consumption_sidewall_one"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total NRM consumption sidewall - (Kg)
          </label>
          <input
            type="text"
            id="total_nrm_consumption_sidewall_one"
            name="total_nrm_consumption_sidewall_one"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Total NRM consumption sidewall"
            value={liningMaterialConsumption.total_nrm_consumption_sidewall_one}
            onChange={handleChange}
            disabled
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* NRM Specific consumption */}
        <div className="w-full mb-4">
          <label
            htmlFor="nrm_specific_consumption"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            NRM Specific consumption - (kg/MT of steel)
          </label>
          <input
            type="text"
            id="nrm_specific_consumption"
            name="nrm_specific_consumption"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter NRM Specific consumption"
            value={liningMaterialConsumption.nrm_specific_consumption}
            onChange={handleChange}
            disabled
          />
        </div>
      </div>
    </>
  );
};

export default LiningMaterialConsumptionWeb;
