import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { savings_energy, savings_energy_nrm, trial_name } from "../../../recoil/atom";
import { costSavingsDueToPowerSelector } from '../../../recoil/selector'

const Energy = () => {
  const [energy, setEnergy] = useRecoilState(savings_energy)
  const [energyNRM, setEnergyNRM] = useRecoilState(savings_energy_nrm)
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'

  const costSavingsDutToPower = useRecoilValue(costSavingsDueToPowerSelector)
  console.log('Cost Savings Due to Power:', costSavingsDutToPower)

  const handleEnergy = (name, value) => {
    setEnergy((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleEnergyNRM = (name, value) => {
    setEnergyNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  // useEffect(() => {
  //   if (
  //     props.trialName === 'costSheetEdit' &&
  //     props.editSavingsDetails.length > 0
  //   ) {
  //     console.log(props.editSavingsDetails, 'props.editSavingsDetails')
  //     let editObj = doesKeyExist(props.editSavingsDetails, 'energyDataObject')
  //     console.log(editObj, 'editObj')
  //     if (editObj) {
  //       setEnergy(editObj.energyDataObject.energy.basic)
  //       setEnergyNRM(editObj.energyDataObject.energy.basic)
  //     }
  //   }
  // }, [props.editSavingsDetails])

  function doesKeyExist(array, key) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].hasOwnProperty(key)) {
        return array[i]
      }
    }
    return false
  }

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        S4 : Savings due to energy
      </h2>

      <div className="flex space-x-4 mt-4 mb-4">
        <div className="w-1/2">
          <label
            htmlFor="cost_per_electricity_unit"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Cost per electricity unit (dia /KWh)
          </label>
          <input
            type="text"
            id="cost_per_electricity_unit"
            name="cost_per_electricity_unit"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Cost"
            value="NA"
            onChange={(event) =>
              handleEnergy('cost_per_electricity_unit', event.target.value)
            }
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="power_savings_per_mt"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Power savings per MT (KWh / MT)
          </label>
          <input
            type="text"
            id="power_savings_per_mt"
            name="power_savings_per_mt"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter savings"
            value="NA"
            onChange={(event) =>
              handleEnergy('power_savings_per_mt', event.target.value)
            }
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="savings_power_consumption"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Cost Savings due to power consumption in KWh / MT of steel (dia /KWh)
        </label>
        <input
          type="text"
          id="savings_power_consumption"
          name="savings_power_consumption"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter savings"
          value="NA"
          onChange={(event) =>
            handleEnergy('savings_power_consumption', event.target.value)
          }
        />
      </div>

      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        NRM
      </h2>

      <div className="flex space-x-4 mt-4 mb-4">
        <div className="w-1/2">
          <label
            htmlFor="cost_per_electricity_unit"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Cost per electricity unit (dia /KWh)
          </label>
          <input
            type="text"
            id="cost_per_electricity_unit"
            name="cost_per_electricity_unit"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Cost"
            value={energyNRM.cost_per_electricity_unit}
            onChange={(event) =>
              handleEnergyNRM('cost_per_electricity_unit', event.target.value)
            }
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="power_savings_per_mt"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Power savings per MT (KWh / MT)
          </label>
          <input
            type="text"
            id="power_savings_per_mt"
            name="power_savings_per_mt"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter savings"
            value={energyNRM.power_savings_per_mt}
            onChange={(event) =>
              handleEnergyNRM('power_savings_per_mt', event.target.value)
            }
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="costSavingsDutToPower"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Cost Savings due to power consumption in KWh / MT of steel (dia /KWh)
        </label>
        <input
          type="text"
          id="costSavingsDutToPower"
          name="costSavingsDutToPower"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          value={costSavingsDutToPower}
          onChange={() => { }}
        />
      </div>
    </>
  );
};

export default Energy;
