import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { furnace_details, general_details } from "../../recoil/atom";
import { energy_conspt } from "../../recoil/selector";
import TimeSelector from "./../Common/TimePicker";

const FurnaceDetailsWeb = () => {
  const [furnaceDetails, setFurnaceDetails] = useRecoilState(furnace_details);
  console.log(furnaceDetails, "furnaceDetails");
  const [generalDetails] = useRecoilState(general_details);
  const uniqueId = generalDetails.id ? generalDetails.id : "";
  const energyConsumption = useRecoilValue(energy_conspt);
  console.log(energyConsumption, "energyConsumption");
  const [time, setTime] = useState("0:0");

  const handleTimeChange = (newTime) => {
    setTime(newTime);
    console.log("Updated Time:", newTime);
  };

  function convertToMinutes(timeStr) {
    if (typeof timeStr === "string") {
      const [hours, minutes] = timeStr.split(":").map(Number);
      return hours * 60 + minutes;
    } else {
      console.error("Invalid time string:", timeStr);
      return NaN;
    }
  }

  const calculateDuration = (startTime, endTime) => {
    console.log(startTime, endTime, "endTime", "endTime")
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const [endHours, endMinutes] = endTime.split(":").map(Number);

    let durationHours = endHours - startHours;
    let durationMinutes = endMinutes - startMinutes;

    if (durationMinutes < 0) {
      durationMinutes += 60;
      durationHours -= 1;
    }

    if (durationHours < 0) {
      durationHours += 24;
    }

    const formattedHours = String(durationHours).padStart(2, "0");
    const formattedMinutes = String(durationMinutes).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const TrialName = localStorage.getItem("Trial");
    const regex = /^\d{0,5}(\.\d{0,2})?$/;
    if (!regex.test(value)) {
      console.warn("Invalid input: ", value);
      return;
    }

    setFurnaceDetails((prevState) => {
      const updatedDetails = {
        ...prevState,
        [name]: value,
        id: TrialName === "new" ? uniqueId : prevState.id,
        energy_consumption_per_unit_ton_calculated: energyConsumption,
      };

      console.log("updatedDetails", updatedDetails);

      if (
        updatedDetails.heat_duration_calculated &&
        updatedDetails.heat_duration_calculated !== ""
      ) {
        const [hours, minutes] = updatedDetails.heat_duration_calculated
          .split(":")
          .map(Number);
        const heatDurationMinutesCalculated = hours * 60 + minutes;
        if (!isNaN(hours) && !isNaN(minutes)) {
          const meltRateCalculated = (
            (((parseFloat(updatedDetails.tapped_tonnage) * 60) /
              heatDurationMinutesCalculated) *
              1000) /
            60
          ).toFixed(2);

          updatedDetails.melt_rate_based_on_calculated_duration =
            meltRateCalculated;
        } else {
          updatedDetails.melt_rate_based_on_calculated_duration = 0; // or any default value
        }
      } else {
        updatedDetails.melt_rate_based_on_calculated_duration = 0; // or any default value
      }

      if (
        updatedDetails.tapped_tonnage &&
        updatedDetails.heat_duration_actual
      ) {
        const tappedTonnage = parseFloat(updatedDetails.tapped_tonnage);
        const [hours, minutes] = updatedDetails.heat_duration_actual
          .split(":")
          .map(Number);
        const heatDurationMinutesActual = hours * 60 + minutes;
        const meltRate = (
          (((tappedTonnage * 60) / heatDurationMinutesActual) * 1000) /
          60
        ).toFixed(2);

        updatedDetails.melt_rate_based_on_actual_duration = meltRate;
      }

      return updatedDetails;
    });
  };

  const changeHeatTimes = (name, newValue) => {
    console.log(newValue, "newValue")
    if (newValue) {
      const formattedTime = newValue;

      setFurnaceDetails((prevDetails) => {
        const updatedDetails = {
          ...prevDetails,
          [name]: formattedTime,
        };

        if (updatedDetails.heat_on_time && updatedDetails.heat_tap_time) {
          const duration = calculateDuration(
            updatedDetails.heat_on_time,
            updatedDetails.heat_tap_time
          );
          updatedDetails.heat_duration_calculated = duration;

          console.log("Calculated Duration:", duration);
        }

        return updatedDetails;
      });
    } else {
      console.error("Invalid time value:", newValue);
    }
  };


  // useEffect(() => {
  //   console.log("heat_on_time1232", furnaceDetails.heat_on_time);
  //   console.log("heat_tap_time1232", furnaceDetails.heat_tap_time);
  // }, [furnaceDetails]);

  // // Recoil state to manage form data
  // const [formData, setFormData] = useRecoilState(furnace_details);

  // // Common handle input change function for all fields
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value, // Update the state dynamically based on the input name
  //   });
  // };

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Furnace Details
      </h2>

      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="heat_on_time"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Heat ON Time (HH:mm)
          </label>
          <TimeSelector time={furnaceDetails.heat_on_time || '0:0'} onTimeChange={(newValue) => changeHeatTimes('heat_on_time', newValue)} />
        </div>
        <div className="w-full mb-4">
          <label
            htmlFor="date"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Heat Tap Time (HH:mm)
          </label>
          <TimeSelector time={furnaceDetails.heat_tap_time || '0:0'} onTimeChange={(newValue) => changeHeatTimes('heat_tap_time', newValue)} />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="break_down_duration"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Breakdown duration (HH:mm)
          </label>
          <TimeSelector time={furnaceDetails.break_down_duration || '0:0'} onTimeChange={(newValue) => changeHeatTimes('break_down_duration', newValue)} />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="heat_duration_actual"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Heat Duration Actual (HH:mm)
          </label>
          <TimeSelector time={furnaceDetails.heat_duration_actual || '0:0'} onTimeChange={(newValue) => changeHeatTimes('heat_duration_actual', newValue)} />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="heat_duration_calculated"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Heat Duration Calculated (HH:mm)
          </label>
          <input
            type="text"
            id="tapping_temperature"
            name="tapping_temperature"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Calculated Heat Duration"
            value={furnaceDetails.heat_duration_calculated || '00:00'}
            onChange={handleChange}
            disabled
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label
            htmlFor="tapping_temperature"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Tapping temperature (°C)
          </label>
          <input
            type="text"
            id="tapping_temperature"
            name="tapping_temperature"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Tapping temperature"
            value={furnaceDetails.tapping_temperature}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label
            htmlFor="tapped_tonnage"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Tapped tonnage (MT)
          </label>
          <input
            type="text"
            id="tapped_tonnage"
            name="tapped_tonnage"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Tapped tonnage"
            value={furnaceDetails.tapped_tonnage}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="melt_rate_based_on_actual_duration"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Melt Rate based on actual duration (kg/min)
        </label>
        <input
          type="text"
          id="melt_rate_based_on_actual_duration"
          name="melt_rate_based_on_actual_duration"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Calculated Melt Rate"
          value={furnaceDetails.melt_rate_based_on_actual_duration}
          onChange={handleChange}
          disabled
        />
      </div>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="melt_rate_based_on_calculated_duration"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Melt Rate based on calculated duration(kg/min)
          </label>
          <input
            type="text"
            id="melt_rate_based_on_calculated_duration"
            name="melt_rate_based_on_calculated_duration"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Calculated Melt Rate"
            value={furnaceDetails.melt_rate_based_on_calculated_duration}
            onChange={handleChange}
            disabled
          />
        </div>

        {/* Grade */}

      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="frequency"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Frequency (Hz)
        </label>
        <input
          type="text"
          id="frequency"
          name="frequency"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Voltage"
          value={furnaceDetails.frequency}
          onChange={handleChange}
        />
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label
            htmlFor="voltage"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Voltage (V)
          </label>
          <input
            type="text"
            id="voltage"
            name="voltage"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Voltage"
            value={furnaceDetails.voltage}
            onChange={handleChange}
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="power_in_the_panel"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Power in the panel (KW)
          </label>
          <input
            type="text"
            id="power_in_the_panel"
            name="power_in_the_panel"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Power"
            value={furnaceDetails.power_in_the_panel}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="capacitors_removed"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Capacitors Removed (No.)
          </label>
          <input
            type="text"
            id="capacitors_removed"
            name="capacitors_removed"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Capacitors Removed"
            value={furnaceDetails.capacitors_removed}
            onChange={handleChange}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label
            htmlFor="total_power_consumed"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total Power Consumed (KWH)
          </label>
          <input
            type="text"
            id="total_power_consumed"
            name="total_power_consumed"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Total Power Consumed"
            value={furnaceDetails.total_power_consumed}
            onChange={handleChange}
          />
        </div>
      </div>

      {/* Customer Ref No. */}
      <div className="w-full mb-4">
        <label
          htmlFor="energy_consumption_per_unit_ton_actual"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Energy consumption per unit Ton actual (KWH/Ton)
        </label>
        <input
          type="text"
          id="energy_consumption_per_unit_ton_actual"
          name="energy_consumption_per_unit_ton_actual"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Unit"
          value={furnaceDetails.energy_consumption_per_unit_ton_actual}
          onChange={handleChange}
          
        />
      </div>

      {/* Grade */}
      <div className="w-full">
        <label
          htmlFor="grade"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Energy consumption per unit Ton calculated KWH/Ton (KWH/Ton)
        </label>
        <input
          type="text"
          id="grade"
          name="grade"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Unit"
          value={energyConsumption}
          onChange={handleChange}
          disabled
        />
      </div>
    </>
  );
};

export default FurnaceDetailsWeb;
