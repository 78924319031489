import React , { useEffect } from "react";
import { useRecoilState } from "recoil";
import { patching_details, patching_details_nrm, trial_name } from "../../../recoil/atom";
import Helper from "../../../service/Helper";

const PatchingDetails = () => {
  const [patchingDetails, setPatchingDetails] = useRecoilState(patching_details)
  const [patchingDetailsNRM, setPatchingDetailsNRM] =
    useRecoilState(patching_details_nrm)
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'
  const handlePatchingDetailsChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setPatchingDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handlePatchingDetailsNRMChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setPatchingDetailsNRM((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  useEffect(() => {
    let patchingDetailsObj = {
      patchingDetails: {
        basic: patchingDetails,
        nrm: patchingDetailsNRM,
      },
    }
    console.log(patchingDetailsObj, '35656123patching')
  }, [patchingDetails, patchingDetailsNRM])

  useEffect(() => {
    localStorage.setItem('patchingDetails', JSON.stringify(patchingDetails))
    localStorage.setItem(
      'patchingDetailsNRM',
      JSON.stringify(patchingDetailsNRM)
    )
  }, [patchingDetails, patchingDetailsNRM])

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Patching Details(D)
      </h2>
      <div className="w-full mb-4">
        <label
          htmlFor="ramming_material_patching_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ramming material used in patching - 1
        </label>
        <select
          id="ramming_material_patching_1"
          name="ramming_material_patching_1"
          value={patchingDetails.ramming_material_patching_1}
          onChange={(event) =>
            handlePatchingDetailsChange(
              'ramming_material_patching_1',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_patching_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Quantity of material required for patching - 1 (MT)
        </label>
        <input
          type="text"
          id="qty_patching_1"
          name="qty_patching_1"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Quantity"
          value={patchingDetails.qty_patching_1}
          onChange={(event) =>
            handlePatchingDetailsChange('qty_patching_1', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_2"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material used in spout and top - 2
        </label>
        <select
          id="material_used_spout_and_top_2"
          name="material_used_spout_and_top_2"
          value={patchingDetails.material_used_spout_and_top_2}
          onChange={(event) =>
            handlePatchingDetailsChange(
              'material_used_spout_and_top_2',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_spout_and_top_2"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Qty. of Material required for Spout & Top - 2 (MT)
        </label>
        <input
          type="text"
          id="qty_spout_and_top_2"
          name="qty_spout_and_top_2"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Quantity"
          value={patchingDetails.qty_spout_and_top_2}
          onChange={(event) =>
            handlePatchingDetailsChange(
              'qty_spout_and_top_2',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_top_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material used in spout and top - 3
        </label>
        <select
          id="material_used_top_spout_and_top_3"
          name="material_used_top_spout_and_top_3"
          value={patchingDetails.material_used_top_spout_and_top_3}
          onChange={(event) =>
            handlePatchingDetailsChange(
              'material_used_top_spout_and_top_3',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Qty. of Material required for Spout & Top - 3 (MT)
        </label>
        <input
          type="text"
          id="qty_spout_and_top_3"
          name="qty_spout_and_top_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Quantity"
          value={patchingDetails.qty_spout_and_top_3}
          onChange={(event) =>
            handlePatchingDetailsChange(
              'qty_spout_and_top_3',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Average Patching lining life (Heat / Lining)
        </label>
        <input
          type="text"
          id="qty_spout_and_top_3"
          name="qty_spout_and_top_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={patchingDetails.average_patching_lining_life}
          onChange={(event) =>
            handlePatchingDetailsChange(
              'average_patching_lining_life',
              event.target.value
            )
          }
        />
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="no_of_patching"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            No of patching (No)
          </label>
          <input
            type="text"
            id="no_of_patching"
            name="no_of_patching"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={patchingDetails.no_of_patching}
            onChange={(event) =>
              handlePatchingDetailsChange('no_of_patching', event.target.value)
            }
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="avg_heat_size_per_patching"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average heat size per Patching (MT)
          </label>
          <input
            type="text"
            id="avg_heat_size_per_patching"
            name="avg_heat_size_per_patching"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={patchingDetails.avg_heat_size_per_patching}
            onChange={(event) =>
              handlePatchingDetailsChange(
                'avg_heat_size_per_patching',
                event.target.value
              )
            }
          />
        </div>
      </div>

      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
       NRM
      </h2>

      <div className="w-full mb-4">
        <label
          htmlFor="ramming_material_patching_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ramming material used in patching - 1
        </label>
        <select
          id="ramming_material_patching_1"
          name="ramming_material_patching_1"
          value={patchingDetailsNRM.ramming_material_patching_1}
          onChange={(event) =>
            handlePatchingDetailsNRMChange(
              'ramming_material_patching_1',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_patching_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Quantity of material required for patching - 1 (MT)
        </label>
        <input
          type="text"
          id="qty_patching_1"
          name="qty_patching_1"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Quantity"
          value={patchingDetailsNRM.qty_patching_1}
          onChange={(event) =>
            handlePatchingDetailsNRMChange('qty_patching_1', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_2"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material used in spout and top - 2
        </label>
        <select
          id="material_used_spout_and_top_2"
          name="material_used_spout_and_top_2"
          value={patchingDetailsNRM.material_used_spout_and_top_2}
          onChange={(event) =>
            handlePatchingDetailsNRMChange(
              'material_used_spout_and_top_2',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_spout_and_top_2"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Qty. of Material required for Spout & Top - 2 (MT)
        </label>
        <input
          type="text"
          id="qty_spout_and_top_2"
          name="qty_spout_and_top_2"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Quantity"
          value={patchingDetailsNRM.qty_spout_and_top_2}
          onChange={(event) =>
            handlePatchingDetailsNRMChange(
              'qty_spout_and_top_2',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_top_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material used in spout and top - 3
        </label>
        <select
          id="material_used_top_spout_and_top_3"
          name="material_used_top_spout_and_top_3"
          value={patchingDetailsNRM.material_used_top_spout_and_top_3}
          onChange={(event) =>
            handlePatchingDetailsNRMChange(
              'material_used_top_spout_and_top_3',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a campaign</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Qty. of Material required for Spout & Top - 3
        </label>
        <input
          type="text"
          id="qty_spout_and_top_3"
          name="qty_spout_and_top_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={patchingDetailsNRM.qty_spout_and_top_3}
          onChange={(event) =>
            handlePatchingDetailsNRMChange(
              'qty_spout_and_top_3',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Average Patching lining life
        </label>
        <input
          type="text"
          id="qty_spout_and_top_3"
          name="qty_spout_and_top_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter "
          value={patchingDetailsNRM.average_patching_lining_life}
          onChange={(event) =>
            handlePatchingDetailsNRMChange(
              'average_patching_lining_life',
              event.target.value
            )
          }
        />
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="no_of_patching"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            No of patching
          </label>
          <input
            type="text"
            id="no_of_patching"
            name="no_of_patching"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={patchingDetailsNRM.no_of_patching}
            onChange={(event) =>
              handlePatchingDetailsNRMChange('no_of_patching', event.target.value)
            }
          />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="avg_heat_size_per_patching"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average heat size per Patching
          </label>
          <input
            type="text"
            id="avg_heat_size_per_patching"
            name="avg_heat_size_per_patching"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value={patchingDetailsNRM.avg_heat_size_per_patching}
            onChange={(event) =>
              handlePatchingDetailsNRMChange(
                'avg_heat_size_per_patching',
                event.target.value
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default PatchingDetails;
