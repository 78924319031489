import React from "react";

const TimeSelector = ({ time, onTimeChange }) => {
  const [hour, minute] = time.split(":").map(Number);

  const handleHourChange = (newHour) => {
    onTimeChange(`${newHour}:${minute}`);
  };

  const handleMinuteChange = (newMinute) => {
    onTimeChange(`${hour}:${newMinute}`);
  };

  return (
    <div className="flex space-x-2">
      {/* Hour Selector */}
      <div className="w-1/2">
        <label className="block text-gray-700 text-xs mb-1 mt-2">Hour</label>
        <select
          value={hour}
          onChange={(e) => handleHourChange(e.target.value)}
          className="block w-full px-2 h-10 py-1 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500 focus:border-blue-500"
        >
          {[...Array(24).keys()].map((h) => (
            <option key={h} value={h}>
              {h}
            </option>
          ))}
        </select>
      </div>

      {/* Minute Selector */}
      <div className="w-1/2">
        <label className="block text-gray-700 text-xs mb-1 mt-2">Minute</label>
        <select
          value={minute}
          onChange={(e) => handleMinuteChange(e.target.value)}
          className="block w-full px-2 h-10 py-1 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500 focus:border-blue-500"
        >
          {[...Array(60).keys()].map((m) => (
            <option key={m} value={m}>
              {m}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TimeSelector;
