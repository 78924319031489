import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { general_details } from "../../recoil/atom";
import axios from "axios";
import API from "../../service/API";
import Helper from "../../service/Helper";
import { v4 as uuidv4 } from "uuid";
import DatePicker from "react-datepicker";

const GeneralDetailsWeb = ({ validationErrors }) => {
  const [compaignNo, setCompaignNo] = useState([]);
  const [liningPatchingNo, setLiningPatchingNo] = useState([]);
  const [generalDetails, setGeneralDetails] = useRecoilState(general_details);
  console.log(generalDetails, "heheh");
  const [furnaces, setFurnaces] = useState([]);
  console.log(furnaces, "furnacesfurnaces");
  const dataFile = localStorage.getItem("dataFileResponse");
  const dataFileResponse = JSON.parse(dataFile);
  const access_token = localStorage.getItem("accesstoken");
  const [isEdit, setIsEdit] = useState(
    localStorage.getItem("Trial") === "Edit"
  );
  /////////////offline//////////
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [db, setDb] = useState(null);

  function getAbbreviatedLiningType(liningType) {
    if (!liningType) return ""; // Check if liningType is undefined or empty
    let abbreviated = "";
    liningType.split(" ").forEach((word) => {
      abbreviated += word.charAt(0).toUpperCase();
    });
    return abbreviated;
  }

  const lorp_id =
    (generalDetails.furnace_no ? generalDetails.furnace_no : "") +
    "-" +
    (generalDetails.crucible_no ? generalDetails.crucible_no : "") +
    "-" +
    (generalDetails.campaign_no ? generalDetails.campaign_no : "") +
    "-" +
    getAbbreviatedLiningType(generalDetails.lining_patch_no);


    useEffect(() => {
      const TrialName = localStorage.getItem("Trial");
      if (TrialName === "new") {
        const dataFile = localStorage.getItem("dataFileResponse");
        const dataFileResponse = JSON.parse(dataFile);
    
        if (dataFileResponse?.furnace?.furnace_number) {
          // Ensure generalDetails is defined before accessing its properties
          if (!generalDetails || generalDetails.furnace_no !== dataFileResponse.furnace.furnace_number) {
            setGeneralDetails((prevState) => ({
              ...prevState,
              furnace_no: dataFileResponse.furnace.furnace_number,
            }));
          }
        }
      } else {
        const selectedItem = localStorage.getItem("SelectedItem");
        const selectedItemData = JSON.parse(selectedItem);
        if (selectedItemData?.general_details?.length) {
          const furnaceNumber = selectedItemData.general_details[0]?.furnace_no || "";
          if (!generalDetails || generalDetails.furnace_no !== furnaceNumber) {
            setGeneralDetails((prevState) => ({
              ...prevState,
              furnace_no: furnaceNumber,
            }));
          }
        }
      }
    }, [generalDetails]);
    

  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true);
        localStorage.setItem("onlinestate", 1);
        window.location.reload();
      }
    };

    const handleOffline = () => {
      localStorage.setItem("onlinestate", 0);
      // setIsOnlines(false)
      setIsOnline(false);
      // navigate('/reports')
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [isOnline]);

  const dbName = "pcr";
  const customerName = "customers";
  const furnacesName = "furnaces";
  const templateName = "template";
  const segmentName = "segment";
  const datafileName = "datafile";
  const dailytrialName = "daily_trial";
  const nrmName = "nrm_performance";
  const erosionName = "erosion_profile";
  const dataCollectionName = "data_collection";
  const costSheetName = "cost_sheet";
  const LiningName = "lining_patching_no";

  useEffect(() => {
    if (!isOnline) {
      const request = indexedDB.open(dbName, 1);

      request.onupgradeneeded = (event) => {
        const database = event.target.result;
        database.createObjectStore(customerName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(furnacesName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(templateName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(segmentName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(datafileName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(dailytrialName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(nrmName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(erosionName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(dataCollectionName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(costSheetName, {
          keyPath: "id",
          autoIncrement: true,
        });
        database.createObjectStore(LiningName, {
          keyPath: "id",
          autoIncrement: true,
        });
      };

      request.onsuccess = (event) => {
        const database = event.target.result;
        const transaction = database.transaction(
          [
            "customers",
            "furnaces",
            "template",
            "segment",
            "datafile",
            "daily_trial",
            "nrm_performance",
            "erosion_profile",
            "data_collection",
            "cost_sheet",
            "lining_patching_no",
          ],
          "readwrite"
        );

        setDb(database);
      };

      request.onerror = (event) => {
        console.error("Error opening IndexedDB", event);
      };
    }
  }, [isOnline]);

  useEffect(() => {
    if (isOnline) {
      fetchFurnaceData();
      fetchData();
      fetchLiningData();
    }
  }, [isOnline]);

  const fetchFurnaceData = async () => {
    try {
      const dataFile = localStorage.getItem("dataFileResponse");
      const dataFileResponse = JSON.parse(dataFile);
      if (
        dataFileResponse &&
        dataFileResponse.furnace
      ) {
        const customer_id = dataFileResponse.customer_id
        const matchingFurnace = [dataFileResponse.furnace]
        setFurnaces(matchingFurnace)
      } else {
        console.error('Error: No furnace data found in dataFileResponse')
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const handleChange = (name, value) => {
    console.log("nammeee", name);
    console.log("valuee", value);
    const TrialName = localStorage.getItem("Trial");
    if (TrialName === "new" || TrialName === "add") {
      const uniqueId = uuidv4();
      setGeneralDetails((prevState) => ({
        ...prevState,
        [name]: value,
        id: uniqueId,
        isdelete: false,
        lorp_id: lorp_id,
      }));
    } else {
      setGeneralDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const fetchData = async () => {
    try {
      const campaignNumbers = await Helper.getCompaignNumbers();
      console.log("campaignNumbers", campaignNumbers);
      setCompaignNo(campaignNumbers);
    } catch (error) {
      console.error("Error fetching campaign numbers:", error);
    }
  };

  const fetchLiningData = async () => {
    if (isOnline) {
      try {
        const response = await axios.get(API.LINING_PATCHING_NO, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        console.log("RESSSS", response);
        setLiningPatchingNo(response.data);
      } catch (error) {
        console.error("Error fetching lining:", error);
      }
    } else {
      const transaction = db.transaction(LiningName, "readonly");
      const objectStore = transaction.objectStore(LiningName);
      const getAllRequest = objectStore.getAll();
      getAllRequest.onsuccess = () => {
        const allData = getAllRequest.result;
        console.log("allDataLiningPatchingNo", allData);
        setLiningPatchingNo(allData);
      };

      getAllRequest.onerror = (event) => {
        setLiningPatchingNo([]);
        console.error(
          "Error getting data from the area table in IndexedDB",
          event
        );
      };
    }
  };

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        General Details
      </h2>
      <div className="flex space-x-4 mt-4">
      <div className="w-1/2">
            <label
              htmlFor="furnace_no"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Furnace No *
            </label>
            <input
              type="text"
              id="furnace_no"
              name="furnace_no"
              value={generalDetails.furnace_no || ""}
              readOnly
              className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
            />
          </div>

        {/* Crucible No Dropdown */}
        <div className="w-1/2">
          <label
            htmlFor="crucible_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Crucible No *
          </label>
          <select
            id="crucible_no"
            name="crucible_no"
            value={generalDetails.crucible_no || ""}
            onChange={(event) =>
              handleChange("crucible_no", event.target.value)
            }
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a crucible</option>
            {Helper.CrucibleNo.map((crucible) => (
              <option key={crucible.id} value={crucible.name}>
                {crucible.name}
              </option>
            ))}
          </select>
          {validationErrors.crucible_no && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors.crucible_no}
            </p>
          )}
        </div>
      </div>

      <div className="flex space-x-4 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="campaign_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Campaign No *
          </label>
          <select
            id="campaign_no"
            name="campaign_no"
            value={generalDetails.campaign_no}
            onChange={(event) => handleChange("campaign_no", event.target.value)}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a campaign</option>
            {compaignNo.map((compaignNo) => (
              <option key={compaignNo.id} value={compaignNo.name}>
                {compaignNo.name}
              </option>
            ))}
          </select>
          {validationErrors.campaign_no && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors.campaign_no}
            </p>
          )}
        </div>

        {/* Lining/Patching No Dropdown */}
        <div className="w-1/2">
          <label
            htmlFor="lining_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Lining/Patching No *
          </label>
          <select
            id="lining_no"
            name="lining_no"
            value={generalDetails.lining_patch_no}
            onChange={(event, value) => handleChange("lining_patch_no", event.target.value)}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a lining/patching</option>
            {liningPatchingNo.map((liningPatchingNo) => (
              <option key={liningPatchingNo.id} value={liningPatchingNo.name}>
                {liningPatchingNo.name}
              </option>
            ))}
          </select>
          {validationErrors.lining_patch_no && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors.lining_patch_no}
            </p>
          )}
        </div>
      </div>

      <div className="flex space-x-4 mt-4">
        {/* Heat No */}
        <div className="w-1/2">
          <label
            htmlFor="heat_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Heat No (Nos)
          </label>
          <input
            type="text"
            id="heat_no"
            name="heat_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Heat No"
            value={generalDetails.heat_no || ""}
            onChange={(event) => handleChange("heat_no", event.target.value)}
          />
        </div>

        <div className="w-1/2">
          <label
            htmlFor="date"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Date
          </label>
          <DatePicker
            id="date"
            selected={generalDetails.date}
            onChange={(date) => handleChange("date", date)}
            dateFormat="dd-MM-yyyy"
            placeholderText="Select Date"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </div>

      <div className="flex space-x-4 mt-4">
        {/* Customer Ref No. */}
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Customer Ref No.
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Customer Ref No."
            value={
              generalDetails.customer_ref_no
                ? generalDetails.customer_ref_no
                : ""
            }
            onChange={(event) =>
              handleChange("customer_ref_no", event.target.value)
            }
          />
        </div>

        {/* Grade */}
        <div className="w-1/2">
          <label
            htmlFor="grade"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Grade
          </label>
          <input
            type="text"
            id="grade"
            name="grade"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Grade"
            value={generalDetails.grade ? generalDetails.grade : ""}
            onChange={(event) => handleChange("grade", event.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default GeneralDetailsWeb;
