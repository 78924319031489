import React, { Suspense, useState } from 'react'
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'
import './App.css'
import RouteMissing from './RouteMissing'
import Login from './components/Login/LoginScreen'
import routes from './route'
import {AuthContext} from './Context' 
import { RecoilRoot } from 'recoil'
import Layout from './components/Common/TemplateLayout'
export const switchRoutes = (
  <React.Fragment> 
    {routes.mainRoutes.map((route, index) => {
      return route.component ? (
        <Route
          path={route.path}
          name={route.name}
          element={<route.component />}
          key={route.name}
        />
      ) : (
        <React.Fragment key={route.name}></React.Fragment>
      )
    })}

    <Route path="/" element={<Navigate replace to="/login" />} />
  </React.Fragment>
)

const PrivateOutlet = () => {
  const currentUser = localStorage.getItem('user')
  const user = JSON.parse(JSON.stringify(currentUser))
  return user ? <Layout /> : <Navigate to="/login" />;}

function App() {
  const currentUser = localStorage.getItem('user')
  const user = JSON.parse(JSON.stringify(currentUser))
  const [userId, _setUserId] = useState(user)

  return (
    // <ThemeProvider theme={theme}>
    <RecoilRoot>
      <AuthContext.Provider value={{ userId, setAuthTokens: _setUserId }}>
        {/* <CssBaseline /> */}
        <BrowserRouter>
          <Suspense>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<RouteMissing />} />
              <Route path="/" element={<PrivateOutlet />}>
                {switchRoutes}
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </AuthContext.Provider>
      </RecoilRoot>
    // </ThemeProvider>
  )
}

export default App
