import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { sintering_parameters, lining_reference } from "../../recoil/atom";
import axios from "axios";
import API from "../../service/API";
import TimeSelector from "../Common/TimePicker";

const SinteringParametersWeb = () => {
  const [sinteringParameters, setSinteringParameters] =
    useRecoilState(sintering_parameters);
  const [reason, setReason] = useState([]);
  console.log(reason, "asjdag");
  const [liningReference] = useRecoilState(lining_reference);
  const uniqueId = liningReference.id ? liningReference.id : "";

  const handleChange = (e) => {
    const { name, value } = e.target;
    const TrialName = localStorage.getItem("Trial");
    // Check if TrialName is "new"
    if (TrialName === "new") {
      setSinteringParameters((prevState) => ({
        ...prevState,
        [name]: value,
        id: uniqueId,
      }));
    } else {
      // If TrialName is not "new", no need to generate unique ID
      setSinteringParameters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    fetchReasonData();
  }, []);

  const fetchReasonData = async () => {
    try {
      const access_token = localStorage.getItem("accesstoken");

      const response = await axios.get(API.GET_REASON, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      console.log(response, "askadh");
      const reposneData = response.data;
      setReason(reposneData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Sintering Parameters
      </h2>
      <div className="flex space-x-4 mt-4">
        <div className="w-full">
          <label
            htmlFor="total_sintering_time"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total Sintering time - (HH:MM:SS)
          </label>

          <div className="relative -mt-4">
            <TimeSelector
              time={sinteringParameters.total_sintering_time || "0:0"}
              onTimeChange={(newValue) =>
                handleChange({
                  target: { name: "total_sintering_time", value: newValue },
                })
              }
            />
          </div>
        </div>

        {/* Holding Time */}
        <div className="w-full">
          <label
            htmlFor="holding_time"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Holding time - Mins - (HH:MM:SS)
          </label>

          <div className="relative -mt-4">
            <TimeSelector
              time={sinteringParameters.holding_time || "0:0"}
              onTimeChange={(newValue) =>
                handleChange({
                  target: { name: "holding_time", value: newValue },
                })
              }
            />
          </div>
        </div>
      </div>

      <div className="flex space-x-4 mt-4">
        {/* Holding Temp */}
        <div className="w-full mb-4">
          <label
            htmlFor="holding_temp"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Holding Temp - ⁰C
          </label>
          <input
            type="text"
            id="holding_temp"
            name="holding_temp"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Holding Temp"
            value={sinteringParameters.holding_temp}
            onChange={handleChange}
          />
        </div>

        {/* Reason for Down */}
        <div className="w-full mb-4">
          <label
            htmlFor="reason_for_down"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Reason for Down
          </label>
          <select
            id="reason_for_down"
            name="reason_for_down"
            value={sinteringParameters.reason_for_down}
            onChange={handleChange}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select Reason for Down</option>
            {reason.map((reason) => (
              <option key={reason.id} value={reason.name}>
                {reason.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex space-x-4">
        {/* IR No */}
        <div className="w-full mb-4">
          <label
            htmlFor="IR_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            IR no.
          </label>
          <input
            type="text"
            id="IR_no"
            name="IR_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter IR No."
            value={sinteringParameters.IR_no}
            onChange={handleChange}
          />
        </div>

        {/* Job Done By */}
        <div className="w-full mb-4">
          <label
            htmlFor="job_done_by"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Job Done By
          </label>
          <input
            type="text"
            id="job_done_by"
            name="job_done_by"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Job Done By"
            value={sinteringParameters.job_done_by}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Remarks */}
        <div className="w-full mb-4">
          <label
            htmlFor="remarks"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Remarks
          </label>
          <input
            type="text"
            id="remarks"
            name="remarks"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Remarks"
            value={sinteringParameters.remarks}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export default SinteringParametersWeb;
