import React, { useState } from "react";
import rightArrow from "./../../../assets/images/icons/RightArrow.svg";
import GeneralDetails from "./GeneralDetails";
import FurnaceDetails from "./FurnaceDetails";
import NewLiningDetails from "./NewLiningDetails";
import PatchingDetails from "./PatchingDetails";
import RepairDetails from "./RepairDetails";
import SideLiningDetails from "./SideLiningDetails";

const ConsumptionLayout = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [activeTab, setActiveTab] = React.useState("generalDetails");
  const [currentStep, setCurrentStep] = useState(1);

  // const steps = ["1", "2", "3", "4", "5", "6"];
  const steps = [
    { id: 1, component: <GeneralDetails /> },
    { id: 2, component: <FurnaceDetails /> },
    { id: 3, component: <NewLiningDetails /> },
    { id: 4, component: <SideLiningDetails /> },
    { id: 5, component: <PatchingDetails /> },
    { id: 6, component: <RepairDetails /> },
  ];

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
      setActiveTab(steps[currentStep - 2]?.id); // Set activeTab based on the previous step
    }
  };

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep((prev) => prev + 1);
      setActiveTab(steps[currentStep]?.id); // Set activeTab based on the next step
    }
  };

  const handleOpenUploadModal = () => {
    setModalTitle("Upload New DataFile");
    setIsModalOpen(true);
  };

  const handleOpenAddFormModal = () => {
    setModalTitle("Add Form");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeModalOnBackdropClick = (e) => {
    if (e.target.id === "static-modal") {
      setIsModalOpen(false);
    }
  };
  // State to track the active tab
  // const [activeTab, setActiveTab] = useState("generalDetails");

  // Function to handle tab click
  const handleTabClick = (tab, step) => {
    setActiveTab(tab);
    setCurrentStep(step);
  };
  return (
    <div className="w-full">
      <div className="flex border-b border-gray-200 dark:border-gray-700 pr-4">
        <ul className="flex -mb-px text-sm font-medium text-center overflow-auto py-2 track-form-list">
          {steps.map((step, index) => (
            <li key={step.id} className="me-2" role="presentation">
              <div className="flex items-center">
                <button
                  onClick={() => handleTabClick(step.id, index + 1)}
                  className={`inline-block p-1 whitespace-nowrap font-normal rounded-t-lg ${
                    activeTab === step.id
                      ? "text-custom-orange"
                      : "text-gray-500"
                  }`}
                  type="button"
                  role="tab"
                  aria-controls={step.id}
                  aria-selected={activeTab === step.id}
                >
                  <span className="flex items-center justify-center">
                    <span
                      className={`flex items-center justify-center w-5 h-5 me-2 text-xs bg-gray-100 border rounded-full shrink-0 dark:border-blue-500 ${
                        activeTab === step.id
                          ? "border-custom-orange text-custom-orange bg-custom-orange-light"
                          : "border-gray-300"
                      }`}
                    >
                      {index + 1}
                    </span>
                    {step.id === 1
                      ? "General"
                      : step.id === 2
                        ? "Furnace"
                        : step.id === 3
                          ? "New Lining"
                          : step.id === 4
                            ? "Side Lining"
                            : step.id === 5
                              ? "Patching"
                              : "Repair"}
                  </span>
                </button>
                <img src={rightArrow} alt="arrow" />
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Content Section */}
      <div
        style={{ height: "320px" }}
        className="mt-4 w-full overflow-y-auto pb-4 pr-4"
      >
        {steps[currentStep - 1]?.component}
      </div>

      {/* Navigation Buttons */}
      {/* <div className="flex space-x-4 justify-center bg-white w-full py-4 pr-4">
        <button
          onClick={handlePrevious}
          className="text-grey bg-white border border-gray-500 w-1/2 px-4 py-2 rounded-lg disabled:opacity-50"
          disabled={currentStep === 1}
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          className="text-white bg-custom-red w-1/2 px-4 py-2 rounded-lg disabled:opacity-50"
          disabled={currentStep === steps.length}
        >
          Next
        </button>
      </div> */}
    </div>
  );
};

export default ConsumptionLayout;
