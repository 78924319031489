import React from "react";
import { useRecoilState } from "recoil";
import { trial_name, lining_details } from "../../recoil/atom";
import { v4 as uuidv4 } from 'uuid'
import Helper from "../../service/Helper";

const LiningDetailsWeb = () => {
  const [liningDetails, setLiningDetails] = useRecoilState(lining_details)
  const [trialName] = useRecoilState(trial_name)
  const uuid = uuidv4()

  const handleChange = (e) => {
    const { name, value } = e.target
    const TrialName = localStorage.getItem('Trial')
    const alphabetRegex = /^[a-zA-Z\s]*$/
    const numericRegex = /^\d{0,5}(\.\d{0,2})?$/
    if (name === 'present_lining_method') {
      if (!alphabetRegex.test(value)) {
        return
      }
    } else {
      if (!numericRegex.test(value)) {
        return
      }
    }

    if (TrialName === 'new' || TrialName === 'add') {
      setLiningDetails((prevState) => ({
        ...prevState,
        [name]: value,
        id: uuid,
      }))
    } else {
      setLiningDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }
  const isReadOnly = trialName === 'viewDataCollection'

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Lining Details
      </h2>

      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="consumption_for_new_lining"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Consumption for New lining - (Kg / Lining)
          </label>
          <input
            type="text"
            id="heatconsumption_for_new_lining_no"
            name="consumption_for_new_lining"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter lining"
            value={liningDetails.consumption_for_new_lining}
            onChange={handleChange}
          />
        </div>

        {/* Date */}
        <div className="w-full mb-4">
          <label
            htmlFor="consumption_for_patching"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Consumption for Patching/Side Lining - (Kg / Side Lining)
          </label>
          <input
            type="text"
            id="consumption_for_patching"
            name="consumption_for_patching"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter lining"
            value={liningDetails.consumption_for_patching}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label
            htmlFor="new_lining_life"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            New Lining Life - No. of Heats
          </label>
          <input
            type="text"
            id="new_lining_life"
            name="new_lining_life"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Lining"
            value={liningDetails.new_lining_life}
            onChange={handleChange}
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="patching_lining_life"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Side/Patching lining life - (No. of Heats)
          </label>
          <input
            type="text"
            id="patching_lining_life"
            name="patching_lining_life"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Grade"
            value={liningDetails.patching_lining_life}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-1/2 mb-4">
          <label
            htmlFor="total_no_of_side_lining"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total No. of Side lining with keeping same bottom - (each)
          </label>
          <input
            type="text"
            id="total_no_of_side_lining"
            name="total_no_of_side_lining"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Nos"
            value={liningDetails.total_no_of_side_lining}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="w-1/2">
        <label
          htmlFor="present_lining_method"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Present Lining Method - (Machine / Manual)
        </label>
        <select
          id="present_lining_method"
          name="present_lining_method"
          value={liningDetails.present_lining_method}
          onChange={handleChange}
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a method</option>
          {Helper.LiningMethod.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}

        </select>
      </div>
    </>
  );
};

export default LiningDetailsWeb;
