import React , { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { yield_improvement_nrm, yield_improvement, trial_name } from "../../../recoil/atom";
import {costSavingsDueToYieldImprovementSelector} from "../../../recoil/selector"

const YieldImprovement = () => {
  const [yieldImprovement, setYieldImprovement] =
    useRecoilState(yield_improvement)
  const [yieldImprovementNRM, setYieldImprovementNRM] = useRecoilState(
    yield_improvement_nrm
  )
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'

  const costSavingsDueToPower = useRecoilValue(
    costSavingsDueToYieldImprovementSelector
  )
  console.log('Cost Savings Due to Yield Improvement:', costSavingsDueToPower)

  const handleYieldImprovement = (name, value) => {
    setYieldImprovement((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleYieldImprovementNRM = (name, value) => {
    setYieldImprovementNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  //calculation
  // const costSavingsDueToPower = ((yieldImprovementNRM.yield_benefit) * (yieldImprovementNRM.market_price_of_scrap)).toFixed(2)
  // console.log(costSavingsDueToPower, "costSavingsDueToPower")

  // const yieldImprovementObj = {
  //   yield_benefit: 'NA',
  //   market_price_of_scrap: 'NA',
  //   savings_due_to_yield_benefit: 'NA'
  // }

  // const yieldImprovementObjNRM = {
  //   yield_benefit: yieldImprovementNRM.yield_benefit,
  //   market_price_of_scrap: yieldImprovementNRM.market_price_of_scrap,
  //   savings_due_to_yield_benefit: costSavingsDueToPower
  // }

  // useEffect(() => {
  //   if (
  //     props.trialName === 'costSheetEdit' &&
  //     props.editSavingsDetails.length > 0
  //   ) {
  //     console.log(props.editSavingsDetails, 'props.editSavingsDetails')
  //     let editObj = doesKeyExist(
  //       props.editSavingsDetails,
  //       'yieldImprovementObject'
  //     )
  //     console.log(editObj, 'editObj')
  //     if (editObj) {
  //       setYieldImprovement(
  //         editObj.yieldImprovementObject.yieldImprovement.basic
  //       )
  //       setYieldImprovementNRM(
  //         editObj.yieldImprovementObject.yieldImprovement.nrm
  //       )
  //     }
  //   }
  // }, [props.editSavingsDetails])

  function doesKeyExist(array, key) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].hasOwnProperty(key)) {
        return array[i]
      }
    }
    return false
  }

  // const handlenextTriger =()=>{
  //   props.handleNext()
  //   props.handleYieldImprovementObject(yieldImprovementObj, yieldImprovementObjNRM)
  // }

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))


  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        S5 : Savings due to Yield improvement
      </h2>

      <div className="flex space-x-4 mt-4 mb-4">
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Yield Benefit <br />(%)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter "
            value="NA"
            onChange={() => { }}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="customer_ref_no"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Market price of Scrap or DRI per ton <br />(dia / MT)
          </label>
          <input
            type="text"
            id="customer_ref_no"
            name="customer_ref_no"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter price"
            value="NA"
            onChange={() => { }}
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="customer_ref_no"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Savings due to yield benefit (dia / MT)
        </label>
        <input
          type="text"
          id="customer_ref_no"
          name="customer_ref_no"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          value="NA"
          onChange={() => { }}
        />
      </div>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        NRM
      </h2>
      <div className="flex space-x-4 mt-4 mb-4">
        <div className="w-1/2">
          <label
            htmlFor="yield_benefit"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Yield Benefit <br />(%)
          </label>
          <input
            type="text"
            id="yield_benefit"
            name="yield_benefit"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Value"
            value={yieldImprovementNRM.yield_benefit}
            onChange={(event) =>
              handleYieldImprovementNRM('yield_benefit', event.target.value)
            }
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="market_price_of_scrap"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Market price of Scrap or DRI per ton <br/>(dia / MT)
          </label>
          <input
            type="text"
            id="market_price_of_scrap"
            name="market_price_of_scrap"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter price"
            value={yieldImprovementNRM.market_price_of_scrap}
            onChange={(event) =>
              handleYieldImprovementNRM(
                'market_price_of_scrap',
                event.target.value
              )
            }
          />
        </div>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="costSavingsDueToPower"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Savings due to yield benefit (dia / MT)
        </label>
        <input
          type="text"
          id="costSavingsDueToPower"
          name="costSavingsDueToPower"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          value={costSavingsDueToPower}
          onChange={(event) =>
            handleYieldImprovementNRM(
              'savings_due_to_yield_benefit',
              event.target.value
            )
          }
        />
      </div>
    </>
  );
};

export default YieldImprovement;
