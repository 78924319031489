import React, { useEffect } from "react";
import DataManagementUpload from "../DataManagement/DataManagementUpload";
import AddFormUpload from "../DailyTrialForm/AddFormUpload";
import AddUser from "../Settings/AddUser";
import CreateReport from "../Reports/CreateReport";
import AddReport from "../Reports/AddReport";
import DailyTrialLayout from "../DailyTrialForm/DailyTrialLayout";
import NRMLayout from "../NRMForm/NRMLayout";
import ErosionProfileLayout from "../ErosionProfileForm/ErosionProfileLayout";
import DataCollectionLayout from "../DataCollectionForm/DataCollectionLayout";
import CostSheetLayout from "../CostsheetForm/CostSheetLayout";

const ModalLayout = ({
  closeModal,
  modalTitle,
  formData,
  templateId,
  customers,
  furnaces,
  cardId,
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div
      id="static-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-gray-800 bg-opacity-75 backdrop-blur-md" 
      onClick={(e) => e.stopPropagation()} // Prevent click propagation on the backdrop
    >
      <div
        className="relative m-auto w-full dialog-w max-h-full dialog-h bg-white rounded-lg shadow dark:bg-gray-700"
        onClick={(e) => e.stopPropagation()} // Prevent click propagation on the modal content
      >
        {/* Modal Header */}
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            {modalTitle}
          </h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={closeModal} // Close only with the cross button
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className="flex h-full w-full py-4 pl-4">
          {modalTitle === "Upload New DataFile" && (
            <DataManagementUpload closeModal={closeModal} />
          )}
          {modalTitle === "New Form" && <AddFormUpload closeModal={closeModal} />}
          {modalTitle === "Add User" && <AddUser closeModal={closeModal} />}
          {modalTitle === "Edit User" && (
            <AddUser closeModal={closeModal} formData={formData} />
          )}
          {modalTitle === "Daily Trail Monitoring" && <DailyTrialLayout closeModal={closeModal} />}
         {modalTitle === "NRM Performance" && <NRMLayout closeModal={closeModal} />}
         {modalTitle === "Erosion Profile" && <ErosionProfileLayout closeModal={closeModal} />}
         {modalTitle === "Data Collection" && <DataCollectionLayout closeModal={closeModal} />}
         {modalTitle === "Cost Sheet" && < CostSheetLayout closeModal={closeModal} />}


          {modalTitle === "Create Report" && (
            <CreateReport
              closeModal={closeModal}
              customers={customers}
              furnaces={furnaces}
              formData={formData}
            />
          )}
          {modalTitle === "Add Report" && (
            <AddReport
              closeModal={closeModal}
              customers={customers}
              furnaces={furnaces}
              formData={formData}
              templateId={templateId}
              cardId={cardId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalLayout;
