import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import API from './../../service/API'
import axios from 'axios'
import { sampleCorrelation } from 'simple-statistics'
import { ReactComponent as DownloadIcon } from '../../assets/images/SVGIcon/Download-Icon.svg'
import * as XLSX from 'xlsx'
import AlertDialog from './../Common/AlertDialog'

const ViewReport = ({ rowIdsString }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [generalDetailsArray, setGeneralDetailsArray] = useState([])
    const [furnaceDetailsArray, setFurnaceDetailsArray] = useState([])
    const [carbonArray, setCarbonArray] = useState([])
    const [alloyingAdditionArray, setAlloyingAdditionArray] = useState([])
    const [otherParametersArray, setOtherParametersArray] = useState([])
    const [chargeMixDetailsArray, setChargeMixDetailsArray] = useState([])
    const [excelData, setExcelData] = useState([])

    const [liningReferenceArray, setLiningReferenceArray] = useState([])
    const [productionSummaryArray, setProductionSummaryArray] = useState([])
    const [
        liningMaterialProductDetailsArray,
        setLiningMaterialProductDetailsArray,
    ] = useState([])
    const [liningMaterialConsumptionArray, setLiningMaterialConsumptionArray] =
        useState([])
    const [dimensionalParametersArray, setDimensionalParametersArray] = useState(
        []
    )
    const [sinteringParametersArray, setSinteringParametersArray] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [selectedChart, setSelectedChart] = useState(null)
    const [charts, setCharts] = useState([])
    const [updateCharts, setUpdateCharts] = useState([])
    const [graphType, setGraphType] = useState('')
    const [metric, setMetric] = useState('')
    const [xAxis, setXAxis] = useState('')
    const [yAxis, setYAxis] = useState([])
    const [selectedCharts, setSelectedCharts] = useState([])
    const [activeChart, setActiveChart] = useState(null)
    const [allCharts, setAllCharts] = useState(charts)
    const [correlationMatrix, setCorrelationMatrix] = useState([])
    const [notes, setNotes] = useState('')
    const [jsonMap, setJsonMap] = useState([])
    const [jsonData, setJsonData] = useState(null)

    const templateId = location.state ? location.state.template : null
    ////console.log(templateId,'templateId');
    //     const location = useLocation();

    //     // Access the pathname property to get the current URL
    //     const currentUrl = location.pathname;

    //     // Log the current URL to the ////console
    //     ////console.log("Current URL:", currentUrl);
    //     const parts = currentUrl.split('/');

    // // Get the last part of the URL which contains the desired string
    // const lastPart = parts[parts.length - 1];

    // // Replace '%20' with spaces
    // const decodedString = decodeURIComponent(lastPart);
    // const idList = decodedString.split(',');
    // ////console.log(idList);

    const [viewTitle, setViewTitle] = useState('Merged Report View')
    const [userData, setUserData] = useState([])
    // const [segmentdata, setSegmentdata] = useState('');
    const [segment, setSegment] = useState('Daily Trial Monitoring')
    const tab = localStorage.getItem('selectedTabLabel')
    const firstRowStyles = {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 800,
        lineHeight: '24px',
        letterSpacing: '-0.006em',
        textAlign: 'left',
        color: '#252C32',
    }

    useEffect(() => {
        const storedValue = localStorage.getItem('View')
        if (storedValue === 'singleView') {
            setViewTitle('Selected Report View')
            setHideButtons(true) // Hide buttons based on stored value
        } else {
            setViewTitle('Merged Report View')
            setHideButtons(false) // Show buttons if value is not 'singleView'
        }
    }, [])

    const [hideButtons, setHideButtons] = useState(false)

    const cardId = localStorage.getItem('reportCardId')
    const rowIds = localStorage.getItem('rowIdsString')
    const rowIdsArray = rowIds.split(',')
    // ////console.log('rowIdsArray',rowIdsArray);
    //   ////console.log('hello:')

    const actualdata = []

    //console.log("good", tab);

    if (tab === 'Daily Trial Monitoring') {
        const data1 = generalDetailsArray
        const data2 = furnaceDetailsArray.map((item) => {
            // Convert time durations (HH:mm) to minutes
            const breakDownDuration = parseTimeToMinutes(item.break_down_duration)
            const heatDurationActual = parseTimeToMinutes(item.heat_duration_actual)
            const heatDurationCalculated = parseTimeToMinutes(
                item.heat_duration_calculated
            )
            const heatOnTime = parseTimeToMinutes(item.heat_on_time)
            const heatTapTime = parseTimeToMinutes(item.heat_tap_time)

            // Convert float values to integers
            const energyConsumptionPerUnitTonActual = parseFloat(
                item.energy_consumption_per_unit_ton_actual
            )
            const energyConsumptionPerUnitTonCalculated = parseFloat(
                item.energy_consumption_per_unit_ton_calculated
            )
            const frequency = parseFloat(item.frequency)
            const meltRateBasedOnActualDuration = parseFloat(
                item.melt_rate_based_on_actual_duration
            )
            const meltRateBasedOnCalculatedDuration = parseFloat(
                item.melt_rate_based_on_calculated_duration
            )
            const powerInThePanel = parseFloat(item.power_in_the_panel)
            const tappedTonnage = parseFloat(item.tapped_tonnage)
            const tappingTemperature = parseFloat(item.tapping_temperature)
            const totalPowerConsumed = parseFloat(item.total_power_consumed)
            const voltage = parseFloat(item.voltage)
            const id = item.id
            const capacitorsRemoved = parseFloat(item.capacitors_removed)

            // Return the parsed item
            return {
                id: id,
                capacitors_removed: capacitorsRemoved,
                break_down_duration: breakDownDuration,
                heat_duration_actual: heatDurationActual,
                heat_duration_calculated: heatDurationCalculated,
                heat_on_time: heatOnTime,
                heat_tap_time: heatTapTime,
                energy_consumption_per_unit_ton_actual:
                    energyConsumptionPerUnitTonActual,
                energy_consumption_per_unit_ton_calculated:
                    energyConsumptionPerUnitTonCalculated,
                frequency,
                melt_rate_based_on_actual_duration: meltRateBasedOnActualDuration,
                melt_rate_based_on_calculated_duration:
                    meltRateBasedOnCalculatedDuration,
                power_in_the_panel: powerInThePanel,
                tapped_tonnage: tappedTonnage,
                tapping_temperature: tappingTemperature,
                total_power_consumed: totalPowerConsumed,
                voltage,
            }
        })

        // ////console.log("data2", data2);

        const data3 = carbonArray.map((item) => {
            // Convert time durations (HH:mm) to minutes
            const cpc = parseFloat(item.cpc)
            const eightyFive = parseFloat(item.eightyfive_c_percentage)
            const fifty = parseFloat(item.fifty_c_percentage)
            const final = parseFloat(item.final_c_percentage)
            const metCoke = parseTimeToMinutes(item.met_coke)
            const id = item.id

            // Return the parsed item
            return {
                id: id,
                cpc: cpc,
                eightyfive_c_percentage: eightyFive,
                fifty_c_percentage: fifty,
                final_c_percentage: final,
                met_coke: metCoke,
            }
        })

        // ////console.log("data3", data3);

        const data4 = alloyingAdditionArray.map((item) => {
            // Convert time durations (HH:mm) to minutes
            const anyOtherAlloyAddition = item.any_other_alloy_addition
            const crBeforeAlloyAddition = parsePercentage(
                item.cr_before_alloy_addition
            )
            const Fecr = parseFloat(item.feCr)
            const Femn = parseFloat(item.feMn)
            const Fesi = parseFloat(item.feSi)
            const id = item.id
            const FinalCr = parsePercentage(item.final_cr)
            const FinalMn = parsePercentage(item.final_mn)
            const FinalSi = parsePercentage(item.final_si)
            const MillScale = parseFloat(item.mill_scale)
            const MnBeforeAlloyAddition = parsePercentage(
                item.mn_before_alloy_addition
            )
            const MnInSimn = parseFloat(item.mn_in_simn)
            const SiBeforeAlloyAddition = parsePercentage(
                item.si_before_alloy_addition
            )
            const SiMn = parseFloat(item.si_mn)

            // Return the parsed item
            return {
                id: id,
                any_other_alloy_addition: anyOtherAlloyAddition,
                cr_before_alloy_addition: crBeforeAlloyAddition,
                feCr: Fecr,
                feMn: Femn,
                feSi: Fesi,
                final_cr: FinalCr,
                final_mn: FinalMn,
                final_si: FinalSi,
                mill_scale: MillScale,
                mn_before_alloy_addition: MnBeforeAlloyAddition,
                mn_in_simn: MnInSimn,
                si_before_alloy_addition: SiBeforeAlloyAddition,
                si_mn: SiMn,
            }
        })

        // ////console.log("data4", data4);

        const data5 = otherParametersArray.map((item) => {
            // Convert time durations (HH:mm) to minutes
            const inletWaterTemp = parseFloat(item.inlet_water_temp)
            const outletWaterTemp = parseFloat(item.outlet_water_temp)
            const Remarks = item.remarks
            const waterPr = parseFloat(item.water_pr)
            const id = item.id

            // Return the parsed item
            return {
                id: id,
                inlet_water_temp: inletWaterTemp,
                outlet_water_temp: outletWaterTemp,
                remarks: Remarks,
                water_pr: waterPr,
            }
        })

        // ////console.log("data5", data5);

        const data6 = chargeMixDetailsArray.map((item) => {
            // Convert time durations (HH:mm) to minutes
            const finesLessthan1mm = parseFloat(item.fines_lessthan_1mm)
            const finesLessthan5mm = parseFloat(item.fines_lessthan_5mm)
            const hotHeel = parseFloat(item.hot_heel)
            const Lump = parseFloat(item.lump)
            const id = item.id
            const Pellet = parseFloat(item.pellet)
            const PigIron = parseFloat(item.pig_iron)
            const PooledIron = item.pooled_iron
            const Scrap = parseFloat(item.scrap)
            const Shredded = parseFloat(item.shredded)
            const SlagCrushers = parseFloat(item.slag_crushers)
            const totalCharge = parseFloat(item.total_charge)
            const tundishOrSlagOrMetalicJam = parseFloat(
                item.tundish_or_slag_or_metalic_jam
            )

            // Return the parsed item
            return {
                id: id,
                fines_lessthan_1mm: finesLessthan1mm,
                fines_lessthan_5mm: finesLessthan5mm,
                hot_heel: hotHeel,
                lump: Lump,
                pellet: Pellet,
                pig_iron: PigIron,
                pooled_iron: PooledIron,
                scrap: Scrap,
                shredded: Shredded,
                slag_crushers: SlagCrushers,
                total_charge: totalCharge,
                tundish_or_slag_or_metalic_jam: tundishOrSlagOrMetalicJam,
            }
        })

        // ////console.log("data6", data6);

        // Function to parse time in HH:mm format to minutes
        function parseTimeToMinutes(timeString) {
            if (!timeString) return 0 // If timeString is undefined or null, return 0

            const [hours, minutes] = timeString.split(':').map(Number)
            return hours * 60 + minutes
        }

        function parsePercentage(value) {
            if (typeof value !== 'undefined') {
                const numericValue = parseFloat(value.replace('%', '')) / 100
                return numericValue
            }
            // Return NaN if value is undefined
            return 0
        }

        // Loop through data1
        data1.forEach((item1) => {
            // Initialize mergedItem with properties from data1
            let mergedItem = { ...item1 }

            // Find matching items in other arrays based on id
            const matchingItem2 = data2.find((item2) => item2.id === item1.id)
            const matchingItem3 = data3.find((item3) => item3.id === item1.id)
            const matchingItem4 = data4.find((item4) => item4.id === item1.id)
            const matchingItem5 = data5.find((item5) => item5.id === item1.id)
            const matchingItem6 = data6.find((item6) => item6.id === item1.id)

            // Merge properties from other arrays if matching items are found
            if (matchingItem2) mergedItem = { ...mergedItem, ...matchingItem2 }
            if (matchingItem3) mergedItem = { ...mergedItem, ...matchingItem3 }
            if (matchingItem4) mergedItem = { ...mergedItem, ...matchingItem4 }
            if (matchingItem5) mergedItem = { ...mergedItem, ...matchingItem5 }
            if (matchingItem6) mergedItem = { ...mergedItem, ...matchingItem6 }

            // Push the mergedItem to the actualdata array
            actualdata.push(mergedItem)
        })
        // ////console.log("data123 actualdata", actualdata);
    } else if (tab === 'NRM Performance') {
        //console.log("good");

        const data1 = liningReferenceArray

        //console.log("data1", data1);
        const data2 = productionSummaryArray.map((item) => {
            const id = item.id
            const fceProdStartDate = item.fce_prod_start_date
            const fceProdStartTime = parseTimeToMinutes(item.fce_prod_start_time)
            const fceProdDownDate = item.fce_prod_down_date
            const fceProdDownTime = parseTimeToMinutes(item.fce_prod_down_time)
            const life = item.life

            const averageHeatSize = parseFloat(item.average_heat_size)
            const totalFurnaceRunningHours = parseFloat(
                item.total_furnace_running_hours
            )
            const totalProduction = parseFloat(item.total_production)
            const totalFurnaceRunningHours2 = parseFloat(
                item.total_furnace_running_hours
            )

            // Return the parsed item
            return {
                id: id,
                fce_prod_start_date: fceProdStartDate,
                fce_prod_start_time: fceProdStartTime,
                fce_prod_down_date: fceProdDownDate,
                fce_prod_down_time: fceProdDownTime,
                life: life,
                average_heat_size: averageHeatSize,
                total_furnace_running_hours: totalFurnaceRunningHours,
                total_production: totalProduction,
                total_furnace_running_hours: totalFurnaceRunningHours2,
            }
        })

        //console.log("data2", data2);

        const data3 = liningMaterialProductDetailsArray.map((item) => {
            // Convert time durations (HH:mm) to minutes
            const id = item.id
            const bottomProduct = item.bottom_product
            const sidewallProductOne = item.sidewall_product_one
            const sidewallProductTwo = item.sidewall_product_two
            const topLockingProduct = item.top_locking_product
            const toppingMassProduct = item.topping_mass_product
            const sidewallOneCoverageUptoX = parsePercentage(
                item.sidewall_one_coverage_upto_x
            )
            const sidewallTwoCoverageUpto100 = parsePercentage(
                item.sidewall_two_coverage_upto_100
            )

            // Return the parsed item
            return {
                id: id,
                bottom_product: bottomProduct,
                sidewall_product_one: sidewallProductOne,
                sidewall_product_two: sidewallProductTwo,
                top_locking_product: topLockingProduct,
                topping_mass_product: toppingMassProduct,
                sidewall_one_coverage_upto_x: sidewallOneCoverageUptoX,
                sidewall_two_coverage_upto_100: sidewallTwoCoverageUpto100,
            }
        })

        //console.log("data3", data3);

        const data4 = liningMaterialConsumptionArray.map((item) => {
            // Convert time durations (HH:mm) to minutes
            const nrmConsumptionBottom = parseFloat(item.nrm_consumption_bottom)
            const nrmConsumptionSidewallOne = parseFloat(
                item.nrm_consumption_sidewall_one
            )
            const nrmConsumptionSidewallTwo = parseFloat(
                item.nrm_consumption_sidewall_two
            )
            const nrmConsumptionTopLocking = parseFloat(
                item.nrm_consumption_top_locking
            )
            const toppingMassConsumption = parseFloat(item.topping_mass_consumption)
            const id = item.id
            const totalNrmConsumptionSidewallOne = parseFloat(
                item.total_nrm_consumption_sidewall_one
            )
            const nrmSpecificConsumption = parseFloat(item.nrm_specific_consumption)

            // Return the parsed item
            return {
                id: id,
                nrm_consumption_bottom: nrmConsumptionBottom,
                nrm_consumption_sidewall_one: nrmConsumptionSidewallOne,
                nrm_consumption_sidewall_two: nrmConsumptionSidewallTwo,
                nrm_consumption_top_locking: nrmConsumptionTopLocking,
                topping_mass_consumption: toppingMassConsumption,
                total_nrm_consumption_sidewall_one: totalNrmConsumptionSidewallOne,
                nrm_specific_consumption: nrmSpecificConsumption,
            }
        })

        //console.log("data4", data4);

        const data5 = dimensionalParametersArray.map((item) => {
            // Convert time durations (HH:mm) to minutes
            const formerDimension = item.former_dimension
            const coilCoatId = parseFloat(item.coil_coat_id)
            const fceHt = parseFloat(item.fce_ht)
            const costableTopHt = parseFloat(item.costable_top_ht)
            const bottomHt = parseFloat(item.bottom_ht)
            const id = item.id

            // Return the parsed item
            return {
                id: id,
                former_dimension: formerDimension,
                coil_coat_id: coilCoatId,
                fce_ht: fceHt,
                costable_top_ht: costableTopHt,
                bottom_ht: bottomHt,
            }
        })

        //console.log("data5", data5);

        const data6 = sinteringParametersArray.map((item) => {
            // Convert time durations (HH:mm) to minutes
            const totalSinteringTime = parseTimeToMinutes(item.total_sintering_time)
            const holdingTime = parseFloat(item.holding_time)
            const holdingTemp = parseFloat(item.holding_temp)
            const reasonForDown = item.reason_for_down
            const id = item.id
            const IRno = item.IR_no
            const jobDoneBy = item.job_done_by
            const remarks = item.remarks

            // Return the parsed item
            return {
                id: id,
                total_sintering_time: totalSinteringTime,
                holding_time: holdingTime,
                holding_temp: holdingTemp,
                reason_for_down: reasonForDown,
                IR_no: IRno,
                job_done_by: jobDoneBy,
                remarks: remarks,
            }
        })

        //console.log("data6", data6);

        // Function to parse time in HH:mm format to minutes
        function parseTimeToMinutes(timeString) {
            if (!timeString) return 0 // If timeString is undefined or null, return 0

            const [hours, minutes] = timeString.split(':').map(Number)
            return hours * 60 + minutes
        }

        function parsePercentage(value) {
            if (typeof value !== 'undefined') {
                const numericValue = parseFloat(value.replace('%', '')) / 100
                return numericValue
            }
            // Return NaN if value is undefined
            return 0
        }

        // Loop through data1
        data1.forEach((item1) => {
            // Initialize mergedItem with properties from data1
            let mergedItem = { ...item1 }

            // Find matching items in other arrays based on id
            const matchingItem2 = data2.find((item2) => item2.id === item1.id)
            const matchingItem3 = data3.find((item3) => item3.id === item1.id)
            const matchingItem4 = data4.find((item4) => item4.id === item1.id)
            const matchingItem5 = data5.find((item5) => item5.id === item1.id)
            const matchingItem6 = data6.find((item6) => item6.id === item1.id)

            // Merge properties from other arrays if matching items are found
            if (matchingItem2) mergedItem = { ...mergedItem, ...matchingItem2 }
            if (matchingItem3) mergedItem = { ...mergedItem, ...matchingItem3 }
            if (matchingItem4) mergedItem = { ...mergedItem, ...matchingItem4 }
            if (matchingItem5) mergedItem = { ...mergedItem, ...matchingItem5 }
            if (matchingItem6) mergedItem = { ...mergedItem, ...matchingItem6 }

            // Push the mergedItem to the actualdata array
            actualdata.push(mergedItem)
        })
    }

    //console.log('actualdata', actualdata);

    function replaceOutliersWithNaN(data, property) {
        // Extract numerical values for the specified property, filtering out NaN and undefined
        let values = data
            .map((item) => item[property])
            .filter((value) => !isNaN(value) && value !== undefined)

        if (values.length === 0) {
            // If there are no valid values to process, return the original data
            return { updatedData: data, hasOutliers: false }
        }

        // Function to calculate quartiles and IQR
        function calculateIQR(values) {
            values.sort((a, b) => a - b)
            let q1 = values[Math.floor(values.length * 0.25)]
            let q3 = values[Math.floor(values.length * 0.75)]
            let iqr = q3 - q1
            return { q1, q3, iqr }
        }

        let { q1, q3, iqr } = calculateIQR(values)

        // Define lower and upper bounds
        let lowerBound = q1 - 1.5 * iqr
        let upperBound = q3 + 1.5 * iqr

        // Replace outlier values with NaN
        let updatedData = data.map((item) => {
            let value = item[property]
            if (value < lowerBound || value > upperBound) {
                return { ...item, [property]: NaN }
            } else {
                return item
            }
        })

        // Check if there are any outliers
        let hasOutliers = updatedData.some((item) => item[property] === NaN)

        return { updatedData, hasOutliers }
    }

    function replaceOutliersInAllProperties(data, properties) {
        let updatedData = data
        let hasOutliers = false

        properties.forEach((property) => {
            let result = replaceOutliersWithNaN(updatedData, property)
            updatedData = result.updatedData
            hasOutliers = hasOutliers || result.hasOutliers
        })

        return { updatedData, hasOutliers }
    }

    let propertiesToCheck
    if (tab === 'Daily Trial Monitoring') {
        propertiesToCheck = [
            // 'break_down_duration',
            'heat_duration_actual',
            'heat_duration_calculated',
            'heat_on_time',
            'heat_tap_time',
            'energy_consumption_per_unit_ton_actual',
            'energy_consumption_per_unit_ton_calculated',
            'frequency',
            'melt_rate_based_on_actual_duration',
            'melt_rate_based_on_calculated_duration',
            'power_in_the_panel',
            'tapped_tonnage',
            'tapping_temperature',
            'total_power_consumed',
            'voltage',
            'capacitors_removed',
            'cpc',
            'eightyfive_c_percentage',
            'fifty_c_percentage',
            'final_c_percentage',
            'met_coke',
            'any_other_alloy_addition',
            'cr_before_alloy_addition',
            'feCr',
            'feMn',
            'feSi',
            'final_cr',
            'final_mn',
            'final_si',
            'mill_scale',
            'mn_before_alloy_addition',
            'mn_in_simn',
            'si_before_alloy_addition',
            'si_mn',
            'heat_duration_calculated',
            'inlet_water_temp',
            'outlet_water_temp',
            'fines_lessthan_1mm',
            'fines_lessthan_5mm',
            'hot_heel',
            'lump',
            'pellet',
            'pig_iron',
            'pooled_iron',
            'scrap',
            'shredded',
            'slag_crushers',
            'total_charge',
            'tundish_or_slag_or_metalic_jam',
            // Add other properties as needed
        ]
    } else if (tab === 'NRM Performance') {
        propertiesToCheck = [
            'fce_prod_start_date',
            'fce_prod_start_time',
            'fce_prod_down_date',
            'fce_prod_down_time',
            'life',
            'average_heat_size',
            'total_furnace_running_hours',
            'total_production',
            'total_furnace_running_hours',

            'sidewall_one_coverage_upto_x',
            'sidewall_two_coverage_upto_100',
            'nrm_consumption_bottom',
            'nrm_consumption_sidewall_one',
            'nrm_consumption_sidewall_two',
            'nrm_consumption_top_locking',
            'topping_mass_consumption',
            'total_nrm_consumption_sidewall_one',
            'nrm_specific_consumption',

            'coil_coat_id',
            'fce_ht',
            'costable_top_ht',
            'bottom_ht',
            'total_sintering_time',
            'holding_time',
            'holding_temp',
        ]
    }

    let result = replaceOutliersInAllProperties(actualdata, propertiesToCheck)

    //console.log('result data', result);

    //console.log('Cleaned data', result.updatedData);
    //console.log('Has outliers:', result.hasOutliers);

    // //console.log('Cleaned data', cleanedData);

    let DataGraph = result.updatedData
    localStorage.setItem('cleanedDataGraph', JSON.stringify(DataGraph))

    const cleanedDataGraph = JSON.parse(localStorage.getItem('cleanedDataGraph'))
    //console.log("cleanedDataGraph", cleanedDataGraph);

    const handleChart = async () => {
        if (tab === 'Daily Trial Monitoring') {
            ////console.log("handlechart calling");
            const repcardId = localStorage.getItem('reportCardId')

            const response = await axios.get(
                API.GET_GRAPH,
                {
                    params: { reports_id: repcardId, template_id: templateId },
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            const graphdata = response.data
            ////console.log("handlechart dataresponse", graphdata.length);

            try {
                if (graphdata.length === 0) {
                    ////console.log("pcr");
                    const chartsConfigurations = [
                        {
                            xAxis: 'lorp_id',
                            yAxis: ['tapped_tonnage'],
                            notes: 'LORP ID vs Tapped Tonnage (Box)',
                            type: 'box',
                        },
                        {
                            xAxis: 'lorp_id',
                            yAxis: ['si_mn', 'tapped_tonnage'],
                            notes: 'LORP ID vs SiMn (Stacked Bar)',
                            type: 'stacked-bar',
                        },
                        {
                            xAxis: 'lorp_id',
                            yAxis: ['si_mn', 'tapped_tonnage', 'feSi'],
                            notes: 'Heat No (Heatmap Grid)',
                            type: 'heatmap',
                        },
                        {
                            xAxis: 'heat_no',
                            yAxis: ['heat_duration_calculated'],
                            notes: 'Heat No vs Heat Duration calculated [Line]',
                            type: 'line',
                        },
                    ]

                    const newCharts = []

                    for (const config of chartsConfigurations) {
                        const { xAxis, yAxis, notes, type } = config
                        const newData = groupDataByXAxis(actualdata, xAxis, yAxis)
                        const newData2 = groupDataByXAxis1(cleanedDataGraph, xAxis, yAxis)
                        //console.log('newData2 data', newData2);
                        const chartData = []

                        if (type === 'box') {
                            yAxis.forEach((yAxisItem) => {
                                if (yAxisItem !== 'undefined') {
                                    const yAxisData = Object.keys(newData).map((key) => ({
                                        x: key,
                                        y: newData[key][yAxisItem],
                                        name: key,
                                        type: 'box',
                                    }))
                                    chartData.push(...yAxisData)
                                }
                            })
                        } else if (type === 'stacked-bar') {
                            const categories = Object.keys(newData2)

                            yAxis.forEach((yAxisItem) => {
                                const yValues = categories.map((category) => {
                                    const values = newData2[category][yAxisItem]
                                    return Array.isArray(values)
                                        ? values.reduce((total, val) => total + val, 0)
                                        : values
                                })

                                chartData.push({
                                    x: categories,
                                    y: yValues,
                                    name: yAxisItem,
                                    type: 'bar',
                                    metric: 'total',
                                })
                            })
                        } else if (type === 'heatmap') {
                            try {
                                const lorpIds = Array.from(
                                    new Set(cleanedDataGraph.map((item) => item.lorp_id))
                                ).filter((id) => id !== undefined)
                                const correlationMatrix = []

                                lorpIds.forEach((lorpId) => {
                                    const filteredData = cleanedDataGraph.filter(
                                        (item) => item.lorp_id === lorpId
                                    )
                                    const correlations = yAxis.map((yAxisItem) => {
                                        const yAxisData = filteredData.map(
                                            (item) => item[yAxisItem]
                                        )
                                        if (yAxisData.some(isNaN)) {
                                            return null
                                        }
                                        return sampleCorrelation(
                                            yAxisData,
                                            filteredData.map((item) => item.heat_no)
                                        )
                                    })

                                    correlationMatrix.push({
                                        lorp_id: lorpId,
                                        correlations: correlations,
                                    })
                                })

                                const heatmapData = {
                                    x: yAxis,
                                    y: correlationMatrix.map((matrix) => matrix.lorp_id),
                                    z: correlationMatrix.map((matrix) => matrix.correlations),
                                    type: 'heatmap',
                                    colorscale: 'Viridis',
                                    heatmapSelection: 'heat_no',
                                }
                                chartData.push(heatmapData)
                            } catch {
                                //console.error(`Error processing chart of type ${type}:`, error);
                            }
                        } else if (
                            type === 'bar' ||
                            type === 'scatter' ||
                            type === 'line' ||
                            type === 'horizontal-bar'
                        ) {
                            ////console.log("metricmetricmetric", metric);
                            yAxis.forEach((yAxisItem) => {
                                const xValues = []
                                const yValues = []
                                for (const key in newData2) {
                                    if (Object.hasOwnProperty.call(newData2, key)) {
                                        // xValues.push(key);
                                        const values = newData2[key][yAxisItem]
                                        ////console.log('values', values);
                                        let result
                                        result = Array.isArray(values)
                                            ? values.reduce((total, val) => total + val, 0)
                                            : values

                                        ////console.log('result', result);
                                        // yValues.push(result);
                                        if (key !== 'undefined') {
                                            xValues.push(key)
                                            yValues.push(result)
                                        }
                                    }
                                }
                                ////console.log("xValues",xValues);
                                ////console.log("yValues",yValues);
                                chartData.push({
                                    x: type === 'horizontal-bar' ? yValues : xValues,
                                    y: type === 'horizontal-bar' ? xValues : yValues,
                                    mode: type === 'scatter' ? 'markers' : 'lines',
                                    name: yAxisItem,
                                    type: type === 'horizontal-bar' ? 'bar' : type,
                                    orientation: type === 'horizontal-bar' ? 'h' : undefined,
                                    metric: 'total',
                                })
                            })
                        }

                        newCharts.push({
                            id: newCharts.length + 1,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            title: notes,
                            data: chartData,
                        })
                    }

                    // const updatedData = [...charts, ...newCharts];
                    setCharts(newCharts)

                    const excelresponse = await axios.post(
                        API.GRAPH,
                        {
                            reports_id: cardId,
                            report_datasetup_id: rowIdsArray,
                            standard_graphs: newCharts,
                            template_id: templateId,
                        },
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    )

                    ////console.log("Excel data inserted successfully:", excelresponse);
                    AlertDialog({
                        type: 'reports saved',
                        title: 'Success',
                        text: 'Reports saved successfully',
                        confirmButtonText: 'Ok',
                    })
                    // window.alert("Report is saved successfully.");
                } else {
                    const response = await axios.get(
                        API.GET_GRAPH,
                        {
                            params: { reports_id: repcardId, template_id: templateId },
                        },
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    )
                    const responseData = await response.data[0].standard_graphs
                    ////console.log("responseData", responseData);

                    const newCharts = []

                    for (const item of responseData) {
                        try {
                            const { id, xAxis, yAxis, title, data } = item

                            if (
                                (Array.isArray(yAxis) && yAxis.length === 0) ||
                                !data[0].type
                            ) {
                                //console.warn(`Skipping item with id ${id} because yAxis is empty.`);
                                continue // Skip this iteration if yAxis is empty
                            }
                            ////console.log("id", id,xAxis, yAxis, title);
                            const notes = title // Assuming 'title' from responseData is used as 'notes'
                            const graphType = data[0].type // Example graph type, adjust as per your logic
                            const metric = data[0].metric
                            const heatmapSelection = data[0].heatmapSelection
                            // Call handleAddGraph with extracted parameters
                            const updatedData = await handleAddGraph({
                                id,
                                xAxis,
                                yAxis,
                                notes,
                                graphType,
                                responseData,
                                metric,
                                heatmapSelection,
                            })
                            ////console.log("updatedData", updatedData);
                            newCharts.push({
                                id: id,
                                xAxis: xAxis,
                                yAxis: yAxis,
                                title: title,
                                // title: `${xAxis}-Combined`, // Use a title indicating it's combined
                                data: updatedData,
                            })
                            // Create a single chart with the combined data

                            ////console.log("newCharts", newCharts);
                        } catch (error) {
                            continue
                        }
                    }
                    ////console.log("newCharts", newCharts);
                    const excelresponse = await axios.post(
                        API.UPDATE_GRAPH,
                        {
                            reports_id: cardId,
                            standard_graphs: newCharts,
                            template_id: templateId,
                        },
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    )

                    ////console.log("updated successfully:", excelresponse);
                    AlertDialog({
                        type: 'reports updated',
                        title: 'Success',
                        text: 'Reports updated successfully',
                        confirmButtonText: 'Ok',
                    })
                    // window.alert("Report updated successfully.");
                }

                navigate('/Charts', {
                    state: {
                        actualdata: actualdata,
                        cleanedDataGraph: cleanedDataGraph,
                        template: templateId,
                    },
                })
            } catch (error) {
                ////console.error('An error occurred while inserting chart data:', error);
                window.alert('An error occurred while inserting chart data.')
                // Optionally, handle error state here
            }
        } else if (tab === 'NRM Performance') {
            ////console.log("handlechart calling");
            const repcardId = localStorage.getItem('reportCardId')

            const response = await axios.get(
                API.GET_GRAPH,
                {
                    params: { reports_id: repcardId, template_id: templateId },
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            const graphdata = response.data
            ////console.log("handlechart dataresponse", graphdata.length);

            try {
                if (graphdata.length === 0) {
                    ////console.log("pcr");
                    const chartsConfigurations = [
                        {
                            xAxis: 'life',
                            yAxis: ['total_nrm_consumption_sidewall_one'],
                            notes: 'LIFE vs Total NRM Consumption Sidewall (Box)',
                            type: 'box',
                        },
                        {
                            xAxis: 'life',
                            yAxis: ['coil_coat_id', 'fce_ht'],
                            notes: 'LIFE vs Coil Coat ID (Stacked Bar)',
                            type: 'stacked-bar',
                        },
                        {
                            xAxis: 'life',
                            yAxis: ['average_heat_size', 'total_production'],
                            notes: 'Total Furnace Running Hours(Heatmap Grid)',
                            type: 'heatmap',
                        },
                        {
                            xAxis: 'life',
                            yAxis: ['costable_top_ht'],
                            notes: 'LIFE vs Castable Top Ht [Line]',
                            type: 'line',
                        },
                    ]

                    const newCharts = []

                    for (const config of chartsConfigurations) {
                        const { xAxis, yAxis, notes, type } = config
                        const newData = groupDataByXAxis(actualdata, xAxis, yAxis)
                        const newData2 = groupDataByXAxis1(cleanedDataGraph, xAxis, yAxis)
                        const chartData = []

                        if (type === 'box') {
                            yAxis.forEach((yAxisItem) => {
                                if (yAxisItem !== 'undefined') {
                                    const yAxisData = Object.keys(newData).map((key) => ({
                                        x: key,
                                        y: newData[key][yAxisItem],
                                        name: key,
                                        type: 'box',
                                    }))
                                    chartData.push(...yAxisData)
                                }
                            })
                        } else if (type === 'stacked-bar') {
                            const categories = Object.keys(newData2)

                            yAxis.forEach((yAxisItem) => {
                                const yValues = categories.map((category) => {
                                    const values = newData2[category][yAxisItem]
                                    return Array.isArray(values)
                                        ? values.reduce((total, val) => total + val, 0)
                                        : values
                                })

                                chartData.push({
                                    x: categories,
                                    y: yValues,
                                    name: yAxisItem,
                                    type: 'bar',
                                    metric: 'total',
                                })
                            })
                        } else if (type === 'heatmap') {
                            try {
                                const Lifes = Array.from(
                                    new Set(cleanedDataGraph.map((item) => item.life))
                                ).filter((id) => id !== undefined)
                                const correlationMatrix = []

                                Lifes.forEach((Life) => {
                                    const filteredData = cleanedDataGraph.filter(
                                        (item) => item.life === Life
                                    )
                                    const correlations = yAxis.map((yAxisItem) => {
                                        const yAxisData = filteredData.map(
                                            (item) => item[yAxisItem]
                                        )
                                        if (yAxisData.some(isNaN)) {
                                            return
                                        }
                                        return sampleCorrelation(
                                            yAxisData,
                                            filteredData.map(
                                                (item) => item.total_furnace_running_hours
                                            )
                                        )
                                    })

                                    correlationMatrix.push({
                                        life: Life,
                                        correlations: correlations,
                                    })
                                })

                                const heatmapData = {
                                    x: yAxis,
                                    y: correlationMatrix.map((matrix) => matrix.life),
                                    z: correlationMatrix.map((matrix) => matrix.correlations),
                                    type: 'heatmap',
                                    colorscale: 'Viridis',
                                    heatmapSelection: 'total_furnace_running_hours',
                                }
                                chartData.push(heatmapData)
                            } catch {
                                //console.error(`Error processing chart of type ${type}:`, error);
                            }
                        } else if (
                            type === 'bar' ||
                            type === 'scatter' ||
                            type === 'line' ||
                            type === 'horizontal-bar'
                        ) {
                            ////console.log("metricmetricmetric", metric);
                            yAxis.forEach((yAxisItem) => {
                                const xValues = []
                                const yValues = []
                                for (const key in newData2) {
                                    if (Object.hasOwnProperty.call(newData2, key)) {
                                        // xValues.push(key);
                                        const values = newData2[key][yAxisItem]
                                        ////console.log('values', values);
                                        let result
                                        result = Array.isArray(values)
                                            ? values.reduce((total, val) => total + val, 0)
                                            : values

                                        ////console.log('result', result);
                                        // yValues.push(result);
                                        if (key !== 'undefined') {
                                            xValues.push(key)
                                            yValues.push(result)
                                        }
                                    }
                                }
                                ////console.log("xValues",xValues);
                                ////console.log("yValues",yValues);
                                chartData.push({
                                    x: type === 'horizontal-bar' ? yValues : xValues,
                                    y: type === 'horizontal-bar' ? xValues : yValues,
                                    mode: type === 'scatter' ? 'markers' : 'lines',
                                    name: yAxisItem,
                                    type: type === 'horizontal-bar' ? 'bar' : type,
                                    orientation: type === 'horizontal-bar' ? 'h' : undefined,
                                    metric: 'total',
                                })
                            })
                        }

                        newCharts.push({
                            id: newCharts.length + 1,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            title: notes,
                            data: chartData,
                        })
                    }

                    // const updatedData = [...charts, ...newCharts];
                    setCharts(newCharts)

                    const excelresponse = await axios.post(
                        API.GRAPH,
                        {
                            reports_id: cardId,
                            report_datasetup_id: rowIdsArray,
                            standard_graphs: newCharts,
                            template_id: templateId,
                        },
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    )

                    ////console.log("Excel data inserted successfully:", excelresponse);
                    AlertDialog({
                        type: 'reports saved',
                        title: 'Success',
                        text: 'Reports saved successfully',
                        confirmButtonText: 'Ok',
                    })
                    // window.alert("Report is saved successfully.");
                } else {
                    const response = await axios.get(
                        API.GET_GRAPH,
                        {
                            params: { reports_id: repcardId, template_id: templateId },
                        },
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    )
                    const responseData = await response.data[0].standard_graphs
                    ////console.log("responseData", responseData);

                    const newCharts = []

                    for (const item of responseData) {
                        try {
                            const { id, xAxis, yAxis, title, data } = item

                            if (
                                (Array.isArray(yAxis) && yAxis.length === 0) ||
                                !data[0].type
                            ) {
                                //console.warn(`Skipping item with id ${id} because yAxis is empty.`);
                                continue // Skip this iteration if yAxis is empty
                            }
                            ////console.log("id", id,xAxis, yAxis, title);
                            const notes = title // Assuming 'title' from responseData is used as 'notes'
                            const graphType = data[0].type // Example graph type, adjust as per your logic
                            const metric = data[0].metric
                            const heatmapSelection = data[0].heatmapSelection
                            // Call handleAddGraph with extracted parameters
                            const updatedData = await handleAddGraph({
                                id,
                                xAxis,
                                yAxis,
                                notes,
                                graphType,
                                responseData,
                                metric,
                                heatmapSelection,
                            })
                            ////console.log("updatedData", updatedData);
                            newCharts.push({
                                id: id,
                                xAxis: xAxis,
                                yAxis: yAxis,
                                title: title,
                                // title: `${xAxis}-Combined`, // Use a title indicating it's combined
                                data: updatedData,
                            })
                            // Create a single chart with the combined data

                            ////console.log("newCharts", newCharts);
                        } catch (error) {
                            continue
                        }
                    }
                    ////console.log("newCharts", newCharts);
                    const excelresponse = await axios.post(
                        API.UPDATE_GRAPH,
                        {
                            reports_id: cardId,
                            standard_graphs: newCharts,
                            template_id: templateId,
                        },
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    )

                    ////console.log("updated successfully:", excelresponse);
                    AlertDialog({
                        type: 'reports updated',
                        title: 'Success',
                        text: 'Reports updated successfully',
                        confirmButtonText: 'Ok',
                    })
                    // window.alert("Report updated successfully.");
                }

                navigate('/Charts', {
                    state: {
                        actualdata: actualdata,
                        cleanedDataGraph: cleanedDataGraph,
                        template: templateId,
                    },
                })
            } catch (error) {
                ////console.error('An error occurred while inserting chart data:', error);
                window.alert('An error occurred while inserting chart data.')
                // Optionally, handle error state here
            }
        }
    }

    const handleAddGraph = async ({
        id,
        xAxis,
        yAxis,
        notes,
        graphType,
        responseData,
        metric,
        heatmapSelection,
    }) => {
        if (tab === 'Daily Trial Monitoring') {
            ////console.log('metric',metric);
            ////console.log('responseData',responseData);
            ////console.log('graphType',graphType);
            ////console.log('notes',notes);
            ////console.log('xAxis',xAxis);
            // const yAxis = yAxis;
            ////console.log('yAxis',yAxis);
            const newData = groupDataByXAxis(actualdata, xAxis, yAxis)
            const newData2 = groupDataByXAxis1(cleanedDataGraph, xAxis, yAxis)
            ////console.log("actualdata",actualdata);
            ////console.log("xAxis",xAxis);
            ////console.log("yAxis",yAxis);
            //console.log("newData",newData);
            //console.log("newData2",newData2);

            const chartData = []
            // Define your y-axis variables here
            try {
                if (graphType === 'box') {
                    // Assuming yAxis is an array with at least one element
                    const yAxisItem = yAxis.length > 0 ? yAxis[0] : null

                    for (const key in newData) {
                        if (newData.hasOwnProperty(key) && key !== 'undefined') {
                            chartData.push({
                                x: key,
                                y: newData[key][yAxisItem], // Data for each y-axis variable
                                name: key, // Use the key as the name
                                type: 'box',
                            })
                        }
                    }

                    ////console.log('chartData', chartData);
                } else if (
                    graphType === 'bar' ||
                    graphType === 'scatter' ||
                    graphType === 'line' ||
                    graphType === 'horizontal-bar'
                ) {
                    ////console.log("metricmetricmetric", metric);
                    yAxis.forEach((yAxisItem) => {
                        const xValues = []
                        const yValues = []
                        for (const key in newData2) {
                            if (Object.hasOwnProperty.call(newData2, key)) {
                                // xValues.push(key);
                                const values = newData2[key][yAxisItem]
                                ////console.log('values', values);
                                let result
                                switch (metric) {
                                    case 'mean':
                                        result = calculateMean(values)
                                        ////console.log('result', result);
                                        break
                                    case 'first_value':
                                        result = Array.isArray(values) ? values[0] : values // Handle array values
                                        ////console.log('result', result);
                                        break
                                    case 'last_value':
                                        result = Array.isArray(values)
                                            ? values[values.length - 1]
                                            : values // Handle array values
                                        ////console.log('result', result);
                                        break
                                    case 'maximum':
                                        result = Math.max(...values)
                                        ////console.log('result', result);
                                        break
                                    case 'minimum':
                                        result = Math.min(...values)
                                        ////console.log('result', result);
                                        break
                                    case 'total':
                                        result = Array.isArray(values)
                                            ? values.reduce((total, val) => total + val, 0)
                                            : values
                                        ////console.log('result', result);
                                        break
                                    case 'count':
                                        result = Array.isArray(values) ? values.length : 1 // If array, return length; else, return 1
                                        ////console.log('result', result);
                                        break
                                    default:
                                        // Default behavior, in case metric is not recognized
                                        result = calculateMean(values)
                                    ////console.log('result', result);
                                }
                                ////console.log('result', result);
                                // yValues.push(result);
                                if (key !== 'undefined') {
                                    xValues.push(key)
                                    yValues.push(result)
                                }
                            }
                        }
                        ////console.log("xValues",xValues);
                        ////console.log("yValues",yValues);
                        chartData.push({
                            x: graphType === 'horizontal-bar' ? yValues : xValues,
                            y: graphType === 'horizontal-bar' ? xValues : yValues,
                            mode: graphType === 'scatter' ? 'markers' : 'lines',
                            name: yAxisItem,
                            type: graphType === 'horizontal-bar' ? 'bar' : graphType,
                            orientation: graphType === 'horizontal-bar' ? 'h' : undefined,
                            metric: metric,
                        })
                    })
                }
                // Handle stacked-bar chart
                else if (graphType === 'stacked-bar') {
                    const categories = Object.keys(newData2).filter(
                        (category) => category !== 'undefined'
                    )
                    yAxis.forEach((yAxisItem) => {
                        ////console.log("categories",categories);
                        const yValues = categories.map((category) => {
                            const values = newData2[category][yAxisItem]
                            let result
                            switch (metric) {
                                case 'mean':
                                    result = calculateMean(values)
                                    break
                                case 'first_value':
                                    result = Array.isArray(values) ? values[0] : values
                                    break
                                case 'last_value':
                                    result = Array.isArray(values)
                                        ? values[values.length - 1]
                                        : values
                                    break
                                case 'maximum':
                                    result = Math.max(...values)
                                    break
                                case 'minimum':
                                    result = Math.min(...values)
                                    break
                                case 'total':
                                    result = Array.isArray(values)
                                        ? values.reduce((total, val) => total + val, 0)
                                        : values
                                    break
                                case 'count':
                                    result = Array.isArray(values) ? values.length : 1
                                    break
                                default:
                                    result = calculateMean(values)
                            }
                            return result
                        })
                        chartData.push({
                            x: categories,
                            y: yValues,
                            name: yAxisItem,
                            type: 'bar',
                            metric: metric,
                        })
                    })
                } else if (graphType === 'pie') {
                    const categories = Object.keys(newData2).filter(
                        (category) => category !== 'undefined'
                    )
                    const values = categories.map((category) => {
                        let result = 0
                        yAxis.forEach((yAxisItem) => {
                            // Loop through each yAxisItem
                            switch (metric) {
                                case 'mean':
                                    result += calculateMean(newData2[category][yAxisItem])
                                    break
                                case 'first_value':
                                    result += Array.isArray(newData2[category][yAxisItem])
                                        ? newData2[category][yAxisItem][0]
                                        : newData2[category][yAxisItem]
                                    break
                                case 'last_value':
                                    result = Array.isArray(newData2[category][yAxisItem])
                                        ? newData2[category][yAxisItem][
                                        newData2[category][yAxisItem].length - 1
                                        ]
                                        : newData2[category][yAxisItem]
                                    break
                                case 'maximum':
                                    result = Array.isArray(newData2[category][yAxisItem])
                                        ? Math.max(...newData2[category][yAxisItem])
                                        : newData2[category][yAxisItem]
                                    break
                                case 'minimum':
                                    result = Array.isArray(newData2[category][yAxisItem])
                                        ? Math.min(...newData2[category][yAxisItem])
                                        : newData2[category][yAxisItem]
                                    break
                                case 'total':
                                    result = Array.isArray(newData2[category][yAxisItem])
                                        ? newData2[category][yAxisItem].reduce((a, b) => a + b, 0)
                                        : newData2[category][yAxisItem]
                                    break
                                case 'count':
                                    result = Array.isArray(newData2[category][yAxisItem])
                                        ? newData2[category][yAxisItem].length
                                        : 1
                                    break
                                default:
                                    result = calculateMean(newData2[category][yAxisItem])
                            }
                        })
                        return result
                    })

                    // Construct the data for the pie chart
                    const dataForPie = {
                        labels: categories,
                        values: values,
                        type: 'pie',
                        metric: metric,
                    }

                    // Push the dataForPie object into the chartData array
                    chartData.push(dataForPie)
                } else if (graphType === 'heatmap') {
                    try {
                        // ////console.log('xAxis',xAxis);
                        // const yAxis=xAxis;
                        // ////console.log('yAxis',yAxis);
                        ////console.log('graphType',graphType);

                        ////console.log('heatmapSelection', heatmapSelection);
                        // Extract all unique lorp_id values
                        const lorpIds = Array.from(
                            new Set(cleanedDataGraph.map((item) => item.lorp_id))
                        )
                        ////console.log("lorpIds", lorpIds);

                        // Filter out undefined values
                        const validLorpIds = lorpIds.filter((id) => id !== undefined)

                        // Prepare an empty correlation matrix
                        const correlationMatrix = []

                        // Loop through each lorp_id
                        validLorpIds.forEach((lorpId) => {
                            //////console.log("lorpIdslorpIdslorpIds", lorpIds);
                            // Filter data for the current lorp_id
                            const filteredData = cleanedDataGraph.filter(
                                (item) => item.lorp_id === lorpId
                            )
                            ////console.log("filteredData", filteredData);
                            // Calculate correlation coefficient for each yAxis option
                            const correlations = yAxis.map((yAxisItem) => {
                                // Filter actualdata for the current yAxisItem
                                const yAxisData = filteredData.map((item) => item[yAxisItem])
                                //   ////console.log("actualdata type of yAxisData:", typeof yAxisData);
                                ////console.log("yAxisData:", yAxisData);

                                const check = filteredData.map((item) => item[heatmapSelection])
                                ////console.log("check:", check);

                                if (yAxisData.some(isNaN) || check.some(isNaN)) {
                                    return // Skip this lorpId
                                }

                                const correlation = sampleCorrelation(yAxisData, check)
                                ////console.log("correlation", correlation);

                                return correlation
                            })

                            // Push correlation actualdata for the current lorp_id
                            correlationMatrix.push({
                                lorp_id: lorpId,
                                correlations: correlations,
                            })
                        })

                        // Prepare actualdata for the heatmap
                        const heatmapData = {
                            x: yAxis, // The yAxis items will be represented on the x-axis
                            y: correlationMatrix.map((matrix) => matrix.lorp_id),
                            z: correlationMatrix.map((matrix) => matrix.correlations),
                            type: 'heatmap',
                            colorscale: 'Viridis',
                            heatmapSelection: heatmapSelection,
                        }
                        ////console.log("heatmapData", heatmapData);
                        // Push heatmapData to chartData
                        chartData.push(heatmapData)
                    } catch (error) {
                        ////console.error("Error:", error);
                        window.alert(
                            'Error: sampleCovariance requires at least two data points in each sample'
                        )
                    }
                }

                return chartData
            } catch (error) {
                ////console.error('An error occurred while inserting chart data:', error);
                window.alert('An error occurred while inserting chart data.' + error)
                // Optionally, handle error state here
            }
        } else if (tab === 'NRM Performance') {
            ////console.log('metric',metric);
            ////console.log('responseData',responseData);
            ////console.log('graphType',graphType);
            ////console.log('notes',notes);
            ////console.log('xAxis',xAxis);
            // const yAxis = yAxis;
            ////console.log('yAxis',yAxis);
            const newData = groupDataByXAxis(actualdata, xAxis, yAxis)
            const newData2 = groupDataByXAxis1(cleanedDataGraph, xAxis, yAxis)
            ////console.log("actualdata",actualdata);
            ////console.log("xAxis",xAxis);
            ////console.log("yAxis",yAxis);
            ////console.log("newData",newData);

            const chartData = []
            // Define your y-axis variables here
            try {
                if (graphType === 'box') {
                    // Assuming yAxis is an array with at least one element
                    const yAxisItem = yAxis.length > 0 ? yAxis[0] : null

                    for (const key in newData) {
                        if (newData.hasOwnProperty(key) && key !== 'undefined') {
                            chartData.push({
                                x: key,
                                y: newData[key][yAxisItem], // Data for each y-axis variable
                                name: key, // Use the key as the name
                                type: 'box',
                            })
                        }
                    }

                    ////console.log('chartData', chartData);
                } else if (
                    graphType === 'bar' ||
                    graphType === 'scatter' ||
                    graphType === 'line' ||
                    graphType === 'horizontal-bar'
                ) {
                    ////console.log("metricmetricmetric", metric);
                    yAxis.forEach((yAxisItem) => {
                        const xValues = []
                        const yValues = []
                        for (const key in newData2) {
                            if (Object.hasOwnProperty.call(newData2, key)) {
                                // xValues.push(key);
                                const values = newData2[key][yAxisItem]
                                ////console.log('values', values);
                                let result
                                switch (metric) {
                                    case 'mean':
                                        result = calculateMean(values)
                                        ////console.log('result', result);
                                        break
                                    case 'first_value':
                                        result = Array.isArray(values) ? values[0] : values // Handle array values
                                        ////console.log('result', result);
                                        break
                                    case 'last_value':
                                        result = Array.isArray(values)
                                            ? values[values.length - 1]
                                            : values // Handle array values
                                        ////console.log('result', result);
                                        break
                                    case 'maximum':
                                        result = Math.max(...values)
                                        ////console.log('result', result);
                                        break
                                    case 'minimum':
                                        result = Math.min(...values)
                                        ////console.log('result', result);
                                        break
                                    case 'total':
                                        result = Array.isArray(values)
                                            ? values.reduce((total, val) => total + val, 0)
                                            : values
                                        ////console.log('result', result);
                                        break
                                    case 'count':
                                        result = Array.isArray(values) ? values.length : 1 // If array, return length; else, return 1
                                        ////console.log('result', result);
                                        break
                                    default:
                                        // Default behavior, in case metric is not recognized
                                        result = calculateMean(values)
                                    ////console.log('result', result);
                                }
                                ////console.log('result', result);
                                // yValues.push(result);
                                if (key !== 'undefined') {
                                    xValues.push(key)
                                    yValues.push(result)
                                }
                            }
                        }
                        ////console.log("xValues",xValues);
                        ////console.log("yValues",yValues);
                        chartData.push({
                            x: graphType === 'horizontal-bar' ? yValues : xValues,
                            y: graphType === 'horizontal-bar' ? xValues : yValues,
                            mode: graphType === 'scatter' ? 'markers' : 'lines',
                            name: yAxisItem,
                            type: graphType === 'horizontal-bar' ? 'bar' : graphType,
                            orientation: graphType === 'horizontal-bar' ? 'h' : undefined,
                            metric: metric,
                        })
                    })
                }
                // Handle stacked-bar chart
                else if (graphType === 'stacked-bar') {
                    const categories = Object.keys(newData2).filter(
                        (category) => category !== 'undefined'
                    )
                    yAxis.forEach((yAxisItem) => {
                        ////console.log("categories",categories);
                        const yValues = categories.map((category) => {
                            const values = newData2[category][yAxisItem]
                            let result
                            switch (metric) {
                                case 'mean':
                                    result = calculateMean(values)
                                    break
                                case 'first_value':
                                    result = Array.isArray(values) ? values[0] : values
                                    break
                                case 'last_value':
                                    result = Array.isArray(values)
                                        ? values[values.length - 1]
                                        : values
                                    break
                                case 'maximum':
                                    result = Math.max(...values)
                                    break
                                case 'minimum':
                                    result = Math.min(...values)
                                    break
                                case 'total':
                                    result = Array.isArray(values)
                                        ? values.reduce((total, val) => total + val, 0)
                                        : values
                                    break
                                case 'count':
                                    result = Array.isArray(values) ? values.length : 1
                                    break
                                default:
                                    result = calculateMean(values)
                            }
                            return result
                        })
                        chartData.push({
                            x: categories,
                            y: yValues,
                            name: yAxisItem,
                            type: 'bar',
                            metric: metric,
                        })
                    })
                } else if (graphType === 'pie') {
                    const categories = Object.keys(newData2).filter(
                        (category) => category !== 'undefined'
                    )
                    const values = categories.map((category) => {
                        let result = 0
                        yAxis.forEach((yAxisItem) => {
                            // Loop through each yAxisItem
                            switch (metric) {
                                case 'mean':
                                    result += calculateMean(newData2[category][yAxisItem])
                                    break
                                case 'first_value':
                                    result += Array.isArray(newData2[category][yAxisItem])
                                        ? newData2[category][yAxisItem][0]
                                        : newData2[category][yAxisItem]
                                    break
                                case 'last_value':
                                    result = Array.isArray(newData2[category][yAxisItem])
                                        ? newData2[category][yAxisItem][
                                        newData2[category][yAxisItem].length - 1
                                        ]
                                        : newData2[category][yAxisItem]
                                    break
                                case 'maximum':
                                    result = Array.isArray(newData2[category][yAxisItem])
                                        ? Math.max(...newData2[category][yAxisItem])
                                        : newData2[category][yAxisItem]
                                    break
                                case 'minimum':
                                    result = Array.isArray(newData2[category][yAxisItem])
                                        ? Math.min(...newData2[category][yAxisItem])
                                        : newData2[category][yAxisItem]
                                    break
                                case 'total':
                                    result = Array.isArray(newData2[category][yAxisItem])
                                        ? newData2[category][yAxisItem].reduce((a, b) => a + b, 0)
                                        : newData2[category][yAxisItem]
                                    break
                                case 'count':
                                    result = Array.isArray(newData2[category][yAxisItem])
                                        ? newData2[category][yAxisItem].length
                                        : 1
                                    break
                                default:
                                    result = calculateMean(newData2[category][yAxisItem])
                            }
                        })
                        return result
                    })

                    // Construct the data for the pie chart
                    const dataForPie = {
                        labels: categories,
                        values: values,
                        type: 'pie',
                        metric: metric,
                    }

                    // Push the dataForPie object into the chartData array
                    chartData.push(dataForPie)
                } else if (graphType === 'heatmap') {
                    try {
                        // ////console.log('xAxis',xAxis);
                        // const yAxis=xAxis;
                        // ////console.log('yAxis',yAxis);
                        ////console.log('graphType',graphType);

                        ////console.log('heatmapSelection', heatmapSelection);
                        // Extract all unique life values
                        const Lifes = Array.from(
                            new Set(cleanedDataGraph.map((item) => item.life))
                        )
                        ////console.log("Lifes", Lifes);

                        // Filter out undefined values
                        const validLifes = Lifes.filter((id) => id !== undefined)

                        // Prepare an empty correlation matrix
                        const correlationMatrix = []

                        // Loop through each life
                        validLifes.forEach((Life) => {
                            ////console.log("LifesLifesLifes", Lifes);
                            // Filter data for the current life
                            const filteredData = cleanedDataGraph.filter(
                                (item) => item.life === Life
                            )
                            ////console.log("filteredData", filteredData);
                            // Calculate correlation coefficient for each yAxis option
                            const correlations = yAxis.map((yAxisItem) => {
                                // Filter actualdata for the current yAxisItem
                                const yAxisData = filteredData.map((item) => item[yAxisItem])
                                //   ////console.log("actualdata type of yAxisData:", typeof yAxisData);
                                ////console.log("yAxisData:", yAxisData);

                                const check = filteredData.map((item) => item[heatmapSelection])
                                ////console.log("check:", check);

                                if (yAxisData.some(isNaN) || check.some(isNaN)) {
                                    return // Skip this Life
                                }

                                const correlation = sampleCorrelation(yAxisData, check)
                                ////console.log("correlation", correlation);

                                return correlation
                            })

                            // Push correlation actualdata for the current life
                            correlationMatrix.push({
                                life: Life,
                                correlations: correlations,
                            })
                        })

                        // Prepare actualdata for the heatmap
                        const heatmapData = {
                            x: yAxis, // The yAxis items will be represented on the x-axis
                            y: correlationMatrix.map((matrix) => matrix.life),
                            z: correlationMatrix.map((matrix) => matrix.correlations),
                            type: 'heatmap',
                            colorscale: 'Viridis',
                            heatmapSelection: heatmapSelection,
                        }
                        ////console.log("heatmapData", heatmapData);
                        // Push heatmapData to chartData
                        chartData.push(heatmapData)
                    } catch (error) {
                        ////console.error("Error:", error);
                        window.alert(
                            'Error: sampleCovariance requires at least two data points in each sample'
                        )
                    }
                }
            } catch (error) {
                ////console.error('An error occurred while inserting chart data:', error);
                window.alert('An error occurred while inserting chart data.' + error)
                // Optionally, handle error state here
            }

            return chartData
        }
    }

    const groupDataByXAxis = (actualdata, xAxis, yAxis) => {
        const groupedData = {}

        actualdata.forEach((item) => {
            const xValue = item[xAxis]
            if (!groupedData[xValue]) {
                groupedData[xValue] = {}
            }
            yAxis.forEach((axis) => {
                if (!groupedData[xValue][axis]) {
                    groupedData[xValue][axis] = []
                }
                if (item[axis] !== undefined) {
                    groupedData[xValue][axis].push(item[axis])
                }
            })
        })

        ////console.log("groupedData", groupedData);
        return groupedData
    }

    const groupDataByXAxis1 = (cleanedDataGraph, xAxis, yAxis) => {
        const groupedData = {}

        cleanedDataGraph.forEach((item) => {
            const xValue = item[xAxis]
            if (!groupedData[xValue]) {
                groupedData[xValue] = {}
            }
            yAxis.forEach((axis) => {
                if (!groupedData[xValue][axis]) {
                    groupedData[xValue][axis] = []
                }
                if (item[axis] !== undefined) {
                    groupedData[xValue][axis].push(item[axis])
                }
            })
        })

        //console.log("groupedData", groupedData);
        return groupedData
    }

    function calculateMean(array) {
        return array.reduce((total, val) => total + val, 0) / array.length
    }

    useEffect(() => {
        const fetchData = async () => {
            //console.log("good");
            try {
                const rowIdsString = localStorage.getItem('rowIdsString')
                if (!rowIdsString) {
                    ////console.log("rowIdsString is undefined");
                    return
                }

                // const rowIdsString = JSON.parse(storedRowIdsString);
                ////console.log("rowIdsString", rowIdsString);
                // Split the rowIdsString into an array of IDs
                const idsArray = rowIdsString.split(',')
                ////console.log("idsArray", idsArray);

                if (tab === 'Daily Trial Monitoring') {
                    let generalDetailsArray = []
                    let furnaceDetailsArray = []
                    let carbonArray = []
                    let alloyingAdditionArray = []
                    let otherParametersArray = []
                    let chargeMixDetailsArray = []

                    for (const id of idsArray) {
                        const check = `${API.CHECK_ID_EXIST}/${id}`
                        const checkresponse = await axios.get(check)
                        console.log("checkresponse", checkresponse.data);
                        if (
                            checkresponse.data.length > 0 &&
                            checkresponse.data[0].case === 'Exists'
                        ) {
                            const trimmedId = id.trim() // Trim any extra spaces around the ID
                            const url = `${API.VIEW_COMPLETE_DATA}/${trimmedId}`
                            console.log("url", url);
                            const response = await axios.get(url)
                            console.log("viewtrial", response.data);
                            // Append data to respective arrays for each detail type
                            generalDetailsArray.push(...response.data[0].general_details)
                            furnaceDetailsArray.push(...response.data[0].furnace_details)
                            carbonArray.push(...response.data[0].carbon)
                            alloyingAdditionArray.push(...response.data[0].alloying_addition)
                            otherParametersArray.push(...response.data[0].other_parameters)
                            chargeMixDetailsArray.push(...response.data[0].charge_mix_details)

                            // Append data to arrays for other details as needed
                        } else {
                            console.log("ID does not exist");
                            const url = `${API.DATA_SEARCH_LIST}/${id}`
                            const response = await axios.get(url)
                            console.log("setData", response.data);

                            const jsonData = {
                                id: id,
                                fromdate: response.data.from_date,
                                todate: response.data.to_date,
                                grade: response.data.grade,
                                liningPatching: response.data.lining_patching,
                                minYield: response.data.min_yield,
                                maxYield: response.data.max_yield,
                                minTappedTonnage: response.data.min_tapped_tonnage,
                                maxTappedTonnage: response.data.max_tapped_tonnage,
                            }
                            console.log("jsonData", jsonData);
                            const result = await axios.post(API.DATA_SEARCH_VIEW, jsonData)
                            console.log("result", result.data);

                            generalDetailsArray.push(...result.data[0].general_details)
                            furnaceDetailsArray.push(...result.data[0].furnace_details)
                            carbonArray.push(...result.data[0].carbon)
                            alloyingAdditionArray.push(...result.data[0].alloying_addition)
                            otherParametersArray.push(...result.data[0].other_parameters)
                            chargeMixDetailsArray.push(...result.data[0].charge_mix_details)
                        }
                    }
                    // ////console.log("generalDetailsArray", generalDetailsArray);
                    // Update state with the arrays
                    setGeneralDetailsArray(generalDetailsArray)
                    setFurnaceDetailsArray(furnaceDetailsArray)
                    setCarbonArray(carbonArray)
                    setAlloyingAdditionArray(alloyingAdditionArray)
                    setOtherParametersArray(otherParametersArray)
                    setChargeMixDetailsArray(chargeMixDetailsArray)

                    // Update state with arrays for other details as needed
                } else if (tab === 'NRM Performance') {
                    //console.log("good");
                    let liningReferenceArray = []
                    let productionSummaryArray = []
                    let liningMaterialProductDetailsArray = []
                    let liningMaterialConsumptionArray = []
                    let dimensionalParametersArray = []
                    let sinteringParametersArray = []

                    for (const id of idsArray) {
                        const check = `${API.CHECK_ID_EXIST_NRM}/${id}`
                        const checkresponse = await axios.get(check)
                        ////console.log("checkresponse", checkresponse.data);
                        if (
                            checkresponse.data.length > 0 &&
                            checkresponse.data[0].case === 'Exists'
                        ) {
                            const trimmedId = id.trim() // Trim any extra spaces around the ID
                            const url = `${API.VIEW_COMPLETE_DATA_NRM}/${trimmedId}`
                            ////console.log("url", url);
                            const response = await axios.get(url)
                            // ////console.log("viewtrial", response.data);
                            // Append data to respective arrays for each detail type
                            liningReferenceArray.push(...response.data[0].lining_reference)
                            productionSummaryArray.push(
                                ...response.data[0].production_summary
                            )
                            liningMaterialProductDetailsArray.push(
                                ...response.data[0].lining_material_product_details
                            )
                            liningMaterialConsumptionArray.push(
                                ...response.data[0].lining_material_consumption
                            )
                            dimensionalParametersArray.push(
                                ...response.data[0].dimensional_parameters
                            )
                            sinteringParametersArray.push(
                                ...response.data[0].sintering_parameters
                            )

                            // Append data to arrays for other details as needed
                        } else {
                            ////console.log("ID does not exist");
                            const url = `${API.DATA_SEARCH_LIST_NRM}/${id}`
                            const response = await axios.get(url)
                            ////console.log("setData",response.data);

                            const jsonData = {
                                id: id,
                                fromdate: response.data.from_date,
                                todate: response.data.to_date,
                                crucible: response.data.crucible,
                                liningPatching: response.data.lining_patching,
                            }
                            ////console.log("jsonData", jsonData);
                            const result = await axios.post(
                                API.DATA_SEARCH_VIEW_NRM,
                                jsonData
                            )
                            // ////console.log("result", result.data);

                            liningReferenceArray.push(...result.data[0].lining_reference)
                            productionSummaryArray.push(...result.data[0].production_summary)
                            liningMaterialProductDetailsArray.push(
                                ...result.data[0].lining_material_product_details
                            )
                            liningMaterialConsumptionArray.push(
                                ...result.data[0].lining_material_consumption
                            )
                            dimensionalParametersArray.push(
                                ...result.data[0].dimensional_parameters
                            )
                            sinteringParametersArray.push(
                                ...result.data[0].sintering_parameters
                            )
                        }
                    }
                    // ////console.log("generalDetailsArray", generalDetailsArray);
                    // Update state with the arrays
                    setLiningReferenceArray(liningReferenceArray)
                    setProductionSummaryArray(productionSummaryArray)
                    setLiningMaterialProductDetailsArray(
                        liningMaterialProductDetailsArray
                    )
                    setLiningMaterialConsumptionArray(liningMaterialConsumptionArray)
                    setDimensionalParametersArray(dimensionalParametersArray)
                    setSinteringParametersArray(sinteringParametersArray)
                } else {
                    return null
                }
            } catch (error) {
                //console.error('Error fetching data:', error);
            }
        }

        fetchData() // Call the fetchData function
    }, [rowIdsString])

    const handleDownload = () => {
        const excelData = JSON.parse(localStorage.getItem('setExcelData'))
        const ws = XLSX.utils.json_to_sheet(excelData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
        XLSX.writeFile(wb, 'table_data.xlsx')
    }

    // useEffect(() => {
    //     if (segment.length > 0) {
    //         SetSegment(segmentdata[0]?.ud_segment);
    //     }
    // }, [segmentdata]);

    const TableA = () => {
        const validateFurnaceNo = (value) => {
            return value >= 1 && value <= 20
        }

        const validateCrucibleNo = (value) => {
            return /[A-E]/.test(value)
        }

        const validateCampaignNo = (value) => {
            return value >= 1 && value <= 100
        }

        const validateLiningPatchingNo = (value) => {
            const validValues = [
                'New Lining',
                'Side Lining 1',
                'Side Lining 2',
                'Side Lining 3',
                'Side Lining 4',
                'Side Lining 5',
                'Side Lining 6',
                'Side Lining 7',
                'Side Lining 8',
                'Side Lining 9',
                'Side Lining 10',
                'Side Lining 11',
                'Side Lining 12',
                'Side Lining 13',
                'Side Lining 14',
                'Side Lining 15',
                'Patching 1',
                'Patching 2',
                'Patching 3',
                'Patching 4',
                'Patching 5',
                'Patching 6',
                'Patching 7',
                'Patching 8',
                'Patching 9',
                'Patching 10',
                'Patching 11',
                'Patching 12',
                'Patching 13',
                'Patching 14',
                'Patching 15',
                'Patching 16',
                'Patching 17',
                'Patching 18',
                'Patching 19',
                'Patching 20',
                'Patching 21',
                'Patching 22',
                'Patching 23',
                'Patching 24',
                'Patching 25',
            ]
            return validValues.includes(value)
        }

        const validateHeatNo = (value) => {
            return !isNaN(value) // Check if it's a valid number
        }

        const validateDate = (value) => {
            const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/
            return regex.test(value)
        }
        let counter = 1

        let newData = []

        // Assuming generalDetailsArray and furnaceDetailsArray are defined somewhere
        generalDetailsArray.forEach((detail, index) => {
            if (
                Object.keys(detail).length > 1 ||
                (Object.keys(detail).length === 1 && Object.keys(detail)[0] !== 'id')
            ) {
                // Process each row's data and add to newData array
                let rowData = {
                    sno: index + 1,
                    furnaceNo: detail['furnace_no'],
                    crucibleNo: detail['crucible_no'],
                    campaignNo: detail['campaign_no'],
                    liningPatching: detail['lining_patch_no'],
                    heatNo: detail['heat_no'],
                    date: detail['date'],
                    customerRefNo: detail['customer_ref_no'],
                    grade: detail['grade'],
                    // Add other properties as needed
                }

                // Assuming furnaceDetailsArray is available and has corresponding data
                if (furnaceDetailsArray[index]) {
                    rowData.heatOnTime = furnaceDetailsArray[index]['heat_on_time']
                        .toString()
                        .trim()
                    rowData.heatTapTime = furnaceDetailsArray[index]['heat_tap_time']
                        .toString()
                        .trim()
                    rowData.breakDownDuration = furnaceDetailsArray[index][
                        'break_down_duration'
                    ]
                        .toString()
                        .trim()
                    rowData.heatDurationActual = furnaceDetailsArray[index][
                        'heat_duration_actual'
                    ]
                        .toString()
                        .trim()
                    rowData.heatDurationCalculated = furnaceDetailsArray[index][
                        'heat_duration_calculated'
                    ]
                        .toString()
                        .trim()
                    rowData.tappingTemperature =
                        furnaceDetailsArray[index]['tapping_temperature']
                    rowData.tappedTonnage = furnaceDetailsArray[index]['tapped_tonnage']
                    rowData.meltRateBasedOnActualDuration =
                        furnaceDetailsArray[index]['melt_rate_based_on_actual_duration']
                    rowData.meltRateBasedOnCalculatedDuration =
                        furnaceDetailsArray[index]['melt_rate_based_on_calculated_duration']
                    rowData.frequency = furnaceDetailsArray[index]['frequency']
                    rowData.voltage = furnaceDetailsArray[index]['voltage']
                    rowData.powerInThePanel =
                        furnaceDetailsArray[index]['power_in_the_panel']
                    rowData.capacitorsRemoved =
                        furnaceDetailsArray[index]['capacitors_removed']
                    rowData.totalPowerConsumed =
                        furnaceDetailsArray[index]['total_power_consumed']
                    rowData.energyConsumptionPerUnitTonnActual =
                        furnaceDetailsArray[index]['energy_consumption_per_unit_ton_actual']
                    rowData.energyConsumptionPerUnitTonnCalculated =
                        furnaceDetailsArray[index][
                        'energy_consumption_per_unit_ton_calculated'
                        ]
                }

                if (chargeMixDetailsArray[index]) {
                    rowData.scrap = chargeMixDetailsArray[index]['scrap']
                    rowData.shredded = chargeMixDetailsArray[index]['shredded']
                    rowData.pigIron = chargeMixDetailsArray[index]['pig_iron']
                    rowData.pooledIron = chargeMixDetailsArray[index]['pooled_iron']
                    rowData.hotHeel = chargeMixDetailsArray[index]['hot_heel']
                    rowData.lump = chargeMixDetailsArray[index]['lump']
                    rowData.pellet = chargeMixDetailsArray[index]['pellet']
                    rowData.finesLessThan5mm =
                        chargeMixDetailsArray[index]['fines_lessthan_5mm']
                    rowData.finesLessThan1mm =
                        chargeMixDetailsArray[index]['fines_lessthan_1mm']
                    rowData.slagCrushers = chargeMixDetailsArray[index]['slag_crushers']
                    rowData.tundishOrSlagOrMetalicJam =
                        chargeMixDetailsArray[index]['tundish_or_slag_or_metalic_jam']
                    rowData.totalCharge = chargeMixDetailsArray[index]['total_charge']
                }

                if (carbonArray[index]) {
                    rowData.cpc = carbonArray[index]['cpc']
                    rowData.metCoke = carbonArray[index]['met_coke']
                    rowData.fiftyC = carbonArray[index]['fifty_c_percentage']
                    rowData.eightyfiveC = carbonArray[index]['eightyfive_c_percentage']
                    rowData.finalC = carbonArray[index]['final_c_percentage']
                }

                if (alloyingAdditionArray[index]) {
                    rowData.siBeforeAlloyAddition =
                        alloyingAdditionArray[index]['si_before_alloy_addition']
                    rowData.finalSi = alloyingAdditionArray[index]['final_si']
                    rowData.mnBeforeAlloyAddition =
                        alloyingAdditionArray[index]['mn_before_alloy_addition']
                    rowData.finalMn = alloyingAdditionArray[index]['final_mn']
                    rowData.crBeforeAlloyAddition =
                        alloyingAdditionArray[index]['cr_before_alloy_addition']
                    rowData.finalCr = alloyingAdditionArray[index]['final_cr']
                    rowData.siMn = alloyingAdditionArray[index]['si_mn']
                    rowData.MnInSimn = alloyingAdditionArray[index]['mn_in_simn']
                    rowData.feMn = alloyingAdditionArray[index]['feMn']
                    rowData.feSi = alloyingAdditionArray[index]['feSi']
                    rowData.feCr = alloyingAdditionArray[index]['feCr']
                    rowData.millScale = alloyingAdditionArray[index]['mill_scale']
                    rowData.anyOtherAlloyAddition =
                        alloyingAdditionArray[index]['any_other_alloy_addition']
                }

                if (otherParametersArray[index]) {
                    rowData.inletWaterTemp =
                        otherParametersArray[index]['inlet_water_temp']
                    rowData.outletWaterTemp =
                        otherParametersArray[index]['outlet_water_temp']
                    rowData.waterPr = otherParametersArray[index]['water_pr']
                    rowData.remarks = otherParametersArray[index]['remarks']
                }

                newData.push(rowData)
            }
        })

        localStorage.setItem('setExcelData', JSON.stringify(newData))

        // Update the state variable with newData
        // setExcelData(newData);

        return (
            <div>
                <div className="overflow-auto">
                    <table className="whitespace-nowrap min-w-full border-collapse border border-gray-300">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">S.No</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Furnace NO.</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Crucible NO.</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Campaign NO.</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Lining / Patching</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Heat No</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Date</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Customer Ref No</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Grade</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Heat ON Time</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Heat TAP Time</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Breakdown duration</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Heat Duration actual</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Heat Duration calculated</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Tapping temperature</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Tapped tonnage</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Melt Rate (actual)</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Melt Rate (calculated)</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Frequency</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Voltage</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Power In the panel</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Capacitors Removed</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Total Power Consumed</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Energy/Unit Ton (actual)</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Energy/Unit Ton (calculated)</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Scrap</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Shredded</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Pig Iron</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Pooled Iron</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Hot Heel</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Lump</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Pellet</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Fines (5mm)</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Fines (1mm)</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Slag Crushers</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Tundish/Slag Jam</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Total Charge</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">CPC</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Met Coke</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">50 C%</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">85 C%</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Final C%</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Si% (Before)</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Final Si%</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Mn% (Before)</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Final Mn%</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Cr% (Before)</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Final Cr%</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">SiMn</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Mn% in SiMn</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">FeMn</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">FeSi</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">FeCr</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Mill Scale</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Other Alloy</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Inlet Water Temp.</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Outlet Water Temp.</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Water Pr.</th>
                                <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {generalDetailsArray.map((detail, index) => {
                                if (
                                    Object.keys(detail).length > 1 ||
                                    (Object.keys(detail).length === 1 && Object.keys(detail)[0] !== 'id')
                                ) {
                                    return (
                                        <tr key={index} className="border-b border-gray-200">
                                            <td
                                                className="border border-gray-300 px-4 py-2 text-xs "
                                                style={{ width: '20px' }}
                                            >
                                                {counter++}
                                            </td>
                                            <td
                                                className={`border border-gray-300 px-4 py-2 text-xs  ${!validateFurnaceNo(detail['furnace_no']) ? 'border-2 border-red-400' : ''
                                                    }`}
                                            >
                                                {detail['furnace_no']}
                                            </td>
                                            <td
                                                className={`border border-gray-300 px-4 py-2 text-xs  ${!validateCrucibleNo(detail['crucible_no']) ? 'border-2 border-red-400' : ''
                                                    }`}
                                            >
                                                {detail['crucible_no']}
                                            </td>
                                            <td
                                                className={`border border-gray-300 px-4 py-2 text-xs  ${!validateCampaignNo(detail['campaign_no']) ? 'border-2 border-red-400' : ''
                                                    }`}
                                            >
                                                {detail['campaign_no']}
                                            </td>
                                            <td
                                                className={`border border-gray-300 px-4 py-2 text-xs  ${!validateLiningPatchingNo(detail['lining_patch_no'])
                                                    ? 'border-2 border-red-400'
                                                    : ''
                                                    }`}
                                            >
                                                {detail['lining_patch_no']}
                                            </td>
                                            <td
                                                className={`border border-gray-300 px-4 py-2 text-xs  ${!validateHeatNo(detail['heat_no']) ? 'border-2 border-red-400' : ''
                                                    }`}
                                            >
                                                {detail['heat_no']}
                                            </td>
                                            <td
                                                className={`border border-gray-300 px-4 py-2 text-xs  ${!validateDate(detail['date']) ? 'border-2 border-red-400' : ''
                                                    }`}
                                            >
                                                {detail['date']}
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2 text-xs">{detail['customer_ref_no']}</td>
                                            <td className="border border-gray-300 px-4 py-2 text-xs">{detail['grade']}</td>


                                            {furnaceDetailsArray[index] && (
                                                <>
                                                    <td
                                                        className={`border border-gray-300 px-4 py-2 text-xs ${(() => {
                                                            const timeString =
                                                                furnaceDetailsArray[index]["heat_on_time"]
                                                                    ? furnaceDetailsArray[index]["heat_on_time"].toString().trim()
                                                                    : "";
                                                            const [hours, minutes] = timeString.split(":").map(Number);
                                                            if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
                                                                return "border";
                                                            } else {
                                                                return "border-2 border-lightcoral";
                                                            }
                                                        })()
                                                            }`}
                                                    >
                                                        {furnaceDetailsArray[index]["heat_on_time"] === "NaN"
                                                            ? ""
                                                            : furnaceDetailsArray[index]["heat_on_time"]}
                                                    </td>

                                                    <td
                                                        className={`border border-gray-300 px-4 py-2 text-xs ${(() => {
                                                            const timeString =
                                                                furnaceDetailsArray[index]["heat_tap_time"]
                                                                    ? furnaceDetailsArray[index]["heat_tap_time"].toString().trim()
                                                                    : "";
                                                            const [hours, minutes] = timeString.split(":").map(Number);
                                                            if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
                                                                return "border";
                                                            } else {
                                                                return "border-2 border-lightcoral";
                                                            }
                                                        })()
                                                            }`}
                                                    >
                                                        {furnaceDetailsArray[index]["heat_tap_time"] === "NaN"
                                                            ? ""
                                                            : furnaceDetailsArray[index]["heat_tap_time"]}
                                                    </td>

                                                    <td
                                                        className={`border border-gray-300 px-4 py-2 text-xs ${(() => {
                                                            const timeString =
                                                                furnaceDetailsArray[index]["break_down_duration"]
                                                                    ? furnaceDetailsArray[index]["break_down_duration"].toString().trim()
                                                                    : "";
                                                            const [hours, minutes] = timeString.split(":").map(Number);
                                                            if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
                                                                return "border";
                                                            } else {
                                                                return "border-2 border-lightcoral";
                                                            }
                                                        })()
                                                            }`}
                                                    >
                                                        {furnaceDetailsArray[index]["break_down_duration"] === "NaN"
                                                            ? ""
                                                            : furnaceDetailsArray[index]["break_down_duration"]}
                                                    </td>

                                                    <td
                                                        className={`border border-gray-300 px-4 py-2 text-xs ${(() => {
                                                            const timeString =
                                                                furnaceDetailsArray[index]["heat_duration_actual"]
                                                                    ? furnaceDetailsArray[index]["heat_duration_actual"].toString().trim()
                                                                    : "";
                                                            const [hours, minutes] = timeString.split(":").map(Number);
                                                            if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
                                                                return "border";
                                                            } else {
                                                                return "border-2 border-lightcoral";
                                                            }
                                                        })()
                                                            }`}
                                                    >
                                                        {furnaceDetailsArray[index]["heat_duration_actual"] === "NaN"
                                                            ? ""
                                                            : furnaceDetailsArray[index]["heat_duration_actual"]}
                                                    </td>

                                                    <td
                                                        className={`border border-gray-300 px-4 py-2 text-xs ${(() => {
                                                            const timeString =
                                                                furnaceDetailsArray[index]["heat_duration_calculated"]
                                                                    ? furnaceDetailsArray[index]["heat_duration_calculated"].toString().trim()
                                                                    : "";
                                                            const [hours, minutes] = timeString.split(":").map(Number);
                                                            if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
                                                                return "border";
                                                            } else {
                                                                return "border-2 border-lightcoral";
                                                            }
                                                        })()
                                                            }`}
                                                    >
                                                        {furnaceDetailsArray[index]["heat_duration_calculated"] === "NaN"
                                                            ? ""
                                                            : furnaceDetailsArray[index]["heat_duration_calculated"]}
                                                    </td>

                                                    <td
                                                        className={`border border-gray-300 px-4 py-2 text-xs ${(() => {
                                                            const value = furnaceDetailsArray[index]["tapping_temperature"];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            if (regex.test(value)) {
                                                                return "border";
                                                            } else {
                                                                return "border-2 border-lightcoral";
                                                            }
                                                        })()
                                                            }`}
                                                    >
                                                        {furnaceDetailsArray[index]["tapping_temperature"] === "NaN"
                                                            ? ""
                                                            : furnaceDetailsArray[index]["tapping_temperature"]}
                                                    </td>

                                                    <td
                                                        className={`border border-gray-300 px-4 py-2 text-xs ${(() => {
                                                            const value = furnaceDetailsArray[index]["tapped_tonnage"];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            if (regex.test(value)) {
                                                                return "border";
                                                            } else {
                                                                return "border-2 border-lightcoral";
                                                            }
                                                        })()
                                                            }`}
                                                    >
                                                        {furnaceDetailsArray[index]["tapped_tonnage"] === "NaN"
                                                            ? ""
                                                            : furnaceDetailsArray[index]["tapped_tonnage"]}
                                                    </td>

                                                    <td
                                                        className={`border border-gray-300 px-4 py-2 text-xs ${(() => {
                                                            const value =
                                                                furnaceDetailsArray[index]["melt_rate_based_on_actual_duration"];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            if (regex.test(value)) {
                                                                return "border";
                                                            } else {
                                                                return "border-2 border-lightcoral";
                                                            }
                                                        })()
                                                            }`}
                                                    >
                                                        {furnaceDetailsArray[index]["melt_rate_based_on_actual_duration"] === "NaN"
                                                            ? ""
                                                            : furnaceDetailsArray[index]["melt_rate_based_on_actual_duration"]}
                                                    </td>

                                                    <td
                                                        className={`border border-gray-300 px-4 py-2 text-xs ${(() => {
                                                            const value =
                                                                furnaceDetailsArray[index]["melt_rate_based_on_calculated_duration"];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            if (regex.test(value)) {
                                                                return "border";
                                                            } else {
                                                                return "border-2 border-lightcoral";
                                                            }
                                                        })()
                                                            }`}
                                                    >
                                                        {furnaceDetailsArray[index]["melt_rate_based_on_calculated_duration"] ===
                                                            "NaN"
                                                            ? ""
                                                            : furnaceDetailsArray[index]["melt_rate_based_on_calculated_duration"]}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = furnaceDetailsArray[index]['frequency'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs'; // Tailwind for border
                                                        })()}
                                                    >
                                                        {furnaceDetailsArray[index]['frequency'] === 'NaN'
                                                            ? ''
                                                            : furnaceDetailsArray[index]['frequency']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = furnaceDetailsArray[index]['voltage'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {furnaceDetailsArray[index]['voltage'] === 'NaN'
                                                            ? ''
                                                            : furnaceDetailsArray[index]['voltage']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = furnaceDetailsArray[index]['power_in_the_panel'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {furnaceDetailsArray[index]['power_in_the_panel'] === 'NaN'
                                                            ? ''
                                                            : furnaceDetailsArray[index]['power_in_the_panel']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = furnaceDetailsArray[index]['capacitors_removed'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {furnaceDetailsArray[index]['capacitors_removed'] === 'NaN'
                                                            ? ''
                                                            : furnaceDetailsArray[index]['capacitors_removed']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = furnaceDetailsArray[index]['total_power_consumed'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {furnaceDetailsArray[index]['total_power_consumed'] === 'NaN'
                                                            ? ''
                                                            : furnaceDetailsArray[index]['total_power_consumed']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = furnaceDetailsArray[index]['energy_consumption_per_unit_ton_actual'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {furnaceDetailsArray[index]['energy_consumption_per_unit_ton_actual'] === 'NaN'
                                                            ? ''
                                                            : furnaceDetailsArray[index]['energy_consumption_per_unit_ton_actual']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = furnaceDetailsArray[index]['energy_consumption_per_unit_ton_calculated'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {furnaceDetailsArray[index]['energy_consumption_per_unit_ton_calculated'] === 'NaN'
                                                            ? ''
                                                            : furnaceDetailsArray[index]['energy_consumption_per_unit_ton_calculated']}
                                                    </td>

                                                </>
                                            )}
                                            {chargeMixDetailsArray[index] && (
                                                <>
                                                    <td
                                                        className={(() => {
                                                            const value = chargeMixDetailsArray[index]['scrap'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs'; // Tailwind for border
                                                        })()}
                                                    >
                                                        {chargeMixDetailsArray[index]['scrap'] === 'NaN'
                                                            ? ''
                                                            : chargeMixDetailsArray[index]['scrap']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = chargeMixDetailsArray[index]['shredded'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {chargeMixDetailsArray[index]['shredded'] === 'NaN'
                                                            ? ''
                                                            : chargeMixDetailsArray[index]['shredded']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = chargeMixDetailsArray[index]['pig_iron'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {chargeMixDetailsArray[index]['pig_iron'] === 'NaN'
                                                            ? ''
                                                            : chargeMixDetailsArray[index]['pig_iron']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = chargeMixDetailsArray[index]['pooled_iron'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {chargeMixDetailsArray[index]['pooled_iron'] === 'NaN'
                                                            ? ''
                                                            : chargeMixDetailsArray[index]['pooled_iron']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = chargeMixDetailsArray[index]['hot_heel'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {chargeMixDetailsArray[index]['hot_heel'] === 'NaN'
                                                            ? ''
                                                            : chargeMixDetailsArray[index]['hot_heel']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = chargeMixDetailsArray[index]['lump'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {chargeMixDetailsArray[index]['lump'] === 'NaN'
                                                            ? ''
                                                            : chargeMixDetailsArray[index]['lump']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = chargeMixDetailsArray[index]['pellet'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {chargeMixDetailsArray[index]['pellet'] === 'NaN'
                                                            ? ''
                                                            : chargeMixDetailsArray[index]['pellet']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = chargeMixDetailsArray[index]['fines_lessthan_5mm'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {chargeMixDetailsArray[index]['fines_lessthan_5mm'] === 'NaN'
                                                            ? ''
                                                            : chargeMixDetailsArray[index]['fines_lessthan_5mm']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = chargeMixDetailsArray[index]['fines_lessthan_1mm'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {chargeMixDetailsArray[index]['fines_lessthan_1mm'] === 'NaN'
                                                            ? ''
                                                            : chargeMixDetailsArray[index]['fines_lessthan_1mm']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = chargeMixDetailsArray[index]['slag_crushers'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {chargeMixDetailsArray[index]['slag_crushers'] === 'NaN'
                                                            ? ''
                                                            : chargeMixDetailsArray[index]['slag_crushers']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = chargeMixDetailsArray[index]['tundish_or_slag_or_metalic_jam'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {chargeMixDetailsArray[index]['tundish_or_slag_or_metalic_jam'] === 'NaN'
                                                            ? ''
                                                            : chargeMixDetailsArray[index]['tundish_or_slag_or_metalic_jam']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = chargeMixDetailsArray[index]['total_charge'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {chargeMixDetailsArray[index]['total_charge'] === 'NaN'
                                                            ? ''
                                                            : chargeMixDetailsArray[index]['total_charge']}
                                                    </td>
                                                </>
                                            )}

                                            {carbonArray[index] && (
                                                <>
                                                    <td
                                                        className={(() => {
                                                            const value = carbonArray[index]['cpc'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs'; // Tailwind for border
                                                        })()}
                                                    >
                                                        {carbonArray[index]['cpc'] === 'NaN'
                                                            ? ''
                                                            : carbonArray[index]['cpc']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = carbonArray[index]['met_coke'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {carbonArray[index]['met_coke'] === 'NaN'
                                                            ? ''
                                                            : carbonArray[index]['met_coke']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = carbonArray[index]['fifty_c_percentage'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {carbonArray[index]['fifty_c_percentage'] === 'NaN'
                                                            ? ''
                                                            : carbonArray[index]['fifty_c_percentage']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = carbonArray[index]['eightyfive_c_percentage'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {carbonArray[index]['eightyfive_c_percentage'] === 'NaN'
                                                            ? ''
                                                            : carbonArray[index]['eightyfive_c_percentage']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = carbonArray[index]['final_c_percentage'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {carbonArray[index]['final_c_percentage'] === 'NaN'
                                                            ? ''
                                                            : carbonArray[index]['final_c_percentage']}
                                                    </td>
                                                </>
                                            )}

                                            {alloyingAdditionArray[index] && (
                                                <>
                                                    <td
                                                        className={(() => {
                                                            const value = alloyingAdditionArray[index]['si_before_alloy_addition'];
                                                            const regex = /^\d{1,5}\.\d{2}%?$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs'; // Tailwind for border
                                                        })()}
                                                    >
                                                        {alloyingAdditionArray[index]['si_before_alloy_addition'] === 'NaN%'
                                                            ? ''
                                                            : alloyingAdditionArray[index]['si_before_alloy_addition']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = alloyingAdditionArray[index]['final_si'];
                                                            const regex = /^\d{1,5}\.\d{2}%?$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {alloyingAdditionArray[index]['final_si'] === 'NaN%'
                                                            ? ''
                                                            : alloyingAdditionArray[index]['final_si']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = alloyingAdditionArray[index]['mn_before_alloy_addition'];
                                                            const regex = /^\d{1,5}\.\d{2}%?$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {alloyingAdditionArray[index]['mn_before_alloy_addition'] === 'NaN%'
                                                            ? ''
                                                            : alloyingAdditionArray[index]['mn_before_alloy_addition']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = alloyingAdditionArray[index]['final_mn'];
                                                            const regex = /^\d{1,5}\.\d{2}%?$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {alloyingAdditionArray[index]['final_mn'] === 'NaN%'
                                                            ? ''
                                                            : alloyingAdditionArray[index]['final_mn']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = alloyingAdditionArray[index]['cr_before_alloy_addition'];
                                                            const regex = /^\d{1,5}\.\d{2}%?$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {alloyingAdditionArray[index]['cr_before_alloy_addition'] === 'NaN%'
                                                            ? ''
                                                            : alloyingAdditionArray[index]['cr_before_alloy_addition']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = alloyingAdditionArray[index]['final_cr'];
                                                            const regex = /^\d{1,5}\.\d{2}%?$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {alloyingAdditionArray[index]['final_cr'] === 'NaN%'
                                                            ? ''
                                                            : alloyingAdditionArray[index]['final_cr']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = alloyingAdditionArray[index]['si_mn'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {alloyingAdditionArray[index]['si_mn'] === 'NaN'
                                                            ? ''
                                                            : alloyingAdditionArray[index]['si_mn']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = alloyingAdditionArray[index]['mn_in_simn'];
                                                            const regex = /^\d{1,5}\.\d{2}%?$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {alloyingAdditionArray[index]['mn_in_simn'] === 'NaN%'
                                                            ? ''
                                                            : alloyingAdditionArray[index]['mn_in_simn']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = alloyingAdditionArray[index]['feMn'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {alloyingAdditionArray[index]['feMn'] === 'NaN'
                                                            ? ''
                                                            : alloyingAdditionArray[index]['feMn']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = alloyingAdditionArray[index]['feSi'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {alloyingAdditionArray[index]['feSi'] === 'NaN'
                                                            ? ''
                                                            : alloyingAdditionArray[index]['feSi']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = alloyingAdditionArray[index]['feCr'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {alloyingAdditionArray[index]['feCr'] === 'NaN'
                                                            ? ''
                                                            : alloyingAdditionArray[index]['feCr']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = alloyingAdditionArray[index]['mill_scale'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {alloyingAdditionArray[index]['mill_scale'] === 'NaN'
                                                            ? ''
                                                            : alloyingAdditionArray[index]['mill_scale']}
                                                    </td>

                                                    <td className='border border-gray-300 px-4 py-2 text-xs'>
                                                        {alloyingAdditionArray[index]['any_other_alloy_addition'] === 'NaN'
                                                            ? ''
                                                            : alloyingAdditionArray[index]['any_other_alloy_addition']}
                                                    </td>
                                                </>
                                            )}

                                            {otherParametersArray[index] && (
                                                <>
                                                    <td
                                                        className={(() => {
                                                            const value = otherParametersArray[index]['inlet_water_temp'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs'; // Tailwind for border
                                                        })()}
                                                    >
                                                        {otherParametersArray[index]['inlet_water_temp'] === 'NaN'
                                                            ? ''
                                                            : otherParametersArray[index]['inlet_water_temp']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = otherParametersArray[index]['outlet_water_temp'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {otherParametersArray[index]['outlet_water_temp'] === 'NaN'
                                                            ? ''
                                                            : otherParametersArray[index]['outlet_water_temp']}
                                                    </td>

                                                    <td
                                                        className={(() => {
                                                            const value = otherParametersArray[index]['water_pr'];
                                                            const regex = /^\d{1,5}\.\d{2}$/;
                                                            return regex.test(value) ? 'border border-gray-300 px-4 py-2 text-xs' : 'border-2 border-lightcoral text-xs';
                                                        })()}
                                                    >
                                                        {otherParametersArray[index]['water_pr'] === 'NaN'
                                                            ? ''
                                                            : otherParametersArray[index]['water_pr']}
                                                    </td>

                                                    <td className='border border-gray-300 px-4 py-2 text-xs'>
                                                        {otherParametersArray[index]['remarks']}
                                                    </td>
                                                </>
                                            )}

                                        </tr>
                                    )
                                } else {
                                    // ////console.log("Skipping row for detail:", detail); // Log if a row is being skipped
                                    return null
                                }
                            })}
                        </tbody>
                    </table>
                </div >
            </div >
        )
    }

    const TableB = () => {
        ////console.log("good") ;
        const validateFurnaceNo = (value) => {
            return value >= 1 && value <= 20
        }

        const validateCrucibleNo = (value) => {
            return /[A-E]/.test(value)
        }

        const validateCampaignNo = (value) => {
            return value >= 1 && value <= 100
        }

        const validateLiningPatchingNo = (value) => {
            const validValues = [
                'New Lining',
                'Side Lining 1',
                'Side Lining 2',
                'Side Lining 3',
                'Side Lining 4',
                'Side Lining 5',
                'Side Lining 6',
                'Side Lining 7',
                'Side Lining 8',
                'Side Lining 9',
                'Side Lining 10',
                'Side Lining 11',
                'Side Lining 12',
                'Side Lining 13',
                'Side Lining 14',
                'Side Lining 15',
                'Patching 1',
                'Patching 2',
                'Patching 3',
                'Patching 4',
                'Patching 5',
                'Patching 6',
                'Patching 7',
                'Patching 8',
                'Patching 9',
                'Patching 10',
                'Patching 11',
                'Patching 12',
                'Patching 13',
                'Patching 14',
                'Patching 15',
                'Patching 16',
                'Patching 17',
                'Patching 18',
                'Patching 19',
                'Patching 20',
                'Patching 21',
                'Patching 22',
                'Patching 23',
                'Patching 24',
                'Patching 25',
            ]
            return validValues.includes(value)
        }

        const validateHeatNo = (value) => {
            return !isNaN(value) // Check if it's a valid number
        }

        const validateDate = (value) => {
            const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/
            return regex.test(value)
        }

        let counter = 1

        let newData = []

        // Assuming liningReferenceArray and productionSummaryArray are defined somewhere
        liningReferenceArray.forEach((detail, index) => {
            if (
                Object.keys(detail).length > 1 ||
                (Object.keys(detail).length === 1 && Object.keys(detail)[0] !== 'id')
            ) {
                // Process each row's data and add to newData array
                let rowData = {
                    sno: index + 1,
                    furnaceNo: detail['furnace_no'],
                    crucibleNo: detail['crucible_no'],
                    campaignNo: detail['campaign_no'],
                    liningPatching: detail['lining_or_patching'],
                    dateOfCompletionLining:
                        detail['date_of_completion_lining_or_patching'],
                }

                // Assuming productionSummaryArray is available and has corresponding data
                if (productionSummaryArray[index]) {
                    rowData.fceProdStartDate =
                        productionSummaryArray[index]['fce_prod_start_date']
                    rowData.fceProdStartTime = productionSummaryArray[index][
                        'fce_prod_start_time'
                    ]
                        .toString()
                        .trim()
                    rowData.fceProdDownDate =
                        productionSummaryArray[index]['fce_prod_down_date']
                    rowData.fceProdDownTime = productionSummaryArray[index][
                        'fce_prod_down_time'
                    ]
                        .toString()
                        .trim()
                    rowData.life = productionSummaryArray[index]['life']
                    rowData.averageHeatSize =
                        productionSummaryArray[index]['average_heat_size']
                    rowData.totalFurnaceRunningHours =
                        productionSummaryArray[index]['total_furnace_running_hours']
                    rowData.totalProduction =
                        productionSummaryArray[index]['total_production']
                    rowData.totalFurnaceRunningHours2 =
                        productionSummaryArray[index]['total_furnace_running_hours']
                }

                if (liningMaterialProductDetailsArray[index]) {
                    rowData.bottomProduct =
                        liningMaterialProductDetailsArray[index]['bottom_product']
                    rowData.sidewallProductOne =
                        liningMaterialProductDetailsArray[index]['sidewall_product_one']
                    rowData.sidewallProductTwo =
                        liningMaterialProductDetailsArray[index]['sidewall_product_two']
                    rowData.topLockingProduct =
                        liningMaterialProductDetailsArray[index]['top_locking_product']
                    rowData.toppingMassProduct =
                        liningMaterialProductDetailsArray[index]['topping_mass_product']
                    rowData.sidewallOneCoverageUptoX =
                        liningMaterialProductDetailsArray[index][
                        'sidewall_one_coverage_upto_x'
                        ]
                    rowData.sidewallTwoCoverageUpto100 =
                        liningMaterialProductDetailsArray[index][
                        'sidewall_two_coverage_upto_100'
                        ]
                }

                if (liningMaterialConsumptionArray[index]) {
                    rowData.nrmConsumptionBottom =
                        liningMaterialConsumptionArray[index]['nrm_consumption_bottom']
                    rowData.nrmConsumptionSidewallOne =
                        liningMaterialConsumptionArray[index][
                        'nrm_consumption_sidewall_one'
                        ]
                    rowData.nrmConsumptionSidewallTwo =
                        liningMaterialConsumptionArray[index][
                        'nrm_consumption_sidewall_two'
                        ]
                    rowData.nrmConsumptionTopLocking =
                        liningMaterialConsumptionArray[index]['nrm_consumption_top_locking']
                    rowData.toppingMassConsumption =
                        liningMaterialConsumptionArray[index]['topping_mass_consumption']
                    rowData.totalNrmConsumptionSidewallOne =
                        liningMaterialConsumptionArray[index][
                        'total_nrm_consumption_sidewall_one'
                        ]
                    rowData.nrmSpecificConsumption =
                        liningMaterialConsumptionArray[index]['nrm_specific_consumption']
                }

                if (dimensionalParametersArray[index]) {
                    rowData.formerDimension =
                        dimensionalParametersArray[index]['former_dimension']
                    rowData.coilCoatId = dimensionalParametersArray[index]['coil_coat_id']
                    rowData.fceHt = dimensionalParametersArray[index]['fce_ht']
                    rowData.costableTopHt =
                        dimensionalParametersArray[index]['costable_top_ht']
                    rowData.bottomHt = dimensionalParametersArray[index]['bottom_ht']
                }

                if (sinteringParametersArray[index]) {
                    rowData.totalSinteringTime = sinteringParametersArray[index][
                        'total_sintering_time'
                    ]
                        .toString()
                        .trim()
                    rowData.holdingTime = sinteringParametersArray[index]['holding_time']
                    rowData.holdingTemp = sinteringParametersArray[index]['holding_temp']
                    rowData.reasonForDown =
                        sinteringParametersArray[index]['reason_for_down']
                    rowData.IRno = sinteringParametersArray[index]['IR_no']
                    rowData.jobDoneBy = sinteringParametersArray[index]['job_done_by']
                    rowData.remarks = sinteringParametersArray[index]['remarks']
                }

                newData.push(rowData)
            }
        })

        localStorage.setItem('setExcelData', JSON.stringify(newData))

        // setExcelData(newData);

        return (
            <div className="overflow-x-auto">
                <table className="whitespace-nowrap table-auto w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">
                                S.NO.
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Furnace NO.</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Crucible NO.</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Campaign NO.</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Lining or Patching</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">
                                Date of Completion lining or Patching
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">
                                Fce prod start date
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">
                                Fce prod start time
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Fce prod down date</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Fce prod down time</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Life</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Average Heat size</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">
                                Total Furnace Running Hours
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Total Production</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">
                                Total Furnace Running Hours
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Bottom Product</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Sidewall product 1</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Sidewall product 2</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Top Locking product</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Topping Mass Product</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">
                                Side wall 1 Coverage upto X%
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">
                                Side wall 2 Coverage upto 100%
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">
                                NRM consumption bottom
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">
                                NRM consumption Sidewall 1
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">
                                NRM consumption Sidewall 2
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">
                                NRM consumption at Top Locking
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">
                                Topping mass consumption
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">
                                Total NRM consumption sidewall
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">
                                NRM Specific consumption
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Former Dimension</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Coil coat ID</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Fce Ht.</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Castable top Ht.</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Bottom ht.</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">
                                Total Sintering time
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">holding time</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">holding temp</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Reason for Down</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">IR no.</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Job done by</th>
                            <th className="border border-gray-300 px-4 py-2 text-xs font-medium">Remarks</th>
                        </tr>
                    </thead>

                    <tbody>
                        {liningReferenceArray.map((detail, index) => {
                            if (
                                Object.keys(detail).length > 1 ||
                                (Object.keys(detail).length === 1 && Object.keys(detail)[0] !== 'id')
                            ) {
                                return (
                                    <tr key={index} className="border-b border-gray-200 text-xs">
                                        <td className="px-4 py-2 border-l border-r border-gray-200 w-[20px]">
                                            {counter++}
                                        </td>
                                        <td
                                            className={`px-4 py-2 ${!validateFurnaceNo(detail['furnace_no']) ? 'border-2 border-red-400' : ''
                                                }`}
                                        >
                                            {detail['furnace_no']}
                                        </td>
                                        <td
                                            className={`px-4 py-2 ${!validateCrucibleNo(detail['crucible_no']) ? 'border-2 border-red-400' : ''
                                                }`}
                                        >
                                            {detail['crucible_no']}
                                        </td>
                                        <td
                                            className={`px-4 py-2 ${!validateCampaignNo(detail['campaign_no'])
                                                    ? 'border-2 border-red-400'
                                                    : ''
                                                }`}
                                        >
                                            {detail['campaign_no']}
                                        </td>
                                        <td
                                            className={`px-4 py-2 ${!validateLiningPatchingNo(detail['lining_or_patching'])
                                                    ? 'border-2 border-red-400'
                                                    : ''
                                                }`}
                                        >
                                            {detail['lining_or_patching']}
                                        </td>
                                        <td
                                            className={`px-4 py-2 ${!validateDate(detail['date_of_completion_lining_or_patching'])
                                                    ? 'border-2 border-red-400'
                                                    : ''
                                                }`}
                                        >
                                            {detail['date_of_completion_lining_or_patching']}
                                        </td>


                                        {productionSummaryArray[index] && (
                                            <>
                                                <td
                                                    className={`px-4 py-2 ${!validateDate(productionSummaryArray[index]['fce_prod_start_date'])
                                                            ? 'border-2 border-red-400'
                                                            : ''
                                                        }`}
                                                >
                                                    {productionSummaryArray[index]['fce_prod_start_date']}
                                                </td>
                                                <td
                                                    className={`px-4 py-2 ${(() => {
                                                            const timeString = productionSummaryArray[index]['fce_prod_start_time'] || '';
                                                            const [hours, minutes] = timeString.split(':').map(Number);
                                                            return hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60
                                                                ? ''
                                                                : 'border-2 border-red-400';
                                                        })()
                                                        }`}
                                                >
                                                    {productionSummaryArray[index]['fce_prod_start_time'] === 'NaN'
                                                        ? ''
                                                        : productionSummaryArray[index]['fce_prod_start_time']}
                                                </td>
                                                <td
                                                    className={`px-4 py-2 ${!validateDate(productionSummaryArray[index]['fce_prod_down_date'])
                                                            ? 'border-2 border-red-400'
                                                            : ''
                                                        }`}
                                                >
                                                    {productionSummaryArray[index]['fce_prod_down_date']}
                                                </td>
                                                <td
                                                    className={`px-4 py-2 ${(() => {
                                                            const timeString = productionSummaryArray[index]['fce_prod_down_time'] || '';
                                                            const [hours, minutes] = timeString.split(':').map(Number);
                                                            return hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60
                                                                ? ''
                                                                : 'border-2 border-red-400';
                                                        })()
                                                        }`}
                                                >
                                                    {productionSummaryArray[index]['fce_prod_down_time'] === 'NaN'
                                                        ? ''
                                                        : productionSummaryArray[index]['fce_prod_down_time']}
                                                </td>
                                                <td className="px-4 py-2">
                                                    {productionSummaryArray[index]['life'] === 'NaN'
                                                        ? ''
                                                        : productionSummaryArray[index]['life']}
                                                </td>
                                                <td
                                                    className={`px-4 py-2 ${(() => {
                                                            const value = productionSummaryArray[index]['average_heat_size'];
                                                            return /^\d{1,5}\.\d{2}$/.test(value)
                                                                ? ''
                                                                : 'border-2 border-red-400';
                                                        })()
                                                        }`}
                                                >
                                                    {productionSummaryArray[index]['average_heat_size'] === 'NaN'
                                                        ? ''
                                                        : productionSummaryArray[index]['average_heat_size']}
                                                </td>
                                                <td
                                                    className={`px-4 py-2 ${(() => {
                                                            const value = productionSummaryArray[index]['total_furnace_running_hours'];
                                                            return /^\d{1,5}\.\d{2}$/.test(value)
                                                                ? ''
                                                                : 'border-2 border-red-400';
                                                        })()
                                                        }`}
                                                >
                                                    {productionSummaryArray[index]['total_furnace_running_hours'] === 'NaN'
                                                        ? ''
                                                        : productionSummaryArray[index]['total_furnace_running_hours']}
                                                </td>
                                                <td
                                                    className={`px-4 py-2 ${(() => {
                                                            const value = productionSummaryArray[index]['total_production'];
                                                            return /^\d{1,5}\.\d{2}$/.test(value)
                                                                ? ''
                                                                : 'border-2 border-red-400';
                                                        })()
                                                        }`}
                                                >
                                                    {productionSummaryArray[index]['total_production'] === 'NaN'
                                                        ? ''
                                                        : productionSummaryArray[index]['total_production']}
                                                </td>
                                            </>
                                        )}
                                        {liningMaterialProductDetailsArray[index] && (
                                            <>
                                                <td className="px-4 py-2">
                                                    {liningMaterialProductDetailsArray[index]['bottom_product']}
                                                </td>
                                                <td className="px-4 py-2">
                                                    {liningMaterialProductDetailsArray[index]['sidewall_product_one']}
                                                </td>
                                                <td className="px-4 py-2">
                                                    {liningMaterialProductDetailsArray[index]['sidewall_product_two']}
                                                </td>
                                                <td className="px-4 py-2">
                                                    {liningMaterialProductDetailsArray[index]['top_locking_product']}
                                                </td>
                                                <td className="px-4 py-2">
                                                    {liningMaterialProductDetailsArray[index]['topping_mass_product']}
                                                </td>
                                                <td
                                                    className={`px-4 py-2 ${(() => {
                                                            const value = liningMaterialProductDetailsArray[index]['sidewall_one_coverage_upto_x'];
                                                            return /^\d{1,5}\.\d{2}%?$/.test(value)
                                                                ? ''
                                                                : 'border-2 border-red-400';
                                                        })()
                                                        }`}
                                                >
                                                    {liningMaterialProductDetailsArray[index]['sidewall_one_coverage_upto_x'] === 'NaN%'
                                                        ? ''
                                                        : liningMaterialProductDetailsArray[index]['sidewall_one_coverage_upto_x']}
                                                </td>
                                                <td
                                                    className={`px-4 py-2 ${(() => {
                                                            const value = liningMaterialProductDetailsArray[index]['sidewall_two_coverage_upto_100'];
                                                            return /^\d{1,5}\.\d{2}%?$/.test(value)
                                                                ? ''
                                                                : 'border-2 border-red-400';
                                                        })()
                                                        }`}
                                                >
                                                    {liningMaterialProductDetailsArray[index]['sidewall_two_coverage_upto_100'] === 'NaN%'
                                                        ? ''
                                                        : liningMaterialProductDetailsArray[index]['sidewall_two_coverage_upto_100']}
                                                </td>
                                            </>
                                        )}

                                        {liningMaterialConsumptionArray[index] && (
                                            <>
                                                <td className={`border ${/^\d{1,5}\.\d{2}$/.test(liningMaterialConsumptionArray[index]['nrm_consumption_bottom']) ? 'border-none' : 'border-lightcoral'}`}>
                                                    {liningMaterialConsumptionArray[index]['nrm_consumption_bottom'] === 'NaN' ? '' : liningMaterialConsumptionArray[index]['nrm_consumption_bottom']}
                                                </td>

                                                <td className={`border ${/^\d{1,5}\.\d{2}$/.test(liningMaterialConsumptionArray[index]['nrm_consumption_sidewall_one']) ? 'border-none' : 'border-lightcoral'}`}>
                                                    {liningMaterialConsumptionArray[index]['nrm_consumption_sidewall_one'] === 'NaN' ? '' : liningMaterialConsumptionArray[index]['nrm_consumption_sidewall_one']}
                                                </td>

                                                <td className={`border ${/^\d{1,5}\.\d{2}$/.test(liningMaterialConsumptionArray[index]['nrm_consumption_sidewall_two']) ? 'border-none' : 'border-lightcoral'}`}>
                                                    {liningMaterialConsumptionArray[index]['nrm_consumption_sidewall_two'] === 'NaN' ? '' : liningMaterialConsumptionArray[index]['nrm_consumption_sidewall_two']}
                                                </td>

                                                <td className={`border ${/^\d{1,5}\.\d{2}$/.test(liningMaterialConsumptionArray[index]['nrm_consumption_top_locking']) ? 'border-none' : 'border-lightcoral'}`}>
                                                    {liningMaterialConsumptionArray[index]['nrm_consumption_top_locking'] === 'NaN' ? '' : liningMaterialConsumptionArray[index]['nrm_consumption_top_locking']}
                                                </td>

                                                <td className={`border ${/^\d{1,5}\.\d{2}$/.test(liningMaterialConsumptionArray[index]['topping_mass_consumption']) ? 'border-none' : 'border-lightcoral'}`}>
                                                    {liningMaterialConsumptionArray[index]['topping_mass_consumption'] === 'NaN' ? '' : liningMaterialConsumptionArray[index]['topping_mass_consumption']}
                                                </td>

                                                <td className={`border ${/^\d{1,5}\.\d{2}$/.test(liningMaterialConsumptionArray[index]['total_nrm_consumption_sidewall_one']) ? 'border-none' : 'border-lightcoral'}`}>
                                                    {liningMaterialConsumptionArray[index]['total_nrm_consumption_sidewall_one'] === 'NaN' ? '' : liningMaterialConsumptionArray[index]['total_nrm_consumption_sidewall_one']}
                                                </td>

                                                <td className={`border ${/^\d{1,5}\.\d{2}$/.test(liningMaterialConsumptionArray[index]['nrm_specific_consumption']) ? 'border-none' : 'border-lightcoral'}`}>
                                                    {liningMaterialConsumptionArray[index]['nrm_specific_consumption'] === 'NaN' ? '' : liningMaterialConsumptionArray[index]['nrm_specific_consumption']}
                                                </td>
                                            </>
                                        )}

                                        {dimensionalParametersArray[index] && (
                                            <>
                                                <td>{dimensionalParametersArray[index]['former_dimension']}</td>

                                                <td className={`border ${/^\d{1,5}\.\d{2}$/.test(dimensionalParametersArray[index]['coil_coat_id']) ? 'border-none' : 'border-lightcoral'}`}>
                                                    {dimensionalParametersArray[index]['coil_coat_id'] === 'NaN' ? '' : dimensionalParametersArray[index]['coil_coat_id']}
                                                </td>

                                                <td className={`border ${/^\d{1,5}\.\d{2}$/.test(dimensionalParametersArray[index]['fce_ht']) ? 'border-none' : 'border-lightcoral'}`}>
                                                    {dimensionalParametersArray[index]['fce_ht'] === 'NaN' ? '' : dimensionalParametersArray[index]['fce_ht']}
                                                </td>

                                                <td className={`border ${/^\d{1,5}\.\d{2}$/.test(dimensionalParametersArray[index]['costable_top_ht']) ? 'border-none' : 'border-lightcoral'}`}>
                                                    {dimensionalParametersArray[index]['costable_top_ht'] === 'NaN' ? '' : dimensionalParametersArray[index]['costable_top_ht']}
                                                </td>

                                                <td className={`border ${/^\d{1,5}\.\d{2}$/.test(dimensionalParametersArray[index]['bottom_ht']) ? 'border-none' : 'border-lightcoral'}`}>
                                                    {dimensionalParametersArray[index]['bottom_ht'] === 'NaN' ? '' : dimensionalParametersArray[index]['bottom_ht']}
                                                </td>
                                            </>
                                        )}

                                        {sinteringParametersArray[index] && (
                                            <>
                                                <td className={`border ${(() => {
                                                    const timeString = sinteringParametersArray[index]['total_sintering_time']
                                                        ? sinteringParametersArray[index]['total_sintering_time']
                                                            .toString()
                                                            .trim()
                                                        : '';
                                                    const [hours, minutes] = timeString.split(':').map(Number);
                                                    if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
                                                        return 'border-none';
                                                    } else {
                                                        return 'border-2 border-lightcoral text-xs';
                                                    }
                                                })()}`}>
                                                    {sinteringParametersArray[index]['total_sintering_time'] === 'NaN'
                                                        ? ''
                                                        : sinteringParametersArray[index]['total_sintering_time']}
                                                </td>

                                                <td className={`border ${(() => {
                                                    const value = sinteringParametersArray[index]['holding_time'];
                                                    const regex = /^\d{1,5}\.\d{2}$/;
                                                    return regex.test(value) ? 'border-none' : 'border-2 border-lightcoral text-xs';
                                                })()}`}>
                                                    {sinteringParametersArray[index]['holding_time'] === 'NaN'
                                                        ? ''
                                                        : sinteringParametersArray[index]['holding_time']}
                                                </td>

                                                <td className={`border ${(() => {
                                                    const value = sinteringParametersArray[index]['holding_temp'];
                                                    const regex = /^\d{1,5}\.\d{2}$/;
                                                    return regex.test(value) ? 'border-none' : 'border-2 border-lightcoral text-xs';
                                                })()}`}>
                                                    {sinteringParametersArray[index]['holding_temp'] === 'NaN'
                                                        ? ''
                                                        : sinteringParametersArray[index]['holding_temp']}
                                                </td>

                                                <td className="border">{sinteringParametersArray[index]['reason_for_down']}</td>
                                                <td className="border">{sinteringParametersArray[index]['IR_no']}</td>
                                                <td className="border">{sinteringParametersArray[index]['job_done_by']}</td>
                                                <td className="border">{sinteringParametersArray[index]['remarks']}</td>
                                            </>
                                        )}

                                    </tr>
                                )
                            } else {
                                // ////console.log("Skipping row for detail:", detail); // Log if a row is being skipped
                                return null
                            }
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    const TableC = () => {
        ////console.log("good") ;
        ////console.log("userData",userData);
    }
    const renderTable = () => {
        switch (tab) {
            case 'Daily Trial Monitoring':
                return <TableA />;
            case 'NRM Performance':
                return <TableB />;
            case 'Erosion Profile':
            // return <TableC />;
            default:
                return null;
        }
    };
    return (
        <div>
            <div className="flex space-x-2 w-full p-3 border-b">
                <div className="w-1/2">
                    <p className="text-2xl font-semibold font-inter p-1">
                        {viewTitle}
                    </p>
                </div>
                {!hideButtons && (
                    <div className="w-1/2 flex justify-end items-center">
                        <button
                            className="text-white whitespace-nowrap border-blue-400 border lg:border-0 py-1 px-2 lg:bg-gradient-to-br lg:from-custom-red lg:to-custom-orange hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-regular rounded-md text-sm lg:pl-5 lg:pr-10 xl:pr-5 py-2 ml-3 text-center flex"
                            onClick={handleChart}
                        >
                            Go to Analysis
                        </button>
                        <button
                            className="ml-2 text-primary border rounded-lg p-0.5"
                            onClick={handleDownload}
                        >
                            <DownloadIcon />
                        </button>
                    </div>
                )}
            </div>
            <div className="table-container p-4 bg-white rounded-lg shadow-md">
                {renderTable()}
            </div>
        </div>
    )
}
export default ViewReport
