import React from 'react';
import { useNavigate } from 'react-router-dom';
import StatusImage from '../../assets/images/icons/ic_status.svg';

const SubmittedStatus = ({closeModal}) => {

  const navigate = useNavigate();

  const handleDataManagement = () => {
    navigate('/Datamanagement');
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img src={StatusImage} alt="Status" className="mb-5" />
      <p className="font-inter font-extrabold text-lg leading-7">
        Data File is Submitted
      </p>
      <p className="text-center text-sm mt-2">
        Your Data is Submitted and is sent for approval. You won’t be able to edit this Data File.
      </p>
  
      <div className="mt-10">
        <button
          className="bg-primary text-white hover:bg-primary-dark px-6 py-2 rounded"
          onClick={closeModal}
        >
          Go to Data Management
        </button>
      </div>
    </div>
  );
  
  
};

export default SubmittedStatus;
