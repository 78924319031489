import React, { useState, useEffect, useRef } from "react";
import DataManagementCard from "./DataManagementCard";
import Board from "../../assets/images/icons/BoardIcon.svg";
import TableIcon from "../../assets/images/icons/TableIcon.svg";

export default function NRMPerformance({
  cardsSubmitted,
  cardsDraft,
  cardsApproved,
  cardsRejected,
  selectedTab,
  filteredNRMPerformanceData
}) {
  const [isDraftOpen, setIsDraftOpen] = useState(true);
  const [isSubmittedOpen, setIsSubmittedOpen] = useState(false);
  const [isRejectedOpen, setIsRejectedOpen] = useState(false);
  const [isApprovedOpen, setIsApprovedOpen] = useState(false);

  console.log(cardsApproved, "cardsApprovednrm");
  console.log(cardsRejected, "cardsRejectednmr");
  console.log(cardsDraft, "cardsDraft");
  console.log(cardsSubmitted, "cardsSubmitted");
  const [toggleCount, setToggleCount] = useState(0);

  const handleToggle = (section) => {
    switch (section) {
      case "draft":
        setIsDraftOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1);
          return !prev;
        });
        break;
      case "submitted":
        setIsSubmittedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1);
          return !prev;
        });
        break;
      case "rejected":
        setIsRejectedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1);
          return !prev;
        });
        break;
      case "approved":
        setIsApprovedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1);
          return !prev;
        });
        break;
      default:
        break;
    }
  };
  return (
    <>
      <div className="relative overflow-hidden">
        <div className="table-header bg-white p-2">
          <div className="sm:flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-800 font-medium p-2 mb-0">
                NRM Performance Board View
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex space-x-4 px-4 mt-4 lg:flex hidden">
        {/* Draft */}
        {!isDraftOpen && (
          <button
            onClick={() => handleToggle("draft")}
            class="vertical-text tracking-wider py-2 px-4 uppercase justify-start  h-full flex"
          >
            <div class="flex justify-start items-center mb-4">
              <img src={TableIcon} className="mb-2" alt="table" />
              <h5 class="text-custom-red text-sm font-semibold uppercase mb-2">
                Drafts
              </h5>

            </div>
          </button>
        )}
        {isDraftOpen && (
          <div className="col w-full">
            <button onClick={() => handleToggle("draft")}>
              <div className="flex justify-start items-center mb-4">
                <img src={TableIcon} className="mr-2" alt="table" />
                <h5 className="text-custom-red text-sm font-semibold uppercase">
                  Drafts
                </h5>
                <span className="border-blue-100 h-8 w-8 text-blue-800 ml-4 text-sm font-medium flex items-center justify-center rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
                  0
                </span>
              </div>
            </button>
            <div
              className={`grid gap-6 gridList${toggleCount && isDraftOpen ? toggleCount + 1 : ""}`}
            >
              {(filteredNRMPerformanceData.length > 0
                ? filteredNRMPerformanceData.filter(
                  (item) =>
                    item?.lining_reference[0]?.submission_status === "draft"
                )
                : cardsDraft
              ).map((item) => {
                if (item.dataFile.template.template_id === 2) {
                  const createdDate = new Date(item.created_at);
                  const formattedDate = createdDate.toLocaleDateString(
                    "en-US",
                    {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }
                  );
                  return (
                    <DataManagementCard
                      key={item.id}
                      formattedDate={formattedDate}
                      item={item}
                      template_id={item.dataFile.template.template_id}
                    />
                  );
                }
              })}
            </div>
          </div>
        )}
        {/* Submitted */}
        {!isSubmittedOpen && (
          <button
            onClick={() => handleToggle("submitted")}
            class="vertical-text tracking-wider py-2 px-4 uppercase justify-start  h-full flex"
          >
            <div class="flex justify-start items-center mb-4">
              <img src={TableIcon} className="mb-2" alt="table" />
              <h5 class="text-custom-red text-sm font-semibold uppercase mb-2">
                awaiting approval
              </h5>

            </div>
          </button>
        )}
        {isSubmittedOpen && (
          <div className="col w-full">
            <button onClick={() => handleToggle("submitted")}>
              <div class="flex justify-start items-center mb-4">
                <img src={TableIcon} className="mr-2" alt="table" />
                <h5 class="text-custom-red text-sm font-semibold uppercase">
                  awaiting approval
                </h5>
                <span class="border-blue-100 h-8 w-8 text-blue-800 ml-4 text-sm font-medium flex items-center justify-center rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
                  0
                </span>
              </div>
            </button>
            <div
              className={`grid gap-6 gridList${toggleCount && isSubmittedOpen ? toggleCount + 1 : ""}`}
            >
              {(filteredNRMPerformanceData.length > 0
                ? filteredNRMPerformanceData.filter(
                  (item) => item?.lining_reference[0]?.submission_status === "submitted")
                : cardsSubmitted && cardsSubmitted
              ).map((item) => {
                if (item.dataFile.template.template_id === 2) {
                  const createdDate = new Date(item.created_at);
                  const formattedDate = createdDate.toLocaleDateString(
                    "en-US",
                    {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }
                  );

                  return (
                    <DataManagementCard
                      key={item.id}
                      formattedDate={formattedDate}
                      item={item}
                      template_id={item.dataFile.template.template_id}
                    />
                  );
                }
              })}
            </div>
          </div>
        )}
        {/* Rejected */}
        {!isRejectedOpen && (
          <button
            onClick={() => handleToggle("rejected")}
            class="vertical-text tracking-wider py-2 px-4 uppercase justify-start  h-full flex"
          >
            <div class="flex justify-start items-center mb-4">
              <img src={TableIcon} className="mb-2" alt="table" />
              <h5 class="text-custom-red text-sm font-semibold uppercase mb-2">
                rejected
              </h5>

            </div>
          </button>
        )}
        {isRejectedOpen && (
          <div className="col w-full">
            <button onClick={() => handleToggle("rejected")}>
              <div class="flex justify-start items-center mb-4">
                <img src={TableIcon} className="mr-2" alt="table" />
                <h5 class="text-custom-red text-sm font-semibold uppercase">
                  rejected
                </h5>
                <span class="border-blue-100 h-8 w-8 text-blue-800 ml-4 text-sm font-medium flex items-center justify-center rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
                  0
                </span>
              </div>
            </button>
            <div
              className={`grid gap-6 gridList${toggleCount && isRejectedOpen ? toggleCount + 1 : ""}`}
            >
              {" "}
              {(filteredNRMPerformanceData.length > 0
                ? filteredNRMPerformanceData.filter(
                  (item) => item?.lining_reference[0]?.submission_status ===
                    "rejected"
                )
                : cardsRejected && cardsRejected
              ).map((item) => {
                if (item.dataFile.template.template_id === 2) {
                  const createdDate = new Date(item.created_at);
                  const formattedDate = createdDate.toLocaleDateString(
                    "en-US",
                    {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }
                  );
                  return (
                    <DataManagementCard
                      key={item.id}
                      formattedDate={formattedDate}
                      item={item}
                      template_id={item.dataFile.template.template_id}
                    />
                  );
                }
              })}
            </div>
          </div>
        )}
        {/* Approved */}
        {!isApprovedOpen && (
          <button
            onClick={() => handleToggle("approved")}
            class="vertical-text tracking-wider py-2 px-4 uppercase justify-start  h-full flex"
          >
            <div class="flex justify-start items-center mb-4">
              <img src={TableIcon} className="mb-2" alt="table" />
              <h5 class="text-custom-red text-sm font-semibold uppercase mb-2">
                approved
              </h5>

            </div>
          </button>
        )}
        {isApprovedOpen && (
          <div className="col w-full">
            <button onClick={() => handleToggle("approved")}>
              <div class="flex justify-start items-center mb-4">
                <img src={TableIcon} className="mr-2" alt="table" />
                <h5 class="text-custom-red text-sm font-semibold uppercase">
                  approved
                </h5>
                <span class="border-blue-100 h-8 w-8 text-blue-800 ml-4 text-sm font-medium flex items-center justify-center rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
                  0
                </span>
              </div>
            </button>
            <div
              className={`grid gap-6 gridList${toggleCount && isApprovedOpen ? toggleCount + 1 : ""}`}
            >
              {(filteredNRMPerformanceData.length > 0
                ? filteredNRMPerformanceData.filter(
                  (item) => item?.lining_reference[0]?.submission_status ===
                    "approved"
                )
                : cardsApproved && cardsApproved
              ).map((item) => {
                if (item.dataFile.template.template_id === 2) {
                  const createdDate = new Date(item.created_at);
                  const formattedDate = createdDate.toLocaleDateString(
                    "en-US",
                    {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }
                  );
                  return (
                    <DataManagementCard
                      key={item.id}
                      formattedDate={formattedDate}
                      item={item}
                      template_id={item.dataFile.template.template_id}
                    />
                  );
                }
              })}
            </div>
          </div>
        )}
      </div>

      <div className="p-4 lg:hidden block">
        <div
          className="grid gap-6 grid-cols-2"
        >
          <>
            {(
              filteredNRMPerformanceData.length > 0
                ? filteredNRMPerformanceData
                : [...cardsDraft, ...cardsSubmitted, ...cardsRejected, ...cardsApproved]
            )
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map((item) => {
                if (item.dataFile.template.template_id === 2) {
                  const createdDate = new Date(item.created_at);
                  const formattedDate = createdDate.toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  });
                  return (
                    <DataManagementCard
                      key={item.id}
                      formattedDate={formattedDate}
                      item={item}
                      template_id={item.dataFile.template.template_id}
                    />
                  );
                }
                return null;
              })}
          </>

        </div>
      </div>
    </>
  );
}
