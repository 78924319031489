import React , { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { savings_tangible_benefit, savings_tangible_benefit_nrm, trial_name } from "../../../recoil/atom";
import { otherTangibleBenefitsSelector } from "../../../recoil/selector";

const TangibleBenfit = () => {
  const [tangibleBenefits, setTangibleBenefits] = useRecoilState(
    savings_tangible_benefit
  )
  const [tangibleBenefitsNRM, setTangibleBenefitsNRM] = useRecoilState(
    savings_tangible_benefit_nrm
  )
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'

  const otherTangibleBenefits = useRecoilValue(otherTangibleBenefitsSelector)

  const handleTangibleBenefits = (name, value) => {
    setTangibleBenefits((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleTangibleBenefitsNRM = (name, value) => {
    setTangibleBenefitsNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const tangibleBenefitsObj = {
    asbestos_sheet: 'NA',
    coil_coat_for_repair: 'NA',
    slag_dumping: 'NA',
    other_tangible_benefits: 'NA',
  }

  const tangibleBenefitsObjNRM = {
    asbestos_sheet: tangibleBenefitsNRM.asbestos_sheet,
    coil_coat_for_repair: tangibleBenefitsNRM.coil_coat_for_repair,
    slag_dumping: tangibleBenefitsNRM.slag_dumping,
    other_tangible_benefits: otherTangibleBenefits,
  }

  // useEffect(() => {
  //   if (
  //     props.trialName === 'costSheetEdit' &&
  //     props.editSavingsDetails.length > 0
  //   ) {
  //     console.log(props.editSavingsDetails, 'props.editSavingsDetails')
  //     let editObj = doesKeyExist(
  //       props.editSavingsDetails,
  //       'tangibleBenefitsObject'
  //     )
  //     console.log(editObj, 'editObj')
  //     if (editObj) {
  //       setTangibleBenefits(
  //         editObj.tangibleBenefitsObject.tangibleBenefits.basic
  //       )
  //       setTangibleBenefitsNRM(
  //         editObj.tangibleBenefitsObject.tangibleBenefits.nrm
  //       )
  //     }
  //   }
  // }, [props.editSavingsDetails])

  function doesKeyExist(array, key) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].hasOwnProperty(key)) {
        return array[i]
      }
    }
    return false
  }

  const handlenextTriger = () => {
    props.handleNext()
    props.handleTangibleBenefitsObject(
      tangibleBenefitsObj,
      tangibleBenefitsObjNRM
    )
  }

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        S7 : Savings due to other tangible benefits
      </h2>

      <div className="w-full mb-4">
        <label
          htmlFor="asbestos_sheet"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Savings due to Asbestos sheet , avoiding antenna replacement (dia /MT)
        </label>
        <input
          type="text"
          id="asbestos_sheet"
          name="asbestos_sheet"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          value="NA"
          onChange={(event) =>
            handleTangibleBenefits('asbestos_sheet', event.target.value)
          }
        />
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="customer_ref_no"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Savings due to Coil coat for repair , Sodium Silicate (dia /MT)
        </label>
        <input
          type="text"
          id="customer_ref_no"
          name="customer_ref_no"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          value="NA"
          onChange={(event) =>
            handleTangibleBenefits('asbestos_sheet', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="customer_ref_no"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Savings due to Coil coat for repair , Sodium Silicate (dia /MT)
        </label>
        <input
          type="text"
          id="asbestos_sheet"
          name="asbestos_sheet"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          value="NA"
          onChange={(event) =>
            handleTangibleBenefits('asbestos_sheet', event.target.value)
          }
        />
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="customer_ref_no"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Savings due to Other Tangible Benefit (F5) (dia /MT)
        </label>
        <input
          type="text"
          id="customer_ref_no"
          name="customer_ref_no"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          value="NA"
          onChange={(event) =>
            handleTangibleBenefits('asbestos_sheet', event.target.value)
          }
        />
      </div>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        NRM
      </h2>

      <div className="w-full mb-4">
        <label
          htmlFor="asbestos_sheet"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Savings due to Asbestos sheet , avoiding antenna replacement (dia /MT)
        </label>
        <input
          type="text"
          id="asbestos_sheet"
          name="asbestos_sheet"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          value={tangibleBenefitsNRM.asbestos_sheet}
          onChange={(event) =>
            handleTangibleBenefitsNRM('asbestos_sheet', event.target.value)
          }
        />
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="coil_coat_for_repair"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Savings due to Coil coat for repair , Sodium Silicate (dia /MT)
        </label>
        <input
          type="text"
          id="coil_coat_for_repair"
          name="coil_coat_for_repair"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          value={tangibleBenefitsNRM.coil_coat_for_repair}
          onChange={(event) =>
            handleTangibleBenefitsNRM(
              'coil_coat_for_repair',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="antenna_replacement"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Savings due to Coil coat for repair , Sodium Silicate (dia /MT)
        </label>
        <input
          type="text"
          id="antenna_replacement"
          name="antenna_replacement"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          value={tangibleBenefitsNRM.antenna_replacement}
          onChange={(event) =>
            handleTangibleBenefitsNRM('antenna_replacement', event.target.value)
          }
        />
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="sodium_silicate"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Savings due to Other Tangible Benefit (F5) (dia /MT)
        </label>
        <input
          type="text"
          id="sodium_silicate"
          name="sodium_silicate"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Savings"
          value={otherTangibleBenefits}
          onChange={(event) =>
            handleTangibleBenefitsNRM(
              'other_tangible_benefits',
              event.target.value
            )
          }
        />
      </div>
    </>
  );
};

export default TangibleBenfit;
