import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import StatusImage from "../../assets/images/icons/ic_status.svg";
import DailyTrialLayout from "../DailyTrialForm/DailyTrialLayout";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  general_details,
  furnace_details,
  charge_mix_details,
  carbon,
  alloying_addition,
  other_parameters,
} from '../../recoil/atom';
import {
  lining_reference,
  production_summary,
  lining_material_product_details,
  lining_material_consumption,
  dimensional_parameters,
  sintering_parameters,
} from '../../recoil/atom';


const DraftStatus = ({ closeModal }) => {
  const dataFile = localStorage.getItem("dataFileResponse");
  const dataFileResponse = JSON.parse(dataFile);
  console.log("dataFileResponse", dataFileResponse);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [DailyTrial, setDailyTrial] = useState(false);
  const [Nrm, setNrm] = useState(false);
  const resetGeneralDetailsValue = useResetRecoilState(general_details)
  const resetFurnaceDetailsValue = useResetRecoilState(furnace_details)
  const resetChargeMixDetailsValue = useResetRecoilState(charge_mix_details)
  const resetCarbonValue = useResetRecoilState(carbon)
  const resetAlloyingadditionValue = useResetRecoilState(alloying_addition)
  const resetOtherParametersValue = useResetRecoilState(other_parameters)
  const resetLiningReferenceValue = useResetRecoilState(lining_reference);
  const resetProductionSummaryValue = useResetRecoilState(production_summary);
  const resetLiningMaterialProductDetailsValue = useResetRecoilState(lining_material_product_details);
  const resetLiningMaterialConsumptionValue = useResetRecoilState(lining_material_consumption);
  const resetDimensionalParametersValue = useResetRecoilState(dimensional_parameters);
  const resetSinteringParametersValue = useResetRecoilState(sintering_parameters);

  // const handleDataManagement = () => {
  //   navigate("/Datamanagement");
  // };

  // const handleAddRow = () => {
  //   localStorage.setItem("Trial", "add");
  //   const templateId = dataFileResponse?.template?.template_id;
  //   console.log(templateId, "templateId")
  //   if (templateId === 1) {
  //     resetDailyTrialValues();
  //     setDailyTrial(true);
  //   } else if (templateId === 2) {
  //     resetNRMValues();
  //     setNrm(true);
  //   } else if (templateId === 4) {
  //     navigate("/HorizontalDataCollectionStepper");
  //   } else if (templateId === 5) {
  //     navigate("/costsheet");
  //   }
  // };

  const resetNRMValues = () => {
    resetLiningReferenceValue();
    resetProductionSummaryValue();
    resetLiningMaterialProductDetailsValue();
    resetLiningMaterialConsumptionValue();
    resetDimensionalParametersValue();
    resetSinteringParametersValue();
  };

  const resetDailyTrialValues = () => {
    resetGeneralDetailsValue()
    resetFurnaceDetailsValue()
    resetChargeMixDetailsValue()
    resetCarbonValue()
    resetAlloyingadditionValue()
    resetOtherParametersValue()
  }

  return (
    <div className="w-full flex flex-col items-center">
      {DailyTrial ? (
        <DailyTrialLayout/>
      ) : (
        <>
          <img src={StatusImage} alt="Status" className="mb-5" />
          <p className="font-inter font-medium text-lg leading-7">
            Data File is saved as Draft
          </p>
          <div className="mt-10 flex gap-5">
            <button
              className="border border-blue-500 text-blue-500 hover:bg-blue-100 px-4 py-2 rounded"
              onClick={closeModal}
            >
              Go to Data Management
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default DraftStatus;
