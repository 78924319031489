import React from "react";
import { useRecoilState } from "recoil";
import { production_summary, lining_reference } from "../../recoil/atom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimeSelector from "../Common/TimePicker";

const LiningMaterialProductWeb = () => {
  const [productionSummary, setProductionSummary] =
    useRecoilState(production_summary);
  const [liningReference] = useRecoilState(lining_reference);
  const uniqueId = liningReference.id ? liningReference.id : "";

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericValue = parseFloat(value) || ""; // Convert value to a number or default to an empty string
    const TrialName = localStorage.getItem("Trial");

    // Prepare updated state with the current changes
    const updatedState = { ...productionSummary, [name]: numericValue };

    // Recalculate total_production if average_heat_size or life is updated
    if (name === "average_heat_size" || name === "life") {
      const averageHeatSize = parseFloat(updatedState.average_heat_size) || 0;
      const life = parseFloat(updatedState.life) || 0;
      updatedState.total_production = averageHeatSize * life;
    }

    // Check if TrialName is "new"
    if (TrialName === "new") {
      updatedState.id = uniqueId; // Add unique ID for new trials
    }

    setProductionSummary(updatedState);
  };
  console.log("productionSummary", productionSummary);

  const handleDateChange = (name, date) => {
    setProductionSummary((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  // function calculateProductionTime(productionSummary) {
  //   console.log(productionSummary, "productionSummary");
  //   try {
  //     const {
  //       fce_prod_start_date,
  //       fce_prod_start_time,
  //       fce_prod_down_date,
  //       fce_prod_down_time,
  //     } = productionSummary;

  //     if (
  //       !fce_prod_start_date ||
  //       !fce_prod_start_time ||
  //       !fce_prod_down_date ||
  //       !fce_prod_down_time
  //     ) {
  //       console.error("Missing date or time values.");
  //       return "0.00"; // Or handle this case as appropriate
  //     }

  //     // Validate date and time formats
  //     const startDate = new Date(fce_prod_start_date);
  //     if (isNaN(startDate.getTime())) throw new Error("Invalid start date.");

  //     const startTime = fce_prod_start_time;
  //     const downDate = new Date(fce_prod_down_date);
  //     if (isNaN(downDate.getTime())) throw new Error("Invalid down date.");
  //     const downTime = fce_prod_down_time;

  //     console.log("startDate",startDate);
  //     console.log("startTime",startTime);
  //     console.log("downdate", downDate);
  //     console.log("downdate", downTime);


  //     // Combine date and time
  //     const startDateTime = new Date(
  //       `${startDate.toISOString().split("T")[0]}T${startTime}:00`
  //     );
  //     const downDateTime = new Date(
  //       `${downDate.toISOString().split("T")[0]}T${downTime}:00`
  //     );

  //     // Validate combined DateTime
  //     if (isNaN(startDateTime.getTime()) || isNaN(downDateTime.getTime()))
  //       throw new Error("Invalid DateTime values.");

  //     // Calculate difference
  //     const differenceInMillis = downDateTime - startDateTime;
  //     const differenceInHours = differenceInMillis / (1000 * 60 * 60);

  //     return differenceInHours.toFixed(2);
  //   } catch (error) {
  //     console.error("Error calculating production time:", error);
  //     return "Error"; // Or handle this case as appropriate
  //   }
  // }

  function calculateProductionTime(productionSummary) {
    console.log(productionSummary, "productionSummary");
    try {
      const {
        fce_prod_start_date,
        fce_prod_start_time,
        fce_prod_down_date,
        fce_prod_down_time,
      } = productionSummary;

      // Return "00" if any required value is missing
      if (
        !fce_prod_start_date ||
        !fce_prod_start_time ||
        !fce_prod_down_date ||
        !fce_prod_down_time
      ) {
        return "00";
      }

      // Normalize time (e.g., 8:9 -> 08:09)
      const normalizeTime = (time) => {
        const [hour, minute] = time.split(":").map((t) => t.padStart(2, "0"));
        return `${hour}:${minute}`;
      };

      const startDate = new Date(fce_prod_start_date);
      const downDate = new Date(fce_prod_down_date);

      // Return "00" if date parsing fails
      if (isNaN(startDate.getTime()) || isNaN(downDate.getTime())) {
        return "00";
      }

      const startTime = normalizeTime(fce_prod_start_time);
      const downTime = normalizeTime(fce_prod_down_time);

      // Combine date and time into ISO strings
      const startDateTime = new Date(
        `${startDate.toISOString().split("T")[0]}T${startTime}:00`
      );
      const downDateTime = new Date(
        `${downDate.toISOString().split("T")[0]}T${downTime}:00`
      );

      // Return "00" if DateTime parsing fails
      if (isNaN(startDateTime.getTime()) || isNaN(downDateTime.getTime())) {
        return "00";
      }

      // Calculate difference
      const differenceInMillis = downDateTime - startDateTime;

      // Return "00" if difference is negative
      if (differenceInMillis < 0) {
        return "00";
      }

      const differenceInHours = differenceInMillis / (1000 * 60 * 60);

      return differenceInHours.toFixed(2);
    } catch (error) {
      // Return "00" if any unexpected error occurs
      return "00";
    }
  }

  const totFurnaceRunninghrsCal = calculateProductionTime(productionSummary);
  console.log(totFurnaceRunninghrsCal, "12ksajd");

  const changeHeatTimes = (name, newValue) => {
    if (newValue) {
      setProductionSummary((prevDetails) => {
        const updatedDetails = {
          ...prevDetails,
          [name]: newValue,
        };

        return updatedDetails;
      });
    } else {
      console.error("Invalid date value:", newValue);
    }
  };

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Production Summary
      </h2>

      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label
            htmlFor="fce_prod_start_date"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Fce Prod Start Date / Time(HH:MM)
          </label>
          <DatePicker
            id="fce_prod_start_date"
            selected={
              productionSummary.fce_prod_start_date
                ? new Date(productionSummary.fce_prod_start_date)
                : null
            }
            onChange={(date) => {
              const formattedDate = date
                ? date.toISOString().split("T")[0]
                : ""; // Format date as yyyy-MM-dd
              handleDateChange("fce_prod_start_date", formattedDate);
            }}
            dateFormat="dd-MM-yyyy" // Display format in ISO style
            placeholderText="Select Down Date"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

        {/* Furnace Production Start Time */}
        <div className="w-full mb-4">
          <div className="relative mt-0">
            <TimeSelector
              time={
                productionSummary.fce_prod_start_time
                  ? productionSummary.fce_prod_start_time
                  : "0:0"
              }
              onTimeChange={(newValue) =>
                changeHeatTimes("fce_prod_start_time", newValue)
              }
            />
          </div>
        </div>
      </div>


      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="fce_prod_down_date"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Fce Prod Down Date / Time (HH:MM)
          </label>
          <DatePicker
            id="fce_prod_down_date"
            selected={
              productionSummary.fce_prod_down_date
                ? new Date(productionSummary.fce_prod_down_date)
                : null
            }
            onChange={(date) => {
              const formattedDate = date
                ? date.toISOString().split("T")[0]
                : ""; // Format date as yyyy-MM-dd
              handleDateChange("fce_prod_down_date", formattedDate);
            }}
            dateFormat="dd-MM-yyyy" // Display format in ISO style
            placeholderText="Select Down Date"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="w-full mb-4">
          <div className="relative -mt-0">
            <TimeSelector
              time={
                productionSummary.fce_prod_down_time
                  ? productionSummary.fce_prod_down_time
                  : "00:00"
              }
              onTimeChange={(newValue) =>
                changeHeatTimes("fce_prod_down_time", newValue)
              }
            />
          </div>
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="life"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Life (No of Heats)
          </label>
          <input
            type="text"
            id="life"
            name="life"
            value={productionSummary.life}
            onChange={handleChange}
            placeholder="Enter Life"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="average_heat_size"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Average Heat Size - (MT)
          </label>
          <input
            type="text"
            id="average_heat_size"
            name="average_heat_size"
            value={productionSummary.average_heat_size}
            onChange={handleChange}
            placeholder="Enter Average Heat Size"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label
            htmlFor="total_furnace_running_hours"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total Furnace Running Hours - (Hrs)
          </label>
          <input
            type="text"
            id="total_furnace_running_hours"
            name="total_furnace_running_hours"
            value={totFurnaceRunninghrsCal}
            onChange={handleChange}
            placeholder="Enter Total Furnace Running Hours"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="total_production"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total Production - (MT)
          </label>
          <input
            type="text"
            id="total_production"
            name="total_production"
            value={productionSummary.total_production}
            onChange={handleChange}
            placeholder="Enter Total Production"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled
          />
        </div>
      </div>
    </>
  );
};

export default LiningMaterialProductWeb;
