import React from 'react';
import { useRecoilState } from 'recoil';
import { furnace_dimension , trial_name} from '../../recoil/atom';
import { v4 as uuidv4 } from 'uuid'

const FurnaceDimensionWeb = () => {
  const [furnaceDimension, setFurnaceDimension] =
    useRecoilState(furnace_dimension)
  const [trialName] = useRecoilState(trial_name)
  const uuid = uuidv4()

  const handleChange = (e) => {
    const { name, value } = e.target
    setFurnaceDimension((prevState) => ({
      ...prevState,
      [name]: value,
      id: uuid,
    }))
  }

  const handleDateSelection = (value, type) => {
    console.log('typee', type)
  }

  const isReadOnly = trialName === 'viewDataCollection'

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">Furnace Dimension</h2>

      <div className="flex space-x-4 mt-4">
        <div className="w-full mb-4">
          <label htmlFor="inner_dia_of_coil" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Inner Dia. of Coil - (inch / mm)
          </label>
          <input
            type="text"
            id="inner_dia_of_coil"
            name="inner_dia_of_coil"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Diameter"
            value={furnaceDimension.inner_dia_of_coil}
            onChange={handleChange}
            disabled={isReadOnly}
          />
        </div>

        <div className="w-full mb-4">
          <label htmlFor="inner_dia_after_coilcoat" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            A (Inner Dia. after doing Coil Coat / Grout) - (Inch)
          </label>
          <input
            type="text"
            id="inner_dia_after_coilcoat"
            name="inner_dia_after_coilcoat"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Dia"
            value={furnaceDimension.inner_dia_after_coilcoat}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="former_outer_dia" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            B (Former Outer Dia.) - (Inch)
          </label> 
          <input
            type="text"
            id="former_outer_dia"
            name="former_outer_dia"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Dia"
            value={furnaceDimension.former_outer_dia}
            onChange={handleChange}
          />
        </div>

        <div className="w-full mb-4">
          <label htmlFor="furnace_height" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            C Furnace height - (Inch)
          </label>
          <input
            type="text"
            id="furnace_height"
            name="furnace_height"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Grade"
            value={furnaceDimension.furnace_height}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="bottom_taper_dia" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            E Bottom Taper Dia - (Inch)
          </label>
          <input
            type="text"
            id="bottom_taper_dia"
            name="bottom_taper_dia"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Dia"
            value={furnaceDimension.bottom_taper_dia}
            onChange={handleChange}
          />
        </div>

        <div className="w-full mb-4">
          <label htmlFor="bottom_outer_dia" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            F (Bottom Outer Dia.) - (Inch)
          </label>
          <input
            type="text"
            id="bottom_outer_dia"
            name="bottom_outer_dia"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Dia"
            value={furnaceDimension.bottom_outer_dia}
            onChange={handleChange}
          />
        </div>
      </div>


      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="former_ht" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            G (Former ht.) - (Inch)
          </label>
          <input
            type="text"
            id="former_ht"
            name="former_ht"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Height"
            value={furnaceDimension.former_ht}
            onChange={handleChange}
          />
        </div>
        <div className="w-full mb-4">
          <label htmlFor="bottom_lining_ht" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            H (Bottom Lining Ht.) - (Inch)
          </label>
          <input
            type="text"
            id="bottom_lining_ht"
            name="bottom_lining_ht"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Height"
            value={furnaceDimension.bottom_lining_ht}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label htmlFor="total_power_given_to_ms" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Total Power given to SMS - (MW)
          </label>
          <input
            type="text"
            id="total_power_given_to_ms"
            name="total_power_given_to_ms"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Power."
            value={furnaceDimension.total_power_given_to_ms}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export default FurnaceDimensionWeb;
