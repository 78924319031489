import React from "react";
import { useLocation } from "react-router-dom";
import DashboardIcon from "../../assets/images/SVGIcon/Dashboard.svg";
import DataManagementIcon from "../../assets/images/SVGIcon/DataManagement.svg";
import AnalysisIcon from "../../assets/images/SVGIcon/DataAnalysis&Reports.svg";
import SettingIcon from "../../assets/images/SVGIcon/Settings.svg";
import SGLogo from "./../../assets/images/SaintGobainLogo.svg";
import PCRLogo from "./../../assets/images/DFLogo.svg";
import helpIcon from "./../../assets/images/SVGIcon/Help&Support.svg";
const SideBar = ({ sideWidth, isDrawerOpen, setIsDrawerOpen }) => {
  // const location = useLocation(); // This will give the current route

  // const isActive = (path) => location.pathname === path; // Check if the current path matches the menu link
  const handleMouseEnter = () => {
    if (typeof setIsDrawerOpen === "function") {
      setIsDrawerOpen(true); // Call the function to open the drawer if it's a function
    }
  };

  return (
    <aside
      style={{
        width: sideWidth,
        transform: isDrawerOpen ? "translateX(0)" : "translateX(0%)",
        transition: "0.3s ease-in-out",
      }}
      id="drawer-navigation"
      className={`fixed top-0 border-r left-0 z-40 pt-16 lg:pt-0 h-screen overflow-y-auto bg-white dark:bg-gray-800 drawer ${isDrawerOpen ? "drawer-open" : ""
        }`}
      tabIndex="-1"
      aria-labelledby="drawer-navigation-label"
    >
      <div className="overflow-y-auto h-full bg-white dark:bg-gray-800">
        <div style={{minHeight:'80px'}} className={`${isDrawerOpen ? "pl-4 pr-10 py-4 hidden lg:block" : "hidden"}`}>
          <h1 className="text-lg font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
            <img src={PCRLogo} alt="PCR Logo"/>
          </h1>
        </div>
        <ul
          className={`space-y-2 text-gray-900 ${isDrawerOpen ? "" : " lg:mt-16 mb-16"
            }`}
          onMouseEnter={handleMouseEnter}
        >
          <li>
            <a
              href="/DashboardLayout"
              className={`flex items-center p-2 text-sm font-medium focus:outline-none dark:focus:ring-blue-800 dark:text-white dark:hover:bg-gray-700 group ${isDrawerOpen ? "" : "flex-col justify-center py-2"
                } `}
            >
              <img src={DashboardIcon} alt="Dashboard" />
              <span
                className={`antialiased hover:subpixel-antialiased whitespace-nowrap overflow-hidden text-ellipsis ${isDrawerOpen ? "ml-3" : "hidden"
                  }`}
              >
                Dashboard
              </span>
            </a>
          </li>
          <li>
            <a
              href="/Datamanagement"
              className={`flex items-center p-2 text-sm font-medium focus:outline-none dark:focus:ring-blue-800 dark:text-white dark:hover:bg-gray-700 group ${isDrawerOpen ? "" : "flex-col justify-center py-2"
                } `}
            >
              <img src={DataManagementIcon} alt="Dashboard" />
              <span
                className={`antialiased hover:subpixel-antialiased whitespace-nowrap overflow-hidden text-ellipsis ${isDrawerOpen ? "ml-3" : "hidden"
                  }`}
              >
                Data Management
              </span>
            </a>
          </li>

          <li>
            <a
              href="/reports"
              className={`hidden lg:flex items-center p-2 text-sm font-medium focus:outline-none dark:focus:ring-blue-800 dark:text-white dark:hover:bg-gray-700 group ${isDrawerOpen ? "" : "flex-col justify-center py-2"
                } `}
            >
              <img src={AnalysisIcon} alt="Dashboard" />
              <span
                className={`antialiased hover:subpixel-antialiased whitespace-nowrap overflow-hidden text-ellipsis ${isDrawerOpen ? "ml-3" : "hidden"
                  }`}
              >
                Data Analytics & Reports
              </span>
            </a>
          </li>
          <li>
            <a
              href="/Settings"
              className={`hidden lg:flex items-center p-2 text-sm font-medium focus:outline-none dark:focus:ring-blue-800 dark:text-white dark:hover:bg-gray-700 group ${isDrawerOpen ? "" : "flex-col justify-center py-2"
                } `}
            >
              <img src={SettingIcon} alt="Settings" />
              <span
                className={`antialiased hover:subpixel-antialiased whitespace-nowrap overflow-hidden text-ellipsis ${isDrawerOpen ? "ml-3" : "hidden"
                  }`}
              >
                User Management
              </span>
            </a>
          </li>

          <li>
            <a
              href="helpandSupport"
              className={`hidden lg:flex items-center p-2 text-sm font-medium focus:outline-none dark:focus:ring-blue-800 dark:text-white dark:hover:bg-gray-700 group
                                ${isDrawerOpen
                  ? ""
                  : "flex-col justify-center py-2"
                } 
                               `}
            >
              <img src={helpIcon} alt="eLR & ePOD" />
              <span
                className={`antialiased hover:subpixel-antialiased whitespace-nowrap overflow-hidden text-ellipsis ${isDrawerOpen ? "ml-3" : "hidden"
                  }`}
              >
                Help & Support
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div className="w-full px-2 absolute left-0 bottom-0 pb-4 text-center bg-white">
        <img className="mx-full inline-block" src={SGLogo} alt="SG Logo" />
      </div>
    </aside>
  );
};

export default SideBar;
