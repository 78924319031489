import React, { useState, useEffect } from 'react';
import { Drawer } from 'flowbite';
import { useMediaQuery } from 'react-responsive';
import 'flowbite';
import Header from './Header';
import SideBar from './SideBar';
import { Outlet } from 'react-router-dom';

function Layout() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navWidth = isMobile ? '100%' : isDrawerOpen ? 'calc(100% - 256px)' : 'calc(100% - 64px)';
  const sideWidth = isDrawerOpen ? '256px' : isMobile ? '0' : '64px';
  const sideNavWidth = isMobile ? '0' : isDrawerOpen ? '256px' : '64px';
  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  useEffect(() => {
    const $targetEl = document.getElementById('drawer-navigation');

    // Return early if the target element is not found
    if (!$targetEl) return;

    const options = {
      placement: 'left',
      backdrop: false,
      bodyScrolling: true,
      edge: false,
      backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30',
      onHide: () => {
        console.log('drawer is hidden');
        setIsDrawerOpen(false); 
      },
      onShow: () => {
        setIsDrawerOpen(true);
      },
    };


    const drawer = new Drawer($targetEl, options);

    if (isDrawerOpen) {
      drawer.show();
    } else {
      drawer.hide();
    }

    return () => {
      drawer.hide(); // Cleanup on unmount
    };
  }, [isDrawerOpen]); // Re-run the effect when isDrawerOpen changes
  return (
    <div className="antialiased w-full">
      <Header navWidth={navWidth} isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      <div className='flex'>
        <SideBar isDrawerOpen={isDrawerOpen} sideWidth={sideWidth} setIsDrawerOpen={setIsDrawerOpen} />
        <main style={{
          width: navWidth,
          marginLeft: sideNavWidth,
          transition: '0.3s ease-in-out',
        }} className="w-full min-h-screen pt-16">
          {/* <route.component /> */}
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default Layout;
