import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import {
  lining_material_product_details,
  lining_reference,
} from "../../recoil/atom";
import API from "../../service/API";
import axios from "axios";

const LiningMaterialProductWeb = () => {
  const [liningMaterialProductDetails, setLiningMaterialProductDetails] =
    useRecoilState(lining_material_product_details);
  const [liningReference] = useRecoilState(lining_reference);
  const uniqueId = liningReference.id ? liningReference.id : "";
  const [nrmProductRange, setNRMProductRange] = useState([]);

  useEffect(() => {
    fetchLiningData();
  }, []);

  const fetchLiningData = async () => {
    const access_token = localStorage.getItem("accesstoken");

    try {
      const response = await axios.get(API.GET_NRM_PRODUCT_RANGE, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      console.log("RESSSS123", response);
      setNRMProductRange(response.data);
    } catch (error) {
      console.error("Error fetching lining:", error);
    }
  };

  const handleChange = (name, value) => {
    // const { name, value } = e.target;
    const TrialName = localStorage.getItem("Trial");
    // Check if TrialName is "new"
    if (TrialName === "new") {
      setLiningMaterialProductDetails((prevState) => ({
        ...prevState,
        [name]: value,
        id: uniqueId, // Set the unique ID
      }));
    } else {
      // If TrialName is not "new", no need to generate unique ID
      setLiningMaterialProductDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Lining material product details
      </h2>

      <div className="flex space-x-4 mt-4">
        {/* Bottom Product Dropdown */}
        <div className="w-1/2">
          <label
            htmlFor="bottom_product"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Bottom Product
          </label>
          <select
            id="bottom_product"
            name="bottom_product"
            value={liningMaterialProductDetails.bottom_product}
            onChange={(e) => handleChange("bottom_product", e.target.value)}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a bottom product</option>
            {nrmProductRange.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
        </div>

        {/* Sidewall Product 1 Dropdown */}
        <div className="w-1/2">
          <label
            htmlFor="sidewall_product_one"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Sidewall Product 1
          </label>
          <select
            id="sidewall_product_one"
            name="sidewall_product_one"
            value={liningMaterialProductDetails.sidewall_product_one}
            onChange={(e) =>
              handleChange("sidewall_product_one", e.target.value)
            }
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a sidewall product 1</option>
            {nrmProductRange.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex space-x-4 mt-4">
        {/* Bottom Product Dropdown */}

        {/* Sidewall Product 1 Dropdown */}
        <div className="w-1/2">
          <label
            htmlFor="sidewall_product_two"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Sidewall product 2
          </label>
          <select
            id="sidewall_product_two"
            name="sidewall_product_two"
            value={liningMaterialProductDetails.sidewall_product_two}
            onChange={(e) =>
              handleChange("sidewall_product_two", e.target.value)
            }
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a sidewall product 1</option>
            {nrmProductRange.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
        </div>

        <div className="w-1/2">
          <label
            htmlFor="top_locking_product"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Top Locking product
          </label>
          <select
            id="top_locking_product"
            name="top_locking_product"
            value={liningMaterialProductDetails.top_locking_product}
            onChange={(e) => handleChange("top_locking_product", e.target.value)}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a top locking product</option>
            {nrmProductRange.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </div>



      <div className="flex space-x-4 mt-4">
        {/* Bottom Product Dropdown */}

        {/* Sidewall Product 1 Dropdown */}
        <div className="w-1/2">
          <label
            htmlFor="topping_mass_product"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Topping Mass Product
          </label>
          <select
            id="topping_mass_product"
            name="topping_mass_product"
            value={liningMaterialProductDetails.topping_mass_product}
            onChange={(e) =>
              handleChange("topping_mass_product", e.target.value)
            }
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          >
            <option value="">Select a sidewall topping mass product</option>
            {nrmProductRange.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
        </div>

        <div className="w-1/2">
        <label
          htmlFor="sidewall_two_coverage_upto_100"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Side wall 1  Coverage upto X% - %
        </label>
        <input
          type="text"
          id="sidewall_one_coverage_upto_x"
          name="sidewall_one_coverage_upto_x"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Grade"
          value={liningMaterialProductDetails.sidewall_one_coverage_upto_x}
          onChange={(e) =>
            handleChange("sidewall_one_coverage_upto_x", e.target.value)
          }
        />
        </div>
      </div>

      <div className="w-full mb-4 mt-4">
        <label
          htmlFor="sidewall_two_coverage_upto_100"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Side wall 2 Coverage upto 100% 
        </label>
        <input
          type="text"
          id="sidewall_two_coverage_upto_100"
          name="sidewall_two_coverage_upto_100"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter the sidewall 2 coverage upto 100%"
          value={liningMaterialProductDetails.sidewall_two_coverage_upto_100}
          onChange={(e) =>
            handleChange("sidewall_two_coverage_upto_100", e.target.value)
          }
        />
      </div>
    </>
  );
};

export default LiningMaterialProductWeb;
