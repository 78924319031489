import React from 'react';
import { useRecoilState } from 'recoil';
import { other_parameters, general_details } from '../../recoil/atom';

const OtherParametersWeb = () => {
  const [otherParameters, setOtherParameters] = useRecoilState(other_parameters)
  const [generalDetails] = useRecoilState(general_details)
  const uniqueId = generalDetails.id ? generalDetails.id : ''

  const handleChange = (e) => {
    const { name, value } = e.target
    console.log(value, 'value')
    const TrialName = localStorage.getItem('Trial')

    const regex = /^\d{0,5}(\.\d{0,2})?$/

    if (regex.test(value) || (name === 'remarks' && value.length <= 75)) {
      if (TrialName === 'new') {
        setOtherParameters((prevState) => ({
          ...prevState,
          [name]: value,
          id: uniqueId,
        }))
      } else {
        // If TrialName is not "new", no need to generate unique ID
        setOtherParameters((prevState) => ({
          ...prevState,
          [name]: value,
        }))
      }
    }
  }

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">Other Parameters</h2>
      <div className="flex space-x-4">
        <div className="w-full mb-4">
          <label htmlFor="inlet_water_temp" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Inlet water temp. - ⁰C
          </label>
          <input
            type="text"
            id="inlet_water_temp"
            name="inlet_water_temp"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Inlet water temp."
            value={otherParameters.inlet_water_temp}
          onChange={handleChange}
          />
        </div>

        <div className="w-full mb-4">
          <label htmlFor="outlet_water_temp" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Outlet Water temp. - ⁰C
          </label>
          <input
            type="text"
            id="outlet_water_temp"
            name="outlet_water_temp"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Outlet Water temp."
            value={otherParameters.outlet_water_temp}
          onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Customer Ref No. */}
        <div className="w-full mb-4">
          <label htmlFor="water_pr" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Water Pr. - Bar
          </label>
          <input
            type="text"
            id="water_pr"
            name="water_pr"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Water Pr."
            value={otherParameters.water_pr}
          onChange={handleChange}
          />
        </div>

        {/* Grade */}
        <div className="w-full mb-4">
          <label htmlFor="remarks" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Remarks
          </label>
          <input
            type="text"
            id="remarks"
            name="remarks"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter remarks"
            value={otherParameters.remarks}
            onChange={handleChange}
          />
        </div>
      </div>

    </>
  );
};

export default OtherParametersWeb;
