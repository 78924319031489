import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { repairing_details, repairing_details_nrm, trial_name } from "../../../recoil/atom";
import Helper from "../../../service/Helper";

const PatchingDetails = () => {
  const [repairingDetails, setRepairingDetails] =
    useRecoilState(repairing_details)
  const [repairingDetailsNRM, setRepairingDetailsNRM] = useRecoilState(
    repairing_details_nrm
  )
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'

  const handleRepairingDetailsChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setRepairingDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleRepairingDetailsNRMChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setRepairingDetailsNRM((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  useEffect(() => {
    let repairingDetailsObj = {
      repairingDetails: {
        basic: repairingDetails,
        nrm: repairingDetailsNRM,
      },
    }
    console.log(repairingDetailsObj, '35656123repairing')
  }, [repairingDetails, repairingDetailsNRM])

  useEffect(() => {
    localStorage.setItem('repairingDetails', JSON.stringify(repairingDetails))
    localStorage.setItem(
      'repairingDetailsNRM',
      JSON.stringify(repairingDetailsNRM)
    )
  }, [repairingDetails, repairingDetailsNRM])

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))

  return (
    <>
      <h2 className="text-l font-medium text-gray-400 mb-4 uppercase">
        Repairing Details(E)
      </h2>
      <div className="w-full mb-4">
        <label
          htmlFor="ramming_material_repairing_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ramming material used in repairing - 1
        </label>
        <select
          id="ramming_material_repairing_1"
          name="ramming_material_repairing_1"
          value={repairingDetails.ramming_material_repairing_1}
          onChange={(event) =>
            handleRepairingDetailsChange(
              'ramming_material_repairing_1',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_repairing_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Quantity of material required for repairing - 1 (MT)
        </label>
        <input
          type="text"
          id="qty_repairing_1"
          name="qty_repairing_1"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Quantity"
          value={repairingDetails.qty_repairing_1}
          onChange={(event) =>
            handleRepairingDetailsChange('qty_repairing_1', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_2"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material used in spout and top - 2
        </label>
        <select
          id="material_used_spout_and_top_2"
          name="material_used_spout_and_top_2"
          value={repairingDetails.material_used_spout_and_top_2}
          onChange={(event) =>
            handleRepairingDetailsChange(
              'material_used_spout_and_top_2',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_spout_and_top_2"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Qty. of Material required for Spout & Top - 2 (MT)
        </label>
        <input
          type="text"
          id="qty_spout_and_top_2"
          name="qty_spout_and_top_2"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Quantity"
          value={repairingDetails.qty_spout_and_top_2}
          onChange={(event) =>
            handleRepairingDetailsChange(
              'qty_spout_and_top_2',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material used in spout and top - 3
        </label>
        <select
          id="material_used_spout_and_top_3"
          name="material_used_spout_and_top_3"
          value={repairingDetails.material_used_spout_and_top_3}
          onChange={(event) =>
            handleRepairingDetailsChange(
              'material_used_spout_and_top_3',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Qty. of Material required for Spout & Top - 3 (MT)
        </label>
        <input
          type="text"
          id="qty_spout_and_top_3"
          name="qty_spout_and_top_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Quantity"
          value={repairingDetails.qty_spout_and_top_3}
          onChange={(event) =>
            handleRepairingDetailsChange(
              'qty_spout_and_top_3',
              event.target.value
            )
          }
        />
      </div>
      {/* nrm  */}
      <h2 className="text-l font-medium text-gray-800 mb-4 uppercase">
       NRM
      </h2>
      <div className="w-full mb-4">
        <label
          htmlFor="ramming_material_side_lining_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Ramming material used in repairing - 1
        </label>
        <select
          id="ramming_material_side_lining_1"
          name="ramming_material_side_lining_1"
          value={repairingDetailsNRM.ramming_material_repairing_1}
          onChange={(event) =>
            handleRepairingDetailsNRMChange(
              'ramming_material_side_lining_1',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_repairing_1"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Quantity of material required for repairing - 1 (MT)
        </label>
        <input
          type="text"
          id="qty_repairing_1"
          name="qty_repairing_1"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Quantity"
          value={repairingDetailsNRM.qty_repairing_1}
          onChange={(event) =>
            handleRepairingDetailsNRMChange('qty_repairing_1', event.target.value)
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_2"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material used in spout and top - 2
        </label>
        <select
          id="material_used_spout_and_top_2"
          name="material_used_spout_and_top_2"
          value={repairingDetailsNRM.material_used_spout_and_top_2}
          onChange={(event) =>
            handleRepairingDetailsNRMChange(
              'material_used_spout_and_top_2',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_spout_and_top_2"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Qty. of Material required for Spout & Top - 2 (MT)
        </label>
        <input
          type="text"
          id="qty_spout_and_top_2"
          name="qty_spout_and_top_2"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Quantity"
          value={repairingDetailsNRM.qty_spout_and_top_2}
          onChange={(event) =>
            handleRepairingDetailsNRMChange(
              'qty_spout_and_top_2',
              event.target.value
            )
          }
        />
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="material_used_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Material used in spout and top - 3
        </label>
        <select
          id="material_used_spout_and_top_3"
          name="material_used_spout_and_top_3"
          value={repairingDetailsNRM.material_used_spout_and_top_3}
          onChange={(event) =>
            handleRepairingDetailsNRMChange(
              'material_used_spout_and_top_3',
              event.target.value
            )
          }
          className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        >
          <option value="">Select a Material</option>
          {Helper.CostSheet.map((costSheet) => (
            <option key={costSheet.id} value={costSheet.name}>
              {costSheet.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full mb-4">
        <label
          htmlFor="qty_spout_and_top_3"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Qty. of Material required for Spout & Top - 3
        </label>
        <input
          type="text"
          id="qty_spout_and_top_3"
          name="qty_spout_and_top_3"
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Quantity"
          value={repairingDetailsNRM.qty_spout_and_top_3}
          onChange={(event) =>
            handleRepairingDetailsNRMChange(
              'qty_spout_and_top_3',
              event.target.value
            )
          }
        />
      </div>
    </>
  );
};

export default PatchingDetails;
