import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import API from "./../../service/API";

const StackedBarChart = ({ selectedRegion, userId, selectedDates }) => {
  const [seriesData, setSeriesData] = useState([]);
  const storedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [categories] = useState([
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ]);
  const access_token = localStorage.getItem("accesstoken");
  console.log(access_token, "aaa")

  useEffect(() => {
    const fetchMonthlyCounts = async () => {
      try {
        const params = {
          customerId: storedCustomer.id,
          userId: currentUser.id,
          regionId: selectedRegion,
          startDate: selectedDates.startDate,
          endDate: selectedDates.endDate,
        };

        console.log(params, "params")

        const responses = await Promise.all([
          axios.get(API.GET_DTM_MONTHLY_COUNT, {
            params,
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }),
          axios.get(API.GET_NRM_MONTHLY_COUNT, {
            params,
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }),
          axios.get(API.GET_EP_MONTHLY_COUNT, {
            params,
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }),
          axios.get(API.GET_COST_SHEET_MONTHLY_COUNT, {
            params,
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }),
          axios.get(API.GET_DATA_COLLECTION_MONTHLY_COUNT, {
            params,
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }),
        ]);


        const [
          dtmResponse,
          nrmResponse,
          erosionResponse,
          costSheetResponse,
          dataCollectionResponse,
        ] = responses;

        // Initialize empty arrays for each series
        const dtmData = Array(12).fill(0);
        const nrmData = Array(12).fill(0);
        const erosionData = Array(12).fill(0);
        const costSheetData = Array(12).fill(0);
        const dataCollectionData = Array(12).fill(0);

        // Map the data to the correct months
        dtmResponse.data.forEach(item => {
          const monthIndex = new Date(item.month).getMonth(); // Assuming 'item.month' is in 'YYYY-MM' format
          dtmData[monthIndex] = parseInt(item.total_daily_trial || 0);
        });

        nrmResponse.data.forEach(item => {
          const monthIndex = new Date(item.month).getMonth(); // Assuming 'item.month' is in 'YYYY-MM' format
          nrmData[monthIndex] = parseInt(item.total_nrm_performance || 0);
        });

        erosionResponse.data.forEach(item => {
          const monthIndex = new Date(item.month).getMonth(); // Assuming 'item.month' is in 'YYYY-MM' format
          erosionData[monthIndex] = parseInt(item.total_erosion_profiles || 0);
        });

        costSheetResponse.data.forEach(item => {
          const monthIndex = new Date(item.month).getMonth(); // Assuming 'item.month' is in 'YYYY-MM' format
          costSheetData[monthIndex] = parseInt(item.total_cost_sheet || 0);
        });

        dataCollectionResponse.data.forEach(item => {
          const monthIndex = new Date(item.month).getMonth(); // Assuming 'item.month' is in 'YYYY-MM' format
          dataCollectionData[monthIndex] = parseInt(item.total_data_collection || 0);
        });

        // Set the data for the chart
        setSeriesData([
          { name: "Daily Trial Monitoring", data: dtmData },
          { name: "NRM Performance", data: nrmData },
          { name: "Erosion Profile", data: erosionData },
          { name: "Cost Sheet", data: costSheetData },
          { name: "Data Collection", data: dataCollectionData },
        ]);
      } catch (error) {
        console.error("Error fetching monthly counts:", error);

        // Handle error case by resetting seriesData or adding fallback data
        setSeriesData([
          { name: "Daily Trial Monitoring", data: Array(12).fill(0) },
          { name: "NRM Performance", data: Array(12).fill(0) },
          { name: "Erosion Profile", data: Array(12).fill(0) },
          { name: "Cost Sheet", data: Array(12).fill(0) },
          { name: "Data Collection", data: Array(12).fill(0) },
        ]);
      }
    };

    fetchMonthlyCounts();
  }, [selectedRegion, userId, selectedDates]); // Dependency array includes `selectedRegion`, `userId`, and `selectedDates`

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        columnWidth: "50%",
      },
    },
    xaxis: {
      categories: categories,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    fill: {
      opacity: 1,
    },
    colors: ["#00A81C", "#9747FF", "#F25C19", "#ef4444", "#9ca3af"],
  };

  return seriesData.length > 0 ? (
    <div className="w-full p-4">
      <Chart options={options} series={seriesData} type="bar" height="400" />
    </div>
  ) : (
    <div>Loading chart data...</div>
  );
};

export default StackedBarChart;
