import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the default styles for react-datepicker
import { ReactComponent as CalendarIcon } from "../../assets/images/icons/calendar.svg"; // Make sure this path is correct

const DateRangePicker = ({ startDate, endDate, onDateChange }) => {
  const handleStartDateChange = (date) => {
    onDateChange({
      startDate: date,
      endDate: date > endDate ? null : endDate, // Reset endDate if startDate is after it
    });
  };

  const handleEndDateChange = (date) => {
    onDateChange({
      startDate,
      endDate: date,
    });
  };

  const CustomInput = ({ value, onClick }) => (
    <div className="relative w-full">
      <input
        type="text"
        value={value}
        onClick={onClick}
        className="w-48 p-2 pl-4 pr-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="DD/MM/YYYY"
      />
      <CalendarIcon
        onClick={onClick}
        className="absolute right-2 top-2 cursor-pointer"
        width={20}
        height={20}
      />
    </div>
  );

  return (
    <div className="flex justify-between gap-4 w-full">
      <div className="w-1/2">
        <label className="text-black w-full block font-medium text-xs">From</label>
        <DatePicker
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="DD-MM-YYYY"
          className="w-full"
          dateFormat="dd-MM-yyyy" // Updated to use DD/MM/YYYY format
          customInput={<CustomInput />}
        />
      </div>

      <div className="w-1/2">
        <label className="text-black w-full block font-medium text-xs">To</label>
        <DatePicker
          selected={endDate}
          onChange={handleEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate} // Ensure the "To" date is after or equal to the "From" date
          placeholderText="DD-MM-YYYY"
          className="w-full"
          dateFormat="dd-MM-yyyy" // Updated to use DD/MM/YYYY format
          customInput={<CustomInput />}
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
