import React, { useState, useEffect } from 'react'
import Plot from 'react-plotly.js'
// import { ReactComponent as DownloadIcon } from '../../assets/icons/download_icon.svg'
// import { ReactComponent as DeletIcon } from '../../assets/icons/delete_icon.svg'
import SGLogo from '../../assets/images/SaintGobainLogo.svg'
import { sampleCorrelation } from 'simple-statistics'
import { useLocation } from 'react-router-dom'
import API from './../../service/API'
import axios from 'axios'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import AlertDialog from './../Common/AlertDialog'
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/Trash.svg";

import Plotly from 'plotly.js-dist-min'
import MultiSelect from '../Common/MultiSelect'

const Charts = () => {
    const location = useLocation()
    const [tabs, setTabs] = useState([])
    const tab = localStorage.getItem('selectedTabLabel')
    const [selectedTab, setSelectedTab] = useState(tab || tabs[0].label)
    const [warning, setWarning] = useState('')
    useEffect(() => {
        localStorage.setItem('selectedTabLabel', selectedTab)
    }, [selectedTab])

    const templateId = location.state ? location.state.template : null
    console.log('templateId', templateId)
    const data = location.state ? location.state.cleanedDataGraph : null
    console.log('cleanedDataGraph', data)
    //console.log(templateId,'templateId');

    const actualdata = location.state ? location.state.actualdata : null ///data
    console.log('actualdata', actualdata)

    const [modalOpen, setModalOpen] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [selectedChart, setSelectedChart] = useState(null)
    const [charts, setCharts] = useState([])
    const [updateCharts, setUpdateCharts] = useState([])
    const [graphType, setGraphType] = useState('')
    const [metric, setMetric] = useState('')
    const [xAxis, setXAxis] = useState('')
    const [yAxis, setYAxis] = useState([])
    const [selectedCharts, setSelectedCharts] = useState([])
    const [activeChart, setActiveChart] = useState(null)
    const [allCharts, setAllCharts] = useState(charts)
    const [correlationMatrix, setCorrelationMatrix] = useState([])
    const [notes, setNotes] = useState('')
    const [jsonMap, setJsonMap] = useState([])
    const [jsonData, setJsonData] = useState(null)
    const [heatmapModalOpen, setHeatmapModalOpen] = useState(false)
    const [heatmapSelection, setHeatmapSelection] = useState('')
    const handleModalOpen = () => {
        setShowModal(true)
    }

    const handleModalClose = () => {
        setShowModal(false)
    }

    const handleGraphTypeChange = (event) => {
        setGraphType(event.target.value)
        console.log("graphsss",event.target.value);
        // if (event.target.value === 'heatmap' || metric === 'correlation') {
        //     setHeatmapModalOpen(true)
        //     setHeatmapSelection('')
        //     if (tab === 'Daily Trial Monitoring') {
        //         setXAxis('lorp_id')
        //     } else if (tab === 'NRM Performance') {
        //         setXAxis('life')
        //     }
        // } else {
        //      console.log("graphsssnot");
        //     setXAxis('')
        //     setHeatmapModalOpen(false)
           
        // }
    }

    useEffect(() => {
        if (graphType === 'heatmap' || metric === 'correlation') {
            setHeatmapModalOpen(true);
            setHeatmapSelection('');
            if (tab === 'Daily Trial Monitoring') {
                setXAxis('lorp_id');
                setGraphType('heatmap');
            } else if (tab === 'NRM Performance') {
                setXAxis('life');
                setGraphType('heatmap');
            }
        } else {
            setXAxis('');
            setGraphType('');
            setHeatmapModalOpen(false);
        }
    }, [graphType, metric, tab]);
    

    const handleXAxisChange = (event) => {
        console.log("xaxizzz",event.target.value);
        setXAxis(event.target.value)
    }

    const handleMetricChange = (event) => {
        const selectedMetric = event.target.value
        setMetric(selectedMetric)
        console.log("metric",metric);
        setGraphType('')
        setXAxis('')
        setYAxis([])
    }

      // Log metric whenever it changes
    //   useEffect(() => {
    //     console.log("Updated metric:", metric);
    // }, [metric]);

    // const handleYAxisChange = (event) => {
    //   const selected = event.target.value;
    //   setYAxis(selected);
    // };

    const handleYAxisChange = (event) => {
        const {
            target: { value },
        } = event

        // Check if the number of selections exceeds 5
        if (value.length > 5) {
            setWarning('You can only select up to 5 options.')
        } else {
            setWarning('')
            setYAxis(value)
        }
    }

    // const tabs = [
    //   { label: "Daily Trial Monitoring", value: "1278e494-7771-421c-8bbe-d0409031efa2" },
    //   { label: "NRM performance", value: "0271c530-6fb5-4b96-9576-3eadaab5c317" },
    //   { label: "Erosion profile", value: "492e5308-1ebd-4cf0-965b-0f323cc2e531" }
    // ];

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    const handleNotesChange = (event) => {
        setNotes(event.target.value)
    }

    function calculateMean(array) {
        return array.reduce((total, val) => total + val, 0) / array.length
    }
    const cardId = localStorage.getItem('reportCardId')
    const rowIdsString = localStorage.getItem('rowIdsString')
    const rowIdsArray = rowIdsString.split(',')
    //console.log('rowIdsArray',rowIdsArray);

    // let jsonData = null;
    // const jsonMap=[]
    useEffect(() => {
        fetchCards()
    }, [])

    const fetchCards = async () => {
        try {
            const url_tab = `${API.GET_TEMPLATETAB}`
            const response_tab = await axios.get(url_tab)
            const data = response_tab.data
            console.log('tab data', data)
            const trimmedData = data.slice(0, -2)
            const tab_data = trimmedData.map((item) => ({
                label: item.name,
                value: item.id,
            }))

            setTabs(tab_data)

            const repcardId = localStorage.getItem('reportCardId')

            const response = await axios.get(
                API.GET_GRAPH,
                {
                    params: { reports_id: repcardId, template_id: templateId },
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            // const graphdata = response.data;
            const responseData = await response.data[0].standard_graphs

            // const sg=data.standard_graphs;
            // //console.log("sg",sg);
            console.log("responsegraph", responseData);
            setCharts(responseData)
        } catch (error) {
            //console.error('An error occurred while fetching submitted cards:', error);
            // Optionally, you can handle the error state here
        }
    }

    const handleAddGraph = () => {
        let lastId = 0
        if (charts.length > 0) {
            lastId = charts[charts.length - 1].id
        }

        // Calculate newId
        const newId = lastId + 1
        //console.log('newId',newId, charts.length);
        // const yAxis = yAxis;
        //console.log('yAxis',yAxis);
        const newData = groupDataByXAxis(data, xAxis, yAxis)
        const newData2 = groupDataByXAxis1(actualdata, xAxis, yAxis)
        console.log("data",data);
        console.log("xAxis",xAxis);
        console.log("yAxis",yAxis);
        console.log("newData",newData);

        const newJsonData = {
            notes: notes,
            graph_type: graphType,
            x_axis: xAxis,
            y_axis: yAxis,
        }
        setJsonData(newJsonData)

        setJsonMap((prevJsonMap) => [...prevJsonMap, newJsonData])

        const newCharts = []
        const chartData = []

        if (tab === 'Daily Trial Monitoring') {
            // Define your y-axis variables here
            try {
                if (graphType === 'box') {
                    // Assuming yAxis is an array with at least one element
                    const yAxisItem = yAxis.length > 0 ? yAxis[0] : null

                    for (const key in newData2) {
                        if (newData2.hasOwnProperty(key) && key !== 'undefined') {
                            chartData.push({
                                x: key,
                                y: newData2[key][yAxisItem], // Data for each y-axis variable
                                name: key, // Use the key as the name
                                type: 'box',
                            })
                        }
                    }

                    //console.log('chartData', chartData);
                } else if (
                    graphType === 'bar' ||
                    graphType === 'scatter' ||
                    graphType === 'line' ||
                    graphType === 'horizontal-bar'
                ) {
                    //console.log("metricmetricmetric", metric);
                    yAxis.forEach((yAxisItem) => {
                        const xValues = []
                        const yValues = []
                        for (const key in newData) {
                            if (Object.hasOwnProperty.call(newData, key)) {
                                // xValues.push(key);
                                const values = newData[key][yAxisItem]
                                //console.log('values', values);
                                let result
                                switch (metric) {
                                    case 'mean':
                                        result = calculateMean(values)
                                        break
                                    case 'first_value':
                                        result = Array.isArray(values) ? values[0] : values // Handle array values
                                        break
                                    case 'last_value':
                                        result = Array.isArray(values)
                                            ? values[values.length - 1]
                                            : values // Handle array values
                                        break
                                    case 'maximum':
                                        result = Math.max(...values)
                                        break
                                    case 'minimum':
                                        result = Math.min(...values)
                                        break
                                    case 'total':
                                        result = Array.isArray(values)
                                            ? values.reduce((total, val) => total + val, 0)
                                            : values
                                        break
                                    case 'count':
                                        result = Array.isArray(values) ? values.length : 1 // If array, return length; else, return 1
                                        break
                                    default:
                                        // Default behavior, in case metric is not recognized
                                        result = calculateMean(values)
                                }
                                //console.log('result', result);
                                // yValues.push(result);
                                if (key !== 'undefined') {
                                    xValues.push(key)
                                    yValues.push(result)
                                }
                            }
                        }
                        //console.log("xValues",xValues);
                        //console.log("yValues",yValues);
                        chartData.push({
                            x: graphType === 'horizontal-bar' ? yValues : xValues,
                            y: graphType === 'horizontal-bar' ? xValues : yValues,
                            mode: graphType === 'scatter' ? 'markers' : 'lines',
                            name: yAxisItem,
                            type: graphType === 'horizontal-bar' ? 'bar' : graphType,
                            orientation: graphType === 'horizontal-bar' ? 'h' : undefined,
                            metric: metric,
                        })
                    })
                }
                // Handle stacked-bar chart
                else if (graphType === 'stacked-bar') {
                    const categories = Object.keys(newData).filter(
                        (category) => category !== 'undefined'
                    )
                    yAxis.forEach((yAxisItem) => {
                        //console.log("categories",categories);
                        const yValues = categories.map((category) => {
                            const values = newData[category][yAxisItem]
                            let result
                            switch (metric) {
                                case 'mean':
                                    result = calculateMean(values)
                                    break
                                case 'first_value':
                                    result = Array.isArray(values) ? values[0] : values
                                    break
                                case 'last_value':
                                    result = Array.isArray(values)
                                        ? values[values.length - 1]
                                        : values
                                    break
                                case 'maximum':
                                    result = Math.max(...values)
                                    break
                                case 'minimum':
                                    result = Math.min(...values)
                                    break
                                case 'total':
                                    result = Array.isArray(values)
                                        ? values.reduce((total, val) => total + val, 0)
                                        : values
                                    break
                                case 'count':
                                    result = Array.isArray(values) ? values.length : 1
                                    break
                                default:
                                    result = calculateMean(values)
                            }
                            return result
                        })
                        chartData.push({
                            x: categories,
                            y: yValues,
                            name: yAxisItem,
                            type: 'bar',
                            metric: metric,
                        })
                    })
                } else if (graphType === 'pie') {
                    const categories = Object.keys(newData).filter(
                        (category) => category !== 'undefined'
                    )
                    const values = categories.map((category) => {
                        let result = 0
                        yAxis.forEach((yAxisItem) => {
                            // Loop through each yAxisItem
                            switch (metric) {
                                case 'mean':
                                    result += calculateMean(newData[category][yAxisItem])
                                    break
                                case 'first_value':
                                    result += Array.isArray(newData[category][yAxisItem])
                                        ? newData[category][yAxisItem][0]
                                        : newData[category][yAxisItem]
                                    break
                                case 'last_value':
                                    result = Array.isArray(newData[category][yAxisItem])
                                        ? newData[category][yAxisItem][
                                        newData[category][yAxisItem].length - 1
                                        ]
                                        : newData[category][yAxisItem]
                                    break
                                case 'maximum':
                                    result = Array.isArray(newData[category][yAxisItem])
                                        ? Math.max(...newData[category][yAxisItem])
                                        : newData[category][yAxisItem]
                                    break
                                case 'minimum':
                                    result = Array.isArray(newData[category][yAxisItem])
                                        ? Math.min(...newData[category][yAxisItem])
                                        : newData[category][yAxisItem]
                                    break
                                case 'total':
                                    result = Array.isArray(newData[category][yAxisItem])
                                        ? newData[category][yAxisItem].reduce((a, b) => a + b, 0)
                                        : newData[category][yAxisItem]
                                    break
                                case 'count':
                                    result = Array.isArray(newData[category][yAxisItem])
                                        ? newData[category][yAxisItem].length
                                        : 1
                                    break
                                default:
                                    result = calculateMean(newData[category][yAxisItem])
                            }
                        })
                        return result
                    })

                    // Construct the data for the pie chart
                    const dataForPie = {
                        labels: categories,
                        values: values,
                        type: 'pie',
                        metric: metric,
                    }

                    // Push the dataForPie object into the chartData array
                    chartData.push(dataForPie)
                } else if (graphType === 'heatmap') {
                    try {
                        // Extract all unique lorp_id values
                        const lorpIds = Array.from(
                            new Set(data.map((item) => item.lorp_id))
                        )
                        //console.log("lorpIds", lorpIds);

                        // Filter out undefined values
                        const validLorpIds = lorpIds.filter((id) => id !== undefined)

                        // Prepare an empty correlation matrix
                        const correlationMatrix = []

                        // Loop through each lorp_id
                        validLorpIds.forEach((lorpId) => {
                            //console.log("lorpIdslorpIdslorpIds", lorpIds);
                            // Filter data for the current lorp_id
                            const filteredData = data.filter(
                                (item) => item.lorp_id === lorpId
                            )
                            //console.log("filteredData", filteredData);
                            // Calculate correlation coefficient for each yAxis option
                            const correlations = yAxis.map((yAxisItem) => {
                                // Filter data for the current yAxisItem
                                const yAxisData = filteredData.map((item) => item[yAxisItem])
                                //   //console.log("Data type of yAxisData:", typeof yAxisData);
                                //console.log("yAxisData:", yAxisData);
                                //   //console.log("Is yAxisData an array?", Array.isArray(yAxisData));
                                //   // const yAxisValues = Array.isArray(yAxisData) ? yAxisData : [yAxisData];
                                //   const numericYAxisData = yAxisData.map(timeString => {
                                //     const [hours, minutes] = timeString.split(':').map(Number);
                                //     return hours + minutes / 60; // Convert minutes to fraction of an hour
                                // });
                                //   //console.log("Data type of yAxisValues:", typeof numericYAxisData);
                                //   //console.log("numericYAxisData", numericYAxisData);
                                //   //console.log("Data type of filteredData:", typeof filteredData.map(item => item.heat_tap_time));

                                const check = filteredData.map((item) => item[heatmapSelection])
                                //console.log("check:", check);
                                //   const numericcheckData = check.map(timeString => {
                                //     const [hours, minutes] = timeString.split(':').map(Number);
                                //     return hours + minutes / 60; // Convert minutes to fraction of an hour
                                // });
                                //   //console.log(" numericcheckData:",  numericcheckData);
                                //   //console.log("Is check an array?", Array.isArray(check));

                                if (yAxisData.some(isNaN) || check.some(isNaN)) {
                                    return // Skip this lorpId
                                }

                                const correlation = sampleCorrelation(yAxisData, check)
                                //console.log("correlation", correlation);

                                return correlation
                            })

                            // Push correlation data for the current lorp_id
                            correlationMatrix.push({
                                lorp_id: lorpId,
                                correlations: correlations,
                            })
                        })

                        // Prepare data for the heatmap
                        const heatmapData = {
                            x: yAxis, // The yAxis items will be represented on the x-axis
                            y: correlationMatrix.map((matrix) => matrix.lorp_id),
                            z: correlationMatrix.map((matrix) => matrix.correlations),
                            type: 'heatmap',
                            colorscale: 'Viridis',
                            heatmapSelection: heatmapSelection,
                        }
                        //console.log("heatmapData", heatmapData);
                        // Push heatmapData to chartData
                        chartData.push(heatmapData)
                    } catch (error) {
                        AlertDialog({
                            type: "warning", // Changed type to "warning" since it aligns with the title
                            title: "Warning",
                            text: "sampleCovariance requires at least two data points in each sample",
                            confirmButtonText: "Ok",
                          });
                        //console.error("Error:", error);
                        window.alert(
                            'Error: sampleCovariance requires at least two data points in each sample'
                        )
                    }
                }
            } catch (error) {
                //console.error('An error occurred while inserting chart data:', error);
                window.alert('An error occurred while inserting chart data.' + error)
                // Optionally, handle error state here
            }
        } else if (tab === 'NRM Performance') {
            try {
                if (graphType === 'box') {
                    // Assuming yAxis is an array with at least one element
                    const yAxisItem = yAxis.length > 0 ? yAxis[0] : null

                    for (const key in newData2) {
                        if (newData2.hasOwnProperty(key) && key !== 'undefined') {
                            chartData.push({
                                x: key,
                                y: newData2[key][yAxisItem], // Data for each y-axis variable
                                name: key, // Use the key as the name
                                type: 'box',
                            })
                        }
                    }

                    //console.log('chartData', chartData);
                } else if (
                    graphType === 'bar' ||
                    graphType === 'scatter' ||
                    graphType === 'line' ||
                    graphType === 'horizontal-bar'
                ) {
                    //console.log("metricmetricmetric", metric);
                    yAxis.forEach((yAxisItem) => {
                        const xValues = []
                        const yValues = []
                        for (const key in newData) {
                            if (Object.hasOwnProperty.call(newData, key)) {
                                // xValues.push(key);
                                const values = newData[key][yAxisItem]
                                //console.log('values', values);
                                let result
                                switch (metric) {
                                    case 'mean':
                                        result = calculateMean(values)
                                        //console.log('result', result);
                                        break
                                    case 'first_value':
                                        result = Array.isArray(values) ? values[0] : values // Handle array values
                                        //console.log('result', result);
                                        break
                                    case 'last_value':
                                        result = Array.isArray(values)
                                            ? values[values.length - 1]
                                            : values // Handle array values
                                        //console.log('result', result);
                                        break
                                    case 'maximum':
                                        result = Math.max(...values)
                                        //console.log('result', result);
                                        break
                                    case 'minimum':
                                        result = Math.min(...values)
                                        //console.log('result', result);
                                        break
                                    case 'total':
                                        result = Array.isArray(values)
                                            ? values.reduce((total, val) => total + val, 0)
                                            : values
                                        //console.log('result', result);
                                        break
                                    case 'count':
                                        result = Array.isArray(values) ? values.length : 1 // If array, return length; else, return 1
                                        //console.log('result', result);
                                        break
                                    default:
                                        // Default behavior, in case metric is not recognized
                                        result = calculateMean(values)
                                    //console.log('result', result);
                                }
                                //console.log('result', result);
                                // yValues.push(result);
                                if (key !== 'undefined') {
                                    xValues.push(key)
                                    yValues.push(result)
                                }
                            }
                        }
                        //console.log("xValues",xValues);
                        //console.log("yValues",yValues);
                        chartData.push({
                            x: graphType === 'horizontal-bar' ? yValues : xValues,
                            y: graphType === 'horizontal-bar' ? xValues : yValues,
                            mode: graphType === 'scatter' ? 'markers' : 'lines',
                            name: yAxisItem,
                            type: graphType === 'horizontal-bar' ? 'bar' : graphType,
                            orientation: graphType === 'horizontal-bar' ? 'h' : undefined,
                            metric: metric,
                        })
                    })
                }
                // Handle stacked-bar chart
                else if (graphType === 'stacked-bar') {
                    const categories = Object.keys(newData).filter(
                        (category) => category !== 'undefined'
                    )
                    yAxis.forEach((yAxisItem) => {
                        //console.log("categories",categories);
                        const yValues = categories.map((category) => {
                            const values = newData[category][yAxisItem]
                            let result
                            switch (metric) {
                                case 'mean':
                                    result = calculateMean(values)
                                    break
                                case 'first_value':
                                    result = Array.isArray(values) ? values[0] : values
                                    break
                                case 'last_value':
                                    result = Array.isArray(values)
                                        ? values[values.length - 1]
                                        : values
                                    break
                                case 'maximum':
                                    result = Math.max(...values)
                                    break
                                case 'minimum':
                                    result = Math.min(...values)
                                    break
                                case 'total':
                                    result = Array.isArray(values)
                                        ? values.reduce((total, val) => total + val, 0)
                                        : values
                                    break
                                case 'count':
                                    result = Array.isArray(values) ? values.length : 1
                                    break
                                default:
                                    result = calculateMean(values)
                            }
                            return result
                        })
                        chartData.push({
                            x: categories,
                            y: yValues,
                            name: yAxisItem,
                            type: 'bar',
                            metric: metric,
                        })
                    })
                } else if (graphType === 'pie') {
                    const categories = Object.keys(newData).filter(
                        (category) => category !== 'undefined'
                    )
                    const values = categories.map((category) => {
                        let result = 0
                        yAxis.forEach((yAxisItem) => {
                            // Loop through each yAxisItem
                            switch (metric) {
                                case 'mean':
                                    result += calculateMean(newData[category][yAxisItem])
                                    break
                                case 'first_value':
                                    result += Array.isArray(newData[category][yAxisItem])
                                        ? newData[category][yAxisItem][0]
                                        : newData[category][yAxisItem]
                                    break
                                case 'last_value':
                                    result = Array.isArray(newData[category][yAxisItem])
                                        ? newData[category][yAxisItem][
                                        newData[category][yAxisItem].length - 1
                                        ]
                                        : newData[category][yAxisItem]
                                    break
                                case 'maximum':
                                    result = Array.isArray(newData[category][yAxisItem])
                                        ? Math.max(...newData[category][yAxisItem])
                                        : newData[category][yAxisItem]
                                    break
                                case 'minimum':
                                    result = Array.isArray(newData[category][yAxisItem])
                                        ? Math.min(...newData[category][yAxisItem])
                                        : newData[category][yAxisItem]
                                    break
                                case 'total':
                                    result = Array.isArray(newData[category][yAxisItem])
                                        ? newData[category][yAxisItem].reduce((a, b) => a + b, 0)
                                        : newData[category][yAxisItem]
                                    break
                                case 'count':
                                    result = Array.isArray(newData[category][yAxisItem])
                                        ? newData[category][yAxisItem].length
                                        : 1
                                    break
                                default:
                                    result = calculateMean(newData[category][yAxisItem])
                            }
                        })
                        return result
                    })

                    // Construct the data for the pie chart
                    const dataForPie = {
                        labels: categories,
                        values: values,
                        type: 'pie',
                        metric: metric,
                    }

                    // Push the dataForPie object into the chartData array
                    chartData.push(dataForPie)
                } else if (graphType === 'heatmap') {
                    try {
                        // //console.log('xAxis',xAxis);
                        // const yAxis=xAxis;
                        // //console.log('yAxis',yAxis);
                        //console.log('graphType',graphType);

                        //console.log('heatmapSelection', heatmapSelection);
                        // Extract all unique life values
                        const Lifes = Array.from(new Set(data.map((item) => item.life)))
                        //console.log("Lifes", Lifes);

                        // Filter out undefined values
                        const validLifes = Lifes.filter((id) => id !== undefined)

                        // Prepare an empty correlation matrix
                        const correlationMatrix = []

                        // Loop through each life
                        validLifes.forEach((Life) => {
                            //console.log("LifesLifesLifes", Lifes);
                            // Filter data for the current life
                            const filteredData = data.filter((item) => item.life === Life)
                            //console.log("filteredData", filteredData);
                            // Calculate correlation coefficient for each yAxis option
                            const correlations = yAxis.map((yAxisItem) => {
                                // Filter data for the current yAxisItem
                                const yAxisData = filteredData.map((item) => item[yAxisItem])
                                //   //console.log("data type of yAxisData:", typeof yAxisData);
                                //console.log("yAxisData:", yAxisData);

                                const check = filteredData.map((item) => item[heatmapSelection])
                                //console.log("check:", check);

                                if (yAxisData.some(isNaN) || check.some(isNaN)) {
                                    return // Skip this Life
                                }

                                const correlation = sampleCorrelation(yAxisData, check)
                                //console.log("correlation", correlation);

                                return correlation
                            })

                            // Push correlation data for the current life
                            correlationMatrix.push({
                                life: Life,
                                correlations: correlations,
                            })
                        })

                        // Prepare data for the heatmap
                        const heatmapData = {
                            x: yAxis, // The yAxis items will be represented on the x-axis
                            y: correlationMatrix.map((matrix) => matrix.life),
                            z: correlationMatrix.map((matrix) => matrix.correlations),
                            type: 'heatmap',
                            colorscale: 'Viridis',
                            heatmapSelection: heatmapSelection,
                        }
                        //console.log("heatmapData", heatmapData);
                        // Push heatmapData to chartData
                        chartData.push(heatmapData)
                    } catch (error) {
                        //console.error("Error:", error);
                        window.alert(
                            'Error: sampleCovariance requires at least two data points in each sample'
                        )
                    }
                }
            } catch (error) {
                //console.error('An error occurred while inserting chart data:', error);
                window.alert('An error occurred while inserting chart data.' + error)
                // Optionally, handle error state here
            }
        }
        // Create a single chart with the combined data
        newCharts.push({
            id: newId + newCharts.length,
            xAxis: xAxis,
            yAxis: yAxis,
            title: notes,
            // title: `${xAxis}-Combined`, // Use a title indicating it's combined
            data: chartData,
        })
        const updatedData = [...charts, ...newCharts]

        //console.log("updatedData", updatedData);

        setCharts(updatedData)

        axios
            .post(
                API.UPDATE_GRAPH,
                {
                    reports_id: cardId,
                    standard_graphs: updatedData,
                    template_id: templateId,
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((excelresponse) => {
                if (excelresponse.status === 200) {
                    AlertDialog({
                        type: 'success',
                        title: 'Success',
                        text: 'Saved Successfully!',
                        confirmButtonText: 'Ok',
                        onConfirm: async () => {
                            window.location.reload()
                        },
                    })
                } else {
                    //console.error('Error saving data:', excelresponse.data);
                    AlertDialog({
                        type: 'warning',
                        title: 'Error',
                        text: 'Failed to save data',
                    })
                }
            })
        // .catch((error) => {
        //   AlertDialog({
        //     type: 'warning',
        //     title: 'Error',
        //     text: 'Failed to save data',
        //   });
        // });

        setSelectedCharts([])
        setNotes('')
        setShowModal(false)
    }

    const groupDataByXAxis = (data, xAxis, yAxis) => {
        const groupedData = {}

        data.forEach((item) => {
            const xValue = item[xAxis]
            if (!groupedData[xValue]) {
                groupedData[xValue] = {}
            }
            yAxis.forEach((axis) => {
                if (!groupedData[xValue][axis]) {
                    groupedData[xValue][axis] = []
                }
                if (item[axis] !== undefined) {
                    groupedData[xValue][axis].push(item[axis])
                }
            })
        })

        //console.log("groupedData", groupedData);
        return groupedData
    }

    const groupDataByXAxis1 = (actualdata, xAxis, yAxis) => {
        const groupedData = {}

        actualdata.forEach((item) => {
            const xValue = item[xAxis]
            if (!groupedData[xValue]) {
                groupedData[xValue] = {}
            }
            yAxis.forEach((axis) => {
                if (!groupedData[xValue][axis]) {
                    groupedData[xValue][axis] = []
                }
                if (item[axis] !== undefined) {
                    groupedData[xValue][axis].push(item[axis])
                }
            })
        })

        console.log('groupedData', groupedData)
        return groupedData
    }

    const groupedData = groupDataByXAxis(data, xAxis, yAxis) // Call groupDataByXAxis to get grouped data

    //console.log('jsonMap', jsonMap);

    // Generate traces based on groupedData
    const traces = []
    Object.keys(groupedData).forEach((xValue) => {
        Object.keys(groupedData[xValue]).forEach((yAxis) => {
            traces.push({
                y: groupedData[xValue][yAxis],
                name: `${xValue} - ${yAxis}`,
                type: 'box',
            })
        })
    })

    const convertImageToBase64 = () => {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = () => {
                const canvas = document.createElement('canvas')
                canvas.width = img.width
                canvas.height = img.height

                const ctx = canvas.getContext('2d')
                ctx.drawImage(img, 0, 0)

                const base64Img = canvas.toDataURL('image/png')
                resolve(base64Img)
            }
            img.onerror = (error) => {
                reject(error)
            }
            img.src = SGLogo
        })
    }

    //  const base64Img = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARDSURBVHgB5VZNaF1FFP7O3Js0ie8lBTVaJfo22tJN2rpqFBHEhZqKboxCoYK1G0EptGuRgkgJFBcuNIJxI6kLLbF14c9Oo1DandC4KW2hQWpqm74meUnuPT1z5ufeV0rfeymlix64d+bOnDvnO2e+OWeA+12olcLjn44OU5ZMiuI2tCH9Tw0hrfahPeFJ00qlE+OdC73TGgBhI+6itARwt+WeA0gHd28fJuYjAqXGdkRiDjJKTzIGK+f+rdkx0nEC67hv5WHRSXo2IKm0S7ybACDLj8HQk8zeuFgmfZGOYC1TYwGU6CLPrarx44wsW4Lp6QalKTqVVDyrScvSWou6pm2hiKS1E2KZbde27P8k1XMq8ldj7jKSgV40lq8j68rbB8BslyTrCGuY4ZOD2qVgzsUlBqmk5995vQFeWgVvlYhx+wDKJLQY1KuDb+zDycPT+Pr9wxQ8fnPnKzj5yQ/4fv/n4BgG+D+c5BZAI0MnYoLX0PC7/Z/6/TgG+ip4eccLeHbLM2rk4GvvYejBTZj68wTCP44kzcmU8/a9VwDQYJO3rQ9fuHSRJn6ZUtcOiOG3RkbV+IX5OXz310/OELNTBjdFoVMxbi0Oq2hwLQm/+PlbXF28hpHNO3BobL8qfzB56JaLUOuSclsATWv5MNDCUh3j01+pa/19VRydOYGZf04rQvU+EpEijPVICsd3RrGt8Rj+fX42rnpewh8QulCx/9EdT1xbAZbWkC8sY623C+2K4UBpjowmL/js3Y+i4r4Xx/CE8MArxPF4PDMBspKDV7OOHkMhx+or5Ffmsed2OeL9N4cvf52SU1HFgV17YyIqg7hjEobIKxN9SIT9amF8egLjP04oIcd2voqRp7c7o1yYpSItdg6gnFRCOG0iGnpoE/6YPSXkO46FxTomfjvq5kb3Ot1YoOCfdZJQiSTiagHbZEQzZ07xh/MfY2b2dFS0ACwRrX5/bwVX5ZS4RO1BrDMENPj2cG7Lq2UDAhgpsf5bKc6BIaR6rizbocSfYvnOzl4B11fxwEubkT5SbRtAUT+LI+Dd8sNcfIUz6mhLTfkAcgKMPQVn5sEXF52S8VtlSjnD3ye0L20qGt/I3B72lTeUZLgM6S4f8We3QDAc8gBsAbrccNG5tAheWHVGwz3COF1j3B3CtvFSYy0N7t72usnl8qkhlWKSFAly5Vz9iChtZO+1dz9uhy7w/zI4c571PTwgF5NEV1ZDIQIehG4vIfZbUndg62NnRbtWZj3p7YjQPCbhrPagu9LrFvaGyRTXOdc3MTpWr+UdKq30xPCX9y565gGZrgTJhq7SsYzcigB9vdM1AndaArCLUjnsVHhlW2NM3JZo8CbihrItMIvD6idaX8uZJ0OuKl0HCwOBkCjXQy7XlnKFQ7hfhrm20tejz2/ZI9SsqbdWpNWutkbdMO4F7aXGq5XnfN8Uc0k3HcO9lhvRha5ivLbR8AAAAABJRU5ErkJggg=='

    const handleChartDownload = () => {
        //console.log("selectedCharts", selectedCharts);
        selectedCharts.forEach((chartId) => {
            const chartElement = document.querySelector(`#chart-${chartId}`)
            if (chartElement) {
                //console.log("good");
                try {
                    const plotlyPlot = chartElement.querySelector('.js-plotly-plot')
                    if (plotlyPlot) {
                        const svgString = plotlyPlot.outerHTML
                        //console.log("SVG String for chart", chartId, ":", svgString);

                        const blob = new Blob([svgString], { type: 'image/svg+xml' })
                        const url = URL.createObjectURL(blob)

                        const link = document.createElement('a')
                        link.href = url
                        link.download = `chart-${chartId}.svg`
                        document.body.appendChild(link)
                        link.click()

                        document.body.removeChild(link)
                        URL.revokeObjectURL(url)
                    } else {
                        //console.error(`Plotly plot not found for chart ${chartId}`);
                    }
                } catch (error) {
                    //console.error("Error during download for chart", chartId, ":", error);
                    // Handle the error (e.g., show an error message)
                }
            } else {
                //console.error(`Chart element not found for chart ${chartId}`);
            }
        })
    }

    const getCurrentDateFormatted = () => {
        const today = new Date()
        const day = String(today.getDate()).padStart(2, '0') // Ensures 2-digit day
        const month = String(today.getMonth() + 1).padStart(2, '0') // Months are zero-based, so +1
        const year = today.getFullYear()

        return `${day}-${month}-${year}`
    }

    const handlePDF = async (textA, textB) => {
        handleClose()
        handlePDFopen()
        const updatedDate = getCurrentDateFormatted()

        const introductionText = textA
        const conclusionText = textB

        console.log(introductionText, 'introductionText')
        console.log(conclusionText, 'conclusionText')

        const pdf = new jsPDF('landscape', 'mm', 'a4')
        const pageWidth = 297 // A4 width in mm
        const pageHeight = 210 // A4 height in mm
        const headerHeight = 15
        const footerHeight = 15
        const contentMargin = 10 // Margin for text content
        const lineHeight = 10 // Approximate line height in mm

        // Function to calculate available height on the page
        const calculateAvailableHeight = () => {
            return pageHeight - headerHeight - footerHeight - contentMargin * 2
        }

        // Function to add header and footer to the current page
        const addHeaderFooter = async () => {
            const base64 = await convertImageToBase64()
            pdf.addImage(base64, 'PNG', 140, 3, 30, 30)

            const footerLine1 = 'SAINT-GOBAIN PERFORMANCE CERAMICS & REFRACTORIES'
            const footerLine2 =
                'Grindwell Norton LTD, Devanahalli Road, Off Old Madras Road, Bangalore. 560049, India'
            const footerLine3 =
                'E: SteelClubIndia@saint-gobain.com; W: www.ceramicsrefractories.saint-gobain.com; www.heatkingsolutions.com'

            // const footerY = pageHeight - footerHeight - contentMargin;

            // pdf.addImage(base64, 'PNG', 60, footerY, 15, 15);

            const footerY = pageHeight - footerHeight + 5 // Adjust footer Y to fit better

            pdf.addImage(base64, 'PNG', 60, footerY - 5, 15, 15)

            const lineX = 80
            const lineYStart = footerY - 10
            const lineYEnd = pageHeight - footerHeight + 10

            pdf.setLineWidth(0.1)
            pdf.line(lineX, lineYStart, lineX, lineYEnd)

            pdf.setFontSize(10)

            // Adjust footer text positions to fit within the footer area
            pdf.text(footerLine1, 85, footerY - 10)
            pdf.text(footerLine2, 85, footerY - 5)
            pdf.text(footerLine3, 85, footerY + 5)

            // const lineX = 80;
            // const lineYStart = footerY + 13;
            // const lineYEnd = pageHeight - footerHeight - 10;

            // pdf.setLineWidth(0.1);
            // pdf.line(lineX, lineYStart, lineX, lineYEnd);

            // pdf.setFontSize(10);
            // pdf.text(footerLine1, 85, pageHeight - footerHeight + 5);
            // pdf.text(footerLine2, 85, pageHeight - footerHeight + 10);
            // pdf.text(footerLine3, 85, pageHeight - footerHeight + 15);
        }

        // Function to add text to the PDF with page handling
        const addTextToPDF = async (text, startY) => {
            const lines = pdf.splitTextToSize(text, pageWidth - contentMargin * 2)
            let y = startY

            for (const line of lines) {
                if (y + lineHeight > pageHeight - footerHeight - contentMargin) {
                    pdf.addPage()
                    await addHeaderFooter()
                    y = headerHeight + contentMargin
                }
                pdf.text(line, contentMargin, y)
                y += lineHeight
            }
        }

        // Function to add a chart to the PDF
        const addChartPage = async (chart) => {
            const data = chart.data
            const layout = { title: chart.title }

            pdf.addPage()
            await addHeaderFooter()
            const tempDiv = document.createElement('div')
            document.body.appendChild(tempDiv)

            await Plotly.newPlot(tempDiv, data, layout)
            const imageData = await Plotly.toImage(tempDiv, { format: 'png' })
            const chartHeight = 140
            let y = headerHeight + contentMargin

            if (y + chartHeight > pageHeight - footerHeight - contentMargin) {
                pdf.addPage()
                await addHeaderFooter()
                y = headerHeight + contentMargin
            }

            pdf.addImage(
                imageData,
                'PNG',
                contentMargin,
                y,
                pageWidth - contentMargin * 2,
                chartHeight
            )
            y += chartHeight + 10

            document.body.removeChild(tempDiv)
        }

        try {
            const excelresponse = await axios.post(
                API.UPDATE_REPORTS,
                { id: cardId, updated_at: updatedDate },
                { headers: { 'Content-Type': 'application/json' } }
            )

            if (excelresponse.status === 200) {
                AlertDialog({
                    type: 'pdf',
                    title: 'Report saved successfully',
                    text: 'Do you want to download the reports?',
                    confirmButtonText: 'Yes',
                    onConfirm: async () => {
                        // Add Introduction Text
                        await addHeaderFooter()
                        await addTextToPDF(introductionText, headerHeight + contentMargin)

                        // Add Charts
                        if (selectedCharts.length === 0) {
                            for (const chart of charts) {
                                await addChartPage(chart)
                            }
                        } else {
                            for (const chartId of selectedCharts) {
                                const chart = charts.find((c) => c.id === chartId)
                                if (chart) {
                                    await addChartPage(chart)
                                }
                            }
                        }

                        // Add Conclusion Text
                        pdf.addPage()
                        await addHeaderFooter()
                        await addTextToPDF(conclusionText, headerHeight + contentMargin)

                        // Save the PDF
                        pdf.save('combined_charts.pdf')
                    },
                })
            } else {
                //console.error('Error saving data:', excelresponse.data);
                AlertDialog({
                    type: 'warning',
                    title: 'Error',
                    text: 'Failed to save data',
                })
            }
        } catch (error) {
            //console.error('Error:', error);
            AlertDialog({
                type: 'warning',
                title: 'Error',
                text: 'An unexpected error occurred',
            })
        }
    }

    const handleChartDelete = () => {
        if (selectedCharts.length === 0) {
            alert('Please select at least one chart to delete.')
        } else {
            // Log the selected charts for debugging
            //console.log("Selected Charts for Deletion:", selectedCharts);

            // Confirm the deletion with the user
            AlertDialog({
                type: 'delete',
                title: 'Are you sure?',
                text: 'Are you sure you want to delete?',
                confirmButtonText: 'Yes',
                onConfirm: async () => {
                    // Create a new array excluding the selected charts
                    const updatedCharts = charts.filter(
                        (chart) => !selectedCharts.includes(chart.id)
                    )

                    setCharts(updatedCharts)
                    // Send the updated charts list to the server
                    axios
                        .post(
                            API.UPDATE_GRAPH,
                            {
                                reports_id: cardId,
                                standard_graphs: updatedCharts,
                                template_id: templateId,
                            },
                            {
                                headers: { 'Content-Type': 'application/json' },
                            }
                        )
                        .then((response) => {
                            if (response.status === 200) {
                                // Show success dialog
                                AlertDialog({
                                    type: 'success',
                                    title: 'Success',
                                    text: 'Deleted Successfully!',
                                    confirmButtonText: 'Ok',
                                    onConfirm: async () => {
                                        window.location.reload()
                                    },
                                })
                            } else {
                                // Handle server-side errors
                                //console.error('Error deleting data:', response.data);
                                AlertDialog({
                                    type: 'warning',
                                    title: 'Error',
                                    text: 'Failed to save data',
                                })
                            }
                        })
                        .catch((error) => {
                            // Handle client-side or network errors
                            //console.error('Error in API request:', error);
                            AlertDialog({
                                type: 'warning',
                                title: 'Error',
                                text: 'An error occurred during deletion',
                            })
                        })

                    // Clear the selection
                    setSelectedCharts([])
                },
            })
            // const confirmed = window.confirm("Are you sure you want to delete?");

            // if (!confirmed) {
            //   return; // User canceled the deletion
            // }
        }
    }

    const handleChartCheckboxChange = (chartId) => {
        setSelectedCharts((prevSelectedCharts) =>
            prevSelectedCharts.includes(chartId)
                ? prevSelectedCharts.filter((id) => id !== chartId)
                : [...prevSelectedCharts, chartId]
        )
        //console.log("selectedCharts", selectedCharts);
    }

    const handleChartButtonClick = (chartId) => {
        setActiveChart(chartId)
    }

    const handleHeatmapClose = () => {
        setHeatmapModalOpen(false)
    }

    const handleHeatmapSelect = (value) => {
        setHeatmapSelection(value)
        //console.log(heatmapSelection,'heatmapSelection');
        setHeatmapModalOpen(false)
    }

    const restrictedMetrics = [
        'first_value',
        'last_value',
        'minimum',
        'maximum',
        'total',
        'mean',
        'count',
        'quartiles',
        'correlation',
    ]

    // Determine available graph types based on selected metric
    const getAvailableGraphTypes = (selectedMetric) => {
        switch (selectedMetric) {
            case 'first_value':
            case 'last_value':
            case 'minimum':
            case 'maximum':
            case 'total':
            case 'mean':
            case 'count':
                return [
                    'scatter',
                    'bar',
                    'line',
                    'horizontal-bar',
                    'stacked-bar',
                    'pie',
                ]
            case 'quartiles':
                return ['box']
            case 'correlation':
                return ['heatmap']
            default:
                return [
                    'scatter',
                    'bar',
                    'box',
                    'line',
                    'horizontal-bar',
                    'stacked-bar',
                    'pie',
                    'heatmap',
                ]
        }
    }

    const availableGraphTypes = getAvailableGraphTypes(metric)

    const handleClose = () => {
        setModalOpen(false)
    }

    const handlePDFopen = () => {
        setModalOpen(true)
    }

    const [textA, setTextA] = useState('')
    const [textB, setTextB] = useState('')
    const [textC, setTextC] = useState('')

    const maxLengths = {
        A: 500,
        B: 1000,
        C: 1500,
    }

    const handleChangeTextA = (event) => {
        if (event.target.value.length <= maxLengths.A) {
            setTextA(event.target.value)
        }
    }

    const handleChangeTextB = (event) => {
        if (event.target.value.length <= maxLengths.B) {
            setTextB(event.target.value)
        }
    }

    const handleChangeTextC = (event) => {
        if (event.target.value.length <= maxLengths.C) {
            setTextC(event.target.value)
        }
    }

    const getModalContent = () => {
        if (tab === 'Daily Trial Monitoring') {
          return (
            <div
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 bg-white shadow-lg p-6"
            >
              <h2
                id="heatmap-modal-title"
                className="text-lg font-semibold text-gray-800"
              >
                Select Heatmap Option
              </h2>
              <select
                id="heatmap-select"
                value={heatmapSelection}
                onChange={(event) => handleHeatmapSelect(event.target.value)}
                className="w-full mt-4 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="total_power_consumed">Total Power Consumed</option>
                <option value="tapped_tonnage">Tapped Tonnage</option>
                <option value="energy_consumption_per_unit_ton_actual">
                  Energy Consumption Per Unit Ton Actual
                </option>
                <option value="energy_consumption_per_unit_ton_calculated">
                  Energy Consumption Per Unit Ton Calculated
                </option>
                <option value="heat_no">Heat No</option>
              </select>
            </div>
          );
        } else if (tab === 'NRM Performance') {
          return (
            <div
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 bg-white shadow-lg p-6"
            >
              <h2
                id="nrm-performance-modal-title"
                className="text-lg font-semibold text-gray-800"
              >
                NRM Performance Options
              </h2>
              <select
                id="nrm-performance-select"
                value={heatmapSelection}
                onChange={(event) => handleHeatmapSelect(event.target.value)}
                className="w-full mt-4 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="total_furnace_running_hours">
                  Total Furnace Running Hours
                </option>
                <option value="total_nrm_consumption_sidewall_one">
                  Total NRM consumption sidewall
                </option>
                <option value="nrm_specific_consumption">
                  NRM Specific consumption
                </option>
                <option value="holding_time">Holding Time</option>
                <option value="holding_temp">Holding Temp</option>
              </select>
            </div>
          );
        }
      };
      

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOptions1, setSelectedOptions1] = useState([]);

    const options = [
        { value: "lorp_id", label: "LORP ID" },
        { value: "furnace_no", label: "Furnace No" },
        { value: "campaign_no", label: "Campaign No" },
        { value: "crucible_no", label: "Crucible No" },
        { value: "lining_or_patching", label: "Lining or Patching" },
        { value: "date_of_completion_lining_or_patching", label: "Date of Completion Lining or Patching" },
        { value: "fce_prod_start_date", label: "Fce Prod Start Date" },
        { value: "fce_prod_start_time", label: "Fce Prod Start Time" },
        { value: "fce_prod_down_date", label: "Fce Prod Down Date" },
        { value: "fce_prod_down_time", label: "Fce Prod Down Time" },
        { value: "life", label: "Life" },
        { value: "average_heat_size", label: "Average Heat Size" },
        { value: "total_furnace_running_hours", label: "Total Furnace Running Hours" },
        { value: "total_production", label: "Total Production" },
        { value: "bottom_product", label: "Bottom Product" },
        { value: "sidewall_product_one", label: "Sidewall Product 1" },
        { value: "sidewall_product_two", label: "Sidewall Product 2" },
        { value: "top_locking_product", label: "Top Locking Product" },
        { value: "topping_mass_product", label: "Topping Mass Product" },
        { value: "sidewall_one_coverage_upto_x", label: "Side Wall 1 Coverage upto X%" },
        { value: "sidewall_two_coverage_upto_100", label: "Side Wall 2 Coverage upto 100%" },
        { value: "nrm_consumption_bottom", label: "NRM Consumption Bottom" },
        { value: "nrm_consumption_sidewall_one", label: "NRM Consumption Sidewall 1" },
        { value: "nrm_consumption_sidewall_two", label: "NRM Consumption Sidewall 2" },
        { value: "nrm_consumption_top_locking", label: "NRM Consumption at Top Locking" },
        { value: "topping_mass_consumption", label: "Topping Mass Consumption" },
        { value: "nrm_specific_consumption", label: "NRM Specific Consumption" },
        { value: "former_dimension", label: "Former Dimension" },
        { value: "coil_coat_id", label: "Coil Coat ID" },
        { value: "fce_ht", label: "Fce Ht." },
        { value: "costable_top_ht", label: "Castable Top Ht." },
        { value: "bottom_ht", label: "Bottom Ht." },
        { value: "total_sintering_time", label: "Total Sintering Time" },
        { value: "holding_time", label: "Holding Time" },
        { value: "holding_temp", label: "Holding Temp" },
        { value: "IR_no", label: "IR No." },
      ];
      const options1 = [
        { value: "furnace_no", label: "Furnace No" },
        { value: "campaign_no", label: "Campaign No" },
        { value: "crucible_no", label: "Crucible No" },
        { value: "heat_on_time", label: "Heat On Time" },
        { value: "heat_tap_time", label: "Heat Tap Time" },
        { value: "capacitors_removed", label: "Capacitors Removed" },
        { value: "cr_before_alloy_addition", label: "Cr Before Alloy Addition" },
        { value: "eightyfive_c_percentage", label: "85%" },
        { value: "energy_consumption_per_unit_ton_actual", label: "Energy Consumption Per Unit Ton Actual" },
        { value: "energy_consumption_per_unit_ton_calculated", label: "Energy Consumption Per Unit Ton Calculated" },
        { value: "feCr", label: "FeCr" },
        { value: "feMn", label: "FeMn" },
        { value: "feSi", label: "FeSi" },
        { value: "fines_lessthan_1mm", label: "Fines Less than 1mm" },
        { value: "fines_lessthan_5mm", label: "Fines Less than 5mm" },
        { value: "frequency", label: "Frequency" },
        { value: "heat_duration_actual", label: "Heat Duration Actual" },
        { value: "heat_duration_calculated", label: "Heat Duration Calculated" },
        { value: "heat_no", label: "Heat No" },
        { value: "hot_heel", label: "Hot Heel" },
        { value: "inlet_water_temp", label: "Inlet Water Temp" },
        { value: "lump", label: "Lump" },
        { value: "melt_rate_based_on_actual_duration", label: "Melt Rate Based On Actual Duration" },
        { value: "melt_rate_based_on_calculated_duration", label: "Melt Rate Based On Calculated Duration" },
        { value: "met_coke", label: "Met Coke" },
        { value: "mill_scale", label: "Mill Scale" },
        { value: "mn_before_alloy_addition", label: "Mn Before Alloy Addition" },
        { value: "mn_in_simn", label: "Mn In Simn" },
        { value: "outlet_water_temp", label: "Outlet Water Temp" },
        { value: "pellet", label: "Pellet" },
        { value: "pig_iron", label: "Pig Iron" },
        { value: "pooled_iron", label: "Pooled Iron" },
        { value: "power_in_the_panel", label: "Power In The Panel" },
        { value: "scrap", label: "Scrap" },
        { value: "shredded", label: "Shredded" },
        { value: "si_before_alloy_addition", label: "Si Before Alloy Addition" },
        { value: "si_mn", label: "Si Mn" },
        { value: "slag_crushers", label: "Slag Crushers" },
        { value: "tapped_tonnage", label: "Tapped Tonnage" },
        { value: "tapping_temperature", label: "Tapping Temperature" },
        { value: "total_charge", label: "Total Charge" },
        { value: "total_power_consumed", label: "Total Power Consumed" },
        { value: "tundish_or_slag_or_metalic_jam", label: "Tundish Or Slag Or Metallic Jam" },
        { value: "voltage", label: "Voltage" },
        { value: "water_pr", label: "Water PR" },
      ];
      
    return (
        <div>
            <div className="py-4 bg-white">
                {/* Header Section */}
                <div className="flex justify-between items-center mb-4 border-b pb-4 px-4">
                    <h2 className="text-xl font-semibold font-inter">Reports Data List</h2>
                    <button
                        onClick={handlePDFopen}
                        className="px-4 py-2 text-sm font-medium text-white bg-custom-red rounded-md hover:bg-custom-red"
                    >
                        GENERATE REPORT
                    </button>
                </div>

                {/* Modal */}
                {modalOpen && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="relative w-11/12 max-w-3xl bg-white rounded-lg shadow-lg">
                            <div className="flex justify-between items-center p-4 border-b border-gray-200">
                                <h3 className="text-lg font-medium">Enter Text for PDF</h3>
                                <button
                                    onClick={handleClose}
                                    className="p-2 text-gray-500 rounded-full hover:bg-gray-200"
                                >
                                    &#x2715; {/* Replace with an SVG or icon for close */}
                                </button>
                            </div>
                            <div className="p-4 space-y-4">
                                <div>
                                    <label htmlFor="textA" className="block mb-2 text-sm font-medium">
                                        Background
                                    </label>
                                    <textarea
                                        id="textA"
                                        rows="8"
                                        maxLength={maxLengths.A}
                                        value={textA}
                                        onChange={handleChangeTextA}
                                        className="w-full p-2 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                        placeholder="Enter background text"
                                    />
                                    <p className="text-sm text-gray-500">{`${textA.length}/${maxLengths.A} characters`}</p>
                                </div>
                                <div>
                                    <label htmlFor="textB" className="block mb-2 text-sm font-medium">
                                        Summary
                                    </label>
                                    <textarea
                                        id="textB"
                                        rows="8"
                                        maxLength={maxLengths.B}
                                        value={textB}
                                        onChange={handleChangeTextB}
                                        className="w-full p-2 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                        placeholder="Enter summary text"
                                    />
                                    <p className="text-sm text-gray-500">{`${textB.length}/${maxLengths.B} characters`}</p>
                                </div>
                                <button
                                    onClick={() => {
                                        handlePDF(textA, textB);
                                        handleClose();
                                    }}
                                    className="px-4 py-2 text-sm font-medium text-white bg-custom-red rounded-md hover:bg-custom-red"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Tabs Section */}
                <div className="border-b border-gray-200 px-4">
                    <div className="flex space-x-4">
                        {tabs.map((tab) => (
                            <button
                                key={tab.value}
                                onClick={() => handleTabChange(tab.label)}
                                className={`px-4 py-2 font-medium ${tab.label === selectedTab
                                    ? 'text-blue-600 border-b-2 border-blue-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                disabled={tab.label !== selectedTab}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            <div className="p-4 lg:flex">
                <div className="xl:1/3 lg:w-1/2 w-full">
                    {/* Header Section */}
                    <div className="flex items-center justify-between mb-4">
                        <h2 className="text-lg font-semibold flex-4">Standard Charts</h2>

                        <div className="flex-1 flex items-center justify-end">
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-custom-red rounded-md hover:bg-custom-red"
                                onClick={handleModalOpen}
                            >
                                Add Graph
                            </button>
                            <button
                                onClick={handleChartDelete}
                                className="p-2 hover:bg-gray-200 rounded-full"
                            >
                                <DeleteIcon />
                            </button>
                        </div>
                    </div>

                    {/* Chart List */}
                    <div>
                        {charts.map((chart) => (
                            <div
                                key={chart.id}
                                className="chart-list-item flex items-center mb-2"
                            >
                                <input
                                    type="checkbox"
                                    checked={selectedCharts.includes(chart.id)}
                                    onChange={() => handleChartCheckboxChange(chart.id)}
                                    className="mr-2 transform scale-150"
                                />
                                <button
                                    onClick={() => handleChartButtonClick(chart.id)}
                                    className={`text-base font-normal ${chart.id === activeChart ? 'bg-blue-100' : 'bg-none'
                                        } text-gray-800 px-2 py-1`}
                                >
                                    {chart.title}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Chart Display Section */}
                <div className="w-full bg-gray-100 overflow-y-auto max-h-[900px] min-h-[700px] p-4">
                    {selectedCharts.map((chartId) => {
                        const chart = charts.find((chart) => chart.id === chartId);
                        return (
                            <div
                                key={chartId}
                                className="relative mb-5 min-w-[550px] w-full"
                            >
                                <Plot
                                    data={chart.data}
                                    layout={{
                                        title: chart.title,
                                        yaxis: { title: 'Values' },
                                        width: '100%',
                                        height: 800,
                                    }}
                                />
                                {/* <div className="mt-3">
                                    <label
                                        htmlFor="textA"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        COMMENTS
                                    </label>
                                    <textarea
                                        id="textA"
                                        rows="8"
                                        value={textA}
                                        onChange={handleChangeTextA}
                                        maxLength={maxLengths.A}
                                        className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-400"
                                    />
                                    <p className="text-sm text-gray-500">
                                        {textA.length}/{maxLengths.A} characters
                                    </p>
                                </div> */}
                            </div>
                        );
                    })}

                    {activeChart && !selectedCharts.includes(activeChart) && (
                        <div
                            key={activeChart}
                            className="relative mb-5"
                        >
                            {charts.find((chart) => chart.id === activeChart) && (
                                <Plot
                                    data={charts.find((chart) => chart.id === activeChart).data}
                                    layout={{
                                        title: charts.find((chart) => chart.id === activeChart).title,
                                        width: 1265,
                                        height: 800,
                                    }}
                                />
                            )}
                            {/* <div className="mt-3">
                                <label
                                    htmlFor="textA"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    COMMENTS
                                </label>
                                <textarea
                                    id="textA"
                                    rows="8"
                                    value={textA}
                                    onChange={handleChangeTextA}
                                    maxLength={maxLengths.A}
                                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-400"
                                />
                                <p className="text-sm text-gray-500">
                                    {textA.length}/{maxLengths.A} characters
                                </p>
                            </div> */}
                        </div>
                    )}
                </div>


                <div
                    className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ${showModal ? 'visible' : 'invisible'
                        }`}
                >
                    {showModal && (
                        <div className='bg-white rounded-lg p-4 max-w-[400px]'>
                            <div className="w-full ">
                                {/* Header */}
                                <div className="flex items-center justify-between mb-4">
                                    <h2 className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Add Graph</h2>
                                    <button
                                        className="p-2 rounded-full hover:bg-gray-200"
                                        onClick={handleModalClose}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="w-5 h-5"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>

                                {/* Form Section */}
                                <div className="flex flex-col gap-4 md:flex-row mb-2">
                                    {/* Yaxis Metric */}
                                    <div className="flex-1">
                                        <label htmlFor="metricSelect" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Yaxis Metric *
                                        </label>
                                        <select
                                            id="metricSelect"
                                            value={metric}
                                            onChange={handleMetricChange}
                                            className="w-full text-sm mt-1 px-3 py-2 border border-gray-300 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        >
                                            <option value="Visualization">Visualization</option>
                                            <option value="first_value">First Value</option>
                                            <option value="last_value">Last Value</option>
                                            <option value="minimum">Minimum</option>
                                            <option value="maximum">Maximum</option>
                                            <option value="total">Total</option>
                                            <option value="mean">Mean</option>
                                            <option value="count">Count</option>
                                            <option value="quartiles">Quartiles</option>
                                            <option value="correlation">Correlation</option>
                                        </select>
                                    </div>

                                    {/* Graph Type */}
                                    <div className="flex-1">
                                        <label htmlFor="graphTypeSelect" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Graph Type *
                                        </label>
                                        <select
                                            id="graphTypeSelect"
                                            value={graphType}
                                            onChange={handleGraphTypeChange}
                                            className="w-full text-sm mt-1 text-sm px-3 py-2 border border-gray-300 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        >
                                            {availableGraphTypes.map((type) => (
                                                <option key={type} value={type}>
                                                    {type.charAt(0).toUpperCase() + type.slice(1).replace('-', ' ')}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {tab === 'Daily Trial Monitoring' && (
                                <div className="w-full mb-2">
                                    {/* X-Axis Selection */}
                                    <div className="w-full mb-2">
                                        <label htmlFor="x-axis-select" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            X-Axis *
                                        </label>
                                        <select
                                            id="x-axis-select"
                                            value={xAxis}
                                            onChange={handleXAxisChange}
                                            className={`w-full text-sm mt-1 px-3 py-2 border rounded-lg text-sm ${graphType === 'heatmap' ? 'bg-gray-100 cursor-not-allowed' : 'border-gray-300'
                                                }`}
                                            disabled={graphType === 'heatmap'}
                                        >
                                          <option value="" disabled selected>
                                                Select Values
                                            </option>
                                            <option value="lorp_id">LORP ID</option>
                                            <option value="furnace_no">Furnace No</option>
                                            <option value="campaign_no">Campaign No</option>
                                            <option value="crucible_no">Crucible No</option>
                                            <option value="heat_on_time">Heat On Time</option>
                                            <option value="heat_tap_time">Heat Tap Time</option>
                                            <option value="capacitors_removed">Capacitors Removed</option>
                                            <option value="cr_before_alloy_addition">Cr Before Alloy Addition</option>
                                            <option value="eightyfive_c_percentage">85%</option>
                                            <option value="energy_consumption_per_unit_ton_actual">
                                                Energy Consumption Per Unit Ton Actual
                                            </option>
                                            <option value="energy_consumption_per_unit_ton_calculated">
                                                Energy Consumption Per Unit Ton Calculated
                                            </option>
                                            <option value="feCr">FeCr</option>
                                            <option value="feMn">FeMn</option>
                                            <option value="feSi">FeSi</option>
                                            <option value="fines_lessthan_1mm">Fines Lessthan 1mm</option>
                                            <option value="fines_lessthan_5mm">Fines Lessthan 5mm</option>
                                            <option value="frequency">Frequency</option>
                                            <option value="heat_duration_actual">Heat Duration Actual</option>
                                            <option value="heat_duration_calculated">Heat Duration Calculated</option>
                                            <option value="heat_no">Heat No</option>
                                            <option value="hot_heel">Hot Heel</option>
                                            <option value="inlet_water_temp">Inlet Water Temp</option>
                                            <option value="lump">Lump</option>
                                            <option value="melt_rate_based_on_actual_duration">
                                                Melt Rate Based On Actual Duration
                                            </option>
                                            <option value="melt_rate_based_on_calculated_duration">
                                                Melt Rate Based On Calculated Duration
                                            </option>
                                            <option value="met_coke">Met Coke</option>
                                            <option value="mill_scale">Mill Scale</option>
                                            <option value="mn_before_alloy_addition">Mn Before Alloy Addition</option>
                                            <option value="mn_in_simn">Mn In Simn</option>
                                            <option value="outlet_water_temp">Outlet Water Temp</option>
                                            <option value="pellet">Pellet</option>
                                            <option value="pig_iron">Pig Iron</option>
                                            <option value="pooled_iron">Pooled Iron</option>
                                            <option value="power_in_the_panel">Power In The Panel</option>
                                            <option value="scrap">Scrap</option>
                                            <option value="shredded">Shredded</option>
                                            <option value="si_before_alloy_addition">Si Before Alloy Addition</option>
                                            <option value="si_mn">Si Mn</option>
                                            <option value="slag_crushers">Slag Crushers</option>
                                            <option value="tapped_tonnage">Tapped Tonnage</option>
                                            <option value="tapping_temperature">Tapping Temperature</option>
                                            <option value="total_charge">Total Charge</option>
                                            <option value="total_power_consumed">Total Power Consumed</option>
                                            <option value="tundish_or_slag_or_metalic_jam">Tundish Or Slag Or Metalic Jam</option>
                                            <option value="voltage">Voltage</option>
                                            <option value="water_pr">Water PR</option>
                                        </select>
                                    </div>

                                    {/* Y-Axis Selection */}
                                    <div className="w-full mb-2">
                                        <label htmlFor="y-axis-select" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Y-Axis *
                                        </label>
                                        <MultiSelect options={options1}
                                                selectedOptions={yAxis}
                                                onSelectionChange={setYAxis}
                                                placeholder="Select values" />
                                        {/* <select
                                            id="y-axis-select"
                                            multiple
                                            value={yAxis}
                                            onChange={handleYAxisChange}
                                            className="w-full mt-1 px-3 py-2 border rounded-lg text-sm border-gray-300"
                                        >
                                            <option value="furnace_no">Furnace No</option>
                                            <option value="campaign_no">Campaign No</option>
                                            <option value="crucible_no">Crucible No</option>
                                            <option value="heat_on_time">Heat On Time</option>
                                            <option value="heat_tap_time">Heat Tap Time</option>
                                            <option value="capacitors_removed">Capacitors Removed</option>
                                            <option value="cr_before_alloy_addition">Cr Before Alloy Addition</option>
                                            <option value="eightyfive_c_percentage">85%</option>
                                            <option value="energy_consumption_per_unit_ton_actual">
                                                Energy Consumption Per Unit Ton Actual
                                            </option>
                                            <option value="energy_consumption_per_unit_ton_calculated">
                                                Energy Consumption Per Unit Ton Calculated
                                            </option>
                                            <option value="feCr">FeCr</option>
                                            <option value="feMn">FeMn</option>
                                            <option value="feSi">FeSi</option>
                                            <option value="fines_lessthan_1mm">Fines Lessthan 1mm</option>
                                            <option value="fines_lessthan_5mm">Fines Lessthan 5mm</option>
                                            <option value="frequency">Frequency</option>
                                            <option value="heat_duration_actual">Heat Duration Actual</option>
                                            <option value="heat_duration_calculated">Heat Duration Calculated</option>
                                            <option value="heat_no">Heat No</option>
                                            <option value="hot_heel">Hot Heel</option>
                                            <option value="inlet_water_temp">Inlet Water Temp</option>
                                            <option value="lump">Lump</option>
                                            <option value="melt_rate_based_on_actual_duration">
                                                Melt Rate Based On Actual Duration
                                            </option>
                                            <option value="melt_rate_based_on_calculated_duration">
                                                Melt Rate Based On Calculated Duration
                                            </option>
                                            <option value="met_coke">Met Coke</option>
                                            <option value="mill_scale">Mill Scale</option>
                                            <option value="mn_before_alloy_addition">Mn Before Alloy Addition</option>
                                            <option value="mn_in_simn">Mn In Simn</option>
                                            <option value="outlet_water_temp">Outlet Water Temp</option>
                                            <option value="pellet">Pellet</option>
                                            <option value="pig_iron">Pig Iron</option>
                                            <option value="pooled_iron">Pooled Iron</option>
                                            <option value="power_in_the_panel">Power In The Panel</option>
                                            <option value="scrap">Scrap</option>
                                            <option value="shredded">Shredded</option>
                                            <option value="si_before_alloy_addition">Si Before Alloy Addition</option>
                                            <option value="si_mn">Si Mn</option>
                                            <option value="slag_crushers">Slag Crushers</option>
                                            <option value="tapped_tonnage">Tapped Tonnage</option>
                                            <option value="tapping_temperature">Tapping Temperature</option>
                                            <option value="total_charge">Total Charge</option>
                                            <option value="total_power_consumed">Total Power Consumed</option>
                                            <option value="tundish_or_slag_or_metalic_jam">Tundish Or Slag Or Metalic Jam</option>
                                            <option value="voltage">Voltage</option>
                                            <option value="water_pr">Water PR</option>
                                        </select> */}
                                        {warning && <p className="text-sm text-red-600 mt-1">{warning}</p>}
                                    </div>
                                </div>
                            )}

                            {tab === 'NRM Performance' && (
                                <div className="w-full mb-2">
                                    {/* X-Axis Section */}
                                    <div className="w-full">
                                        <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">X-Axis *</p>
                                        <div className="mt-2">
                                            <select
                                                id="x-axis-select"
                                                value={xAxis}
                                                onChange={handleXAxisChange}
                                                className={`w-full text-sm rounded-md border border-gray-300 p-2 ${graphType === 'heatmap' && 'cursor-not-allowed bg-gray-100'
                                                    }`}
                                                disabled={graphType === 'heatmap'}
                                            >
                                                <option value="lorp_id">LORP ID</option>
                                                <option value="furnace_no">Furnace No</option>
                                                <option value="campaign_no">Campaign No</option>
                                                <option value="crucible_no">Crucible No</option>
                                                <option value="lining_or_patching">Lining or Patching</option>
                                                <option value="date_of_completion_lining_or_patching">
                                                    Date of Completion Lining or Patching
                                                </option>
                                                <option value="fce_prod_start_date">Fce prod start date</option>
                                                <option value="fce_prod_start_time">Fce prod start time</option>
                                                <option value="fce_prod_down_date">Fce prod down date</option>
                                                <option value="fce_prod_down_time">Fce prod down time</option>
                                                <option value="life">Life</option>
                                                <option value="average_heat_size">Average Heat size</option>
                                                <option value="total_furnace_running_hours">
                                                    Total Furnace Running Hours
                                                </option>
                                                <option value="total_production">Total Production</option>
                                                <option value="bottom_product">Bottom Product</option>
                                                <option value="sidewall_product_one">Sidewall Product 1</option>
                                                <option value="sidewall_product_two">Sidewall Product 2</option>
                                                <option value="top_locking_product">Top Locking Product</option>
                                                <option value="topping_mass_product">Topping Mass Product</option>
                                                <option value="sidewall_one_coverage_upto_x">
                                                    Side wall 1 Coverage upto X%
                                                </option>
                                                <option value="sidewall_two_coverage_upto_100">
                                                    Side wall 2 Coverage upto 100%
                                                </option>
                                                <option value="nrm_consumption_bottom">
                                                    NRM Consumption Bottom
                                                </option>
                                                <option value="nrm_consumption_sidewall_one">
                                                    NRM Consumption Sidewall 1
                                                </option>
                                                <option value="nrm_consumption_sidewall_two">
                                                    NRM Consumption Sidewall 2
                                                </option>
                                                <option value="nrm_consumption_top_locking">
                                                    NRM Consumption at Top Locking
                                                </option>
                                                <option value="topping_mass_consumption">
                                                    Topping Mass Consumption
                                                </option>
                                                <option value="nrm_specific_consumption">
                                                    NRM Specific Consumption
                                                </option>
                                                <option value="former_dimension">Former Dimension</option>
                                                <option value="coil_coat_id">Coil Coat ID</option>
                                                <option value="fce_ht">Fce Ht.</option>
                                                <option value="costable_top_ht">Castable Top Ht.</option>
                                                <option value="bottom_ht">Bottom Ht.</option>
                                                <option value="total_sintering_time">Total Sintering Time</option>
                                                <option value="holding_time">Holding Time</option>
                                                <option value="holding_temp">Holding Temp</option>
                                                <option value="IR_no">IR No.</option>
                                            </select>
                                        </div>
                                    </div>

                                    {/* Y-Axis Section */}
                                    <div className="w-full mb-2">
                                        <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Y-Axis *</p>
                                        <div className="mt-2">
                                            <MultiSelect options={options}
                                                selectedOptions={yAxis}
                                                onSelectionChange={setYAxis}
                                                placeholder="Select values" />
                                            {/* <select
                                                id="y-axis-select"
                                                value={yAxis}
                                                onChange={handleYAxisChange}
                                                className="w-full rounded-md border border-gray-300 p-2"
                                                multiple
                                            >
                                                <option value="lorp_id">LORP ID</option>
                                                <option value="furnace_no">Furnace No</option>
                                                <option value="campaign_no">Campaign No</option>
                                                <option value="crucible_no">Crucible No</option>
                                                <option value="lining_or_patching">Lining or Patching</option>
                                                <option value="date_of_completion_lining_or_patching">
                                                    Date of Completion Lining or Patching
                                                </option>
                                                <option value="fce_prod_start_date">Fce prod start date</option>
                                                <option value="fce_prod_start_time">Fce prod start time</option>
                                                <option value="fce_prod_down_date">Fce prod down date</option>
                                                <option value="fce_prod_down_time">Fce prod down time</option>
                                                <option value="life">Life</option>
                                                <option value="average_heat_size">Average Heat size</option>
                                                <option value="total_furnace_running_hours">
                                                    Total Furnace Running Hours
                                                </option>
                                                <option value="total_production">Total Production</option>
                                                <option value="bottom_product">Bottom Product</option>
                                                <option value="sidewall_product_one">Sidewall Product 1</option>
                                                <option value="sidewall_product_two">Sidewall Product 2</option>
                                                <option value="top_locking_product">Top Locking Product</option>
                                                <option value="topping_mass_product">Topping Mass Product</option>
                                                <option value="sidewall_one_coverage_upto_x">
                                                    Side wall 1 Coverage upto X%
                                                </option>
                                                <option value="sidewall_two_coverage_upto_100">
                                                    Side wall 2 Coverage upto 100%
                                                </option>
                                                <option value="nrm_consumption_bottom">
                                                    NRM Consumption Bottom
                                                </option>
                                                <option value="nrm_consumption_sidewall_one">
                                                    NRM Consumption Sidewall 1
                                                </option>
                                                <option value="nrm_consumption_sidewall_two">
                                                    NRM Consumption Sidewall 2
                                                </option>
                                                <option value="nrm_consumption_top_locking">
                                                    NRM Consumption at Top Locking
                                                </option>
                                                <option value="topping_mass_consumption">
                                                    Topping Mass Consumption
                                                </option>
                                                <option value="nrm_specific_consumption">
                                                    NRM Specific Consumption
                                                </option>
                                                <option value="former_dimension">Former Dimension</option>
                                                <option value="coil_coat_id">Coil Coat ID</option>
                                                <option value="fce_ht">Fce Ht.</option>
                                                <option value="costable_top_ht">Castable Top Ht.</option>
                                                <option value="bottom_ht">Bottom Ht.</option>
                                                <option value="total_sintering_time">Total Sintering Time</option>
                                                <option value="holding_time">Holding Time</option>
                                                <option value="holding_temp">Holding Temp</option>
                                                <option value="IR_no">IR No.</option>
                                            </select> */}
                                        </div>
                                        {warning && <p className="text-sm text-red-600 mt-1">{warning}</p>}
                                    </div>
                                </div>
                            )}

                            {heatmapModalOpen && (
                                <div
                                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                                    aria-labelledby="heatmap-modal-title"
                                    aria-describedby="heatmap-modal-description"
                                >
                                    <div className="bg-white rounded-lg p-6 shadow-lg">
                                        {getModalContent()}
                                    </div>
                                </div>
                            )}

                            <div className="mb-2 flex-1">
                                <div>
                                    <label
                                        htmlFor="notes-textarea"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Title *
                                    </label>
                                    <textarea
                                        id="notes-textarea"
                                        value={notes}
                                        onChange={handleNotesChange}
                                        className="w-full text-sm p-2 border border-gray-300 rounded-md resize-none min-h-[100px] focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                            </div>

                            <div className="flex w-full p-2.5">
                                <button
                                    onClick={handleModalClose}
                                    className="flex-1 mr-6 px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleAddGraph}
                                    className="flex-1 px-4 py-2 bg-custom-red text-white rounded-md hover:bg-custom-red"
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div >
            {heatmapModalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          aria-labelledby="heatmap-modal-title"
          aria-describedby="heatmap-modal-description"
        >
          <div
            className="relative bg-white rounded shadow-lg w-full max-w-lg"
          >
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={handleHeatmapClose}
            >
              ×
            </button>
            {getModalContent()}
          </div>
        </div>
      )}
        </div >
    )
}

export default Charts
