// import { ReactComponent as DashboardIcon } from '../src/assets/sidemenu/ic_dashboard.svg'
// import { ReactComponent as DataManagementIcon } from '../src/assets/sidemenu/ic_data_management.svg'
// import { ReactComponent as DataReportsIcon } from '../src/assets/sidemenu/ic_reports.svg'
// import { ReactComponent as SettingsIcon } from '../src/assets/sidemenu/ic_settings.svg'
// import { ReactComponent as ReleasesIcon } from '../src/assets/sidemenu/ic_releases.svg'
// import { ReactComponent as HelpSupportIcon } from '../src/assets/sidemenu/ic_help_support.svg'

// import { ReactComponent as DashboardColoredIcon } from '../src/assets/sidemenu/ic_dashboard_colored.svg'
// import { ReactComponent as DataManagementColoredIcon } from '../src/assets/sidemenu/ic_data_management_colored.svg'
// import { ReactComponent as DataReportsColoredIcon } from '../src/assets/sidemenu/ic_reports_colored.svg'
// import { ReactComponent as SettingsColoredIcon } from '../src/assets/sidemenu/ic_settings_colored.svg'
// import { ReactComponent as ReleasesIcon } from "../src/assets/sidemenu/ic_releases.svg";
// import { ReactComponent as HelpSupportIcon } from "../src/assets/sidemenu/ic_help_support.svg";
// import CustomerModal from './pages/login/CustomerPage'

// import DataManagement from './pages/datamanagement/DataManagement'
// import Dashboard from './pages/dashboard/Dashboard'
// import Reports from './pages/reports/Reports'
// import Settings from './pages/settings/Settings'
// import DailyTrialMonitoring from './pages/datamanagement/DailyTrialMonitoring'
// import ViewTrial from './pages/datamanagement/ViewTrial'
// import Releases from './pages/releases/Releases'
// import HelpSupport from './pages/helpsupport/HelpSupport'
// import HorizontalDataCollectionStepper from './pages/mobile/datacollection/HorizontalDataCollectionStepper'
// import ErosionProfileHome from './pages/mobile/erosionprofile/ErosionProfileHome'
// import ErosionProfileStepper from './pages/mobile/erosionprofile/ErosionProfileStepper'
// import NRMPerformance from './pages/mobile/nrmperformance/NRMPerformance'
// import DailyTrialMonitoringM from './pages/mobile/dailytrialmonitoring/DailyTrialMonitoringM'
// import CostSheetTabs from './pages/mobile/costsheet/Tabs'
// import DataManagementMobile from './pages/mobile/datamanagement/DataManagementMobile'
// import NRMPerformanceHome from './pages/mobile/nrmperformance/NRMPerformnaceHome'
// import ViewTrialNRM from './pages/mobile/nrmperformance/ViewTrialNRM'
// import ViewDailyTrial from './pages/mobile/dailytrialmonitoring/ViewDailyTrial'
// import AccessTemplate from './pages/input/AccessTemplate'
// import DraftStatus from './pages/mobile/datafilestatus/DraftStatus'
// import SubmittedStatus from './pages/mobile/datafilestatus/SubmittedStatus'
// import FilterPage from './pages/mobile/dailytrialmonitoring/DailyTrialFilter'
// import ReportsDataSetup from './pages/reports/ReportsDataSetup'

// import ViewCompleteData from './pages/reports/ViewCompleteData'
// import Charts from './pages/reports/Charts'
// import ApproveStatus from './pages/mobile/datafilestatus/ApproveStatus'
// import RejectStatus from './pages/mobile/datafilestatus/RejectStatus'
// import ViewErosionProfile from './pages/datamanagement/ErosionProfileWebView'
// import ProfileView from './pages/mobile/profile/ProfileView'
// import ViewDataCollection from './pages/datamanagement/DataCollectionWebView'
// import ViewCostSheet from './pages/datamanagement/costSheetView'
import BarChart from "./components/Dashboard/BarChart"
import DataManagementLayout from "./components/DataManagement/DataManagementLayout"
import CalendarView from "./components/Dashboard/CalendarView"
import DashboardLayout from "./components/Dashboard/DashboardLayout"
import DailyTrialLayout from "./components/DailyTrialForm/DailyTrialLayout"
import Settings from "./components/Settings/SettingsHome"
import CustomerModal from "./components/Login/CustomerSelection"
import ReportsLayout from "./components/Reports/ReportsLayout"
// import ReportsDataSetup from "./components/Reports/ReportsDataSetup"
import ApproveStatus from "./components/Status/ApproveStatus"
import ReportDataSetup from "./components/Reports/ReportDataSetup"
import ViewReport from "./components/Reports/ViewReport"
import Charts from "./components/Reports/Charts"
import ViewTrial from "./components/DataManagement/ViewDailyTrial"
import ViewDailyTrial from "./components/DataManagement/ViewDailyTrial"
import ViewDataCollection from "./components/DataManagement/ViewDataCollection"
import ViewCostSheet from "./components/DataManagement/ViewCostSheet"
import ViewErosionProfile from "./components/DataManagement/ViewErosionProfile"
import HelpandSupport from "./components/Common/HelpandSupport"
const currentUser = localStorage.getItem('user')
const user = JSON.parse(JSON.stringify(currentUser))
console.log('useruser', user)

const routes = {
  mainRoutes: [
    {
      path: '/Dashboard',
      name: 'Dashboard',
      component: BarChart,
    //   icon: DashboardIcon,
    //   iconSelected: DashboardColoredIcon,
    },
    {
      path: '/CalendarView',
      name: 'CalendarView',
      component: CalendarView,
    //   icon: DashboardIcon,
    //   iconSelected: DashboardColoredIcon,
    },
    {
      path: '/DashboardLayout',
      name: 'DashboardLayout',
      component: DashboardLayout,
    //   icon: DashboardIcon,
    //   iconSelected: DashboardColoredIcon,
    },
    {
      path: '/Datamanagement',
      name: 'Data Management',
      component: DataManagementLayout
      // component: isMobileOrTablet ? DataManagementMobile : DataManagement,
      // icon: DataManagementIcon,
      // iconSelected: DataManagementColoredIcon,
    },
    // {
    //   path: '/ApproveStatus',
    //   name: 'Approve Status',
    //   component: ApproveStatus
    //   // component: isMobileOrTablet ? DataManagementMobile : DataManagement,
    //   // icon: DataManagementIcon,
    //   // iconSelected: DataManagementColoredIcon,
    // },
    {
      path: '/DailyTrialLayout',
      name: 'Daily Trial Form',
      component: DailyTrialLayout
      // component: isMobileOrTablet ? DataManagementMobile : DataManagement,
      // icon: DataManagementIcon,
      // iconSelected: DataManagementColoredIcon,
    },
    {
      path: '/Settings',
      name: 'Daily Trial Form',
      component: Settings
      // component: isMobileOrTablet ? DataManagementMobile : DataManagement,
      // icon: DataManagementIcon,
      // iconSelected: DataManagementColoredIcon,
    },
    {
      path: '/CustomerSelection',
      name: 'Customer Selection',
      component: CustomerModal
      // component: isMobileOrTablet ? DataManagementMobile : DataManagement,
      // icon: DataManagementIcon,
      // iconSelected: DataManagementColoredIcon,
    },
    {
      path: '/reports',
      name: 'Data Analytics & Reports',
      component: ReportsLayout
      // component: isMobileOrTablet ? AccessTemplate : Reports,
      // icon: DataReportsIcon,
      // iconSelected: DataReportsColoredIcon,
    },
    // {
    //   path: '/settings',
    //   name: 'Settings',
    //   component: isMobileOrTablet ? AccessTemplate : Settings,
    //   icon: SettingsIcon,
    //   iconSelected: SettingsColoredIcon,
    // },
    {
      path: '/helpandSupport',
      name: 'Help & Support',
      component: HelpandSupport,
    },
    // {
    //   path: '/HorizontalDataCollectionStepper',
    //   name: 'Data collection',
    //   component: HorizontalDataCollectionStepper,
    // },
    // {
    //   path: '/costsheet',
    //   name: 'Cost Sheet',
    //   component: CostSheetTabs,
    // },
    // {
    //   path: '/dailytrialmonitoring',
    //   name: 'Daily Trial Monitoring',
    //   component: DailyTrialMonitoring,
    // },
    {
      path: '/viewtrial',
      name: 'View Trial',
      component: ViewTrial,
    },
    // {
    //   path: '/dailytrialmonitoringM',
    //   name: 'Daily Stepper',
    //   component: DailyTrialMonitoringM,
    // },
    {
      path: '/viewdailytrial',
      name: 'View Trial',
      component: ViewDailyTrial,
    },
    {
      path: '/viewdatacollection',
      name: 'View Data Collection',
      component: ViewDataCollection,
    },
    {
      path: '/viewcostsheet',
      name: 'View Cost Sheet',
      component: ViewCostSheet,
    },
    {
      path: '/viewerosionprofile',
      name: 'View Erosion Profile',
      component: ViewErosionProfile,
    },
    // {
    //   path: '/viewtrialNRM',
    //   name: 'View Trial NRM',
    //   component: ViewTrialNRM,
    // },
    // {
    //   path: '/nrmperformance',
    //   name: 'NRM Performance',
    //   component: NRMPerformance,
    // },
    // {
    //   path: '/nrmhome',
    //   name: 'NRM Performance',
    //   component: NRMPerformanceHome,
    // },
    // {
    //   path: '/erosionprofile',
    //   name: 'Erosion Profile',
    //   component: ErosionProfileStepper,
    // },
    // {
    //   path: '/dailyTrialFilter',
    //   name: 'Daily Trial Filter',
    //   component: FilterPage,
    // },
    // {
    //   path: '/draftstatus',
    //   name: 'Draft Status',
    //   component: DraftStatus,
    // },
    // {
    //   path: '/submittedstatus',
    //   name: 'Submit Status',
    //   component: SubmittedStatus,
    // },
    {
      path: '/ReportsDataSetup',
      name: 'ReportsDataSetup ',
      component: ReportDataSetup,
    },

    {
      path: '/ViewCompleteData',
      name: 'ViewCompleteData ',
      component: ViewReport,
    },
    {
      path: '/Charts',
      name: 'Charts ',
      component: Charts,
    },
    // {
    //   path: '/approvestatus',
    //   name: 'Approve Status',
    //   component: ApproveStatus,
    // },
    // {
    //   path: '/rejectstatus',
    //   name: 'Reject Status',
    //   component: RejectStatus,
    // },
    // {
    //   path: '/viewerosionprofile',
    //   name: 'View Erosion Profile',
    //   component: ViewErosionProfile,
    // },
    // {
    //   path: '/viewdatacollection',
    //   name: 'View data Collection',
    //   component: ViewDataCollection,
    // },
    // {
    //   path: '/viewcostsheet',
    //   name: 'View cost sheet',
    //   component: ViewCostSheet,
    // },
    // {
    //   path: '/profileview',
    //   name: 'Profile View',
    //   component: ProfileView,
    // },

    // {
    //   path: '/customerselection',
    //   name: 'customer Selection Page',
    //   component: CustomerModal,
    // },
  ],
}
export default routes
