import React, { useEffect, useState } from 'react'
import axios from 'axios'
import API from './../../service/API'

import EditIcon from '../../assets/images/SVGIcon/EditTrial.svg'
import DeleteIcon from '../../assets/images/SVGIcon/Delete.svg'
import SearchIcon from '../../assets/images/SVGIcon/SearchIcon.svg'
import Filter from '../../assets/images/SVGIcon/FilterIcon.svg'
import Download from '../../assets/images/SVGIcon/Download-Icon.svg'
import closeIcon from '../../assets/images/icons/Dashboard.svg'
import AlertDialog from '../Common/AlertDialog'
import ModalLayout from '../Common/ModalLayout'
const ExcelJS = require('exceljs')


const Users = () => {
  const [roles, setRoles] = useState([])
  const [customers, setCustomers] = useState([])
  const [modalTitle, setModalTitle] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [region, setRegion] = useState([])
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    sgid: '',
    role_id: '',
    region_id: '',
    designation: '',
    email: '',
    contact_number: '',
    assign_customer_id: '',
  })
  const [users, setUsers] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredUsers, setFilteredUsers] = useState([])
  const [formErrors, setFormErrors] = useState({}) // Initialize formErrors state
  const [loading, setLoading] = useState(false) // Loader state
  const [error, setError] = useState(null) // Error state
  const access_token = localStorage.getItem('accesstoken')
  const [selectedUserIndex, setSelectedUserIndex] = useState(null)

  useEffect(() => {
    fetchCustomers()
    fetchRoles()
    fetchData()
    fetchRegion()
  }, [])

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (searchQuery.trim()) {
      const filtered = users.filter((user) =>
        `${user.firstname} ${user.lastname}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.sgid.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(users);
    }
  }, [searchQuery, users]);


  const fetchRoles = async () => {
    try {
      const response = await axios.get(API.ROLE, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('Roles response', response.data)
      setRoles(response.data)
    } catch (error) {
      console.error('Error fetching roles:', error)
    }
  }


  const fetchRegion = async () => {
    try {
      const response = await axios.get(API.REGION, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('regionnn', response.data)
      setRegion(response.data)
    } catch (error) {
      console.error('Error fetching region:', error)
    }
  }

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(API.GET_CUSTOMER, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }) // Adjust the URL as needed
      console.log('responseeee', response.data)
      setCustomers(response.data) // Update state with fetched data
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const fetchData = async () => {
    setError(null);
    try {
      setLoading(true);
      const response = await axios.get(API.GET_USERS, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      const validUsers = response.data.filter((user) => user.sgid !== null && user.sgid.trim() !== '');
      setUsers(validUsers);
      setFilteredUsers(validUsers); // Initialize filteredUsers with full list
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleEdit = (index) => {
    const selectedUser = filteredUsers.length===0?users[index]:filteredUsers[index]
    setSelectedUserIndex(index); // Set the selected user index
    setModalTitle("Edit User"); // Set the modal title to "Edit User"
  
    // Set form data with selected user details
    setFormData({
      firstname: selectedUser.firstname,
      lastname: selectedUser.lastname,
      sgid: selectedUser.sgid,
      role_id: selectedUser.role_id,
      region_id: selectedUser.region_id,
      designation: selectedUser.designation,
      email: selectedUser.email,
      contact_number: selectedUser.contact_number,
      assign_customer_id: selectedUser.assign_customer_id,
      id:selectedUser.id
    });
  
    setIsDialogOpen(true); // Open the modal
    console.log("selectedUser",selectedUser)
  };

  const handleDelete = async (index) => {
    setSelectedUserIndex(index)
    const selectedUser =  filteredUsers.length===0?users[index]:filteredUsers[index]
    const id = selectedUser.id
    AlertDialog({
      type: 'delete',
      title: 'Delete?',
      text: 'Are you sure you want to delete?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        const response = await axios.delete(`${API.DELETE_USER}/${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        if (response) {
          fetchData()
        }
      },
    })
  }

  const getRoleName = (role_id) => {
    const role = roles.find((role) => role.id === role_id)
    return role ? role.name : '' // Return role name if found, otherwise empty string
  }

  function getRoleIdById(data, id) {
    const item = data.find((obj) => obj.id === id)
    return item ? item.role_id : null
  }
  const roleId = getRoleIdById(roles, formData.role_id)


  // region

  const getRegionName = (region_id) => {
    console.log(region_id, 'regcheck1')
    const regionName = region.find((region) => region.id === region_id)
    return regionName ? regionName.region_name : ''
  }

  function getRegionIdById(data, id) {
    const item = data.find((obj) => obj.id === id)
    return item ? item.region_id : null
  }
  const regionId = getRegionIdById(region, formData.region_id)

  const getAssignCustomer = (assign_customer_id) => {
    // Log the input
    console.log('assign_customer_id', assign_customer_id)

    // Handle single ID case by converting to array
    if (typeof assign_customer_id === 'string') {
      assign_customer_id = [assign_customer_id]
    }

    // Convert Set to Array if necessary
    if (assign_customer_id instanceof Set) {
      assign_customer_id = Array.from(assign_customer_id)
    }

    // Ensure it's an array now
    if (!Array.isArray(assign_customer_id) || assign_customer_id.length === 0) {
      return null
    }

    console.log('customerscustomers', customers)
    // Get organization names
    const organizationNames = assign_customer_id
      .map((id) => {
        const organization = customers.find((org) => org.id === id)
        return organization ? organization.organization_name : null
      })
      .filter((name) => name !== null)

    // Log the result
    console.log('organizationNames', organizationNames)

    // Return the joined names or null
    return organizationNames.length > 0 ? organizationNames.join(', ') : null
  }
  const handleAdduser = () => {
    setIsDialogOpen(true);
    setModalTitle('Add User')
  };

  const closeModal = () => {
    setIsDialogOpen(false);
  };

  const closeModalOnBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };


  
  return (
    <>
      <div className='pt-3 flex justify-between bg-white border-b pb-2 pl-2'>
        <p className='text-xs text-gray-800 font-medium p-2 mb-0'>User Management</p>
        <div className='flex items-center'>
          <div className="flex items-center">
            <label htmlFor="topbar-search" className="sr-only">Search Transactions</label>
            <div className="relative w-full">
              <div
                className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
              >
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  ></path>
                </svg>
              </div>
              <input
                name="search"
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                id="topbar-search"
                className="bg-white w-full text-gray-900  border border-gray-300 rounded-md text-sm focus:outline-none pl-10 p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search Data"
              />
            </div>
          </div>

          
          <span className='border-r h-8 mx-3'></span>
          <button data-modal-target="static-modal" onClick={handleAdduser} data-modal-toggle="static-modal" type="button" class="mr-4 text-white whitespace-nowrap bg-gradient-to-br from-custom-red to-custom-orange hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-regular rounded-md text-sm pl-5 pr-10 xl:pr-5 py-2 ml-3 text-center flex">
            Add User
          </button>
        </div>
      </div>
      <div className='w-full'>
        <div className='overflow-x-auto'>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead>
              <tr>
                <th
                  className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400"
                >
                  <div className="flex items-center">
                    S.No
                  </div>
                </th>
                <th
                  className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400"
                >
                  <div className="flex items-center">
                    Name
                  </div>
                </th>
                <th
                  className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400"
                >
                  <div className="flex items-center">
                    SGID
                  </div>
                </th>
                <th
                  className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400"
                >
                  <div className="flex items-center">
                    Assign Customer
                  </div>
                </th>
                <th
                  className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400"
                >
                  <div className="flex items-center">
                    Role
                  </div>
                </th>
                <th
                  className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400"
                >
                  <div className="flex items-center">
                    Region
                  </div>
                </th>
                <th
                  className="p-4 text-sm text-gray-500 font-normal uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400"
                >
                  <div className="flex items-center">
                    Actions
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user, index) => (
                <tr key={user.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">
                    {index + 1}
                  </td>
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">
                    {user.firstname} {user.lastname}
                  </td>
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">
                    {user.sgid}
                  </td>
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">
                    {getAssignCustomer(user.assign_customer_id)}
                  </td>
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">
                    {getRoleName(user.role_id)}
                  </td>
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">
                    {getRegionName(user.region_id)}
                  </td>
                  <td className="px-4 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white">
                    <div className="flex">
                      <button
                        type="button"
                        className="flex mr-3 text-sm dark:focus:ring-gray-600 flex items-center"
                        onClick={() => handleEdit(index)}
                      >
                        <img className="w-5 h-5" src={EditIcon} alt="Edit" />
                      </button>
                      <button
                        type="button"
                        className="flex text-sm dark:focus:ring-gray-600 flex items-center"
                        onClick={() => handleDelete(index)}
                      >
                        <img className="w-5 h-5" src={DeleteIcon} alt="Delete" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {isDialogOpen && (
        <ModalLayout
        closeModalOnBackdropClick={closeModalOnBackdropClick}
        closeModal={closeModal}
        modalTitle={modalTitle}
        formData={formData}
      >
      </ModalLayout>
      )}
    </>
  )
}

export default Users
