import React, { useState, useEffect, useRef } from "react";

const MultiSelect = ({
  options = [], // List of options {value: string, label: string}
  selectedOptions = [], // Pre-selected values
  onSelectionChange, // Callback to handle selection updates
  placeholder = "Select options", // Placeholder text
  className = "", // Additional class names
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference to the dropdown container

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleOptionToggle = (option) => {
    if (selectedOptions.includes(option.value)) {
      onSelectionChange(selectedOptions.filter((value) => value !== option.value));
    } else {
      onSelectionChange([...selectedOptions, option.value]);
    }
  };

  const isSelected = (option) => selectedOptions.includes(option.value);

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      {/* Dropdown Button */}
      <button
        onClick={toggleDropdown}
        className="w-full px-4 py-2 text-sm text-left bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
      >
        {selectedOptions.length > 0
          ? options
              .filter((opt) => selectedOptions.includes(opt.value))
              .map((opt) => opt.label)
              .join(", ")
          : placeholder}
      </button>

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-64 overflow-auto">
          {options.map((option) => (
            <div
              key={option.value}
              className={`flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                isSelected(option) ? "bg-blue-100" : ""
              }`}
              onClick={() => handleOptionToggle(option)}
            >
              <input
                type="checkbox"
                checked={isSelected(option)}
                onChange={() => handleOptionToggle(option)}
                className="mr-2"
              />
              <span>{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
