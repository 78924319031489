import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";
import API from "./../../service/API";
import DashboardLayout from "./DashboardLayout";

const localizer = momentLocalizer(moment);

const CalendarView = ({ selectedRegion, selectedDates }) => {
  const [events, setEvents] = useState([]);
  const [regions, setRegions] = useState([]); // To store region options
  const [region, setRegion] = useState(""); // Selected region
  const [customerName, setCustomerName] = useState(""); // Selected customer name
  const [year, setYear] = useState(""); // Selected year
  const access_token = localStorage.getItem("accesstoken");
  const storedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));
  const currentUser = JSON.parse(localStorage.getItem("user"));

  // Fetch regions from the database
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await axios.get(API.GET_ALL_REGIONS, {
          headers: { Authorization: `Bearer ${access_token}` },
        });
        setRegions(response.data); // Assuming API response is an array of regions
      } catch (error) {
        console.error("Error fetching regions:", error);
      }
    };

    fetchRegions();
  }, []);

  const fetchData = async () => {
    try {
      // Prepare parameters for the API calls
      const params = {
        customerId: storedCustomer?.id || null,
        regionId: selectedRegion || null,
        startDate: selectedDates?.startDate || null,
        endDate: selectedDates?.endDate || null,
        // userId: currentUser?.id || null, // Uncomment if required
      };

      console.log(params, "API Request Parameters");

      // Fetch data from multiple APIs
      const [
        dailyTrialResponse,
        nrmPerformanceResponse,
        erosionProfileResponse,
        dataCollectionResponse,
        costSheetResponse,
      ] = await Promise.all([
        axios.get(API.GET_DTM_MONTHLY_CALENDAR_DATA, {
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }),
        axios.get(API.GET_NRM_MONTHLY_CALENDAR_DATA, {
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }),
        axios.get(API.GET_EROSION_MONTHLY_CALENDAR_DATA, {
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }),
        axios.get(API.GET_DATACOLLECTION_MONTHLY_CALENDAR_DATA, {
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }),
        axios.get(API.GET_COSTSHEET_MONTHLY_CALENDAR_DATA, {
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }),
      ]);

      // Extract data from responses
      const dailyTrialData = dailyTrialResponse.data || [];
      const nrmPerformanceData = nrmPerformanceResponse.data || [];
      const erosionProfileData = erosionProfileResponse.data || [];
      const dataCollectionData = dataCollectionResponse.data || [];
      const costSheetData = costSheetResponse.data || [];
      console.log(dailyTrialData, dailyTrialData)
      const eventsMap = new Map();

      // Function to add events to the map
      const addEvent = (date, title, category) => {
        if (!eventsMap.has(date)) {
          eventsMap.set(date, new Set());
        }
        eventsMap.get(date).add({ title, category });
      };

      // Map through each dataset and add events
      dailyTrialData.forEach((item) => {
        const eventDate = moment(item.created_at).format("YYYY-MM-DD");
        addEvent(eventDate, "Daily Trial Monitoring", "Daily Trial Monitoring");
      });

      nrmPerformanceData.forEach((item) => {
        const eventDate = moment(item.created_at).format("YYYY-MM-DD");
        addEvent(eventDate, "NRM Performance", "NRM Performance");
      });

      erosionProfileData.forEach((item) => {
        const eventDate = moment(item.created_at).format("YYYY-MM-DD");
        addEvent(eventDate, "Erosion Profile", "Erosion Profile");
      });

      dataCollectionData.forEach((item) => {
        const eventDate = moment(item.created_at).format("YYYY-MM-DD");
        addEvent(eventDate, "Data Collection", "Data Collection");
      });

      costSheetData.forEach((item) => {
        const eventDate = moment(item.created_at).format("YYYY-MM-DD");
        addEvent(eventDate, "Cost Sheet", "Cost Sheet");
      });

      // Transform events into the required format
      const transformedEvents = [];
      eventsMap.forEach((categories, date) => {
        categories.forEach(({ title, category }) => {
          const eventDate = moment(date).toDate();
          transformedEvents.push({
            title,
            start: eventDate,
            end: eventDate,
            category,
          });
        });
      });

      // Update state with the transformed events
      setEvents(transformedEvents);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    fetchData();
  }, [region, customerName, year]);

  const handleDownload = () => {
    const data = JSON.stringify(events, null, 2);
    const blob = new Blob([data], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "events.json";
    a.click();
    URL.revokeObjectURL(url);
  };

  const CustomToolbar = ({ label, onNavigate }) => (
    <div className="flex items-center justify-between p-4 bg-gray-100 border border-b-0">
      <button
        onClick={() => onNavigate("PREV")}
        className="text-md font-medium text-gray-600"
      >
        &#8592; Prev
      </button>
      <span className="text-lg font-semibold">{label}</span>
      <button
        onClick={() => onNavigate("NEXT")}
        className="text-md font-medium text-gray-600"
      >
        Next &#8594;
      </button>
    </div>
  );

  const eventPropGetter = (event) => {
    let backgroundColor;
    switch (event.category) {
      case "Daily Trial Monitoring":
        backgroundColor = "#00A81C";
        break;
      case "NRM Performance":
        backgroundColor = "#9747FF";
        break;
      case "Data Collection":
        backgroundColor = "#9ca3af";
        break;
      case "Erosion Profile":
        backgroundColor = "#F25C19";
        break;
      default:
        backgroundColor = "#ef4444";
    }
    return { style: { backgroundColor, color: "white" } };
  };

  const EventComponent = ({ event }) => <span>{event.title}</span>;

  return (
    <div className="w-full mt-4">
      <div className="relative">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 600 }}
          className="rounded-lg shadow-lg"
          eventPropGetter={eventPropGetter}
          components={{
            toolbar: CustomToolbar,
            event: EventComponent,
          }}
        />

      </div>
    </div>
  );

};

export default CalendarView;
