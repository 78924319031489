import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { RecoilRoot } from "recoil";
import rightArrow from "./../../assets/images/icons/RightArrow.svg";
import GeneralDetailsWeb from "./GeneralDetailsWeb";
import ProductDetailsWeb from "./ProductDetailsWeb";
import FurnaceDetailsWeb from "./FurnaceDetailsWeb";
import MildSteelCustomersWeb from "./MildSteelCustomersWeb";
import RawMaterialWeb from "./RawMaterialWeb";
import FurnaceDimensionWeb from "./FurnaceDimesnsionWeb";
import LiningDetailsWeb from "./LiningDeatilsWeb";
import AlloyingAdditionWeb from "./AlloyingAdditionWeb";
import ApproveStatus from "../Status/ApproveStatus";
import DraftStatus from "../Status/DraftStatus";
import SubmittedStatus from "../Status/SubmittedStatus";
import AlertDialog from "../Common/AlertDialog";
import axios from "axios";
import API from "../../service/API";
import { useNavigate } from "react-router-dom";
import {
  trial_name,
  general_Details,
  furnace_dimension,
  raw_materials_energy_details,
  mild_steel_customer,
  furnace_Details_data_collection,
  Product_Details,
  lining_details,
  alloy_and_steel,
} from '../../recoil/atom';
// import { responsiveFontSizes } from "@mui/material";

const DataCollectionLayout = ({ closeModal }) => {
  console.log("data collect working")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [activeTab, setActiveTab] = React.useState("generalDetails");
  const [currentStep, setCurrentStep] = useState(1);
  const [generalDetails, setGeneralDetails] = useRecoilState(general_Details)
  const [productDetails, setProductDetails] = useRecoilState(Product_Details)
  const [furnaceDetails, setFurnaceDetails] = useRecoilState(
    furnace_Details_data_collection
  )
  const navigate = useNavigate();
  const [mildSteelCustomer, setMildSteelCustomer] = useRecoilState(mild_steel_customer)
  const [rawAndEnergyDetails, setRawAndEnergyDetails] = useRecoilState(
    raw_materials_energy_details
  )
  const [furnaceDimension, setFurnaceDimension] =
    useRecoilState(furnace_dimension)
  const [liningDetails, setLiningDetails] = useRecoilState(lining_details)
  const [alloyAndSteel, setAlloyAndSteel] = useRecoilState(alloy_and_steel)
  const [comments, setComments] = useState('')
  const [trialName] = useRecoilState(trial_name)
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const access_token = localStorage.getItem("accesstoken");
  const [statusDraft, setStatusDraft] = useState(false);
  const [statusSubmitted, setStatusSubmitted] = useState(false);
  const [statusApproved, setStatusApproved] = useState(false);
  const steps = [
    { id: 1, component: <GeneralDetailsWeb /> },
    { id: 2, component: <ProductDetailsWeb /> },
    { id: 3, component: <FurnaceDetailsWeb /> },
    { id: 4, component: <MildSteelCustomersWeb /> },
    { id: 5, component: <RawMaterialWeb /> },
    { id: 6, component: <FurnaceDimensionWeb /> },
    { id: 7, component: <LiningDetailsWeb /> },
    { id: 8, component: <AlloyingAdditionWeb /> },
  ];

  useEffect(() => {
    if (
      trialName === 'DataCollectionedit' ||
      trialName === 'viewDataCollection'
    ) {
      console.log('datahi')
      const fetchData = async () => {
        try {
          const id = localStorage.getItem('dataCollectionID')
          console.log(id, '123445')
          const response = await axios.get(
            `${API.GET_DATA_COLLECTION_BY_ID}/${id}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          console.log(response.data, 'dataCollectionID')
          const dataCollectionData = response.data
          console.log(dataCollectionData, 'dataCollectionData')
          console.log(
            dataCollectionData.general_details,
            'dataCollectionData.general_details[0]'
          )

          if (dataCollectionData && Object.keys(dataCollectionData)) {
            setGeneralDetails(dataCollectionData.general_details)
            setProductDetails(dataCollectionData.product_details)
            setFurnaceDetails(dataCollectionData.furnace_details)
            setMildSteelCustomer(dataCollectionData.mild_steel_customers_only)
            setRawAndEnergyDetails(
              dataCollectionData.raw_material_energy_details
            )
            setFurnaceDimension(dataCollectionData.furnace_dimension)
            setLiningDetails(dataCollectionData.lining_details)
            setAlloyAndSteel(dataCollectionData.alloy_stainless_steel_customers)
          }
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }

      fetchData()
    }
  }, [trialName])

  const handleDraft = async () => {
    if (trialName === 'DataCollectionedit') {
      try {
        const id = localStorage.getItem('dataCollectionID')

        const data = {
          id: id,
          submission_status: 'draft',
          updated_data: {
            general_details: generalDetails,
            product_details: productDetails,
            furnace_details: furnaceDetails,
            mild_steel_customers_only: mildSteelCustomer,
            raw_material_energy_details: rawAndEnergyDetails,
            furnace_dimension: furnaceDimension,
            lining_details: liningDetails,
            alloy_stainless_steel_customers: alloyAndSteel,
          },
        }

        console.log(data, 'update data')
        const response = await axios.put(API.UPDATE_DATA_COLLECTION, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        console.log('Data updated successfully:', response.data)
        if (data.submission_status === "submitted") {
          setStatusSubmitted(true);
          setStatusDraft(false);
          setStatusApproved(false);
        } else if (data.submission_status === "approved") {
          setStatusApproved(true);
          setStatusSubmitted(false);
          setStatusDraft(false);
        } else if (data.submission_status === "draft") {
          setStatusDraft(true);
          setStatusApproved(false);
          setStatusSubmitted(false);
        }
      } catch (error) {
        // Handle errors
        console.error('An error occurred while updating data:', error)
      }
    } else {
      try {
        const uploadId = localStorage.getItem('datafile_id')
        console.log('uploadIduploadId', uploadId)
        const data = {
          datafile_id: uploadId,
          submission_status: 'draft',
          data: {
            general_details: generalDetails,
            product_details: productDetails,
            furnace_details: furnaceDetails,
            mild_steel_customers_only: mildSteelCustomer,
            raw_material_energy_details: rawAndEnergyDetails,
            furnace_dimension: furnaceDimension,
            lining_details: liningDetails,
            alloy_stainless_steel_customers: alloyAndSteel,
          },
        }

        console.log(data, 'data123')
        // Make the POST request using axios
        const response = await axios.post(API.INSERT_DATA_COLLECTION, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })

        console.log(response)

        if (data.submission_status === "submitted") {
          setStatusSubmitted(true);
          setStatusDraft(false);
          setStatusApproved(false);
        } else if (data.submission_status === "approved") {
          setStatusApproved(true);
          setStatusSubmitted(false);
          setStatusDraft(false);
        } else if (data.submission_status === "draft") {
          setStatusDraft(true);
          setStatusApproved(false);
          setStatusSubmitted(false);
        }
      } catch (error) {
        console.error('An error occurred while inserting data:', error)
      }
    }
  }

  const handleApprove = async () => {
    try {
      const id = localStorage.getItem('dataCollectionID')

      const data = {
        id: id,
        submission_status: 'approved',
        updated_data: {
          general_details: generalDetails,
          product_details: productDetails,
          furnace_details: furnaceDetails,
          mild_steel_customers_only: mildSteelCustomer,
          raw_material_energy_details: rawAndEnergyDetails,
          furnace_dimension: furnaceDimension,
          lining_details: liningDetails,
          alloy_stainless_steel_customers: alloyAndSteel,
        },
      }

      console.log(data, 'update data')
      const response = await axios.put(API.UPDATE_DATA_COLLECTION, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('Data updated successfully:', response.data)

      // Navigate after successful update
      navigate('/approvestatus')
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }
  const handleReject = async () => {
    if (comments.trim() === '') {
      // setSnackbarOpen(true)
      return
    }

    try {
      const id = localStorage.getItem('dataCollectionID')

      const data = {
        id: id,
        submission_status: 'rejected',
        comments: comments,
        updated_data: {
          general_details: generalDetails,
          product_details: productDetails,
          furnace_details: furnaceDetails,
          mild_steel_customers_only: mildSteelCustomer,
          raw_material_energy_details: rawAndEnergyDetails,
          furnace_dimension: furnaceDimension,
          lining_details: liningDetails,
          alloy_stainless_steel_customers: alloyAndSteel,
        },
      }

      console.log(data, 'update data')
      const response = await axios.put(API.UPDATE_DATA_COLLECTION, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('Data updated successfully:', response.data)

      // Navigate after successful update
      navigate('/rejectstatus')
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }


  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
      setActiveTab(steps[currentStep - 2]?.id); // Set activeTab based on the previous step
    }
  };

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep((prev) => prev + 1);
      setActiveTab(steps[currentStep]?.id); // Set activeTab based on the next step
    }
    if (activeTab === 8) {
      submitFormData();
    }
  };

  const handleOpenUploadModal = () => {
    setModalTitle("Upload New DataFile");
    setIsModalOpen(true);
  };

  const handleOpenAddFormModal = () => {
    setModalTitle("Add Form");
    setIsModalOpen(true);
  };



  const closeModalOnBackdropClick = (e) => {
    if (e.target.id === "static-modal") {
      setIsModalOpen(false);
    }
  };

  const handleTabClick = (tab, step) => {
    setActiveTab(tab);
    setCurrentStep(step);
  };


  const submitFormData = async () => {
    try {
      AlertDialog({
        type: 'delete',
        title: 'Submission',
        text: 'Are you sure you want to Submit for an Approval?',
        confirmButtonText: 'Yes',
        onConfirm: async () => {
          await insertData()
        },
      })

    } catch (error) {
      console.error('An error occurred while inserting data:', error)
    }
  }

  const insertData = async () => {
    const uploadId = localStorage.getItem('datafile_id')
    let submissionStatus = ''
    if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
      submissionStatus = 'approved'
    } else {
      submissionStatus = 'submitted'
    }
    console.log('uploadIduploadId', uploadId)
    const data = {
      datafile_id: uploadId,
      submission_status: submissionStatus,
      data: {
        general_details: generalDetails,
        product_details: productDetails,
        furnace_details: furnaceDetails,
        mild_steel_customers_only: mildSteelCustomer,
        raw_material_energy_details: rawAndEnergyDetails,
        furnace_dimension: furnaceDimension,
        lining_details: liningDetails,
        alloy_stainless_steel_customers: alloyAndSteel,
      },
    }

    console.log(data, 'data123')

    // Make the POST request using axios
    const response = await axios.post(API.INSERT_DATA_COLLECTION, data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })

    // Handle the response if needed
    console.log('Data inserted successfully', response.data)

    if (data.submission_status === "submitted") {
      setStatusSubmitted(true);
      setStatusDraft(false);
      setStatusApproved(false);
    } else if (data.submission_status === "approved") {
      setStatusApproved(true);
      setStatusSubmitted(false);
      setStatusDraft(false);
    } else if (data.submission_status === "draft") {
      setStatusDraft(true);
      setStatusApproved(false);
      setStatusSubmitted(false);
    }
  }

  const updateFormData = async () => {
    try {
      AlertDialog({
        type: 'delete',
        title: 'Submission',
        text: 'Are you sure you want to Update for an Approval?',
        confirmButtonText: 'Yes',
        onConfirm: async () => {
          await updateData()
        },
      })
    } catch (error) {
      // Handle errors
      console.error('An error occurred while inserting data:', error)
    }
  }

  const updateData = async () => {
    try {
      const id = localStorage.getItem('dataCollectionID')

      const data = {
        id: id,
        submission_status: 'submitted',
        updated_data: {
          general_details: generalDetails,
          product_details: productDetails,
          furnace_details: furnaceDetails,
          mild_steel_customers_only: mildSteelCustomer,
          raw_material_energy_details: rawAndEnergyDetails,
          furnace_dimension: furnaceDimension,
          lining_details: liningDetails,
          alloy_stainless_steel_customers: alloyAndSteel,
        },
      }

      console.log(data, 'update data')
      const response = await axios.put(API.UPDATE_DATA_COLLECTION, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('Data updated successfully:', response.data)

      // Navigate after successful update
      navigate('/submittedstatus')
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }
  useEffect(() => {
    if (
      trialName === 'DataCollectionedit' ||
      trialName === 'viewDataCollection'
    ) {
      const fetchData = async () => {
        try {
          const id = localStorage.getItem('dataCollectionID')
          console.log(id, '123445')
          const response = await axios.get(
            `${API.GET_DATA_COLLECTION_BY_ID}/${id}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          console.log(response.data, 'dataCollectionID')
          const dataCollectionData = response.data
          console.log(dataCollectionData, 'dataCollectionData')
          console.log(
            dataCollectionData.general_details,
            'dataCollectionData.general_details[0]'
          )

          if (dataCollectionData && Object.keys(dataCollectionData)) {
            setGeneralDetails(dataCollectionData.general_details)
            setProductDetails(dataCollectionData.product_details)
            setFurnaceDetails(dataCollectionData.furnace_details)
            setMildSteelCustomer(dataCollectionData.mild_steel_customers_only)
            setRawAndEnergyDetails(
              dataCollectionData.raw_material_energy_details
            )
            setFurnaceDimension(dataCollectionData.furnace_dimension)
            setLiningDetails(dataCollectionData.lining_details)
            setAlloyAndSteel(dataCollectionData.alloy_stainless_steel_customers)
          }
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }

      fetchData()
    }
  }, [trialName])


  return (
    <>
      {statusApproved && <ApproveStatus closeModal={closeModal} />}
      {statusDraft && <DraftStatus closeModal={closeModal} />}
      {statusSubmitted && <SubmittedStatus closeModal={closeModal} />}
      {!statusApproved && !statusDraft && !statusSubmitted && (

        <div className="w-full">
          <div className="flex border-b border-gray-200 dark:border-gray-700 pr-4">
            <ul className="flex -mb-px text-sm font-medium text-center overflow-auto py-2 track-form-list">
              {steps.map((step, index) => (
                <li key={step.id} className="me-2" role="presentation">
                  <div className="flex items-center">
                    <button
                      onClick={() => handleTabClick(step.id, index + 1)}
                      className={`inline-block p-1 whitespace-nowrap font-normal rounded-t-lg ${activeTab === step.id ? "text-custom-orange" : "text-gray-500"
                        }`}
                      type="button"
                      role="tab"
                      aria-controls={step.id}
                      aria-selected={activeTab === step.id}
                    >
                      <span className="flex items-center justify-center">
                        <span
                          className={`flex items-center justify-center w-5 h-5 me-2 text-xs bg-gray-100 border rounded-full shrink-0 dark:border-blue-500 ${activeTab === step.id
                            ? "border-custom-orange text-custom-orange bg-custom-orange-light"
                            : "border-gray-300"
                            }`}
                        >
                          {index + 1}
                        </span>
                        {step.id === 1
                          ? "General"
                          : step.id === 2
                            ? "Product Details"
                            : step.id === 3
                              ? "Furnace Details"
                              : step.id === 4
                                ? "Mild Steel Customers"
                                : step.id === 5
                                  ? "Raw Material & Energy"
                                  : step.id === 6
                                    ? "Furnace Dimension"
                                    : step.id === 7
                                      ? "Lining Details"
                                      : "Alloy and Stainless Steel"}
                      </span>
                    </button>
                    <img src={rightArrow} alt="arrow" />
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {/* Content Section */}
          <div
            style={{ height: "365px" }}
            className="mt-4 w-full overflow-y-auto pb-4 pr-4"
          >
            {steps[currentStep - 1]?.component}
          </div>

          {/* Navigation Buttons */}
          <div className="flex space-x-4 justify-center bg-white w-full py-4 pr-4">
            <button
              onClick={handlePrevious}
              className="text-grey bg-white border border-gray-500 w-1/2 px-4 py-2 rounded-lg disabled:opacity-50"
              disabled={currentStep === 1}
            >
              Previous
            </button>
            <button
              onClick={handleDraft}
              className="text-grey bg-white border border-gray-500 w-1/2 px-4 py-2 rounded-lg disabled:opacity-50"
            >
              Save Draft
            </button>
            <button
              onClick={handleNext}
              className="text-white bg-custom-red w-1/2 px-4 py-2 rounded-lg disabled:opacity-50"
              disabled={activeStep === 6 && !submitFormData}
            >
              {currentStep === 8 ? "Submit" : "Next"}{" "}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default DataCollectionLayout;
